import { useEffect, useState } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import ModelElementUtil from "../../util/modelElementUtil";
import DataUtil from "../../../../../../common/dataUtil";
import ModelEditDialog from "../../modelEditDialog";
import { TabbedPane } from "../../../../../../common/component/tab/tabbedPane";
import styled from "styled-components";

namespace NodeStlarg {

    export type Data = {
        id: string;
        defVal?: string;
    }

    type LocalState = {
        id: FormUtil.CheckableValue;
        defVal: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {

        const [localState, setLocalState] = useState<LocalState>({
            id: { value: '', errors: [] },
            defVal: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.id, localState.defVal];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.id.value = data.id;
                localState.defVal.value = data.defVal ?? '';
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            props.temp.inputOK = true;

            const data: Data = {
                id: localState.id.value,
                defVal: DataUtil.blankToUndefined(localState.defVal.value),
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>

                    <FormUtil.FormRecord
                        titleLabel="Id"
                        jsx={<FormUtil.TextField
                            width={200}
                            checkable={localState.id}
                            setCheckable={(checkable) => {
                                localState.id = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 16),
                                    errorType: "length"
                                },
                                {
                                    checker: (value) => ModelUtil.checkCamelCase(value),
                                    errorType: "value"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Default"
                        jsx={<FormUtil.TextField
                            width={300}
                            checkable={localState.defVal}
                            setCheckable={(checkable) => {
                                localState.defVal = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                // {
                                //     checker: (value) => ModelUtil.checkIdChars(value),
                                //     errorType: "value"
                                // }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'desarg';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeStlarg;
