import { useEffect, useState, useContext, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import ModelEditDialog from "../../modelEditDialog";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelElementUtil from "../../util/modelElementUtil";

namespace SpanEditor {

    type LocalState = {
        text: FormUtil.CheckableValue;
        fontSize: FormUtil.CheckableValue;
        fontColor: FormUtil.CheckableValue;
        useBg: boolean;
        bgColor: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            text: { value: '', errors: [] },
            fontSize: { value: '', errors: [] },
            fontColor: { value: '', errors: [] },
            useBg: false,
            bgColor: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({data, inputOK: true});

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [models, states, args, caches] = useMemo(() => {
            const appRootwrap = ModelElementUtil.getAppRootWrap(manageItems.focusNode);

            const parentWrap = (manageItems.focusNode.parent?.parent?.data) as ModelUtil.WrapElement;
            const isDispatch = parentWrap.type === 'func'

            // 自身がDispach配下のキャッシュである場合のみ取得する
            const states = !isDispatch ? undefined : ModelElementUtil.getStatesFromApp(appRootwrap);
            const args = !isDispatch ? undefined : ModelElementUtil.getArgumentFromCurrent(manageItems.focusNode);

            const caches = ModelElementUtil.getRetentionCachesFromCurrent(manageItems.focusNode);
            const models = ModelElementUtil.getReferableDtypes(manageItems.focusNode);

            return [models, states, args, caches];
        }, []);

        const targetFroms = [localState.text, localState.fontSize, localState.fontColor, localState.bgColor];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as ModelUtil.NodeSpan;
                localState.text.value = data.text;
                localState.fontSize.value = data.fontSize.toString();
                localState.fontColor.value = data.fontColor;
                if(data.bgColor != undefined) {
                    localState.useBg = true;
                    localState.bgColor.value = data.bgColor;
                }
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const data: ModelUtil.NodeSpan = {
                text: localState.text.value,
                fontSize: Number(localState.fontSize.value),
                fontColor: localState.fontColor.value,
                bgColor: DataUtil.blankToUndefined(localState.bgColor.value),
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Text"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.text}
                            setCheckable={(checkable) => {
                                localState.text = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 64),
                                    errorType: "length"
                                },
                                // {
                                //     checker: (value) => !existNameList.includes(value),
                                //     errorType: "relation"
                                // }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Font Size"
                        jsx={<FormUtil.TextField
                            width={80}
                            isNumber
                            checkable={localState.fontSize}
                            setCheckable={(checkable) => {
                                localState.fontSize = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, 8, 80),
                                    errorType: "length"
                                },
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Font Color"
                        jsx={<>
                            <FormUtil.ColorField
                                checkable={localState.fontColor}
                                setCheckable={(checkable) => {
                                    localState.fontColor = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />
                        </>}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Bg Color"
                        jsx={<>
                            <FormUtil.SwitchTwoFace
                                label1="UNUSE"
                                label2="USE"
                                width={180}
                                rate1={60}
                                rate2={40}
                                isUse={localState.useBg}
                                callback={() => {
                                    localState.useBg = !localState.useBg;
                                    if (!localState.useBg) {
                                        localState.bgColor.value = '';
                                    }
                                    invalidate();
                                }}
                            />
                            <FormUtil.ColorField
                                marginLeft={4}
                                checkable={localState.bgColor}
                                setCheckable={(checkable) => {
                                    localState.bgColor = checkable;
                                    invalidate();
                                }}
                                isEnabled={localState.useBg}
                                validates={!localState.useBg ? [] : [
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />
                        </>}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'span';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default SpanEditor;
