import { useEffect, useState, useMemo, useContext } from "react";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import ModelEditDialog from "../../modelEditDialog";
import { GlobalContext } from "../../../../entry/systemEntry";
import assert from "assert";
import ScopeManager from "../../option/scopeManager";
import styled from "styled-components";
import NodeStlarg from "./nodeStlarg";
import StylePropDefiner from "./style/stylePropDefiner";
import NodeStyle from "./nodeStyle";

namespace StyleChooser {

    export const ArgOptions = ['abstract', 'defalut', 'edit'] as const;
    export type ArgOption = typeof ArgOptions[number];

    export type ReferArg = {
        key: string;
        val?: string;
    }
    export type Refer = {
        refId: string;
        accept?: string;
        args: ReferArg[];
    }
    export type Data = {
        inherits: Refer[];
    }

    export type ReferState = {
        isUseCondition: boolean;
        accept: FormUtil.CheckableValue;
        refId: FormUtil.CheckableValue;
        args: ReferArgState[];
    };
    export type ReferArgState = {
        argId: string;
        opt: FormUtil.CheckableValue;
        val: FormUtil.CheckableValue;
    }
    type LocalState = {
        refers: ReferState[];
    }

    type Phase = 'inherit' | 'tag';
    export type Props = {
        phase: Phase;
        styleItems: ScopeManager.StyleItem[];
    }

    const getInitialSet = (phase: Phase, arg: NodeStlarg.Data): [ArgOption, string] => {
        switch (phase) {
            case 'inherit': return ['abstract', ''];
            case 'tag': {
                if (arg.defVal != undefined) return ['defalut', arg.defVal];
                else return ['edit', ''];
            }
        }
    }

    export const getMappedStyleStates = (referDatas: Refer[], props: Props) => {
        return referDatas.map(refer => {
            const style = props.styleItems.find(d => d.id === refer.refId);
            assert(style != undefined, 'style is undefined.');
            const args: ReferArgState[] = style.args
                .map((arg) => {
                    const argData = refer.args.find(a => a.key === arg.id);
                    let [initialOpt, initialVal] = getInitialSet(props.phase, arg);
                    if (argData != undefined) {
                        const getDefaultValue = () => {
                            assert(arg.defVal != undefined, 'arg.defVal is undefined.');
                            return arg.defVal;
                        }
                        initialOpt = argData.val == undefined ? 'defalut' : 'edit';
                        initialVal = argData.val == undefined ? getDefaultValue() : argData.val;
                    }
                    const referArg: ReferArgState = {
                        argId: arg.id,
                        opt: { value: initialOpt, errors: [] },
                        val: { value: initialVal, errors: [] }
                    }
                    return referArg;
                });
            return {
                isUseCondition: false,
                accept: { value: refer.accept ?? '', errors: [] },
                refId: { value: refer.refId, errors: [] },
                args
            };
        });
    }

    export const getMappedReferDatas = (referStates: ReferState[]): Refer[] => {
        return referStates.map(inh => {
            const args: ReferArg[] = inh.args
                .filter(arg => arg.opt.value as ArgOption !== 'abstract')
                .map(arg => {
                    const opt = arg.opt.value as ArgOption;
                    // console.log(`${arg.argId}(${opt}): ${opt === 'defalut' ? undefined : arg.val.value}`);
                    return {
                        key: arg.argId,
                        val: opt === 'defalut' ? undefined : arg.val.value
                    }
                });
            // console.log(inh.args);
            // console.log(args);
            const refer: Refer = {
                accept: DataUtil.blankToUndefined(inh.accept.value),
                refId: inh.refId.value,
                args
            };
            return refer;
        });
    }

    export const hasErrorDesignRefers = (referStates: ReferState[]) => {
        let hasError = false;
        referStates.some(inh => {
            const inhTargets: FormUtil.CheckableValue[] = [inh.refId].concat(inh.args
                .map(arg => arg.val)
            );
            // console.log(inhTargets.length);
            if (ValidateUtil.hasError(inhTargets)) {
                hasError = true;
                return 1;
            }
        });
        return hasError;
    }

    export const Component = (props: {
        localState: LocalState;
        invalidate: () => void;
        props: Props;
    }): JSX.Element => {

        const localState = props.localState;
        const invalidate = props.invalidate;
        const styleItems = props.props.styleItems;

        const inheritJsxes = localState.refers.map((inherit, i) => {

            const getStlarg = (index: number) => {
                const style = styleItems.find(s => s.id === inherit.refId.value);
                assert(style != undefined, 'styleItemsにstyleが存在しない。');
                return style.args[index];
            }

            return (<FormUtil.BorderPanel key={i}
                title={inherit.refId.value}
                innerJsx={<>
                    <FormUtil.ButtonRecord align="left" buttons={[
                        {
                            label: '×',
                            width: 60,
                            callback: () => {
                                // console.log(`del: [${i}]`);
                                localState.refers.splice(i, 1);
                                invalidate();
                            }
                        },
                        {
                            label: '↑',
                            width: 50,
                            isEnable: i > 0,
                            callback: () => {
                                DataUtil.swapOrder(localState.refers, i, -1);
                                invalidate();
                            }
                        },
                        {
                            label: '↓',
                            width: 50,
                            isEnable: i < localState.refers.length-1,
                            callback: () => {
                                DataUtil.swapOrder(localState.refers, i, 1);
                                invalidate();
                            }
                        },
                        {
                            label: 'clone',
                            width: 100,
                            callback: () => {
                                // console.log(`del: [${i}]`);
                                localState.refers.splice(i, 0, JSON.parse(JSON.stringify(inherit)));
                                invalidate();
                            }
                        },
                    ]} />
                    <FormUtil.FormRecord
                        titleLabel="Accept"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={inherit.accept}
                            setCheckable={(checkable) => {
                                inherit.accept.value = checkable.value;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                // {
                                //     checker: (value) => ValidateUtil.checkRequired(value),
                                //     errorType: "required"
                                // },
                                // {
                                //     checker: (value) => ValidateUtil.checkStringLength(value, 1, 16),
                                //     errorType: "length"
                                // },
                                // {
                                //     checker: (value) => ModelUtil.checkIdChars(value),
                                //     errorType: "value"
                                // }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Style Id"
                        jsx={<FormUtil.Combobox
                            width={200}
                            checkable={inherit.refId}
                            setCheckable={(checkable) => {
                                inherit.refId.value = checkable.value;
                                invalidate();
                            }}
                            extend={(value) => {
                                const style = styleItems.find(s => s.id === value);
                                if (style == undefined) inherit.args = [];
                                // assert(style != undefined, 'styleがundefinedであることは許されない。');
                                else inherit.args = style.args.map(a => {
                                    const [initialOpt, initialVal] = getInitialSet(props.props.phase, a);
                                    return {
                                        argId: a.id,
                                        isOverride: false,
                                        opt: { value: initialOpt, errors: [] },
                                        val: { value: initialVal, errors: [] }
                                    }
                                });
                            }}
                            headBlank
                            list={styleItems
                                // // 他で使用されているデザインIDは除外する
                                // .filter(d => (
                                //     localState.refers.find((inh, j) => (
                                //         inh.refId.value === d.id && i !== j
                                //     )) == undefined
                                // ))
                                .map(d => ({
                                    labelText: d.id,
                                    value: d.id
                                }))}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    {inherit.args.map((arg, j) => {
                        return (
                            <FormUtil.FormRecord key={j}
                                titleLabel={arg.argId}
                                jsx={<>
                                    <FormUtil.Combobox
                                        width={130}
                                        checkable={arg.opt}
                                        setCheckable={(checkable) => {
                                            arg.opt.value = checkable.value;
                                            invalidate();
                                        }}
                                        extend={(value) => {
                                            const argData = getStlarg(j);
                                            const opt = value as ArgOption;
                                            switch (opt) {
                                                case 'abstract': arg.val.value = ''; break;
                                                case 'defalut': arg.val.value = argData.defVal ?? ''; break;
                                            }
                                        }}
                                        list={ArgOptions
                                            // tagではabstractを表示しない
                                            .filter(item => !(item === 'abstract' && props.props.phase === 'tag'))
                                            .map(item => {
                                                const argData = getStlarg(j);
                                                return {
                                                    labelText: item, value: item,
                                                    isDisabled: item === 'defalut' && argData.defVal == undefined
                                                };
                                            })
                                        }
                                    />
                                    <FormUtil.TextField
                                        marginLeft={4}
                                        width={260}
                                        checkable={arg.val}
                                        setCheckable={(checkable) => {
                                            arg.val.value = checkable.value;
                                            invalidate();
                                        }}
                                        isEnabled={(arg.opt.value as ArgOption) !== 'abstract'}
                                        isReadOnly={(arg.opt.value as ArgOption) === 'defalut'}
                                        validates={(arg.opt.value as ArgOption) !== 'edit' ? [] : [
                                            {
                                                checker: (value) => ValidateUtil.checkRequired(value),
                                                errorType: "required"
                                            }
                                        ]}
                                    />
                                </>}
                            />
                        );
                    })}
                </>}
            />);
        });
        return (<>
            <FormUtil.ButtonRecord align="left" buttons={[
                {
                    label: '+Add',
                    width: 120,
                    callback: () => {
                        localState.refers.push({
                            isUseCondition: false,
                            refId: { value: '', errors: [] },
                            accept: { value: '', errors: [] },
                            args: []
                        });
                        invalidate();
                    }
                }
            ]} />
            <_ListFrame>{inheritJsxes}</_ListFrame>
        </>);
    }

    export const getTotalStyleMonitor = (refers: ReferState[], arg: Props, defs?: StylePropDefiner.Record[]) => {

        const totalDefs = refers.map(refer => {
            const style = arg.styleItems.find(d => d.id === refer.refId.value);
            assert(style != undefined, 'design is undefined.');
            return style.defs;
        }).flat().concat((defs ?? []));

        const jsx = totalDefs.map((def, j) => {
            if (def.val != undefined) {
                return (<_Record key={j}>
                    <_Span color="#ec5454">{def.prop}</_Span>
                    {': '}
                    <_Span color="#468144">{def.val}</_Span>
                    {';'}
                </_Record>);
            } else if (def.defs != undefined) {
                return (<_Nest key={j}>
                    <_Record>
                        <_Span color="#546bec">{def.prop}</_Span>
                        {' {'}
                    </_Record>
                    {def.defs.map((nDef, k) => {
                        const nKey = `${j}.${k}`;
                        return (
                            <_Record key={nKey}>
                                {'　'}
                                <_Span color="#ec5454">{nDef.prop}</_Span>
                                {': '}
                                <_Span color="#468144">{nDef.val}</_Span>
                                {';'}
                            </_Record>);
                    })}
                    <_Record>
                        {'}'}
                    </_Record>
                </_Nest>);
            }
        });
        return <_StyleFrame>{jsx}</_StyleFrame>;
    }
}

export default StyleChooser;

const _StyleFrame = styled.div`
    display: inline-block;
    margin: 6px 0 0 6px;
    width: calc(100% - 12px);
    height: 300px;
    background-color: #d1dae0;
    border: 1px solid #747474;
    border-radius: 4px;
    padding: 2px;
    box-sizing: border-box;
    overflow-y: auto;
`;

const _ListFrame = styled.div`
    display: inline-block;
    width: 100%;
    height: calc(100% - 40px);
    /* background-color: #ec4b4b6c; */
    overflow-y: auto;
`;

const _Nest = styled.div<{
    // color: string;
}>`
    display: inline-block;
    /* background-color: #93f08039; */
    width: 100%;
`;
const _Record = styled.div<{
    // color: string;
}>`
    display: inline-block;
    background-color: #f8f8f839;
    width: calc(100% - 4px);
    margin: 2px 0 0 2px;
    font-size: 18px;
    font-weight: 600;
    color: #555555;
`;
const _Span = styled.span<{
    color: string;
}>`
    color: ${props => props.color};
`;
