import { useEffect, useState, useMemo, useContext } from "react";
import AbstractModelEditor from "./../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import ModelEditDialog from "../../modelEditDialog";
import { GlobalContext } from "../../../../entry/systemEntry";
import assert from "assert";
import ModelElementUtil from "../../util/modelElementUtil";

namespace ButtonEditor {

    type LocalState = {
        label: FormUtil.CheckableValue;
        width: FormUtil.CheckableValue;
        designId: FormUtil.CheckableValue;
        actionId: FormUtil.CheckableValue;
        args: FormUtil.CheckableValue[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [actions] = useMemo(() => {
            
            const actions = ModelElementUtil.getActionsFromCurrent(manageItems.focusNode);
            return [actions];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            label: { value: '', errors: [] },
            width: { value: '', errors: [] },
            designId: { value: '', errors: [] },
            actionId: { value: '', errors: [] },
            args: [],
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.label, localState.width, localState.actionId];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as ModelUtil.NodeButton;
                localState.label.value = data.label;
                localState.width.value = data.width.toString();
                localState.designId.value = data.designId;
                localState.actionId.value = data.actionId ?? '';
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const data: ModelUtil.NodeButton = {
                label: localState.label.value,
                width: Number(localState.width.value),
                designId: localState.designId.value,
                actionId: localState.actionId.value
            }
            setTempData(data);
        }, [...targetFroms, localState.args]);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Label"
                        jsx={<FormUtil.TextField
                            width={150}
                            checkable={localState.label}
                            setCheckable={(checkable) => {
                                localState.label = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 16),
                                    errorType: "length"
                                },
                                // {
                                //     checker: (value) => !existNameList.includes(value),
                                //     errorType: "relation"
                                // }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Width"
                        jsx={<FormUtil.TextField
                            width={100}
                            isNumber
                            checkable={localState.width}
                            setCheckable={(checkable) => {
                                localState.width = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, 50, 400),
                                    errorType: "length"
                                },
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Action Id"
                        jsx={<FormUtil.Combobox
                            width={160}
                            checkable={localState.actionId}
                            setCheckable={(checkable) => {
                                localState.actionId = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={actions.map(a => {
                                return { value: a.id, labelText: a.id }
                            })}
                            // validates={[
                            //     {
                            //         checker: (value) => ValidateUtil.checkRequired(value),
                            //         errorType: "required"
                            //     }
                            // ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'button';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default ButtonEditor;
