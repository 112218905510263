import styled, { css } from "styled-components";
import assert from "assert";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import StoreProject from "../../../../redux/store/storeProject";
import DataUtil from "../../../../../common/dataUtil";
import ListManger from "../editor/item/listManager2";
import StoreTable from "../../../../redux/store/storeTable";
import Styles from "../../../../../def/design/styles";
import ValidateUtil from "../../../../../common/component/form/validateUtil";
import ModelElementUtil from "./modelElementUtil";
import NodeUpdate from "../editor/var/nodeUpdate";
import VariableChooser from "../editor/proc/variableChooser";
import NodeCompuse from "../editor/ui/nodeCompuse";
import NodeFocus from "../editor/proc/focus/nodeFocus";
import NodeAssign from "../editor/proc/focus/nodeAssign";
import NodeArrayAdd from "../editor/proc/focus/nodeArrayAdd";
import NodeArrayDel from "../editor/proc/focus/nodeArrayDel";
import NodeStyle from "../editor/decrare/nodeStyle";
import NodeStlarg from "../editor/decrare/nodeStlarg";
import NodeTagdiv from "../editor/ui/tag/nodeTagDiv";
import NodeTagSpan from "../editor/ui/tag/nodeTagSpan";
import NodeTagImg from "../editor/ui/tag/nodeTagImg";
import NodeTagInput from "../editor/ui/tag/nodeTagInput";
import PrefixUtil from "./prefixUtil";
import NodeCompDef from "../editor/nodeCompDef";
import NodeCase from "../editor/condition/nodeCase";
import NodeWhen from "../editor/condition/nodeWhen";
import NodeApp from "../editor/nodeApp";
import NodeAccept from "../editor/condition/nodeAccept";
import NodeAsgnNam from "../editor/proc/focus/nodeAsgnNam";
import NodeDtype from "../editor/nodeDtype";
import NodePrpclbk from "../editor/var/nodePrpclbkEditor";
import NodeField from "../editor/var/nodeField";
import NodeFunction from "../editor/var/func/nodeFunction";
import NodeArrayEff from "../editor/proc/focus/nodeArrayEff";
import nodeView from "../editor/ui/nodeView";
import NodeExecute from "../editor/proc/nodeExecute";
import NodeFetch from "../editor/proc/nodeFetch";
import NodeEffect from "../editor/linkage/nodeEffect";
import NodeIterate from "../editor/nodeIterate";
import NodeArrayCat from "../editor/proc/focus/nodeArrayCat";
import NodeConsoleLog from "../editor/ui/tag/nodeConsoleLog";
import NodeCatch from "../editor/proc/nodeCatch";
import NodeAssignFormula from "../editor/proc/focus/nodeAssignFormula";
import NodeLauncher from "../editor/release/nodeLauncher";

namespace ModelUtil {

    // export const FieldTypes = ['text', 'number', 'multiline', 'list', 'checkbox', 'time', 'model'] as const;
    // export type FieldType = typeof FieldTypes[number];

    // export const TimeTypes = ['date-only', 'time-only', 'full'] as const;
    // export type TimeType = typeof TimeTypes[number];
    /**
     * IDに利用可能な文字列チェック
     * @param str 検証する文字列
     * @returns 判定結果
     */
    export const checkIdChars = (str: string) => {
        // 1文字目が数値でないこと
        const isNoHeadNumber = !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].
            includes(str.substring(0, 1));
        const list = '0123456789abcdefghijklmnopqrstuvwxyz_';
        return isNoHeadNumber && ValidateUtil.checkIncludesChars(str, list);
    }

    export const checkCamelCase = (str: string) => {
        const head = str.substring(0, 1);
        // 1文字目が数値でないこと
        const isNoHeadNumber = !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].
            includes(head);

        const isHeadUpper = () => {
            return head === head.toUpperCase();
        }
        const list = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return !isHeadUpper() && isNoHeadNumber && ValidateUtil.checkIncludesChars(str, list);
    }

    export const checkPascalCase = (str: string) => {
        const head = str.substring(0, 1);
        // 1文字目が数値でないこと
        const isNoHeadNumber = !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].
            includes(head);

        const isHeadUpper = () => {
            return head === head.toUpperCase();
        }
        const list = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return isHeadUpper() && isNoHeadNumber && ValidateUtil.checkIncludesChars(str, list);
    }

    export type ManageItems = {
        focusNode: TreeUtil.ElementNode;
        setFocusNode: (node: TreeUtil.ElementNode) => void;
        // model: StoreProject.Props;
        invalidate: () => void;
    }

    export type NodeType =
        'project' |
        'elements' |
        'retention' |
        // 'regulation' |
        'release' |
        'launchers' |
        'launcher' |
        'permissions' |
        'permission' |
        // 'users' |
        // 'user' |
        // 'groups' |
        // 'group' |
        // 'region' |
        'block' |
        'apps' |
        'comps' |
        'compdef' |
        'props' |
        'prpfld' |
        'app' |
        'lncargs' |
        'lncarg' |
        // 'authority' |
        'items' |
        'dtypes' |
        'dtype' |
        'member' |
        'store' |
        'states' |
        'state' |
        'initial' |
        'return' |
        'func' |
        'closure' |
        'action' |
        'execute' |
        'args' |
        'arg' |
        'prpclbk' |
        'proc' |
        'update' |
        'assignbak' |
        'focus' |
        'arrdel' |
        'arradd' |
        'arrcat' |
        'arreff' |
        'arrsort' |
        'assign' |
        'asgnnam' |
        'asgnfml' |
        'asnmtch' |
        'mtchidx' |
        'linkage' |
        'fetches' |
        'fetch' |
        'then' |
        'catch' |
        'view' |
        'tagdiv' |
        'tagspan' |
        'taginput' |
        'tagimg' |
        'div' |
        'compuse' |
        'iterate' |
        'continue' |
        'break' |
        'tabs' |
        'span' |
        'button' |
        'input' |
        'table-mng' |
        'table' |
        'border' |
        'accept' |
        'case' |
        'when' |
        'cache' |
        'style' |
        'desarg' |
        'common' |
        'styles' |
        'temp' |
        'trigger' |
        'block' |
        'log' |

        // ダミー用
        'xxx'
        ;

    export type WrapElement = {
        type: NodeType;
        data: any;
    }
    export interface GenericElements {
        elements: WrapElement[];
    }
    export type NodeGenericMngs = {
        mngs: WrapElement[];
    }
    export type NodeGenericItems = {
        items: WrapElement[];
    }
    export type NodeElements = {
        elements: WrapElement[];
    }
    export type NodeMngTop = {
        mngs: WrapElement[];
    }
    export type NodeGlobal = {
        mngs: WrapElement[];
    }
    export type NodeApps = {
        apps: WrapElement[];
    }
    export type NodeComps = {
        comps: WrapElement[];
    }
    export type NodeProps = {
        props: WrapElement[];
    }
    export type NodeAuthorityMng = {
    }
    export type NodeModels = {
        items: WrapElement[];
    }
    export interface NodeModelField extends Field {
        id: string;
    }

    export const getFieldListItem = (f: ModelUtil.NodeModelField) => {
        // console.log(f);
        const type = f.structId == undefined ? f.dataType : f.structId;
        const array = '[]'.repeat(f.array);
        // const labelText = `${f.id}: @${type}${array}`;
        const labelText = getFieldType(f);
        return { value: f.id, labelText }
    }

    export const getField = (f: ModelUtil.Field) => {
        // const type = f.structId == undefined ? f.dataType : f.structId;
        let type = f.dataType;
        if (f.structId != undefined) {
            type += ('.' + f.structId);
        }
        const array = '[]'.repeat(f.array);
        return `@${type}${array}`;
    }
    export const getFieldType = (f: ModelUtil.NodeModelField) => {
        const type = f.structId == undefined ? f.dataType : f.structId;
        const array = '[]'.repeat(f.array);
        return `${f.id}: @${type}${array}`;
    }

    export const getCacheListItem = (c: NodeField.CacheData) => {
        const labelText = c.id;
        return { value: c.id, labelText }
    }

    export type NodeStore = {
        mngs: WrapElement[];
    }
    export type NodeStates = {
        items: WrapElement[];
    }
    export type NodeInitial = {
        items: WrapElement[];
    }
    export const DataTypes = ['string', 'number', 'boolean', 'color', 'struct', 'name', 'multiline'] as const;
    // export type DataType = 'string' | 'number'| 'boolean'| 'color'| 'struct'| 'name';
    export type DataType = typeof DataTypes[number];

    export type NodeFormula = {
        id: string;
        args: string[];
        source: string;
    }
    export interface ArgsData {
        args: WrapElement[];
    }
    export type NodeAction = {
        id: string;
        eventId: string;
        args: string[];
    }
    export type NodeInput = {
        setter?: string;
        mapper?: string;
        field?: string;

        bgColor: string;
        widthRate: string;
        widthPixel: string;
        heightPixel: string;
        marginTop: string;
        marginRight: string;
        marginBottom: string;
        marginLeft: string;
        paddingTop: string;
        paddingRight: string;
        paddingBottom: string;
        paddingLeft: string;
        lineHeight: number;
        fontSize: number;
        fontColor: string;

        borderSize: number;
        borderColor: string;
        roundSize: number;
    }
    export type NodeProcedure = {
        items: WrapElement[];
    }
    // const ProcTypeModState = 'modify'
    // export const ProcMethods = ['modify-state', 'fetch-dao'] as const;
    // export type ProcMethod = typeof ProcMethods[number];

    export interface NodeAssignBak extends VariableChooser.Chooser {
        dest: string;
        cloneType?: NodeUpdate.CloneType;
    }

    export type NodeRetention = {
        items: WrapElement[];
    }
    export const CatcheMethods = ['clone', 'build'] as const;
    // export type CacheMethod = 'clone' | 'build';
    export type CacheMethod = typeof CatcheMethods[number];

    export interface Field {
        dataType: DataType;
        array: number;
        structId?: string;
    }

    export const isObjectField = (field: Field) => {
        return field.array > 0 || field.dataType === 'struct';
    }

    export interface Variables {
        states: NodeModelField[];
        caches: NodeModelField[];
        propFields: NodeModelField[];
        funcargs?: NodeModelField[];
        models: ModelElementUtil.DtypeInfo[];
    }

    export type CloneProps = {
        target: VariableChooser.RootTargetType;
        rootId: string;
        levelProps: string[];
    }

    export type NodeButton = {
        label: string;
        width: number;
        designId: string;
        actionId?: string;
    }
    export type NodeSetup = {
        mngs: WrapElement[];
    }

    /**
     * Div要素
     */
    export type NodeDiv = {
        memo: string;
        styleId: string;
        innerAlign: DivAlignType;
        bgColor?: string;
        widthRate: string;
        heightRate: string;
        widthPixel: string;
        heightPixel: string;
        marginTop: string;
        marginRight: string;
        marginBottom: string;
        marginLeft: string;
        paddingTop: string;
        paddingRight: string;
        paddingBottom: string;
        paddingLeft: string;
        border?: DivBorder;
        scrollX?: DataUtil.Flag;
        scrollY?: DataUtil.Flag;
        elements: WrapElement[];
        actOnclk?: string;
        actOncntxt?: string;
    }

    /** Divのレイアウト指定方法 */
    export const LayoutTypes = ['wrap', 'free'] as const;
    export type LayoutType = typeof LayoutTypes[number];

    /** Divの子要素の横の詰め方 */
    export const DivAlignTypes = ['left', 'center', 'right'] as const;
    export type DivAlignType = typeof DivAlignTypes[number];

    /** Divのレイアウト「wrap」選択時のプロパティ */
    export type DivWrapProps = {
        margin: number;
        border?: DivBorder;
    }
    /** Divのボーダーのプロパティ */
    export type DivBorder = {
        size: number;
        color: string;
        roundSize: number;
    };
    /** Divのレイアウト「free」選択時のプロパティ */
    export type DivFreeProps = {
        wRate: number;
        wPixel: number;
        hRate: number;
        hPixel: number;
        left: number;
        top: number;
    }
    /**
     * Span要素
     */
    export type NodeSpan = {
        text: string;
        fontSize: number;
        fontColor: string;
        bgColor?: string;
    }

    export type NodeTabs = {
        tabIndex?: string;
        tabs: WrapElement[];
    }


    export type NodeTableMng = {
        requestId?: string;
        mngs: WrapElement[];
    }

    export type NodeFormMng = {
        requestId?: string;
        mngs: WrapElement[];
    }
    export type NodeTextMng = {
        requestId?: string;
        mngs: WrapElement[];
    }

    export type NodeTab = {
        label: string;
        enableCondition: string;
        requestId?: string;
        elements: WrapElement[];
    }

    // export type NodeRetentionBak = {
    //     id: string;
    //     arrayId: string;
    //     index: string;
    // }

    const COLOR_ROOT = '#a1a1a1';
    const COLOR_TOP_MNG = '#d1bda6';
    const COLOR_MNG = '#93b7c0';
    const COLOR_LIST = '#b98989';
    const COLOR_MASTER = '#ac859a';
    const COLOR_DISABLE = '#685b79';
    const COLOR_DIV = '#cac490';
    const COLOR_ITEM = '#a9b993';
    const COLOR_EXECUTE = '#a4a5c5';
    const COLOR_ITERATE = '#8fd4a6';
    const COLOR_LOG = '#dddddd';

    export const getNodeDisplayJsx = (node: TreeUtil.ElementNode, isFocus: boolean) => {
        const wrap = node.data as WrapElement;
        switch (wrap.type) {
            case 'project': {
                return <_Category backgroundColor={COLOR_ROOT}>Project</_Category>;
            }
            case 'release': {
                return <_Category backgroundColor={COLOR_MNG}>Release</_Category>;
            }
            case 'launchers': {
                return <_Category backgroundColor={COLOR_LIST}>Launchers</_Category>;
            }
            case 'launcher': {
                let jsx = <>{'...'}</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeLauncher.Data;
                    jsx = <><_Span color="#fafafac0">{data.name}</_Span><_Span color="#fc384f">{` @${data.appId}`}</_Span></>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>Launcher</_Category>
                    <_EditableValue>{jsx}</_EditableValue>
                </>;
            }
            case 'permissions': {
                return <_Category backgroundColor={COLOR_LIST}>Permissions</_Category>;
            }
            case 'apps': {
                return <_Category backgroundColor={COLOR_TOP_MNG}>Apps</_Category>;
            }
            case 'app': {
                let jsx = <>{'...'}</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeApp.Data;
                    jsx = <_Span color="#fafafac0">{data.id}</_Span>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>App</_Category>
                    <_EditableValue>{jsx}</_EditableValue>
                </>;
            }
            case 'comps': {
                return <_Category backgroundColor={COLOR_LIST}>Components</_Category>;
            }
            case 'compdef': {
                let name = '...';
                if (wrap.data != null) {
                    const data = wrap.data as NodeCompDef.Data;
                    name = data.id;
                }
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>Component</_Category>
                    <_EditableValue>{name}</_EditableValue>
                </>;
            }
            case 'compuse': {
                let dispJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeCompuse.Data;
                    const compIdJsx = <_Span color="#fcef38">{data.compId}</_Span>;
                    const argsJsx = <_Span color="#ffffff7f">{`<${data.props.join(', ')}>`}</_Span>;
                    dispJsx = <>{compIdJsx} {argsJsx}</>
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Component</_Category>
                    <_EditableValue>{dispJsx}</_EditableValue>
                </>;
            }
            // case 'authority': {
            //     return <_Category backgroundColor={COLOR_MNG}>Authority</_Category>;
            // }
            // case 'regulation': {
            //     return <_Category backgroundColor={COLOR_MNG}>Regulation</_Category>;
            // }
            // case 'users': {
            //     return <_Category backgroundColor={COLOR_LIST}>Users</_Category>;
            // }
            // case 'groups': {
            //     return <_Category backgroundColor={COLOR_LIST}>Groups</_Category>;
            // }
            case 'linkage': {
                return <_Category backgroundColor={COLOR_MNG}>Linkage</_Category>;
            }
            case 'common': {
                return <_Category backgroundColor={COLOR_MNG}>Common</_Category>;
            }
            case 'items': {
                return <_Category backgroundColor={COLOR_LIST}>Items</_Category>;
            }
            case 'store': {
                return <_Category backgroundColor={COLOR_MNG}>Store</_Category>;
            }
            case 'initial': {
                return <_Category backgroundColor={COLOR_MNG}>Initial</_Category>;
            }
            case 'fetches': {
                return <_Category backgroundColor={COLOR_LIST}>Fetches</_Category>;
            }
            case 'args': {
                return <_Category backgroundColor={COLOR_LIST}>Arguments</_Category>;
            }
            case 'props': {
                return <_Category backgroundColor={COLOR_LIST}>Props</_Category>;
            }
            case 'dtypes': {
                return <_Category backgroundColor={COLOR_LIST}>Types</_Category>;
            }
            case 'dtype': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeDtype.Data;
                    infoJsx = <_EditableValue>{data.id}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>Type</_Category>
                    {infoJsx}
                </>;
            }
            case 'member': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeModelField;
                    const array = '[]'.repeat(data.array);
                    const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    infoJsx = <_EditableValue>{data.id}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Member</_Category>
                    {infoJsx}
                </>;
            }
            case 'proc': {
                return <_Category backgroundColor={COLOR_MNG}>Procedure</_Category>;
            }
            case 'retention': {
                return <_Category backgroundColor={COLOR_MNG}>Retention</_Category>;
            }
            case 'states': {
                return <_Category backgroundColor={COLOR_LIST}>States</_Category>;
            }
            case 'state': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeModelField;
                    const array = '[]'.repeat(data.array);
                    const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    infoJsx = <_EditableValue>{`\${s.`}<_Span color="#6ffc38">{data.id}</_Span>{`}`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>State</_Category>
                    {infoJsx}
                </>;
            }
            case 'arg': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeModelField;
                    const array = '[]'.repeat(data.array);
                    const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    infoJsx = <_EditableValue>{`\${${PrefixUtil.ARGUMENT}.`}<_Span color="#6ffc38">{data.id}</_Span>{`}`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Argument</_Category>
                    {infoJsx}
                </>;
            }
            case 'prpfld': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeModelField;
                    const array = '[]'.repeat(data.array);
                    const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    infoJsx = <_EditableValue>{`\${pf.`}<_Span color="#6ffc38">{data.id}</_Span>{`}`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Field</_Category>
                    {infoJsx}
                </>;
            }
            case 'prpclbk': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodePrpclbk.Data;
                    const typeJsx = <_Span color={"#fd8f35"}>@callback</_Span>;
                    infoJsx = <_EditableValue>{`\${pc.`}<_Span color="#6ffc38">{data.id}</_Span>{`}`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MNG}>Callback</_Category>
                    {infoJsx}
                </>;
            }
            case 'input': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeInput;
                    const [method, property] = data.mapper == undefined
                        ? ['Setter', `${data.setter}()`]
                        : ['Mapper', `${data.mapper}.${data.field}`];
                    const methodJsx = <_Span color="#f14e71">{method}</_Span>;
                    const propJsx = <_Span color="#e2e189">{property}</_Span>;
                    infoJsx = <_EditableValue>{methodJsx}{'-> '}{propJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Input</_Category>
                    {infoJsx}
                </>;
            }
            case 'trigger': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeEffect.Data;
                    infoJsx = <_EditableValue><_Span color="#ff812d">{data.method}</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_EXECUTE}>Trigger</_Category>
                    {infoJsx}
                </>;
            }
            case 'accept': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeAccept.Data;
                    infoJsx = <_EditableValue><_Span color="#fd3535">{data.condition}</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_EXECUTE}>Accept</_Category>
                    {infoJsx}
                </>;
            }
            case 'case': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeCase.Data;
                    let detail = '';
                    if (data.condition != undefined) detail = data.condition;
                    else if (data.targetId != undefined) detail = data.targetId;
                    infoJsx = <_EditableValue><_Span color="#c8c9ff">@{data.method}: </_Span><_Span color="#fd3535">[{detail}]</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MNG}>Case</_Category>
                    {infoJsx}
                </>;
            }
            case 'when': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeWhen.Data;
                    if (data.bool != undefined) {
                        infoJsx = <_EditableValue>bool: <_Span color="#fffc45">*{data.bool ? 'True' : 'False'}</_Span></_EditableValue>;
                    }
                    else if (data.condition != undefined) {
                        infoJsx = <_EditableValue>condition: <_Span color="#fffc45">*{data.condition}</_Span></_EditableValue>;
                    }
                    else if (data.valFml != undefined) {
                        infoJsx = <_EditableValue>match: <_Span color="#fffc45">*{data.valFml}</_Span></_EditableValue>;
                    }
                    else if (data.numBreak != undefined) {
                        infoJsx = <_EditableValue>break: <_Span color="#fffc45">*{data.numBreak}</_Span></_EditableValue>;
                    }
                }
                return <>
                    <_Category backgroundColor={COLOR_EXECUTE}>When</_Category>
                    {infoJsx}
                </>;
            }
            case 'styles': {
                return <_Category backgroundColor={COLOR_LIST}>Styles</_Category>;
            }
            case 'elements': {
                return <_Category backgroundColor={COLOR_LIST}>Elements</_Category>;
            }
            case 'func': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeFunction.Data;
                    const name = <_Span color="#0077ff">{data.id}</_Span>;
                    const getRetJsx = () => {
                        const ret = data.ret;
                        if (ret == undefined) return <_Span color='#cccccc'>void</_Span>;
                        return <><_Span color='#e60000'>{getField(ret)}-[<_Span color='#e09f9f'>{ret.fml}</_Span>]</_Span></>;
                    }
                    infoJsx = <_EditableValue>{name}{' => '}{getRetJsx()}</_EditableValue>;
                }
                // const categoryName = wrap.type === 'closure' ? 'Closure' : 'Dispatcher';
                // return <>
                //     <_Category backgroundColor={COLOR_MASTER}>{categoryName}</_Category>
                //     {infoJsx}
                // </>;
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>Function</_Category>
                    {infoJsx}
                </>;
            }
            case 'return': {
                return <_Category backgroundColor={COLOR_ITEM}>Return</_Category>;
            }
            case 'continue': {
                return <_Category backgroundColor={COLOR_ITEM}>Continue</_Category>;
            }
            case 'break': {
                return <_Category backgroundColor={COLOR_ITEM}>Break</_Category>;
            }
            case 'update': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeUpdate.Data;
                    const target = <_Span color="#fd5d35">{data.rootId}</_Span>;
                    const assign = <_Span color="#fd5d35">{data.assign}</_Span>;
                    const typeJsx = <_Span color="#e2e2e2">{data.referProps == undefined ? 'Direct' : 'Refer'}</_Span>;
                    const getDetail = () => {
                        switch (data.method) {
                            case 'assign': return <>={assign}@{typeJsx}</>;
                            case 'push': return <>.push[{assign}@{typeJsx}]</>;
                            case 'remove': {
                                const index = <_Span color="#8efc8b">{data.index}</_Span>;
                                return <>.remove({index})</>;
                            }
                        }
                    }
                    const update = <_Span color="#fffffd8f">{data.target}[{target}]{getDetail()}</_Span>;
                    const methodJsx = <_Span color="#2e6bf0">#{data.method}</_Span>;
                    infoJsx = <>{methodJsx}{'->'}{update}</>
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Updater</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'focus': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeFocus.Data;
                    const targetJsx = <_Span color="#f0a22e">#{data.target}</_Span>;
                    const addressJsx = <>[<_Span color="#a9dfb1">{VariableChooser.getAddressSimple(data)}</_Span>]</>;
                    infoJsx = <>{targetJsx}{addressJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Focus</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'assign': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeAssign.Data;
                    const objVal = data.objVal;
                    const directVal = data.fmlVal;
                    let detailJsx = <></>;
                    let instanceStr = '';
                    switch (data.assignType) {
                        case 'pick-out': {
                            if (objVal != undefined) {
                                const targetJsx = <_Span color="#f0a22e">#{objVal.target}</_Span>;
                                const addressJsx = <>[<_Span color="#a9dfb1">{VariableChooser.getAddressSimple(objVal)}</_Span>]</>;
                                detailJsx = <>{targetJsx}{addressJsx}</>;
                                instanceStr = `${objVal.cloneType}`;
                            }
                        } break;
                        case 'formula': {
                            if (directVal != undefined) {
                                detailJsx = <>[<_Span color="#a9dfb1">{directVal}</_Span>]</>;
                            }
                        } break;
                        case 'initial': {
                            detailJsx = <_Span color="#fdfdfd">Initial</_Span>;
                        } break;
                    }
                    infoJsx = <><_Span color="#ffffff">{`←${instanceStr} `}</_Span>{detailJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Assign</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'asgnfml': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeAssignFormula.Data;
                    infoJsx = <_Span color="#e2e2e2">{`←[${data.fml}]`}</_Span>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Assign-Formula</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'asnmtch': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeFocus.Data;
                    const targetJsx = <_Span color="#f0a22e">#{data.target}</_Span>;
                    const addressJsx = <>[<_Span color="#a9dfb1">{VariableChooser.getAddressSimple(data)}</_Span>]</>;
                    infoJsx = <>{targetJsx}{addressJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Assign-Find</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'asgnnam': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeAsgnNam.Data;
                    let detailJsx = <></>;
                    let instanceStr = '';

                    const targetJsx = <_Span color="#f0a22e">#{data.target}</_Span>;
                    const addressJsx = <>[<_Span color="#a9dfb1">{`${data.rootId}.\$.{${data.prpName}}`}</_Span>]</>;
                    detailJsx = <>{targetJsx}{addressJsx}</>;
                    instanceStr = `${data.instance}`;

                    infoJsx = <><_Span color="#ffffff">{`←${instanceStr} `}</_Span>{detailJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Assign-name</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'arradd': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeArrayAdd.Data;
                    const objVal = data.objVal;
                    const directVal = data.fmlVal;
                    let detailJsx = <></>;
                    const indexJsx = <_Span color="#f02e2e">+[{data.index ?? 'last'}]:</_Span>;
                    if (objVal != undefined) {
                        const targetJsx = <_Span color="#f0a22e">#{objVal.target}</_Span>;
                        const addressJsx = <>[<_Span color="#a9dfb1">{VariableChooser.getAddressSimple(objVal)}</_Span>]</>;
                        detailJsx = <>{targetJsx}{addressJsx}</>;
                    } else if (directVal != undefined) {
                        detailJsx = <>[<_Span color="#a9dfb1">{directVal}</_Span>]</>;
                    }
                    infoJsx = <>{indexJsx}<_Span color="#ffffff"></_Span>{detailJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Array-Add</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'arrcat': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeArrayCat.Data;
                    const objVal = data.objVal;
                    let detailJsx = <></>;
                    const indexJsx = <_Span color="#f02e2e">+[{data.index ?? 'last'}]:</_Span>;
                    const targetJsx = <_Span color="#f0a22e">#{objVal.target}</_Span>;
                    const addressJsx = <>[<_Span color="#a9dfb1">{VariableChooser.getAddressSimple(objVal)}</_Span>]</>;
                    detailJsx = <>{targetJsx}{addressJsx}</>;
                    infoJsx = <>{indexJsx}<_Span color="#ffffff"></_Span>{detailJsx}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Array-Cat</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'arrdel': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeArrayDel.Data;
                    const indexJsx = <_Span color="#f02e2e">-[{data.index}]: </_Span>;
                    infoJsx = <>{indexJsx}{data.delCnt}</>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Array-Del</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'arreff': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeArrayEff.Data;
                    const methodJsx = <_Span color="#f02e2e">{data.method}</_Span>;
                    infoJsx = <>{methodJsx}: </>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Array-Eff</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'assignbak': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeAssignBak;
                    const target = <_Span color="#fd5d35">{data.rootId}</_Span>;
                    const assign = <_Span color="#fd5d35">{data.dest}</_Span>;
                    const detail = <_Span color="#fffffd8f">{data.target}[{target}]={assign}</_Span>;
                    infoJsx = <>{detail}</>
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Assign</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'fetch': {
                let url = '...';
                if (wrap.data != null) {
                    const data = wrap.data as NodeFetch.Data;
                    url = data.url;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Fetch</_Category>
                    <_EditableValue><_Span color="#d8fd35">{url}</_Span></_EditableValue>
                </>;
            }
            case 'view': {
                const data = wrap.data as nodeView.Data;
                const type = data.isFixed ? 'fixed' : 'normal'
                const entryJsx = <><_Span color="#f51d1d">entry: </_Span>[{data.entry ?? '-'}]</>;
                const infoJsx = <>window: <_Span color="#fd7435">{type}</_Span></>;
                return <>
                    <_Category backgroundColor={COLOR_MNG}>View</_Category>
                    <_EditableValue>{entryJsx} {infoJsx}</_EditableValue>
                </>;
            }
            case 'div': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeDiv;
                    const getVal = (rate: string, pixel: string) => {
                        let ret = null;
                        if (rate === '' && pixel !== '') {
                            ret = `${pixel}px`;
                        } else if (rate !== '' && pixel === '') {
                            ret = `${rate}%`;
                        } else if (rate !== '' && pixel !== '') {
                            ret = `calc(${rate}% ${pixel.indexOf('-') === -1 ? '+' : ''} ${pixel}px)`;
                        }
                        return ret;
                    }
                    const memoJsx = data.memo == '' ? <></> : <_Span color="#ff98f6">{data.memo}</_Span>
                    const widthVal = getVal(data.widthRate, data.widthPixel);
                    const widthJsx = widthVal == null ? <></> : <>W[<_Span color="#fddf35">{widthVal}]</_Span></>
                    const heightVal = getVal(data.heightRate, data.heightPixel);
                    const heightJsx = heightVal == null ? <></> : <>H[<_Span color="#fddf35">{heightVal}]</_Span></>
                    const colorTipJsx = data.bgColor == undefined ? '' : <_ColorTip color={data.bgColor} />
                    infoJsx = <_EditableValue>{colorTipJsx}{memoJsx}{widthJsx}{heightJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_DIV}>Tag-Div</_Category>
                    {infoJsx}
                </>;
            }
            case 'tagdiv': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeTagdiv.NodeData;
                    infoJsx = <>
                        <_Span color="#b3afad">{`{${data.comment}}`}</_Span>{NodeStyle.getStyleRefsJsx('Styles', data.styles)}
                    </>;
                }
                return <>
                    <_Category backgroundColor={COLOR_DIV}>Tag-Div</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'tagspan': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeTagSpan.NodeData;
                    infoJsx = <>
                        <_Span color="#a3e7e4">{`{${data.text}}`}</_Span>{NodeStyle.getStyleRefsJsx('Styles', data.desrefs)}
                    </>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Tag-Span</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'taginput': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeTagInput.NodeData;
                    infoJsx = <>
                        <_Span color="#b3afad">{`{${data.method}}`}</_Span>{NodeStyle.getStyleRefsJsx('Styles', data.desrefs)}
                    </>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Tag-Input</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'tagimg': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeTagImg.NodeData;
                    const desrefs = data.desrefs.map(d => d.refId).join(', ');
                    infoJsx = <>
                        <_Span color="#b3afad">{`{${data.url}}`}</_Span>{NodeStyle.getStyleRefsJsx('Styles', data.desrefs)}
                    </>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Tag-Img</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'cache': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeModelField;
                    const array = '[]'.repeat(data.array);
                    const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    infoJsx = <_EditableValue>{`\${c.`}<_Span color="#6ffc38">{data.id}</_Span>{`}`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Cache</_Category>
                    {infoJsx}
                </>;
            }
            case 'then': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeModelField;
                    const array = '[]'.repeat(data.array);
                    const [type, color] = data.dataType !== 'struct' ? [data.dataType, "#fddf35"] : [data.structId, "#ff5e5e"];
                    const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                    infoJsx = <_EditableValue>{`\${c.`}<_Span color="#6ffc38">{data.id}</_Span>{`}`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MNG}>Then</_Category>
                    {infoJsx}
                </>;
            }
            case 'catch': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeCatch.Data;
                    const [type, color] = ['string', "#fddf35"];
                    const typeJsx = <_Span color={color}>@{type}</_Span>;
                    infoJsx = <_EditableValue>{`\${c.`}<_Span color="#6ffc38">{data.errVar}</_Span>{`}`}: {typeJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MNG}>Catch</_Category>
                    {infoJsx}
                </>;
            }
            case 'style': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeStyle.Data;
                    let referJsx = <></>;
                    if (data.inherits.length > 0) {
                        const refers = data.inherits.map(d => d.refId).join(', ');
                        referJsx = <_Span color="#d1dfa0">inherit[{refers}]</_Span>;
                    }
                    infoJsx = <>
                        <_Span color="#b3afad">{`@${data.id}`}</_Span>{NodeStyle.getStyleRefsJsx('Inherits', data.inherits)}
                    </>;
                }
                return <>
                    <_Category backgroundColor={COLOR_MASTER}>Style</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'desarg': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeStlarg.Data;
                    const defValJsx = data.defVal == undefined ? '' : <>: <_Span color={'#ffe656'}>[{data.defVal}]</_Span></>;
                    infoJsx = <_EditableValue>{`\${${PrefixUtil.STYLE_ARGUMENT}.`}<_Span color="#6ffc38">{data.id}</_Span>{`}`}{defValJsx}</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Argument</_Category>
                    {infoJsx}
                </>;
            }
            case 'action': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeAction;
                    const id = <_Span color="#6ffc38">{data.id}</_Span>;
                    const eventId = <_Span color="#e2e189">{data.eventId}</_Span>;
                    const args = <_Span color="#90e4f396">{data.args.join(', ')}</_Span>;
                    infoJsx = <_EditableValue>*{id} [{eventId}] ({args})</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Action</_Category>
                    {infoJsx}
                </>;
            }
            case 'execute': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeExecute.Data;
                    const eventId = <_Span color="#e2e189">{data.eventId}</_Span>;
                    const args = <_Span color="#90e4f396">{data.args.join(', ')}</_Span>;
                    infoJsx = <_EditableValue>[{eventId}] ({args})</_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITEM}>Execute</_Category>
                    {infoJsx}
                </>;
            }
            case 'iterate': {
                let infoJsx = <_EditableValue>...</_EditableValue>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeIterate.Data;
                    infoJsx = <_EditableValue>{data.id}: <_Span color="#fd3535">[{data.itrCntFml}]</_Span></_EditableValue>;
                }
                return <>
                    <_Category backgroundColor={COLOR_ITERATE}>Iterate</_Category>
                    {infoJsx}
                </>;
            }
            case 'log': {
                let infoJsx = <>...</>;
                if (wrap.data != null) {
                    const data = wrap.data as NodeConsoleLog.Data;
                    infoJsx = <_Span color="#a3e7e4">{`{${data.text}}`}</_Span>;
                }
                return <>
                    <_Category backgroundColor={COLOR_LOG}>Log</_Category>
                    <_EditableValue>{infoJsx}</_EditableValue>
                </>;
            }
            case 'table-mng': {
                const data = wrap.data as NodeTableMng;
                let request = 'none';
                if (data.requestId != undefined) request = data.requestId;
                return <>
                    <_Category backgroundColor={COLOR_MNG}>Table Manager</_Category>
                    <_EditableValue>{request}</_EditableValue>
                </>;
            }
            // case 'form-mng': {
            //     const data = wrap.data as NodeFormMng;
            //     let request = 'none';
            //     if (data.requestId != undefined) request = data.requestId;
            //     const detailJsx = <span>
            //         <_Span color="#ffe89ba6">request:&nbsp;</_Span>
            //         <_Span color={data.requestId == undefined ? "#ffffffc0" : "#ff1818c0"}>{request}</_Span>
            //     </span>;
            //     return <>
            //         <_Category backgroundColor={COLOR_MNG}>Form Manager</_Category>
            //         <_EditableValue>{detailJsx}</_EditableValue>

            //     </>;
            // }
        }
        return <_Category backgroundColor={'#ffffff'}>XXXXX</_Category>;
    }

    export const getChildrenDataNode = (wrapNode: WrapElement): TreeUtil.DataNode[] => {
        const getRec = (listName: string) => {
            const listProp = wrapNode.data[listName];
            assert(listProp != undefined, `listProp is undefined [type: ${wrapNode.type}, listName: ${listName}]`);
            return (listProp as WrapElement[]).map(wrap => {
                const treeNode: TreeUtil.DataNode = {
                    data: wrap,
                    children: getChildrenDataNode(wrap)
                };
                return treeNode;
            });
        }

        const listName = ModelElementUtil.getListParamName(wrapNode.type);
        // 子要素がある場合再起で処理を行う
        if (listName != null) return getRec(listName);
        return [];
    };
};

export default ModelUtil;

const _Category = styled.div<{
    backgroundColor: string;
    isDisable?: boolean;
}>`
    ${Styles.CSS_LABEL_MIDIUM}
    display: inline-block;
    position: relative;
    background-color: ${props => props.backgroundColor};
    /* width: 100%; */
    ${props => !(props.isDisable ?? false) ? '' : css`
        text-decoration: line-through;
        color: #00054dac;
    `}
    border-radius: 4px 0 0 4px;
    margin: 5px 0 0 0;
    padding: 0 10px;
    border: solid 1px #000000d2;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
`;
const _Detail = styled.span`
    color: #000b69bd;
`;

const _EditableValue = styled.div`
    ${Styles.CSS_LABEL_MIDIUM}
    display: inline-block;
    position: relative;
    font-style: italic;
    background-color: #0c0c0c;
    color: #ebebeb;
    border-radius: 0 4px 4px 0;
    /* width: 100%; */
    margin: 5px 0 0 0;
    padding: 0 10px;
`;
const _ColorTip = styled.div<{
    color: string;
}>`
    display: inline-block;
    width: 10px;
    height: calc(100% - 8px);
    margin: 4px 4px 0 0;
    background-color: ${props => props.color};
    border-radius: 2px;
`;
const _Span = styled.span<{
    color: string;
}>`
    color: ${props => props.color};
`;
const _ValueType = styled.span`
    color: #fff9d6b9;
`;
const _Signature = styled.span`
    color: #ffffff78;
`;
const _Key = styled.span`
    color: #ff2d2d;
`;