import assert from "assert";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import { Store } from "../../../../redux/store/store";
import StructManageTab from "../structManageTab";
import { Dispatcher } from "../../../../redux/dispatcher/dispatcher";
import ModelElementUtil from "../util/modelElementUtil";
import ScopeManager from "./scopeManager";
import { wrap } from "module";
import ModelUtil from "../util/modelUtil";
import StructTreeContext from "../structTreeContext";
import AppReader from "../../../gui/appReader";
import styled from "styled-components";
import NodeApp from "../editor/nodeApp";
import NodeLauncher from "../editor/release/nodeLauncher";

namespace KeyInputManager {

    export type KeySwitchName = 'control' | 'shift';
    export type KeyHold = {
        name: KeySwitchName;
        key: string;
        on: boolean;
    }

    export type Props = {
        e: React.KeyboardEvent<HTMLDivElement>;
        localState: StructManageTab.LocalState;
        store: Store;
        dispatcher: Dispatcher;
        invalidate: () => void;
        treeRef: React.RefObject<HTMLDivElement>;
    }
    export const keyDown = (props: Props) => {

        if (props.localState.focusNode == null ||
            props.store.system.dialog != null) return;
        const node = props.localState.focusNode;
        const wrap = node.data as ModelUtil.WrapElement;
        const setFocusNode = (node: TreeUtil.ElementNode) => {
            props.localState.focusNode = node;
            props.store.system.scope = ScopeManager.getScopeProps(node);
            props.invalidate();
        }
        const getKeyHoldState = (name: KeySwitchName) => {
            const hold = props.localState.keyHolds.find(k => k.name === name);
            assert(hold != undefined, 'hold is undefined.');
            return hold.on;
        };

        const isHead = () => {
            const list = node.parent?.children;
            assert(list != undefined, 'list is undefined.');
            return list.findIndex(l => l == node) === 0;
        }
        const isTail = () => {
            const list = node.parent?.children;
            assert(list != undefined, 'list is undefined.');
            const len = list.length;
            return list.findIndex(l => l == node) === len - 1;
        }
        switch (props.e.key) {
            case 'ArrowRight': {
                if (!getKeyHoldState('shift')) {
                    if (node.children.length > 0) {
                        node.isOpen = true;
                        setFocusNode(node.children[0]);
                    }
                } else {
                    if (!node.isOpen) {
                        node.isOpen = true;
                        props.invalidate();
                    }
                }
            } break;
            case 'ArrowLeft': {
                if (!getKeyHoldState('shift')) {
                    if (node.parent != null) {
                        setFocusNode(node.parent);
                    }
                } else {
                    if (node.isOpen) {
                        node.isOpen = false;
                        props.invalidate();
                    }
                }
            } break;
            case 'ArrowUp': {
                if (!getKeyHoldState('shift')) {
                    if (node.parent == null) return;
                    const curIndex = node.parent.children.findIndex(c => c == node);
                    if (curIndex > 0) {
                        setFocusNode(node.parent.children[curIndex - 1]);
                    }
                } else {
                    if (ModelElementUtil.isSwappableNode(wrap.type) && !isHead()) {
                        ModelElementUtil.swapOrder(node, -1);
                        props.invalidate();
                    }
                }
            } break;
            case 'ArrowDown': {
                if (!getKeyHoldState('shift')) {
                    if (node.parent == null) return;
                    const curIndex = node.parent.children.findIndex(c => c == node);
                    if (curIndex < node.parent.children.length - 1) {
                        setFocusNode(node.parent.children[curIndex + 1]);
                    }
                } else {
                    if (ModelElementUtil.isSwappableNode(wrap.type) && !isTail()) {
                        ModelElementUtil.swapOrder(node, 1);
                        props.invalidate();
                    }
                }
            } break;
            case 'e': {
                const editor = StructTreeContext.getEditorFromNodeType(wrap.type);
                if (editor != undefined) {
                    const manageItems: ModelUtil.ManageItems = {
                        focusNode: node,
                        setFocusNode,
                        invalidate: props.invalidate
                    }
                    // グローバルのキャッシュを更新
                    props.store.system.freeCache = manageItems;
                    props.dispatcher.struct.openEditDialog({
                        isCreate: false,
                        editor,
                        manageItems
                    });
                }
            } break;
            case ' ': {
                try {
                    const projectRootWrap = ModelElementUtil.getProjectRootFromCurrent(props.localState.focusNode).data;
                    const app = ModelElementUtil.getAppRootWrap(props.localState.focusNode).data as NodeApp.Data;
                    // props.store.system.canDialogClose = true;
                    // props.store.system.dialog = (<AppReader.Dialog projectRootWrap={projectRootWrap} launchNo={appData.seq}/>);

                    const launchersData = ModelElementUtil.getInnerWrapFixed(projectRootWrap, 'release', 'launchers').data as ModelUtil.NodeGenericItems;
                    const launcher = launchersData.items
                        .map(lw => (lw.data as NodeLauncher.Data))
                        .find(l => l.appId === app.id);
                    if (launcher != undefined) {
                        props.store.system.canDialogClose = true;
                        props.store.system.dialog = (<AppReader.Dialog projectRootWrap={projectRootWrap} launchNo={launcher.no} />);
                        props.dispatcher.updateStore();
                    }
                } catch (e) {
                    alert('no app!');
                }
            } break;
        }

        if (getKeyHoldState('control')) {
            switch (props.e.key) {
                case 'c': {
                    props.store.system.clipboad = {
                        source: JSON.parse(JSON.stringify(props.localState.focusNode.data)),
                        cutAction: () => {
                            // node.parent.
                        }
                    }
                    props.dispatcher.updateStore();
                } break;
                case 'v': {
                    const isEnable = props.dispatcher.system.isAcceptClipboardPaste(props.localState.focusNode);
                    if (isEnable) {

                        const clipboad = props.store.system.clipboad;
                        assert(clipboad != null, 'clipboad is null.');
                        ModelElementUtil.addElementNodeDeep(node, clipboad.source);
                        props.store.system.clipboad = null;
                        // 追加したノードを選択した状態にする
                        props.localState.focusNode = node.children[node.children.length - 1];
                        props.invalidate();
                    }
                } break;
            }
        }

        props.localState.keyHolds.forEach(k => {
            if (props.e.key === k.key) k.on = true;
        });
    }
    export const keyUp = (props: Props) => {

        props.localState.keyHolds.forEach(k => {
            if (props.e.key === k.key) k.on = false;
        });
    }
}

export default KeyInputManager;

const _AppFrame = styled.div<{
    marginTop: number;
    marginLeft: number;
}>`
    display: inline-block;
    position: absolute;
    z-index: 11;
    top: ${props => props.marginTop}px;
    left: ${props => props.marginLeft}px;
    width: calc(100% - ${props => props.marginLeft * 2}px);
    height: calc(100% - ${props => props.marginTop * 2}px);
    background-color: #000405e6;
    /* border: 1px solid #474747; */
    border-radius: 4px;
    box-shadow: 10px 15px 15px #0000004b;
    overflow-y: auto;
`;