import { useEffect, useState, useContext, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import ModelEditDialog from "../../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import assert from "assert";
import { TabbedPane } from "../../../../../../../common/component/tab/tabbedPane";
import styled from "styled-components";
import ScopeManager from "../../../option/scopeManager";
import StyleChooser from "../../decrare/styleChooser";
import TagUtil from "./tagUtil";

namespace NodeTagImg {

    type LocalState = {
        tabIndex: number,
        url: FormUtil.CheckableValue;
        designRefs: StyleChooser.ReferState[];
        actionOnclick: TagUtil.ListenerForm;
        actionOnContext: TagUtil.ListenerForm;
    }
    export type NodeData = {
        url: string;
        desrefs: StyleChooser.Refer[];
        listener: TagUtil.Listener;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;
        const [actions, valueKeys, styleItems, dispatchItems] = useMemo(() => {
            const funcRootwrap = ModelElementUtil.getAppRootWrap(manageItems.focusNode);

            const states = ModelElementUtil.getStatesFromApp(funcRootwrap);

            const ownerNode = ModelElementUtil.getOwnerCompFromCurrent(manageItems.focusNode);
            const prpflds = ModelElementUtil.getPrpfldsFromCompdef({ type: 'compdef', data: ownerNode });
            const actions = ModelElementUtil.getActionsFromCurrent(manageItems.focusNode);
            const caches = ModelElementUtil.getRetentionCachesFromCurrent(manageItems.focusNode);

            const scope = store.system.scope;
            const dispatchItems = scope.dispatchItems as ScopeManager.DispatchItem[];
            const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];
            const cacheValueKeys = scope.cacheValueKeys as ScopeManager.ValueKeyField[];
            const propFieldValueKeys = scope.propFieldValueKeys as ScopeManager.ValueKeyField[];
            const valueKeys = stateValueKeys
                .concat(cacheValueKeys)
                .concat(propFieldValueKeys);

            const styleItems = scope.styleItems as ScopeManager.StyleItem[];
            return [actions, valueKeys, styleItems, dispatchItems];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            tabIndex: 0,
            url: { value: '', errors: [] },
            designRefs: [],
            actionOnclick: TagUtil.getInitialListenerForm(),
            actionOnContext: TagUtil.getInitialListenerForm(),
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [
            localState.url,
        ];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as NodeData;
                localState.url.value = data.url;
                localState.actionOnclick = TagUtil.getMappedListenerStateFromData(data.listener.click, dispatchItems);
                localState.designRefs = StyleChooser.getMappedStyleStates(data.desrefs, { styleItems, phase: 'tag' });
                invalidate();
            }
        }, []);

        const hasErrorInherits = StyleChooser.hasErrorDesignRefers(localState.designRefs);
        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            if (hasErrorInherits) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const listener: TagUtil.Listener = {
                click: TagUtil.getMappedListenerDataFromState(localState.actionOnclick),
            }
            const data: NodeData = {
                url: localState.url.value,
                desrefs: StyleChooser.getMappedReferDatas(localState.designRefs),
                listener
            }
            setTempData(data);
        }, [localState]);

        const infoTabJsx = (<>
            <FormUtil.BorderPanel
                title="Master"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="URL"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.url}
                            setCheckable={(checkable) => {
                                localState.url = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                        />}
                    />
                </>}
            />
            {localState.tabIndex !== 0 ? <></> : StyleChooser.getTotalStyleMonitor(localState.designRefs, { styleItems, phase: 'tag' })}
        </>);


        const InheritTab = StyleChooser.Component({
            localState: { refers: localState.designRefs },
            invalidate,
            props: { styleItems, phase: 'tag' }
        });

        const getListenerTabJsx = () => {
            return (
                <TagUtil.DispatchListeners
                    owner={localState}
                    invalidate={invalidate}
                    listeners={[
                        { method: 'click', propName: 'actionOnclick' }
                    ]}
                    dispatches={dispatchItems}
                />
            );
        }

        return (<_TabFrame>
            <TabbedPane
                tabElements={[
                    { name: 'Master', cont: <_TabItemWrap>{infoTabJsx}</_TabItemWrap>, enable: !hasErrorInherits },
                    { name: 'Style', cont: <_TabItemWrap>{InheritTab}</_TabItemWrap>, enable: true },
                    { name: 'Listener', cont: <_TabItemWrap>{getListenerTabJsx()}</_TabItemWrap>, enable: !hasErrorInherits },
                ]}
                activeNo={localState.tabIndex}
                selectTabIndex={(index) => {
                    localState.tabIndex = index;
                    invalidate();
                }}
            />
        </_TabFrame>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'tagimg';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeTagImg;

const _TabFrame = styled.div`
    display: inline-block;
    margin: 3px 0 0 3px;
    width: calc(100% - 6px);
    height: calc(100% - 46px);
`;
const _TabItemWrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #c5d3dd;
`;
