import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelEditDialog from "../../modelEditDialog";
import ModelElementUtil from "../../util/modelElementUtil";
import assert from "assert";
import VariableChooser from "../proc/variableChooser";
import NodeUpdate from "./nodeUpdate";

namespace AssignEditor {

    type LocalState = {
        rootTarget: FormUtil.CheckableValue;
        rootId: FormUtil.CheckableValue;
        innerFieldForms: VariableChooser.InnerField[];
        dest: FormUtil.CheckableValue;
        cloneType: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            rootTarget: { value: '', errors: [] },
            rootId: { value: '', errors: [] },
            innerFieldForms: [],
            dest: { value: '', errors: [] },
            cloneType: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [models, states, args, caches, propFields] = useMemo(() => {
            const funcRootwrap = ModelElementUtil.getAppRootWrap(manageItems.focusNode);

            const parentWrap = (manageItems.focusNode.parent?.parent?.data) as ModelUtil.WrapElement;
            const isDispatch = parentWrap.type === 'func'

            // 自身がDispach配下のキャッシュである場合のみ取得する
            const states = ModelElementUtil.getStatesFromApp(funcRootwrap);
            const args = !isDispatch ? undefined : ModelElementUtil.getArgumentFromCurrent(manageItems.focusNode);

            const caches = ModelElementUtil.getRetentionCachesFromCurrent(manageItems.focusNode);
            const propFields = ModelElementUtil.getPropFieldsFromCurrent(manageItems.focusNode);
            const models = ModelElementUtil.getAppLocalDtypes(funcRootwrap);

            // const destKeys =;

            return [models, states, args, caches, propFields];
        }, []);

        const arg: VariableChooser.Argument = {
            localState, states, caches, models, propFields
        }

        useEffect(() => {
            const getTargetInitail = (): VariableChooser.RootTargetType | '' => {
                if (states == null) return 'cache'
                else if (caches == null) return 'state';
                return '';
            }

            localState.rootTarget.value = getTargetInitail();
            if (props.temp.data != null) {
                const data = props.temp.data as ModelUtil.NodeAssignBak;
                VariableChooser.mappingDataToForm(arg, data);

                localState.dest.value = data.dest;
                localState.cloneType.value = data.cloneType ?? '';
            }
            invalidate();
        }, []);

        const targetFroms = [localState.rootId, localState.dest, localState.cloneType];
        const targetFormsModInner = useMemo(() => {
            return localState.innerFieldForms.map(inner => inner.form);
        }, [localState.innerFieldForms]);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.concat(targetFormsModInner).find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const levelProps: string[] = localState.innerFieldForms.map(inner => {
                return inner.form.value;
            });

            const data: ModelUtil.NodeAssignBak = {
                target: localState.rootTarget.value as VariableChooser.RootTargetType,
                rootId: localState.rootId.value,
                levelProps,
                dest: localState.dest.value,
                cloneType: DataUtil.blankToUndefined(localState.cloneType.value) as NodeUpdate.CloneType | undefined,
            }
            setTempData(data);
        }, [...targetFroms, targetFormsModInner]);

        const [last, requestType, directName] = useMemo(() => {
            const last = VariableChooser.getLastFieldInfo(arg);

            const getRequestType = (): null | ModelUtil.Field => {
                if (last == null) return null;
                let array = last.array;
                if (last.isIndex) array--;
                return {
                    dataType: last.dataType,
                    array,
                    structId: last.structId
                };
            }

            const requestType = getRequestType();

            let directName = '-';
            if (requestType != null) {
                switch (requestType.dataType) {
                    case 'string': directName = 'Text'; break;
                    case 'number': directName = 'Number'; break;
                    case 'boolean': directName = 'Boolean'; break;
                }
            }
            return [last, requestType, directName];
        }, [localState.rootId, localState.innerFieldForms]);

        return (<>
            <VariableChooser.Component
                arg={arg}
                invalidate={invalidate}
            />
            <FormUtil.BorderPanel
                title="assign"
                isVisible={requestType != null}
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Destination"
                        jsx={<>
                            {/* <FormUtil.Combobox
                                width={140}
                                checkable={localState.referType}
                                setCheckable={(checkable) => {
                                    localState.referType = checkable;
                                    invalidate();
                                }}
                                list={ModelUtil.ObjectTypes.map(item => {
                                    return { value: item, labelText: item }
                                })}
                                validates={[]}
                            /> */}
                            <FormUtil.Combobox
                                // marginLeft={4}
                                width={284}
                                checkable={localState.dest}
                                setCheckable={(checkable) => {
                                    localState.dest = checkable;
                                    invalidate();
                                }}
                                headBlank
                                list={caches
                                    .filter(c => {
                                        if (requestType == null) return false;
                                        return requestType.array === c.array &&
                                            requestType.dataType === c.dataType &&
                                            requestType.structId === c.structId;
                                    })
                                    .map(c => ModelUtil.getFieldListItem(c))}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />
                        </>}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Instance"
                        isVisible={(requestType != null && (requestType.array > 0 || requestType.dataType === 'struct'))}
                        jsx={<FormUtil.Combobox
                            width={210}
                            checkable={localState.cloneType}
                            setCheckable={(checkable) => {
                                localState.cloneType = checkable;
                                invalidate();
                            }}
                            list={NodeUpdate.CloneTypes.map(item => {
                                return { value: item, labelText: item }
                            })}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'assignbak';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default AssignEditor;
