import styled, { css } from "styled-components";
import { useState, useContext, useMemo, useReducer, useEffect } from "react";
import { GlobalContext } from "../../entry/systemEntry";
import FormUtil from "../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../common/component/form/validateUtil";
import StoreDevelop from "../../../redux/store/storeDevelop";
import assert from "assert";
import ServerUtil from "../../../../common/serverUtil";
import StoreUser from "../../../redux/store/storeUser";
import ConfirmDialog from "../../../../common/component/dialog/confirmDialog";

namespace DevManageTab {

    type LocalState = {
        name: FormUtil.CheckableValue;
        password: FormUtil.CheckableValue;
        outline: FormUtil.CheckableValue;
    }

    const DEFAULT_MANAGE_DATA: StoreUser.Devmd = {
        seq: -1,
        owner: -1,
        name: '',
        password: '',
        outline: '',
        source: ''
    };
    export const Component = (props: {
    }) => {

        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            name: { value: '', errors: [] },
            password: { value: '', errors: [] },
            outline: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });

        const project = store.project;
        const hasProject = project != null;
        const devStore = store.develop;
        const isManage = devStore.manageData != null;
        const isEdit = devStore.isEditManage;
        const setEditManage = (flg: boolean) => {
            devStore.isEditManage = flg;
            invalidate();
        }
        const isCreate = !isManage && isEdit;

        const cacheData: StoreUser.Devmd = devStore.manageData != null ? devStore.manageData : DEFAULT_MANAGE_DATA;

        const mappingForm = () => {
            localState.name.value = cacheData.name;
            localState.password.value = cacheData.password;
            localState.outline.value = cacheData.outline;
            // invalidate();
        }

        useEffect(() => {
            mappingForm();
            invalidate();
        }, [devStore.manageData]);

        const targetFroms = [localState.name, localState.outline];
        const isInputOK = useMemo(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                return false;
            }
            return true;
        }, [...targetFroms]);

        return (
            <_Frame>
                <FormUtil.ButtonRecord
                    align="left"
                    buttons={[
                        {
                            label: !isManage ? 'Start manage' : 'Edit',
                            isEnable: hasProject && !isEdit,
                            callback: () => {
                                setEditManage(true);
                            }
                        },
                        // {
                        //     label: 'Unmanage',
                        //     isEnable: isManage && !devStore.isEditManage,
                        //     callback: () => {
                        //         devStore.manageData = null;
                        //         mappingForm();
                        //         invalidate();
                        //     }
                        // }
                    ]}
                />
                <FormUtil.BorderPanel
                    title="properties"
                    isEnable={isManage || isEdit}
                    height={400}
                    innerJsx={<>
                        <FormUtil.FormRecord
                            titleLabel="Develop id"
                            jsx={<FormUtil.FixedText
                                width={400}
                                value={devStore.manageData == null ? '-' : ServerUtil.getHashedKeyFromDevSeq(devStore.manageData.seq)}
                            />}
                        />
                        <FormUtil.FormRecord
                            titleLabel="Name"
                            jsx={<FormUtil.TextField
                                width={400}
                                isReadOnly={!isEdit}
                                checkable={localState.name}
                                setCheckable={(checkable) => {
                                    localState.name = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={!isEdit ? [] : [
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    },
                                    {
                                        checker: (value) => ValidateUtil.checkStringLength(value, 1, 32),
                                        errorType: "length"
                                    },
                                ]}
                            />}
                        />
                        <FormUtil.BorderPanel
                            title="outline"
                            height={100}
                            innerJsx={<FormUtil.TextArea
                                isReadOnly={!isEdit}
                                checkable={localState.outline}
                                setCheckable={(checkable) => {
                                    localState.outline = checkable;
                                    invalidate();
                                }}

                            />}
                        />
                        <FormUtil.FormRecord
                            titleLabel="Password"
                            jsx={<FormUtil.TextField
                                width={160}
                                isReadOnly={!isEdit}
                                checkable={localState.password}
                                setCheckable={(checkable) => {
                                    localState.password = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={!isEdit ? [] : [
                                    {
                                        checker: (value) => ValidateUtil.checkStringLength(value, 0, 16),
                                        errorType: "length"
                                    },
                                    {
                                        checker: (value) => ValidateUtil.checkPasswordChars(value),
                                        errorType: "value"
                                    },
                                ]}
                            />}
                        />
                        <FormUtil.ButtonRecord
                            align="right"
                            buttons={[
                                {
                                    label: 'Cancel',
                                    isEnable: isEdit,
                                    callback: () => {
                                        mappingForm();
                                        setEditManage(false);
                                    }
                                },
                                {
                                    label: isCreate ? 'Create' : 'Update',
                                    isEnable: isEdit && isInputOK,
                                    callback: () => {
                                        (async () => {
                                            assert(store.user != null, 'store.userがnullであってはならない');
                                            assert(store.project != null, 'store.projectがnullであってはならない');
                                            const source = (JSON.stringify(store.project) as string);
                                            // let encodedSource = Buffer.from(source).toString('base64');

                                            // const encodedSource = ServerUtil.utf8_to_b64(source);
                                            const encodedSource = ServerUtil.gZip(source);
                                            // console.log(encodedSource);
                                            const owner = store.user.seq;
                                            const name = localState.name.value;
                                            const password = localState.password.value;
                                            const outline = localState.outline.value;
                                            let seq = devStore.manageData == null ? -1 : devStore.manageData.seq;
                                            if (isCreate) {
                                                const response = await ServerUtil.sendQueryRequestToAPI('select', `
                                                    select max(seq) maxSeq from devmd;
                                                    `
                                                );
                                                const results: { maxSeq: number }[] = await response.json();
                                                seq = results[0].maxSeq == null ? 0 : (results[0].maxSeq + 1);
                                                // console.log(seq);
                                                await ServerUtil.sendQueryRequestToAPI('update', `
                                                    insert into devmd
                                                    values(
                                                        ${seq}, ${owner}, '${name}',
                                                        '${password}',
                                                        '${outline}',
                                                        '${encodedSource}'
                                                    )
                                                    `
                                                );
                                                store.system.dialog = <ConfirmDialog.Component
                                                    message="Upload successful!"
                                                />;
                                            } else {
                                                console.log(encodedSource.length);
                                                await ServerUtil.sendQueryRequestToAPI('update', `
                                                    update devmd
                                                    set name='${name}', outline='${outline}', password='${password}', source='${encodedSource}'
                                                    where seq=${seq}
                                                `);
                                                store.system.dialog = <ConfirmDialog.Component
                                                    message="Modified successful!"
                                                />;
                                            }
                                            // console.log(seq);
                                            devStore.manageData = {
                                                seq,
                                                owner,
                                                name,
                                                password: password,
                                                outline: localState.outline.value,
                                                source: encodedSource,
                                            };
                                            setEditManage(false);
                                            dispatcher.updateStore();
                                        })();
                                    }
                                }
                            ]}
                        />
                    </>}
                />
            </_Frame>
        );
    }
}

export default DevManageTab;

const _Frame = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #cfd7dae6;
`;
