import { useEffect, useState, useContext, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import ModelEditDialog from "../../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import assert from "assert";
import { TabbedPane } from "../../../../../../../common/component/tab/tabbedPane";
import styled from "styled-components";
import ScopeManager from "../../../option/scopeManager";
import StyleChooser from "../../decrare/styleChooser";
import TagUtil from "./tagUtil";
import NodeTagdiv from "./nodeTagDiv";

namespace NodeTagInput {

    type LocalState = {
        tabIndex: number,
        method: FormUtil.CheckableValue;
        useHeadBlank: boolean;
        listVar: FormUtil.CheckableValue;
        converter: FormUtil.CheckableValue;
        isMapper: boolean;
        owner: FormUtil.CheckableValue;
        isVar: boolean;
        fieldName: FormUtil.CheckableValue;
        attrs: FormUtil.CheckableValue;
        designRefs: StyleChooser.ReferState[];
        targetPointId: FormUtil.CheckableValue;
        clickListenerId: TagUtil.ListenerForm;
        focusListenerId: TagUtil.ListenerForm;
        changeListenerId: TagUtil.ListenerForm;
    }
    export const Methods = ['text', 'number', 'list', 'textarea', 'date'] as const;
    // export type Method = 'clone' | 'build';
    export type Method = typeof Methods[number];
    export type ListProps = {
        headBlank: boolean;
        listVar: string;
        converter?: string;
    }
    export type Mapper = {
        owner: string;
        /** フィールド名（直接指定） */
        fldnam?: string;
        /** フィールド名（変数指定） */
        fldvar?: string;
    }
    export type NodeData = {
        mapper?: Mapper;
        method: Method;
        listProps?: ListProps;
        attrs?: NodeTagdiv.Attr[];
        desrefs: StyleChooser.Refer[];
        listener: TagUtil.Listener;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;
        const [dtypes, actions, valueKeys, styleItems, dispatchItems] = useMemo(() => {

            const actions = ModelElementUtil.getActionsFromCurrent(manageItems.focusNode);
            const dtypes = ModelElementUtil.getReferableDtypes(manageItems.focusNode);

            const scope = store.system.scope;
            const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];
            const cacheValueKeys = scope.cacheValueKeys as ScopeManager.ValueKeyField[];
            const dispatchItems = scope.dispatchItems as ScopeManager.DispatchItem[];
            const propFieldValueKeys = scope.propFieldValueKeys as ScopeManager.ValueKeyField[];
            const valueKeys = stateValueKeys
                .concat(cacheValueKeys)
                .concat(propFieldValueKeys);
            const styleItems = scope.styleItems as ScopeManager.StyleItem[];
            return [dtypes, actions, valueKeys, styleItems, dispatchItems];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            tabIndex: 0,
            method: { value: '', errors: [] },
            useHeadBlank: true,
            listVar: { value: '', errors: [] },
            converter: { value: '', errors: [] },
            isMapper: true,
            owner: { value: '', errors: [] },
            isVar: false,
            fieldName: { value: '', errors: [] },
            attrs: { value: '', errors: [] },
            designRefs: [],
            targetPointId: { value: '', errors: [] },
            clickListenerId: TagUtil.getInitialListenerForm(),
            focusListenerId: TagUtil.getInitialListenerForm(),
            changeListenerId: TagUtil.getInitialListenerForm(),
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });


        const matchMethod = (method: Method) => {
            return method === localState.method.value;
        }
        const getVarInfo = () => {
            const varInfo = valueKeys.find(v => v.key === localState.listVar.value);
            assert(varInfo != undefined, 'varInfoが見つからない。');
            return varInfo;
        }
        const isNeedComverter = () => {
            if (localState.listVar.value === '') return false;
            const varInfo = getVarInfo();
            return varInfo.dataType !== 'string';
        }

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as NodeData;
                localState.method.value = data.method;
                localState.isMapper = false;
                if (data.mapper != undefined) {
                    localState.isMapper = true;
                    localState.owner.value = data.mapper.owner;
                    if (data.mapper.fldnam != undefined) {
                        localState.isVar = false;
                        localState.fieldName.value = data.mapper.fldnam;
                    } else if (data.mapper.fldvar != undefined) {
                        localState.isVar = true;
                        localState.fieldName.value = data.mapper.fldvar;
                    }
                }
                switch (data.method) {
                    case 'list': {
                        assert(data.listProps != undefined, 'methodがlistであるにも関わらず、listPropsがundefinedであってはならない。');
                        localState.useHeadBlank = data.listProps.headBlank;
                        localState.listVar.value = data.listProps.listVar;
                        localState.converter.value = data.listProps.converter ?? '';
                    } break;
                }
                localState.attrs.value = (() => {
                    if (data.attrs == undefined) return '';
                    return data.attrs.map(attr => `${attr.key}:${attr.value}`).join('\n');
                })();
                localState.targetPointId.value = data.listener.point ?? '';
                localState.clickListenerId = TagUtil.getMappedListenerStateFromData(data.listener.click
                    , dispatchItems);
                localState.focusListenerId = TagUtil.getMappedListenerStateFromData(data.listener.focus
                    , dispatchItems);
                localState.changeListenerId = TagUtil.getMappedListenerStateFromData(data.listener.change
                    , dispatchItems);
                localState.designRefs = StyleChooser.getMappedStyleStates(data.desrefs, { styleItems, phase: 'tag' });
                invalidate();
            }
        }, []);

        const fields = useMemo(() => {
            const mapper = valueKeys.find(m => m.key === localState.owner.value);
            if (mapper == undefined) return [];
            // const field = ModelElementUtil.getChoosedField(mapper, { states, caches: [], models })
            const model = dtypes.find(m => m.id === mapper.structId);
            assert(model != null, 'model is null.');
            return model.fields;
        }, [localState.owner]);

        const targetFroms = [
            localState.method,
            localState.attrs,
        ];

        if (matchMethod('list')) {
            targetFroms.push(localState.listVar);
            if (isNeedComverter()) {
                targetFroms.push(localState.converter);
            }
        }
        if (localState.isMapper) {
            targetFroms.push(localState.owner);
            targetFroms.push(localState.fieldName);
        } else {

        }
        const hasErrorInherits = StyleChooser.hasErrorDesignRefers(localState.designRefs);
        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            if (hasErrorInherits) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const listener: TagUtil.Listener = {
                point: DataUtil.blankToUndefined(localState.targetPointId.value),
                click: TagUtil.getMappedListenerDataFromState(localState.clickListenerId),
                focus: TagUtil.getMappedListenerDataFromState(localState.focusListenerId),
                change: TagUtil.getMappedListenerDataFromState(localState.changeListenerId),
            }
            let mapper: Mapper | undefined = undefined;
            if (localState.isMapper) {
                mapper = {
                    owner: localState.owner.value,
                    fldnam: !localState.isVar ? localState.fieldName.value : undefined,
                    fldvar: localState.isVar ? localState.fieldName.value : undefined
                }
            }
            let listProps: ListProps | undefined = undefined;
            const method = localState.method.value as Method;
            switch (method) {
                case 'list': {
                    listProps = {
                        headBlank: localState.useHeadBlank,
                        listVar: localState.listVar.value,
                        converter: DataUtil.blankToUndefined(localState.converter.value)
                    }
                } break;
            }
            let attrs: undefined | NodeTagdiv.Attr[] = undefined;
            if (localState.attrs.value !== '') {
                let hasError = false;
                attrs = [];
                localState.attrs.value.split('\n').some(attr => {
                    const items = attr.split(':');
                    if (items.length === 2) {
                        attrs?.push({
                            key: items[0], value: items[1]
                        })
                    } else {
                        hasError = true;
                        return 1;
                    }
                });
                if (hasError) {
                    setInputOK(false);
                    return;
                }
            }
            const data: NodeData = {
                method,
                mapper,
                listProps,
                attrs,
                desrefs: StyleChooser.getMappedReferDatas(localState.designRefs),
                listener
            }
            setTempData(data);
        }, [localState]);


        const formTabJsx = (<>
            <FormUtil.BorderPanel
                title="Form"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Method"
                        jsx={<FormUtil.Combobox
                            width={250}
                            checkable={localState.method}
                            setCheckable={(checkable) => {
                                localState.method = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={Methods.map(m => ({ labelText: m, value: m }))}
                            isEnabled={true}
                        />}
                    />
                    {(() => {
                        if (localState.method.value === '') return <></>;
                        const jsxList: JSX.Element[] = [];
                        const method = localState.method.value as Method;

                        const addForm = (titleLabel: string, jsx: JSX.Element, isEnable?: boolean) => {
                            jsxList.push(
                                <FormUtil.FormRecord key={jsxList.length}
                                    isEnabled={isEnable ?? true}
                                    titleLabel={titleLabel}
                                    jsx={jsx}
                                />
                            );
                        }
                        if (method === 'list') {
                            addForm("Head Blank", (
                                <FormUtil.SwitchTwoFace
                                    label1="OFF"
                                    label2="ON"
                                    width={160}
                                    rate1={50}
                                    rate2={50}
                                    isUse={localState.useHeadBlank}
                                    callback={() => {
                                        localState.useHeadBlank = !localState.useHeadBlank;
                                        invalidate();
                                    }}
                                />
                            ));
                            addForm("List Var", (
                                <FormUtil.Combobox
                                    width={250}
                                    checkable={localState.listVar}
                                    setCheckable={(checkable) => {
                                        localState.listVar = checkable;
                                        invalidate();
                                    }}
                                    extend={() => {
                                        localState.converter.value = '';
                                    }}
                                    headBlank
                                    list={valueKeys
                                        // 配列変数のみ表示する
                                        .filter(v => v.array >= 1)
                                        .map(v => ({ labelText: v.key, value: v.key }))}
                                    validates={[
                                        {
                                            checker: (value) => ValidateUtil.checkRequired(value),
                                            errorType: "required"
                                        }
                                    ]}
                                />
                            ));
                            addForm("Data Type", (
                                <FormUtil.FixedText
                                    width={250}
                                    value={(() => {
                                        if (localState.listVar.value === '') return '-';
                                        const varInfo = getVarInfo();
                                        return ModelUtil.getField(varInfo);
                                    })()}
                                />
                            ));
                            addForm("Converter", (
                                <FormUtil.Combobox
                                    width={250}
                                    checkable={localState.converter}
                                    setCheckable={(checkable) => {
                                        localState.converter = checkable;
                                        invalidate();
                                    }}
                                    headBlank
                                    list={dispatchItems
                                        .filter(f => {
                                            if (f.ret != undefined) {
                                                const ret = f.ret;
                                                const dtype = dtypes.find(t => t.id === ret.structId);
                                                assert(dtype != undefined, 'dtypeが見つからない。');
                                                if (dtype.fields.length === 2) {
                                                    const valueField = dtype.fields[0];
                                                    const labelField = dtype.fields[1];
                                                    if (valueField.array === 0 && valueField.dataType === 'string'
                                                        && labelField.array === 0 && labelField.dataType === 'string') {
                                                        return true;
                                                    }
                                                }
                                            }
                                            return false;
                                        })
                                        .map(f => ({ labelText: f.id, value: f.id }))}
                                    isEnabled={true}
                                    validates={!isNeedComverter() ? [] : [
                                        {
                                            checker: (value) => ValidateUtil.checkRequired(value),
                                            errorType: "required"
                                        }
                                    ]}
                                />
                            ), isNeedComverter());
                        }
                        return jsxList;
                    })()}
                </>}
            />
            <FormUtil.BorderPanel
                title="mapping"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Method"
                        jsx={<FormUtil.SwitchTwoFace
                            label1="Setter"
                            label2="Mapper"
                            width={240}
                            rate1={50}
                            rate2={50}
                            isUse={localState.isMapper}
                            callback={() => {
                                localState.isMapper = !localState.isMapper;
                                localState.owner.value = '';
                                localState.fieldName.value = '';
                                invalidate();
                            }}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Owner"
                        isVisible={localState.isMapper}
                        jsx={<FormUtil.Combobox
                            width={260}
                            checkable={localState.owner}
                            setCheckable={(checkable) => {
                                localState.owner = checkable;
                                invalidate();
                            }}
                            extend={() => {
                                localState.isVar = false;
                            }}
                            headBlank
                            list={valueKeys
                                .filter(m => m.array === 0 && m.dataType === 'struct')
                                .map(m => {
                                    return { value: m.key, labelText: m.key }
                                })}
                            validates={!localState.isMapper ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Field Id"
                        isVisible={localState.isMapper}
                        isEnabled={localState.owner.value !== ''}
                        jsx={<>
                            <FormUtil.SwitchTwoFace
                                label1="Direct"
                                label2="Var"
                                width={180}
                                rate1={60}
                                rate2={40}
                                isUse={localState.isVar}
                                callback={() => {
                                    localState.isVar = !localState.isVar;
                                    localState.fieldName.value = '';
                                    invalidate();
                                }}
                            />
                            {!localState.isVar ? (
                                <FormUtil.Combobox
                                    marginLeft={4}
                                    width={220}
                                    checkable={localState.fieldName}
                                    setCheckable={(checkable) => {
                                        localState.fieldName = checkable;
                                        invalidate();
                                    }}
                                    headBlank
                                    list={fields.map(e => {
                                        return { value: e.id, labelText: e.id }
                                    })}
                                    validates={localState.owner.value === '' ? [] : [
                                        {
                                            checker: (value) => ValidateUtil.checkRequired(value),
                                            errorType: "required"
                                        }
                                    ]}
                                />
                            ) : (
                                <FormUtil.Combobox
                                    marginLeft={4}
                                    width={220}
                                    checkable={localState.fieldName}
                                    setCheckable={(checkable) => {
                                        localState.fieldName = checkable;
                                        invalidate();
                                    }}
                                    headBlank
                                    list={valueKeys
                                        .filter(v => v.array === 0 && v.dataType === 'name')
                                        .map(v => {
                                            return { value: `\${${v.key}}`, labelText: v.key }
                                        })}
                                    validates={localState.owner.value === '' ? [] : [
                                        {
                                            checker: (value) => ValidateUtil.checkRequired(value),
                                            errorType: "required"
                                        }
                                    ]}
                                />
                            )}
                        </>}
                    />
                </>}
            />
            {localState.tabIndex !== 0 ? <></> : StyleChooser.getTotalStyleMonitor(localState.designRefs, { styleItems, phase: 'tag' })}
        </>);

        const attrTab = (
            <FormUtil.BorderPanel
                title="attributes"
                height={400}
                innerJsx={<>
                    <FormUtil.TextArea
                        checkable={localState.attrs}
                        setCheckable={(checkable) => {
                            localState.attrs = checkable;
                            invalidate();
                        }}
                    />
                </>}
            />
        );

        const InheritTab = StyleChooser.Component({
            localState: { refers: localState.designRefs },
            invalidate,
            props: { styleItems, phase: 'tag' }
        });

        const getListenerTabJsx = () => {
            return (
                <TagUtil.DispatchListeners
                    owner={localState}
                    invalidate={invalidate}
                    listeners={[
                        { method: 'click', propName: 'clickListenerId' },
                        { method: 'focus', propName: 'focusListenerId' },
                        { method: 'change', propName: 'changeListenerId' }
                    ]}
                    dispatches={dispatchItems}
                />
            );
        }

        return (<_TabFrame>
            <TabbedPane
                tabElements={[
                    { name: 'Form', cont: <_TabItemWrap>{formTabJsx}</_TabItemWrap>, enable: !hasErrorInherits },
                    { name: 'Style', cont: <_TabItemWrap>{InheritTab}</_TabItemWrap>, enable: true },
                    { name: 'Attribute', cont: <_TabItemWrap>{attrTab}</_TabItemWrap>, enable: true },
                    { name: 'Listener', cont: <_TabItemWrap>{getListenerTabJsx()}</_TabItemWrap>, enable: !hasErrorInherits },
                ]}
                activeNo={localState.tabIndex}
                selectTabIndex={(index) => {
                    localState.tabIndex = index;
                    invalidate();
                }}
            />
        </_TabFrame>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'taginput';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeTagInput;

const _TabFrame = styled.div`
    display: inline-block;
    margin: 3px 0 0 3px;
    width: calc(100% - 6px);
    height: calc(100% - 46px);
`;
const _TabItemWrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #c5d3dd;
`;
