import { useState } from "react";
import { initialStore } from "../../redux/store/store";
import styled from "styled-components";
import TotalHeader from "./totalHeader";
import MainFrame from "./mainFrame";
import Splash from "./splash";
import { TOTAL_HEADER_WIDTH } from "../../../def/systemConst";
import GloballDialog from "../../../common/component/dialog/globalDialog";

const PCRoot = () => {
    const [store, setStore] = useState(initialStore);

    const splashJsx = !store.system.isSplash ? <></> : (
        <Splash
            remove={() => {
                store.system.isSplash = false;
                // store.system.dialog = <ModeSelectDialog />
                setStore({ ...store });
            }}
        />
    );

    return (<>
        {splashJsx}
        < _Wrap >
            <GloballDialog />
            <TotalHeader />
            <_MainDiv>
                <MainFrame />
            </_MainDiv>
        </_Wrap >
    </>);
}

export default PCRoot;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    min-width: 1200px;
    min-height: 900px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    /* background-color: #a3c1c7; */
    
    background-color: #8b8b8b;
`;

const _MainDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: calc(100% - ${TOTAL_HEADER_WIDTH}px);
    /* background-color: #c2c2c2; */
`;