import { useContext, useMemo } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../../entry/systemEntry";
import assert from "assert";
import StoreProject from "../../../../redux/store/storeProject";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import ModelUtil from "../util/modelUtil";
import ModelElementUtil from "../util/modelElementUtil";
import StoreSystem from "../../../../redux/store/storeSystem";

namespace ClipboardMonitor {

    export const Component = () => {

        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const getDispLabelJsx = (source: ModelUtil.WrapElement) => {
            let detail = '';
            switch (source.type) {
                case 'app':
                case 'compdef': {
                    const data = source.data as { id: string };
                    detail = data.id;
                } break;
            }
            return <><_Span color="#fff56e">{source.type}:</_Span> <_Span color="#bac8ce">{detail}</_Span></>;
        }

        const jsxes = useMemo(() => {
            const clipboad = store.system.clipboad;
            if (clipboad == null) return <_Label>None</_Label>;
            else {
                return <_Record>{getDispLabelJsx(clipboad.source)}</_Record>
            }
        }, [store.system.clipboad]);

        return (<_Wrap>
            {jsxes}
        </_Wrap>);
    }
}

export default ClipboardMonitor;

const _Wrap = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #57686b;
`;

const _Label = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: calc(100% - 4px);
    height: 28px;
    /* background-color: #f800003d; */
    margin: 1px 0 0 2px;
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    padding: 0 0 0 2px;
    box-sizing: border-box;
    line-height: 32px;
`;

const _Record = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: calc(100% - 4px);
    height: 28px;
    background-color: #2936388b;
    margin: 1px 0 0 2px;
    font-size: 18px;
    color: #ffffff88;
    font-weight: 600;
    padding: 0 0 0 2px;
    box-sizing: border-box;
    border-radius: 4px;
`;

const _Span = styled.span<{
    color: string;
}>`
    color: ${props => props.color};
`;