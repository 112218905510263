import StoreProject from "./storeProject";
import StoreDesign from "./storeDesign";
import StoreSystem from "./storeSystem";
import StoreDevelop from "./storeDevelop";
import StoreUser from "./storeUser";

/**
 * システムで使用するステート群
 */
export type Store = {
    user: StoreUser.Props | null;
    system: StoreSystem.Param;
    develop: StoreDevelop.Props;
    // func: StoreProject.Props;
    // table: StoreTable.Props;
    project: StoreProject.Props | null;
    env: StoreSystem.Env;
    design: StoreDesign.Props;
}

export const initialStore: Store = {
    // func: {
    //     define: null,
    // },
    // table: {
    //     tables:[]
    // },
    project: null,
    user: null,
    system: {
        channelIndex: -1,
        devTabIndex: 0,
        isSplash: true,
        dialog: null,
        canDialogClose: false,
        isSPMode: false,
        logoutActions: [],

        freeCache: null,
        scope: StoreSystem.getBlankScope(),
        clipboad: null,

        reader: {
            console: '',
        }
    },
    develop: {
        isEditManage: false,
        manageData: null
    },
    env: {
        serverUrl: '',
        screenRate: 1,
    },
    design: StoreDesign.getPreset('light-blue')
}