import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../../modelEditDialog";
import assert from "assert";
import ModelElementUtil from "../../../util/modelElementUtil";
import StoreSystem from "../../../../../../redux/store/storeSystem";
import ScopeManager from "../../../option/scopeManager";
import VariableChooser from "../variableChooser";
import TreeUtil from "../../../../../../../common/component/tree/treeUtil";
import NodeField from "../../var/nodeField";
import NodeFocus from "./nodeFocus";
import MonacoEditor from "@monaco-editor/react";
import { shikiToMonaco } from "@shikijs/monaco";
import { getHighlighter } from "shiki/bundle/web";
import styled from "styled-components";

namespace NodeAssignFormula {

    type LocalState = {
        fml: FormUtil.CheckableValue;
    }

    export type Data = {
        fml: string;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            fml: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [focusInfo, dtypes, states, funcargs, caches, propFields] = useMemo(() => {

            const cur = manageItems.focusNode;

            // const scope = store.system.scope;
            // const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];

            const caches = ModelElementUtil.getRetentionCachesFromCurrent(cur)
                .concat(ModelElementUtil.getFetchThensFromCurrent(cur));
            // console.log(caches);
            const states = ModelElementUtil.getReferableStates(cur);
            const dtypes = ModelElementUtil.getReferableDtypes(cur);

            const isDispatchSub = () => ModelElementUtil.isDispatchSub(cur);
            const funcargs = !isDispatchSub() ? undefined : ModelElementUtil.getArgumentFromCurrent(cur);
            const propFields = ModelElementUtil.getPropFieldsFromCurrent(cur);

            const focusInfo = NodeFocus.getFocusInfo(cur, {
                states, caches, prpflds: propFields, funcargs, dtypes
            });

            return [focusInfo, dtypes, states, funcargs, caches, propFields];
        }, []);


        useEffect(() => {

            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.fml.value = data.fml;
            }
            invalidate();
        }, []);

        const targetFroms = [
            localState.fml,
        ];

        useEffect(() => {
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const data: Data = {
                fml: localState.fml.value,
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <NodeFocus.Refer
                focusInfo={focusInfo}
            />
            {/* <FormUtil.BorderPanel
                title="formula"
                height={300}
                innerJsx={<>
                    <FormUtil.TextArea
                        checkable={localState.fml}
                        setCheckable={(checkable) => {
                            localState.fml = checkable;
                            invalidate();
                        }}
                    />
                </>}
            /> */}
            <_EditorWrap>
                <MonacoEditor
                    height={400}
                    language={'javascript'}
                    theme="dark-plus"
                    onMount={(editor, monaco) => {
                        (async () => {
                            const highlighter = await getHighlighter({
                                themes: ["dark-plus"],
                                langs: [],

                            });

                            // monaco.languages.register({ id: "js" });

                            shikiToMonaco(highlighter, monaco);
                        })();
                    }}
                    value={localState.fml.value}
                    onChange={(e) => {
                        // console.log(localState.fml.value.length);
                        localState.fml.value = e?.toString() ?? '';
                        localState.fml = {...localState.fml};
                        invalidate();
                    }}
                />
            </_EditorWrap>
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'asgnfml';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeAssignFormula;

export const _EditorWrap = styled.div`
    display: inline-block;
    margin: 4px  4px 0 4px;
    width: calc(100% - 8px);
`;