import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelEditDialog from "../../modelEditDialog";
import assert from "assert";
import ModelElementUtil from "../../util/modelElementUtil";
import StoreSystem from "../../../../../redux/store/storeSystem";
import ScopeManager from "../../option/scopeManager";
import NodeField from "../var/nodeField";

namespace NodeFetch {

    type LocalState = {
        url: FormUtil.CheckableValue;

        method: FormUtil.CheckableValue;
        mode: FormUtil.CheckableValue;
        cache: FormUtil.CheckableValue;
        credentials: FormUtil.CheckableValue;
        contentType: FormUtil.CheckableValue;
        accept: FormUtil.CheckableValue;
        body: FormUtil.CheckableValue;
    }
    export type Data = {
        url: string;
        reqOpt: DataRequest;
        mngs: ModelUtil.WrapElement[];
    }

    export type DataRequest = {
        method?: string;
        mode?: string;
        cache?: string;
        credentials?: string;
        contentType?: string;
        accept?: string;
        body?: string;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        assert(store.project != null, 'project is null.');

        const [valueKeys] = useMemo(() => {
            const scope = store.system.scope;

            const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];
            const cacheValueKeys = scope.cacheValueKeys as ScopeManager.ValueKeyField[];
            const propFieldValueKeys = scope.propFieldValueKeys as ScopeManager.ValueKeyField[];
            const valueKeys = stateValueKeys
                .concat(cacheValueKeys)
                .concat(propFieldValueKeys);
            return [valueKeys];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            url: { value: 'https://xxxxx:yyyy/zzz', errors: [] },

            method: { value: 'POST', errors: [] },
            mode: { value: 'cors', errors: [] },
            cache: { value: '', errors: [] },
            credentials: { value: '', errors: [] },
            contentType: { value: 'application/json', errors: [] },
            accept: { value: 'application/json', errors: [] },
            body: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [
            localState.url, localState.method, localState.mode, localState.cache,
            localState.credentials, localState.contentType, localState.accept, localState.body
        ];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.url.value = data.url;
                localState.method.value = data.reqOpt.method ?? '';
                localState.mode.value = data.reqOpt.mode ?? '';
                localState.cache.value = data.reqOpt.cache ?? '';
                localState.credentials.value = data.reqOpt.credentials ?? '';
                localState.contentType.value = data.reqOpt.contentType ?? '';
                localState.accept.value = data.reqOpt.accept ?? '';
                localState.body.value = data.reqOpt.body ?? '';
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            let mngs: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                mngs = (props.temp.data as Data).mngs;
            }
            const data: Data = {
                url: localState.url.value,
                reqOpt: {
                    method: DataUtil.blankToUndefined(localState.method.value),
                    mode: DataUtil.blankToUndefined(localState.mode.value),
                    cache: DataUtil.blankToUndefined(localState.cache.value),
                    credentials: DataUtil.blankToUndefined(localState.credentials.value),
                    contentType: DataUtil.blankToUndefined(localState.contentType.value),
                    accept: DataUtil.blankToUndefined(localState.accept.value),
                    body: DataUtil.blankToUndefined(localState.body.value),
                },
                mngs
            }
            setTempData(data);
        }, [...targetFroms]);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="URL"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.url}
                            setCheckable={(checkable) => {
                                localState.url = checkable;
                                invalidate();
                            }}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="request options"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Method"
                        jsx={<FormUtil.TextField
                            width={150}
                            checkable={localState.method}
                            setCheckable={(checkable) => {
                                localState.method = checkable;
                                invalidate();
                            }}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Mode"
                        jsx={<FormUtil.TextField
                            width={150}
                            checkable={localState.mode}
                            setCheckable={(checkable) => {
                                localState.mode = checkable;
                                invalidate();
                            }}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Cache"
                        jsx={<FormUtil.TextField
                            width={200}
                            checkable={localState.cache}
                            setCheckable={(checkable) => {
                                localState.cache = checkable;
                                invalidate();
                            }}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Credentials"
                        jsx={<FormUtil.TextField
                            width={250}
                            checkable={localState.credentials}
                            setCheckable={(checkable) => {
                                localState.credentials = checkable;
                                invalidate();
                            }}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="request headers"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Accept"
                        jsx={<FormUtil.TextField
                            width={250}
                            checkable={localState.accept}
                            setCheckable={(checkable) => {
                                localState.accept = checkable;
                                invalidate();
                            }}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="ContentType"
                        jsx={<FormUtil.TextField
                            width={250}
                            checkable={localState.contentType}
                            setCheckable={(checkable) => {
                                localState.contentType = checkable;
                                invalidate();
                            }}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="request body"
                height={200}
                innerJsx={<>
                    <FormUtil.TextArea
                        checkable={localState.body}
                        setCheckable={(checkable) => {
                            localState.body = checkable;
                            invalidate();
                        }}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'fetch';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeFetch;
