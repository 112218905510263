import styled, { css } from "styled-components";
import { useContext, useMemo, useState } from "react";
import Styles from "../../../../def/design/styles";
import { GlobalContext } from "../../entry/systemEntry";
import ModelElementUtil from "./util/modelElementUtil";
import assert from "assert";
import AbstractModelEditor from "./editor/abstractModelEditor";
import NodeApp from "./editor/nodeApp";
import ModelUtil from "./util/modelUtil";
import TreeUtil from "../../../../common/component/tree/treeUtil";
import nodeView from "./editor/ui/nodeView";
import NodeIterate from "./editor/nodeIterate";
import SpanEditor from "./editor/ui/spanEditor";
import ButtonEditor from "./editor/ui/buttonEditor";
import NodeFunction from "./editor/var/func/nodeFunction";
import NodeAccept from "./editor/condition/nodeAccept";
import NodeDtype from "./editor/nodeDtype";
import NodeCase from "./editor/condition/nodeCase";
import NodeField from "./editor/var/nodeField";
import NodeCompDef from "./editor/nodeCompDef";
import nodeInput from "./editor/ui/nodeInput";
import AssignEditor from "./editor/var/assignEditor";
import NodeCompuse from "./editor/ui/nodeCompuse";
import NodePrpclbk from "./editor/var/nodePrpclbkEditor";
import ScopeManager from "./option/scopeManager";
import NodeExecute from "./editor/proc/nodeExecute";
import NodeFocus from "./editor/proc/focus/nodeFocus";
import NodeAssign from "./editor/proc/focus/nodeAssign";
import NodeArrayAdd from "./editor/proc/focus/nodeArrayAdd";
import NodeArrayDel from "./editor/proc/focus/nodeArrayDel";
import NodeStyle from "./editor/decrare/nodeStyle";
import NodeStlarg from "./editor/decrare/nodeStlarg";
import NodeTagdiv from "./editor/ui/tag/nodeTagDiv";
import NodeTagSpan from "./editor/ui/tag/nodeTagSpan";
import NodeTagImg from "./editor/ui/tag/nodeTagImg";
import NodeTagInput from "./editor/ui/tag/nodeTagInput";
import StructManageTab from "./structManageTab";
import NodeWhen from "./editor/condition/nodeWhen";
import NodeAsgnNam from "./editor/proc/focus/nodeAsgnNam";
import NodeArrayEff from "./editor/proc/focus/nodeArrayEff";
import NodeFetch from "./editor/proc/nodeFetch";
import NodeEffect from "./editor/linkage/nodeEffect";
import NodeAssignMatch from "./editor/proc/focus/nodeAssignMatch";
import NodeArrayCat from "./editor/proc/focus/nodeArrayCat";
import NodeConsoleLog from "./editor/ui/tag/nodeConsoleLog";
import NodeCatch from "./editor/proc/nodeCatch";
import NodeAssignFormula from "./editor/proc/focus/nodeAssignFormula";
import MarkNodeFrame from "./markNode/markNodeFrame";
import NodeReturn from "./editor/var/func/nodeReturn";
import NodeLauncher from "./editor/release/nodeLauncher";

namespace StructTreeContext {

    export const ITEM_WIDTH = 200;
    export const SECOND_ITEM_WIDTH = 140;
    export const ITEM_HEIGHT = 34;
    export type Param = {
        pos: Position;
        rect: DOMRect;
    }
    export type Position = {
        x: number;
        y: number;
    }

    type Item = {
        label: string;
        isEnable?: boolean;
        callback?: () => void;
        buildCache?: () => (object | null);
        children?: Item[];
    }

    export const getEditorFromNodeType = (type: ModelUtil.NodeType) => {
        switch (type) {
            case 'app': return new NodeApp.Editor();
            case 'launcher': return new NodeLauncher.Editor();
            // case 'div': return new NodeTagdiv.Editor();
            case 'compdef': return new NodeCompDef.Editor();
            case 'view': return new nodeView.Editor();
            case 'state': return new NodeField.Editor('state');
            case 'member': return new NodeField.Editor('member');
            case 'func': return new NodeFunction.Editor('func');
            case 'return': return new NodeReturn.Editor();
            case 'dtype': return new NodeDtype.Editor();
            // case 'closure': return new NodeFunction.Editor('closure');
            case 'prpfld': return new NodeField.Editor('prpfld');
            case 'prpclbk': return new NodePrpclbk.Editor();
            case 'arg': return new NodeField.Editor('arg');
            case 'focus': return new NodeFocus.Editor();
            case 'assign': return new NodeAssign.Editor();
            case 'asgnfml': return new NodeAssignFormula.Editor();
            case 'asnmtch': return new NodeAssignMatch.Editor('asnmtch');
            case 'mtchidx': return new NodeAssignMatch.Editor('mtchidx');
            case 'asgnnam': return new NodeAsgnNam.Editor();
            case 'arradd': return new NodeArrayAdd.Editor();
            case 'arrcat': return new NodeArrayCat.Editor();
            case 'arrdel': return new NodeArrayDel.Editor();
            case 'arreff': return new NodeArrayEff.Editor();
            // case 'tab': return new TabEditor.Editor();
            // case 'update': return new NodeUpdate.Editor();
            case 'compuse': return new NodeCompuse.Editor();
            // case 'div': return new DivEditor.Editor();
            case 'case': return new NodeCase.Editor();
            case 'accept': return new NodeAccept.Editor();
            case 'tagdiv': return new NodeTagdiv.Editor();
            case 'tagspan': return new NodeTagSpan.Editor();
            case 'taginput': return new NodeTagInput.Editor();
            case 'tagimg': return new NodeTagImg.Editor();
            case 'iterate': return new NodeIterate.Editor();
            // case 'action': return new ActionEditor.Editor();
            case 'cache': return new NodeField.Editor('cache');
            case 'then': return new NodeField.Editor('then');
            case 'catch': return new NodeCatch.Editor();
            case 'style': return new NodeStyle.Editor();
            case 'desarg': return new NodeStlarg.Editor();
            case 'execute': return new NodeExecute.Editor();
            case 'fetch': return new NodeFetch.Editor();
            case 'trigger': return new NodeEffect.Editor();
            case 'log': return new NodeConsoleLog.Editor();
        }
    }

    export const Component = (props: {
        param: Param;
        close: () => void;
        ls: StructManageTab.LocalState;
    }) => {
        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const [subListFrame, setSubListFrame] = useState<{ pos: Position, items: Item[] } | null>(null);
        const [subIndex, setSubIndex] = useState<number>(-1);

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;
        const node = manageItems.focusNode;
        const wrap = manageItems.focusNode.data as ModelUtil.WrapElement;

        // const openEditDialog = (isCreate: boolean, editor: AbstractModelEditor) => {
        //     store.system.dialog = <ModelEditDialog.Conponent isCreate={isCreate} editor={editor} />;
        //     // 新規登録時は仮ノードを追加する
        //     if (isCreate) {
        //         const newNode = ModelElementUtil.addVirtualNode(manageItems, editor.getNodeType());
        //         // ダイアログ生成時はスコープを更新する
        //         store.system.scope = ScopeManager.getScopeProps(newNode);
        //     }
        //     props.close();
        //     dispatcher.updateStore();
        // }

        // const addTabFrame = () => {
        //     const tabEl: ModelUtil.WrapElement = {
        //         type: 'tabs',
        //         data: {
        //             tabs: []
        //         } as ModelUtil.NodeTabs
        //     }
        //     const newNode = ModelElementUtil.addElementNodeDeep(node, tabEl);
        //     assert(newNode != null, 'newNode is null.');
        //     node.isOpen = true;
        //     manageItems.focusNode = newNode;
        //     manageItems.setFocusNode(newNode);
        //     manageItems.invalidate();
        // }
        const isChildEmpty = node.children.length === 0;

        /**
         * コンテキストメニューを構成する
         * @returns コンテキストメニューのアイテムリスト
         */
        const build = () => {
            const itemList: Item[] = [];

            /** 削除メニューを追加する */
            const addDeleteMenu = () => {
                itemList.push({
                    label: 'Delete',
                    callback: () => {
                        const node = manageItems.focusNode;
                        const wrap = node.data as ModelUtil.WrapElement;
                        const editor = getEditorFromNodeType(wrap.type);
                        if (editor != undefined) {
                            editor.deleteExtendAction(node);
                        }
                        ModelElementUtil.removeSelf(manageItems);
                    }
                });
            }

            /** 上下に移動メニューを追加する */
            const addSwapOrderMenu = () => {
                const addSwapMenu = (isUpper: boolean) => {
                    const isDisable = isUpper ? ModelElementUtil.isTopNode : ModelElementUtil.isBottomNode;
                    itemList.push({
                        label: isUpper ? 'Order up' : 'Order down',
                        isEnable: !isDisable(node),
                        callback: () => {
                            ModelElementUtil.swapOrder(node, isUpper ? -1 : 1);
                            manageItems.invalidate();
                        }
                    });
                }
                // 並べ替え（上）
                addSwapMenu(true);
                // 並べ替え（下）
                addSwapMenu(false);
            }

            /** コピーメニューを追加 */
            const addCopyMenu = () => {
                itemList.push({
                    label: 'Copy',
                    callback: () => {
                        store.system.clipboad = {
                            source: JSON.parse(JSON.stringify(wrap)),
                            cutAction: () => {
                                // node.parent.
                            }
                        }
                    }
                });
            }
            /** ペーストメニューを追加 */
            const addPasteMenu = () => {
                let label = 'Paste';
                const isEnable = dispatcher.system.isAcceptClipboardPaste(node);
                if (isEnable) {
                    const clipboad = store.system.clipboad;
                    assert(clipboad != null, 'clipboad is null.');
                    label += ` (${clipboad.source.type})`;
                }
                itemList.push({
                    label,
                    isEnable,
                    callback: () => {
                        const clipboad = store.system.clipboad;
                        assert(clipboad != null, 'clipboad is null.');
                        ModelElementUtil.addElementNodeDeep(node, clipboad.source);
                        store.system.clipboad = null;
                        // 追加したノードを選択した状態にする
                        manageItems.setFocusNode(node.children[node.children.length - 1]);
                        manageItems.invalidate();
                    }
                });
            }

            // type EditorType = 'app' | 'compdef' | 'div' | 'tagdiv' | 'tagspan' | 'tagimg' | 'taginput' |
            //     'compuse' | 'span' | 'iterate' | 'accept' | 'tabmng' | 'tab' | 'button' |
            //     'dispatcher' | 'update' | 'assign' | 'cache' | 'state' | 'argument' | 'model' | 'field' | 'case' | 'closure' |
            //     'action' | 'execute' | 'focus' | 'assign' | 'arradd' | 'arrdel' |
            //     'input' | 'prpfld' | 'prpclbk' |
            //     'copy' | 'paste' | 'design' | 'desarg';
            type EditorType = ModelUtil.NodeType | 'copy' | 'remove';

            /** 追加メニューを追加する */
            const addAddMenuBak = (item: EditorType, enable?: boolean) => {
                const addEditorMenu = (name: string, editor: AbstractModelEditor) => {
                    itemList.push({
                        label: 'Add ' + name,
                        isEnable: enable ?? true,
                        callback: () => {
                            // openEditDialog(true, editor);
                            dispatcher.struct.openEditDialog({
                                isCreate: true,
                                editor,
                                manageItems,
                                extendAction: () => {
                                    // 新規登録時は仮ノードを追加する
                                    const newNode = ModelElementUtil.addVirtualNode(manageItems, editor.getNodeType());
                                    // ダイアログ生成時はスコープを更新する
                                    store.system.scope = ScopeManager.getScopeProps(newNode);
                                    props.close();
                                }
                            });
                        }
                    });
                }
                switch (item) {
                    case 'app': { addEditorMenu(item, new NodeApp.Editor()) } break;
                    case 'launcher': { addEditorMenu(item, new NodeLauncher.Editor()) } break;
                    case 'compdef': { addEditorMenu(item, new NodeCompDef.Editor()) } break;
                    case 'dtype': { addEditorMenu(item, new NodeDtype.Editor()) } break;
                    case 'member': { addEditorMenu(item, new NodeField.Editor('member')) } break;
                    // case 'div': { addEditorMenu(item, new DivEditor.Editor()) } break;
                    case 'compuse': { addEditorMenu(item, new NodeCompuse.Editor()) } break;
                    case 'iterate': { addEditorMenu(item, new NodeIterate.Editor()) } break;
                    // case 'tabs': {
                    //     // タブフレームを追加
                    //     itemList.push({
                    //         label: 'Add tab frame',
                    //         isEnable: enable ?? true,
                    //         callback: () => {
                    //             addTabFrame();
                    //         }
                    //     });
                    // } break;
                    // case 'tab': { addEditorMenu(item, new TabEditor.Editor()) } break;
                    case 'accept': { addEditorMenu(item, new NodeAccept.Editor()) } break;
                    case 'case': { addEditorMenu(item, new NodeCase.Editor()) } break;
                    case 'span': { addEditorMenu(item, new SpanEditor.Editor()) } break;
                    case 'button': { addEditorMenu(item, new ButtonEditor.Editor()) } break;
                    case 'func': { addEditorMenu(item, new NodeFunction.Editor('func')) } break;
                    case 'return': { addEditorMenu(item, new NodeReturn.Editor()) } break;
                    // case 'closure': { addEditorMenu(item, new NodeFunction.Editor('closure')) } break;
                    case 'focus': { addEditorMenu(item, new NodeFocus.Editor()) } break;
                    // case 'update': { addEditorMenu(item, new NodeUpdate.Editor()) } break;
                    case 'arg': { addEditorMenu(item, new NodeField.Editor('arg')) } break;
                    case 'prpfld': { addEditorMenu(item, new NodeField.Editor('prpfld')) } break;
                    case 'state': { addEditorMenu(item, new NodeField.Editor('state')) } break;
                    case 'cache': { addEditorMenu(item, new NodeField.Editor('cache')) } break;
                    case 'then': { addEditorMenu(item, new NodeField.Editor('then')) } break;
                    case 'catch': { addEditorMenu(item, new NodeCatch.Editor()) } break;
                    case 'button': { addEditorMenu(item, new ButtonEditor.Editor()) } break;
                    case 'input': { addEditorMenu(item, new nodeInput.Editor()) } break;
                    case 'assign': { addEditorMenu(item, new AssignEditor.Editor()) } break;
                    case 'trigger': { addEditorMenu(item, new NodeEffect.Editor()) } break;
                    case 'log': { addEditorMenu(item, new NodeConsoleLog.Editor()) } break;
                    // case 'cache': { addEditorMenu(item, new CacheEditor.Editor()) } break;
                }
            }

            type SummarizeMenuProps = { type: EditorType, enable?: boolean };
            /** まとめメニューを追加する */
            const addSummarizeMenu = (name: string, items: SummarizeMenuProps[]) => {
                const secondList: Item[] = [];
                const addEditorMenu = (name: string, editor: AbstractModelEditor, enable?: boolean) => {
                    secondList.push({
                        label: name,
                        isEnable: enable ?? true,
                        callback: () => {
                            // openEditDialog(true, editor);
                            dispatcher.struct.openEditDialog({
                                isCreate: true,
                                editor,
                                manageItems,
                                extendAction: () => {
                                    // 新規登録時は仮ノードを追加する
                                    const newNode = ModelElementUtil.addVirtualNode(manageItems, editor.getNodeType());
                                    // ダイアログ生成時はスコープを更新する
                                    store.system.scope = ScopeManager.getScopeProps(newNode);
                                    props.close();
                                }
                            });
                        }
                    });
                }

                items.forEach(item => {
                    const nodeType = item.type;
                    const getEditor = () => {
                        const type = nodeType as ModelUtil.NodeType;
                        const editor = getEditorFromNodeType(type);
                        assert(editor != undefined, 'editor is undefined.');
                        return editor;
                    }
                    switch (nodeType) {
                        case 'app': { addEditorMenu(nodeType, new NodeApp.Editor()) } break;
                        case 'launcher': { addEditorMenu(nodeType, new NodeLauncher.Editor()) } break;
                        case 'compdef': { addEditorMenu('component', new NodeCompDef.Editor()) } break;
                        case 'dtype': { addEditorMenu('type', new NodeDtype.Editor()) } break;
                        case 'member': { addEditorMenu(nodeType, new NodeField.Editor('member')) } break;
                        // case 'div': { addEditorMenu(editorType, new DivEditor.Editor()) } break;
                        case 'tagdiv':
                        case 'tagimg':
                        case 'taginput':
                        case 'tagspan': { addEditorMenu(nodeType, getEditor()) } break;
                        case 'compuse': { addEditorMenu('component', getEditor()) } break;
                        case 'iterate': { addEditorMenu(nodeType, new NodeIterate.Editor()) } break;
                        case 'accept': { addEditorMenu(nodeType, new NodeAccept.Editor()) } break;
                        case 'case': { addEditorMenu(nodeType, new NodeCase.Editor()) } break;
                        case 'span': { addEditorMenu(nodeType, new SpanEditor.Editor()) } break;
                        case 'button': { addEditorMenu(nodeType, new ButtonEditor.Editor()) } break;
                        case 'func': { addEditorMenu('function', new NodeFunction.Editor('func')) } break;
                        case 'return': { addEditorMenu(nodeType, new NodeReturn.Editor()) } break;
                        // case 'closure': { addEditorMenu(nodeType, new NodeFunction.Editor('closure')) } break;
                        // case 'update': { addEditorMenu(editorType, new NodeUpdate.Editor()) } break;
                        case 'focus': { addEditorMenu(nodeType, new NodeFocus.Editor()) } break;
                        case 'assign': { addEditorMenu(nodeType, new NodeAssign.Editor(), item.enable) } break;
                        case 'asgnfml': { addEditorMenu(nodeType, new NodeAssignFormula.Editor(), item.enable) } break;
                        case 'asnmtch': { addEditorMenu('assign-match', new NodeAssignMatch.Editor('asnmtch'), item.enable) } break;
                        case 'mtchidx': { addEditorMenu('assign-mtchidx', new NodeAssignMatch.Editor('mtchidx'), item.enable) } break;
                        case 'asgnnam': { addEditorMenu('assign-name', new NodeAsgnNam.Editor(), item.enable) } break;
                        case 'arradd': { addEditorMenu('array-addition', new NodeArrayAdd.Editor(), item.enable) } break;
                        case 'arrcat': { addEditorMenu('array-cat', new NodeArrayCat.Editor(), item.enable) } break;
                        case 'arrdel': { addEditorMenu('array-delete', new NodeArrayDel.Editor(), item.enable) } break;
                        case 'arreff': { addEditorMenu('array-effect', new NodeArrayEff.Editor(), item.enable) } break;
                        case 'arg': { addEditorMenu(nodeType, new NodeField.Editor('arg')) } break;
                        case 'prpfld': { addEditorMenu('field', new NodeField.Editor('prpfld')) } break;
                        case 'state': { addEditorMenu(nodeType, new NodeField.Editor('state')) } break;
                        case 'cache': { addEditorMenu(nodeType, new NodeField.Editor('cache')) } break;
                        case 'then': { addEditorMenu(nodeType, new NodeField.Editor('then'), item.enable) } break;
                        case 'catch': { addEditorMenu(nodeType, new NodeCatch.Editor(), item.enable) } break;
                        case 'execute': { addEditorMenu(nodeType, new NodeExecute.Editor()) } break;
                        case 'fetch': { addEditorMenu(nodeType, new NodeFetch.Editor()) } break;
                        case 'button': { addEditorMenu(nodeType, new ButtonEditor.Editor()) } break;
                        case 'input': { addEditorMenu(nodeType, new nodeInput.Editor()) } break;
                        // case 'assign': { addEditorMenu(editorType, new AssignEditor.Editor()) } break;
                        case 'prpclbk': { addEditorMenu('callback', new NodePrpclbk.Editor()) } break;
                        case 'style': { addEditorMenu(nodeType, new NodeStyle.Editor()) } break;
                        case 'desarg': { addEditorMenu('argument', new NodeStlarg.Editor()) } break;
                        case 'trigger': { addEditorMenu(nodeType, new NodeEffect.Editor()) } break;
                        case 'log': { addEditorMenu(nodeType, new NodeConsoleLog.Editor()) } break;
                    }
                });
                itemList.push({
                    label: `${name} >`,
                    isEnable: true,
                    children: secondList
                });
            }

            /** 更新メニューを追加する */
            const addModMenu = () => {
                const editor = getEditorFromNodeType(wrap.type);
                assert(editor != undefined, `指定したType[${wrap.type}]に該当するエディタが定義されていません。`);
                itemList.push({
                    label: 'Modify',
                    isEnable: true,
                    callback: () => {
                        // openEditDialog(false, editor);
                        dispatcher.struct.openEditDialog({
                            isCreate: false,
                            editor,
                            manageItems,
                            extendAction: props.close
                        });
                    }
                });
            }

            /** リテンション利用メニューを追加する */
            const addUseRetentionMenu = () => {
                itemList.push({
                    label: 'Use retention',
                    isEnable: true,
                    callback: () => {
                        const retentionWrap: ModelUtil.WrapElement = {
                            type: 'retention',
                            data: {
                                items: []
                            } as ModelUtil.NodeRetention
                        }
                        const innerElementWrap: ModelUtil.WrapElement = {
                            type: 'elements',
                            data: {
                                elements: wrap.data['elements'] as ModelUtil.WrapElement[]
                            } as ModelUtil.NodeElements
                        }
                        // 自身の子要素を空にする
                        wrap.data['elements'] = [];
                        node.children = [];
                        ModelElementUtil.addElementNodeDeep(node, retentionWrap);
                        ModelElementUtil.addElementNodeDeep(node, innerElementWrap);
                        manageItems.invalidate();
                        dispatcher.updateStore();
                    }
                });
            }
            /** リテンション削除メニューを追加する */
            const addRemoveRetentionMenu = () => {
                itemList.push({
                    label: 'Remove retention',
                    isEnable: true,
                    callback: () => {
                        const elements = wrap.data['elements'][1]['data']['elements'] as ModelUtil.WrapElement[];
                        // 自身の子要素を空にする
                        wrap.data['elements'] = [];
                        node.children = [];
                        elements.forEach(elementWrap => {
                            ModelElementUtil.addElementNodeDeep(node, elementWrap);
                        });
                        manageItems.invalidate();
                        dispatcher.updateStore();
                    }
                });
            }

            const isBelongRetention = () => {

                const parentType = ModelElementUtil.getBelongType(node, 'proc', 'retention', 'elements', 'tagdiv');
                switch (parentType) {
                    case 'proc':
                    case 'retention': return true;
                    case 'elements': return false;
                }
                throw new Error(`[parentType]が想定された値でない。[${parentType}]`);
            }

            const getComponentItems = (enable: boolean): SummarizeMenuProps[] => {
                return [
                    // // タブフレームを追加
                    // { type: 'tabs', enable: isChildEmpty },
                    // Divを追加
                    { type: 'tagdiv', enable },
                    // Spanを追加
                    { type: 'tagspan', enable },
                    // Inputを追加
                    { type: 'taginput', enable },
                    // Imageを追加
                    { type: 'tagimg', enable },
                    // // Divを追加
                    // { type: 'div', enable },
                    // // Spanを追加
                    // { type: 'span', enable },
                    // // Buttonを追加
                    // { type: 'button', enable },
                    // コンポーネントを追加
                    { type: 'compuse', enable },
                    // // フォームを追加
                    // { type: 'input', enable },
                ];
            };

            const getDirectiveItems = (enable?: boolean): SummarizeMenuProps[] => {
                return [
                    // 繰り返しを追加
                    { type: 'iterate', enable },
                    // 条件を追加
                    { type: 'accept', enable },
                    { type: 'case', enable },
                ];
            }

            const addRetentionSwitchMenu = (hasRetention: boolean) => {
                if (!hasRetention) {
                    // リテンション利用メニューを追加
                    addUseRetentionMenu();
                } else {
                    // リテンション削除メニューを追加
                    addRemoveRetentionMenu();
                }
            }

            if (ModelElementUtil.isSwappableNode(wrap.type)) {
                //　上下入れ替え
                addSwapOrderMenu();
            }
            switch (wrap.type) {
                case 'apps': {
                    addSummarizeMenu('Add declare', [
                        // アプリケーションを追加
                        { type: 'app' },
                    ]);
                    // ペースト
                    addPasteMenu();
                } break;
                case 'app': {
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'launchers': {
                    addSummarizeMenu('Add declare', [
                        // ランチャーを追加
                        { type: 'launcher' },
                    ]);
                    // ペースト
                    addPasteMenu();
                } break;
                case 'launcher': {
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'comps': {
                    addSummarizeMenu('Add declare', [
                        // コンポーネントを追加
                        { type: 'compdef' },
                    ]);
                    // ペースト
                    addPasteMenu();
                } break;
                case 'styles': {
                    addSummarizeMenu('Add declare', [
                        // スタイルを追加
                        { type: 'style' },
                    ]);
                    // ペースト
                    addPasteMenu();
                } break;
                case 'compdef': {
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'states': {
                    addSummarizeMenu('Add declare', [
                        // ステートを追加
                        { type: 'state' },
                    ]);
                    // ペースト
                    addPasteMenu();
                } break;
                case 'state': {
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'retention': {
                    addSummarizeMenu('Add declare', [
                        // キャッシュを追加
                        { type: 'cache' },
                        // ディスパチャを追加
                        { type: 'func' },
                        // スタイルを追加
                        { type: 'style' },
                    ]);
                    addSummarizeMenu('Add procedure', [
                        // フォーカスを追加
                        { type: 'focus' },
                        // 実行を追加
                        { type: 'execute' },
                        // ログを追加
                        { type: 'log' },
                    ]);
                    addSummarizeMenu('Add directive', getDirectiveItems());
                    // ペースト
                    addPasteMenu();
                } break;
                case 'view': {
                    // 更新
                    addModMenu();
                } break;
                case 'dtypes': {
                    addSummarizeMenu('Add declare', [
                        // 型を追加
                        { type: 'dtype' },
                    ]);
                    // ペースト
                    addPasteMenu();
                } break;
                case 'dtype': {
                    // 更新
                    addModMenu();
                    // フィールドを追加
                    addAddMenuBak('member');
                    // コピー
                    addCopyMenu();
                    // ペースト
                    addPasteMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'linkage': {
                    addSummarizeMenu('Add effect', [
                        // エフェクトを追加
                        { type: 'trigger' },
                    ]);
                } break;
                case 'trigger': {
                    //　上下入れ替え
                    addSwapOrderMenu();
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // ペースト
                    addPasteMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'member': {
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'elements': {
                    const element = wrap.data as ModelUtil.NodeElements;
                    const hasIterate = element.elements.find(mng => mng.type === 'iterate') != undefined;
                    // const hasTabMng = element.elements.find(el => el.type === 'tabs') != undefined;

                    addSummarizeMenu('Add component', getComponentItems(!hasIterate));
                    addSummarizeMenu('Add directive', getDirectiveItems(!hasIterate));
                    // ペースト
                    addPasteMenu();
                } break;
                case 'func': {
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'props': {
                    // 引数を追加
                    addSummarizeMenu('Add property', [
                        { type: 'prpfld' },
                        { type: 'prpclbk' }
                    ]);
                    // ペースト
                    addPasteMenu();
                } break;
                case 'prpfld': {
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'prpclbk': {
                    // //　上下入れ替え
                    // addSwapOrderMenu();
                    // 更新
                    addModMenu();
                    addSummarizeMenu('Add argument', [
                        { type: 'arg' },
                    ]);
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'args': {
                    // 引数を追加
                    addAddMenuBak('arg');
                    // ペースト
                    addPasteMenu();
                } break;
                case 'proc': {
                    addSummarizeMenu('Add declare', [
                        // キャッシュを追加
                        { type: 'cache' },
                        // // クロージャを追加
                        // { type: 'closure' },
                    ]);
                    addSummarizeMenu('Add procedure', (() => {
                        const list: SummarizeMenuProps[] = [
                            // フォーカスを追加
                            { type: 'focus' },
                            // 実行を追加
                            { type: 'execute' },
                            // ログを追加
                            { type: 'log' },
                            // フェッチを追加
                            // { type: 'fetch' },
                        ];
                        list.push({type: 'return'});
                        return list;
                    })());
                    addSummarizeMenu('Add directive', getDirectiveItems());
                    // ペースト
                    addPasteMenu();
                } break;
                case 'arg': {
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'return': {
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'focus': {
                    const data = wrap.data as NodeFocus.Data;
                    // //　上下入れ替え
                    // addSwapOrderMenu();
                    // 更新
                    addModMenu();
                    addSummarizeMenu('Add operation', [
                        // 代入を追加
                        { type: 'assign', enable: data.items.length === 0 },
                        { type: 'asgnfml', enable: data.items.length === 0 },
                        { type: 'asgnnam', enable: data.items.length === 0 },
                        { type: 'asnmtch', enable: data.items.length === 0 },
                        { type: 'mtchidx', enable: data.items.length === 0 },
                        // 配列への追加を追加
                        { type: 'arradd', enable: data.items.length === 0 },
                        { type: 'arrcat', enable: data.items.length === 0 },
                        { type: 'arrdel', enable: data.items.length === 0 },
                        { type: 'arreff', enable: data.items.length === 0 },
                    ]);
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'assign': {
                    // //　上下入れ替え
                    // addSwapOrderMenu();
                    // 更新
                    addModMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'asgnnam': {
                    // //　上下入れ替え
                    // addSwapOrderMenu();
                    // 更新
                    addModMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'asnmtch': {
                    // 更新
                    addModMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'asgnfml': {
                    // 更新
                    addModMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'mtchidx': {
                    // 更新
                    addModMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'arradd': {
                    // 更新
                    addModMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'mtchidx': {
                    // 更新
                    addModMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'arrcat': {
                    // 更新
                    addModMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'arrdel': {
                    // 更新
                    addModMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'arreff': {
                    // 更新
                    addModMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'tagdiv': {
                    const divElement = wrap.data as ModelUtil.NodeDiv;
                    const hasIterate = divElement.elements.find(mng => mng.type === 'iterate') != undefined;
                    // const hasTabMng = divElement.elements.find(el => el.type === 'tabs') != undefined;
                    const hasRetention = divElement.elements.find(el => el.type === 'retention') != undefined;

                    // //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    addModMenu();
                    if (!hasRetention) {
                        const enableCompItem = !hasIterate;
                        addSummarizeMenu('Add component', getComponentItems(enableCompItem));
                        addSummarizeMenu('Add directive', getDirectiveItems(enableCompItem));
                    }
                    // コピー
                    addCopyMenu();
                    // ペースト
                    addPasteMenu();
                    // リテンションメニューを追加
                    addRetentionSwitchMenu(hasRetention);

                    // 削除
                    addDeleteMenu();
                } break;
                case 'compuse': {
                    // //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();

                    // 削除
                    addDeleteMenu();
                } break;
                case 'case': {
                    // //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();

                    // 削除
                    addDeleteMenu();
                } break;
                case 'when': {
                    const whenElement = wrap.data as NodeWhen.Data;

                    // 親が条件要素かdiv要素か判断
                    // const parentType = (node.parent?.parent?.data as ModelUtil.WrapElement).type;
                    // switch (parentType) {
                    //     case 'proc':
                    //     case 'retention': {
                    //         addSummarizeMenu('Add declare', [
                    //             // キャッシュを追加
                    //             { type: 'cache' },
                    //             // ディスパチャを追加
                    //             { type: 'func' },
                    //             // スタイルを追加
                    //             { type: 'style' },
                    //         ]);
                    //         addSummarizeMenu('Add procedure', [
                    //             // フォーカスを追加
                    //             { type: 'focus' },
                    //             // 実行を追加
                    //             { type: 'execute' },
                    //         ]);
                    //     } break;
                    //     case 'elements': {
                    //         addSummarizeMenu('Add component', getComponentItems(true));
                    //         addSummarizeMenu('Add directive', getDirectiveItems(true));
                    //     } break;
                    // }
                    if (isBelongRetention()) {
                        addSummarizeMenu('Add declare', [
                            // キャッシュを追加
                            { type: 'cache' },
                            // ディスパチャを追加
                            { type: 'func' },
                            // スタイルを追加
                            { type: 'style' },
                        ]);
                        addSummarizeMenu('Add procedure', [
                            // フォーカスを追加
                            { type: 'focus' },
                            // 実行を追加
                            { type: 'execute' },
                            // ログを追加
                            { type: 'log' },
                        ]);
                    } else {
                        addSummarizeMenu('Add component', getComponentItems(true));
                        addSummarizeMenu('Add directive', getDirectiveItems(true));
                    }
                    // //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    // addModMenu(new CaseEditor.Editor());

                    // 削除
                    addDeleteMenu();
                } break;
                case 'tagspan': {
                    // //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'taginput': {
                    // //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'tagimg': {
                    // //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                // case 'span': {
                //     //　上下入れ替え
                //     addSwapOrderMenu();

                //     // 更新
                //     addModMenu(new SpanEditor.Editor());
                //     // コピー
                //     addCopyMenu();
                //     // 削除
                //     addDeleteMenu();
                // } break;
                case 'accept': {
                    const acceptElement = wrap.data as NodeAccept.Data;
                    const hasIterate = acceptElement.elements.find(mng => mng.type === 'iterate') != undefined;
                    // const hasTabMng = acceptElement.elements.find(el => el.type === 'tabs') != undefined;
                    const hasRetention = acceptElement.elements.find(el => el.type === 'retention') != undefined;

                    // 更新
                    addModMenu();

                    // 親が条件要素かdiv要素か判断
                    const parentType = (node.parent?.data as ModelUtil.WrapElement).type;
                    switch (parentType) {
                        case 'proc':
                        case 'iterate': {
                            addSummarizeMenu('Add declare', [
                                // キャッシュを追加
                                { type: 'cache' },
                                // ディスパチャを追加
                                { type: 'func' },
                                // スタイルを追加
                                { type: 'style' },
                            ]);
                            addSummarizeMenu('Add procedure', [
                                // フォーカスを追加
                                { type: 'focus' },
                                // 実行を追加
                                { type: 'execute' },
                                // ログを追加
                                { type: 'log' },
                            ]);
                            addSummarizeMenu('Add directive', getDirectiveItems());
                        } break;
                        case 'retention': {
                            addSummarizeMenu('Add declare', [
                                // キャッシュを追加
                                { type: 'cache' },
                                // ディスパチャを追加
                                { type: 'func' },
                                // スタイルを追加
                                { type: 'style' },
                            ]);
                            addSummarizeMenu('Add procedure', [
                                // フォーカスを追加
                                { type: 'focus' },
                                // 実行を追加
                                { type: 'execute' },
                                // ログを追加
                                { type: 'log' },
                            ]);
                            addSummarizeMenu('Add directive', getDirectiveItems());
                        } break;
                        case 'tagdiv':
                        case 'elements': {
                            const enableCompItem = !hasIterate;
                            addSummarizeMenu('Add component', getComponentItems(enableCompItem));
                            addSummarizeMenu('Add directive', getDirectiveItems(enableCompItem));
                            // リテンション切り替えメニューを追加
                            addRetentionSwitchMenu(hasRetention);
                        }
                    }
                    // コピー
                    addCopyMenu();
                    // ペースト
                    addPasteMenu();

                    // 削除
                    addDeleteMenu();
                } break;
                case 'iterate': {
                    const iterateElement = wrap.data as NodeIterate.Data;
                    const hasRetention = iterateElement.elements.find(el => el.type === 'retention') != undefined;

                    // //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    addModMenu();

                    // // Divを追加
                    // addAddMenuBak('div');

                    if (isBelongRetention()) {
                        addSummarizeMenu('Add declare', [
                            // キャッシュを追加
                            { type: 'cache' },
                            // ディスパチャを追加
                            { type: 'func' },
                            // スタイルを追加
                            { type: 'style' },
                        ]);
                        addSummarizeMenu('Add procedure', [
                            // フォーカスを追加
                            { type: 'focus' },
                            // 実行を追加
                            { type: 'execute' },
                            // ログを追加
                            { type: 'log' },
                        ]);
                        addSummarizeMenu('Add directive', getDirectiveItems(true));
                    } else {
                        addSummarizeMenu('Add component', getComponentItems(true));
                        addSummarizeMenu('Add directive', getDirectiveItems(true));

                        // リテンションメニューを追加
                        addRetentionSwitchMenu(hasRetention);
                    }

                    // コピー
                    addCopyMenu();
                    // ペースト
                    addPasteMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'input': {
                    // //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'cache': {
                    const data = wrap.data as NodeField.CacheData;
                    // //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    addModMenu();
                    addSummarizeMenu('Add operation', [
                        // 代入を追加
                        { type: 'assign', enable: data.items.length === 0 },
                        { type: 'asgnfml', enable: data.items.length === 0 },
                        { type: 'asnmtch', enable: data.items.length === 0 },
                        { type: 'mtchidx', enable: data.items.length === 0 },
                        { type: 'asgnnam', enable: data.items.length === 0 },
                        // 配列への追加を追加
                        { type: 'arradd', enable: data.items.length === 0 },
                        { type: 'arrcat', enable: data.items.length === 0 },
                        { type: 'arrdel', enable: data.items.length === 0 },
                        { type: 'arreff', enable: data.items.length === 0 },
                    ]);
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'style': {
                    //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    addModMenu();
                    addSummarizeMenu('Add argument', [
                        // 引数を追加
                        { type: 'desarg' },
                    ]);
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'desarg': {
                    // //　上下入れ替え
                    // addSwapOrderMenu();

                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                // case 'action': {
                //     //　上下入れ替え
                //     addSwapOrderMenu();

                //     // 更新
                //     addModMenu();
                //     // コピー
                //     addCopyMenu();
                //     // 削除
                //     addDeleteMenu();
                // } break;
                case 'execute': {
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'fetch': {
                    const data = wrap.data as NodeFetch.Data;
                    const hasChild = (type: ModelUtil.NodeType) => {
                        // console.log(`mngs:[${data.mngs.map(m => m.type).join(',')}], type:${type}`);
                        return data.mngs.find(m => m.type === type) != undefined;
                    }
                    // console.log(`hasChild('then'): ${hasChild('then')}`);
                    // 更新
                    addModMenu();
                    addSummarizeMenu('Add manage', [
                        // データ取得時の処理を追加
                        { type: 'then', enable: !hasChild('then') },
                        // エラー時の処理を追加
                        { type: 'catch', enable: !hasChild('catch') },
                    ]);
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
                case 'then':
                case 'catch': {
                    // 更新
                    addModMenu();
                    addSummarizeMenu('Add declare', [
                        // キャッシュを追加
                        { type: 'cache' },
                        // // クロージャを追加
                        // { type: 'closure' },
                    ]);
                    addSummarizeMenu('Add procedure', [
                        // フォーカスを追加
                        { type: 'focus' },
                        // 実行を追加
                        { type: 'execute' },
                        // フェッチを追加
                        { type: 'fetch' },
                        // ログを追加
                        { type: 'log' },
                    ]);
                    addSummarizeMenu('Add directive', getDirectiveItems());
                    // 削除
                    addDeleteMenu();
                } break;
                case 'log': {
                    // 更新
                    addModMenu();
                    // コピー
                    addCopyMenu();
                    // 削除
                    addDeleteMenu();
                } break;
            }

            itemList.push({
                label: 'Mark node',
                isEnable: true,
                callback: () => {
                    const bookmarks = props.ls.bookmarks;
                    bookmarks.push({
                        // key: TreeUtil.getCurKey(node),
                        id: node.id,
                        node,
                        name: `mark[${node.id}]`
                    });
                    props.ls.hideMenu = 'mark-node';
                    dispatcher.updateStore();
                },
                buildCache: () => {
                    let root = node;
                    while (root.parent != null) root = root.parent;
                    const cache: MarkNodeFrame.Cache = {
                        rootNode: root,
                        isAdd: true
                    };
                    return cache;
                }
            });
            // コンテキストメニューを閉じる
            itemList.push({
                label: 'Exit',
                callback: () => { }
            });
            return itemList;
        }

        const convertMenuItemToJsx = (itemList: Item[], isSecond: boolean) => {
            const jsxList = itemList.map((item, i) => {
                const isEnable = item.isEnable ?? true;
                return (
                    <_Item key={i}>
                        <_Inner
                            isEnable={isEnable}
                            isFocus={!isSecond && i === subIndex}
                            onClick={() => {
                                if (item.callback != undefined) {
                                    item.callback();
                                    // store.system.freeCache = null;
                                    if (item.buildCache != undefined) store.system.freeCache = item.buildCache();
                                    props.close();
                                }
                            }}
                            onMouseOver={() => {
                                if (item.children != undefined) {
                                    const totalHeight = item.children.length * ITEM_HEIGHT;
                                    const parentRect = props.param.rect;
                                    const right = parentRect.right - parentRect.x;
                                    const bottom = parentRect.bottom - parentRect.y;
                                    let x = ITEM_WIDTH;
                                    let y = ITEM_HEIGHT * i;
                                    // 右端が見切れる場合は左に表示する
                                    if (mainPos.x + x + SECOND_ITEM_WIDTH > right) {
                                        x = - SECOND_ITEM_WIDTH;
                                    }
                                    // 下端が見切れる場合は上に表示する
                                    if (mainPos.y + y + totalHeight > bottom) {
                                        y -= (totalHeight - ITEM_HEIGHT);
                                    }
                                    setSubListFrame({
                                        items: item.children,
                                        pos: { x, y }
                                    });
                                    setSubIndex(i);
                                } else if (!isSecond) {
                                    setSubListFrame(null);
                                    setSubIndex(-1);
                                }
                            }}
                        >{item.label}</_Inner>
                    </_Item>
                );
            });
            return jsxList;
        }

        const itemJsxes = useMemo(() => {
            return convertMenuItemToJsx(build(), false);
        }, [subListFrame]);

        const subListFrameJsxes = useMemo(() => {
            if (subListFrame == undefined) return [];
            return convertMenuItemToJsx(subListFrame.items, true)
        }, [subListFrame]);

        const getMainPos = (): Position => {
            const totalHeight = itemJsxes.length * ITEM_HEIGHT;
            const parentRect = props.param.rect;
            const right = parentRect.right - parentRect.x;
            const bottom = parentRect.bottom - parentRect.y;
            let x = props.param.pos.x;
            let y = props.param.pos.y;
            // console.log(`bottom:${bottom}, y:${y + totalHeight}`);
            // 右端が見切れる場合は左に表示する
            if (x + ITEM_WIDTH > right) {
                x -= ITEM_WIDTH;
            }
            // 下端が見切れる場合は上に表示する
            if (y + totalHeight > bottom) {
                y -= totalHeight;
            }
            return { x, y };
        }

        const mainPos = getMainPos();

        return (<>
            <_Frame
                x={mainPos.x}
                y={mainPos.y}
                width={ITEM_WIDTH}
            >
                {itemJsxes}
                {subListFrame == null ? <></> :
                    <_Frame
                        x={subListFrame.pos.x}
                        y={subListFrame.pos.y}
                        width={SECOND_ITEM_WIDTH}
                    >{subListFrameJsxes}</_Frame>
                }
            </_Frame>
        </>);
    }

    const addElementNodeRec = (curNode: TreeUtil.ElementNode, newElement: ModelUtil.WrapElement) => {
        const wrap = curNode.data as ModelUtil.WrapElement;
        const listName = ModelElementUtil.getListParamName(wrap.type);
        if (listName != null) {
            // (wrap.data[`${listName}`] as ModelUtil.WrapElement[]).push(newElement);
            const newNode = TreeUtil.createNode(newElement, curNode);
            curNode.children.push(newNode);

            const childListName = ModelElementUtil.getListParamName(newElement.type);
            if (childListName != null) {
                // console.log(childListName);
                (newElement.data[`${childListName}`] as ModelUtil.WrapElement[]).forEach(childWrap => {
                    addElementNodeRec(newNode, childWrap);
                    // console.log(childWrap.type);
                });
            }
            return newNode;
        }
        return null;
    }
}

export default StructTreeContext;

export const _Frame = styled.div<{
    x: number;
    y: number;
    width: number;
}>`
    display: inline-block;
    position: absolute;
    top: ${props => props.y}px;
    left: ${props => props.x}px;
    width: ${props => props.width}px;
    /* min-height: 50px; */
    background-color: #282a2ef6;
    /* border: 1px solid #e00000; */
    box-shadow: 10px 15px 15px #0000004b;
    border-radius: 4px;
    padding: 0 0 3px 0;
    border: 1px solid #e6e6e6c6;
    border-radius: 4px;
    box-sizing: border-box;
`;

export const _Item = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: ${StructTreeContext.ITEM_HEIGHT}px;
    /* border-bottom: 1px solid #ff0000; */

    color: #f1f1f1;
`;

export const _Inner = styled.div<{
    isEnable: boolean;
    isFocus: boolean;
}>`
    display: inline-block;
    width: calc(100% - 6px);
    height: calc(100% - 3px);
    font-size: ${Styles.FONT_SMALL}px;
    margin: 3px 0 0 3px;
    border-radius: 4px;
    padding: 0 0 0 4px;
    line-height: 28px;
    font-weight: 600;
    box-sizing: border-box;
    ${props => props.isEnable ? '' : css`
        opacity: 0.5;
        pointer-events: none;
        text-decoration: line-through;
    `}
    :hover {
        background-color: #636363e0;
        border: 1px solid #e6e6e6c6;
        color: #ff8080;
    }
    ${props => !props.isFocus ? '' : css`
        background-color: #c1d8c5e0;
        /* border: 1px solid #000279c7; */
        color: #00a05d;
    `}
`;