import { useEffect, useState } from "react";
import AbstractModelEditor from "./abstractModelEditor";
import FormUtil from "../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import ModelUtil from "../util/modelUtil";
import ModelElementUtil from "../util/modelElementUtil";
import DataUtil from "../../../../../common/dataUtil";
import ModelEditDialog from "../modelEditDialog";
import RefactoringUtil from "../util/refactoringUtil";
import PrefixUtil from "../util/prefixUtil";

namespace NodeCompDef {

    export type Data = {
        id: string;
        mngs: ModelUtil.WrapElement[];
    }

    type LocalState = {
        id: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {

        const [localState, setLocalState] = useState<LocalState>({
            id: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.id];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.id.value = data.id;
                // invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            props.temp.inputOK = true;

            let mngs: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                mngs = (props.temp.data as Data).mngs;
            }
            const data: Data = {
                id: localState.id.value,
                mngs
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Id"
                        jsx={<FormUtil.TextField
                            width={200}
                            checkable={localState.id}
                            setCheckable={(checkable) => {
                                localState.id = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 16),
                                    errorType: "length"
                                },
                                {
                                    checker: (value) => ModelUtil.checkPascalCase(value),
                                    errorType: "value"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'compdef';
        }

        override createAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            const element = node.data as ModelUtil.WrapElement;
            element.data = tempData;
            // const modelData = element.data as ModelUtil.NodeFunc;

            ModelElementUtil.addElementNodeDeep(node, {
                type: 'props',
                data: {
                    props: []
                } as ModelUtil.NodeProps
            });
            manageItems.invalidate();
            // ModelElementUtil.addElementNodeDeep(node, {
            //     type: 'store',
            //     data: {
            //         mngs: [
            //             {
            //                 type: 'states',
            //                 data: {
            //                     items: []
            //                 } as ModelUtil.NodeStates
            //             },
            //             {
            //                 type: 'initial',
            //                 data: {
            //                     items: []
            //                 } as ModelUtil.NodeInitial
            //             }
            //         ]
            //     } as ModelUtil.NodeStore
            // });
            // ModelElementUtil.addElementNodeDeep(node, {
            //     type: 'events',
            //     data: {
            //         events: []
            //     } as ModelUtil.NodeEvents
            // });
            
            ModelElementUtil.addElementNodeDeep(node, ModelElementUtil.getStoreInitialWrap());
            // ModelElementUtil.addElementNodeDeep(node, {
            //     type: 'retention',
            //     data: {
            //         items: []
            //     } as ModelUtil.NodeRetention
            // });
            ModelElementUtil.addElementNodeDeep(node, {
                type: 'elements',
                data: {
                    elements: []
                } as ModelUtil.NodeElements
            });
            manageItems.invalidate();
        }

        override modifyAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            (node.data as ModelUtil.WrapElement).data = tempData;
            manageItems.invalidate();
        }
    
        override modifyExtendAction(props: ModelEditDialog.ExtendModProps): void {
        
            const projectRoot = ModelElementUtil.getProjectRootFromCurrent(props.focusNode);
            const getData = (data: any) => data as Data;

            RefactoringUtil.syncCompdefId({
                projectRoot,
                oldId: getData(props.prevData).id,
                newId: getData(props.newData).id
            })
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeCompDef;
