import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import assert from "assert";
import VariableChooser from "../variableChooser";
import NodeUpdate from "../../var/nodeUpdate";
import NodeFocus from "./nodeFocus";
import DirectForm from "./directForm";

namespace NodeArrayAdd {

    export interface ObjectValue extends VariableChooser.Chooser {
        cloneType?: NodeUpdate.CloneType;
    }

    export interface Data {
        assignType: NodeFocus.AssignType;
        objVal?: ObjectValue;
        index?: string;
        fmlVal?: string;
    }
    type LocalState = {
        assignType: FormUtil.CheckableValue;
        directVal: FormUtil.CheckableValue;
        rootTarget: FormUtil.CheckableValue;
        rootId: FormUtil.CheckableValue;
        innerFieldForms: VariableChooser.InnerField[];
        isLast: boolean,
        index: FormUtil.CheckableValue;
        cloneType: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            assignType: { value: 'pick-out', errors: [] },
            directVal: { value: '', errors: [] },
            rootTarget: { value: '', errors: [] },
            rootId: { value: '', errors: [] },
            innerFieldForms: [],
            isLast: true,
            index: { value: '', errors: [] },
            cloneType: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [focusInfo, dtypes, states, funcargs, caches, propFields] = useMemo(() => {

            const cur = manageItems.focusNode;

            // const scope = store.system.scope;
            // const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];

            const caches = ModelElementUtil.getRetentionCachesFromCurrent(cur)
                .concat(ModelElementUtil.getFetchThensFromCurrent(cur));
            const states = ModelElementUtil.getReferableStates(cur);
            const dtypes = ModelElementUtil.getReferableDtypes(cur);

            const isDispatchSub = () => ModelElementUtil.isDispatchSub(cur);
            const funcargs = !isDispatchSub() ? undefined : ModelElementUtil.getArgumentFromCurrent(cur);
            const propFields = ModelElementUtil.getPropFieldsFromCurrent(cur);

            const focusInfo = NodeFocus.getFocusInfo(cur, {
                states, caches, prpflds: propFields, funcargs, dtypes
            });

            return [focusInfo, dtypes, states, funcargs, caches, propFields];
        }, []);

        const getLastArrayInner = () => {
            const lastField = JSON.parse(JSON.stringify(focusInfo.lastField)) as ModelUtil.Field;
            // フォーカスは配列である前提で、その中の要素を検査する必要がある
            lastField.array--;
            return lastField;
        };
        const isObject = ModelUtil.isObjectField(getLastArrayInner());
        const isUseObjType = (localState.assignType.value as NodeFocus.AssignType) === 'pick-out';

        const arg: VariableChooser.Argument = {
            localState, states, caches, models: dtypes, propFields
        }

        useEffect(() => {

            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.isLast = true;
                if (data.index != undefined) {
                    localState.isLast = false;
                    localState.index.value = data.index ?? '';
                }
                localState.assignType.value = data.assignType;
                if (data.objVal != undefined) {
                    VariableChooser.mappingDataToForm(arg, data.objVal);
                    localState.cloneType.value = data.objVal.cloneType ?? '';
                } else if (data.fmlVal != undefined) {
                    localState.directVal.value = data.fmlVal;
                }
            }
            invalidate();
        }, []);

        const targetFormsModInner = useMemo(() => {
            return localState.innerFieldForms.map(inner => inner.form);
        }, [localState.innerFieldForms]);

        const [last, requestType, directName] = useMemo(() => {
            const last = VariableChooser.getLastFieldInfo(arg);

            const getRequestType = (): null | ModelUtil.Field => {
                if (last == null) return null;
                let array = last.array;
                if (last.isIndex) array--;
                return {
                    dataType: last.dataType,
                    array,
                    structId: last.structId
                };
            }

            const requestType = getRequestType();

            let directName = '-';
            if (requestType != null) {
                switch (requestType.dataType) {
                    case 'string': directName = 'Text'; break;
                    case 'number': directName = 'Number'; break;
                    case 'boolean': directName = 'Boolean'; break;
                }
            }
            return [last, requestType, directName];
        }, [localState.rootId, localState.innerFieldForms]);

        const [compareMsg, isMatch] = useMemo(() => {
            const focusField = JSON.parse(JSON.stringify(focusInfo.lastField));
            focusField.array--;
            const focusDataType = ModelUtil.getField(focusField);
            const assignDataType = requestType == null ? '...?' : ModelUtil.getField(requestType);
            const isMatch = focusDataType === assignDataType;
            return [`${focusDataType} ← ${assignDataType}`, isMatch];
        }, [requestType]);

        const targetFroms = [localState.index, localState.rootId, localState.cloneType, localState.directVal, localState.assignType];
        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined
            ) {
                setInputOK(false);
                return;
            }
            if (isUseObjType) {
                // 1つでも入力エラーがあると処理しない
                if (targetFormsModInner.find(form => form.errors.length > 0) != undefined
                    || !isMatch
                ) {
                    setInputOK(false);
                    return;
                }
            }
            setInputOK(true);

            const levelProps: string[] = localState.innerFieldForms.map(inner => {
                return inner.form.value;
            });

            let objVal: undefined | ObjectValue = undefined;
            let directVal: undefined | string = undefined;
            if (isObject) {
                objVal = {
                    target: localState.rootTarget.value as VariableChooser.RootTargetType,
                    rootId: localState.rootId.value,
                    levelProps,
                    cloneType: DataUtil.blankToUndefined(localState.cloneType.value) as NodeUpdate.CloneType | undefined
                }
            } else {
                directVal = localState.directVal.value;
            }
            const data: Data = {
                index: DataUtil.blankToUndefined(localState.index.value),
                assignType: localState.assignType.value as NodeFocus.AssignType,
                objVal,
                fmlVal: directVal
            }
            setTempData(data);
        }, [...targetFroms, localState.directVal, localState.isLast, targetFormsModInner, isMatch]);

        // const getDirectForm = (): JSX.Element => {
        //     switch (focusInfo.lastField.dataType) {
        //         case 'string': return (<FormUtil.TextField
        //             width={284}
        //             checkable={localState.directVal}
        //             setCheckable={(checkable) => {
        //                 localState.directVal = checkable;
        //                 invalidate();
        //             }}
        //             validates={isObject ? [] : [
        //                 {
        //                     checker: (value) => ValidateUtil.checkRequired(value),
        //                     errorType: "required"
        //                 }
        //             ]}
        //         />);
        //         case 'number': return (<FormUtil.TextField
        //             width={284}
        //             checkable={localState.directVal}
        //             setCheckable={(checkable) => {
        //                 localState.directVal = checkable;
        //                 invalidate();
        //             }}
        //             validates={isObject ? [] : [
        //                 {
        //                     checker: (value) => ValidateUtil.checkRequired(value),
        //                     errorType: "required"
        //                 }
        //             ]}
        //         />);
        //         case 'color': return (<FormUtil.TextField
        //             width={284}
        //             checkable={localState.directVal}
        //             setCheckable={(checkable) => {
        //                 localState.directVal = checkable;
        //                 invalidate();
        //             }}
        //             validates={isObject ? [] : [
        //                 {
        //                     checker: (value) => ValidateUtil.checkRequired(value),
        //                     errorType: "required"
        //                 }
        //             ]}
        //         />);
        //         case 'boolean': return (<FormUtil.SwitchTwoFace
        //             label1="On"
        //             label2="Off"
        //             width={150}
        //             rate1={50}
        //             rate2={50}
        //             isUse={localState.directVal.value === 'true'}
        //             callback={() => {
        //                 const flag = localState.directVal.value === 'true';
        //                 localState.directVal.value = String(!flag);
        //                 invalidate();
        //             }}
        //         />);
        //         case 'name': return (<FormUtil.Combobox
        //             marginLeft={4}
        //             width={200}
        //             checkable={localState.directVal}
        //             setCheckable={(checkable) => {
        //                 localState.directVal = checkable;
        //                 invalidate();
        //             }}
        //             headBlank
        //             list={(() => {
        //                 const list = dtypes.find(m => m.id === focusInfo.lastField.structId);
        //                 return list?.fields ?? [];
        //             })()
        //                 .map(c => {
        //                     return {
        //                         labelText: c.id, value: c.id
        //                     };
        //                 })
        //             }
        //             validates={[
        //                 {
        //                     checker: (value) => ValidateUtil.checkRequired(value),
        //                     errorType: "required"
        //                 }
        //             ]}
        //         />);
        //         default: return <></>;
        //     }
        // }
        return (<>
            <NodeFocus.Refer
                focusInfo={focusInfo}
            />
            <FormUtil.BorderPanel
                title="method"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Index"
                        jsx={<>
                            <FormUtil.SwitchTwoFace
                                label1="Select"
                                label2="Last"
                                width={170}
                                rate1={55}
                                rate2={45}
                                isUse={localState.isLast}
                                callback={() => {
                                    localState.isLast = !localState.isLast;
                                    localState.index = { value: '', errors: [] };
                                    invalidate();
                                }}
                            />
                            {localState.isLast ? <></> : (
                                <FormUtil.TextField
                                    marginLeft={4}
                                    width={220}
                                    checkable={localState.index}
                                    setCheckable={(checkable) => {
                                        localState.index = checkable;
                                        invalidate();
                                    }}
                                    validates={localState.isLast ? [] : [
                                        {
                                            checker: (value) => ValidateUtil.checkRequired(value),
                                            errorType: "required"
                                        }
                                    ]}
                                />
                            )}
                        </>}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Assign Type"
                        jsx={<FormUtil.Combobox
                            width={210}
                            checkable={localState.assignType}
                            setCheckable={(checkable) => {
                                localState.assignType = checkable;
                                invalidate();
                            }}
                            extend={() => {
                                if (localState.assignType.value as NodeFocus.AssignType === 'pick-out') {
                                    localState.directVal.value = '';
                                } else {
                                    localState.rootTarget.value = '';
                                    localState.rootId.value = '';
                                    localState.innerFieldForms = [];
                                }
                            }}
                            list={NodeFocus.AssignTypes
                                .filter(t => !(isObject && t === 'formula'))
                                .map(item => {
                                    return { value: item, labelText: item }
                                })}
                        />}
                    />
                </>}
            />
            {isUseObjType ? (
                <VariableChooser.Component
                    arg={arg}
                    invalidate={invalidate}
                />
            ) : (
                <FormUtil.BorderPanel
                    title="formula"
                    innerJsx={<DirectForm.Component
                        focusInfo={focusInfo}
                        localState={localState}
                        invalidate={invalidate}
                        isObject={isObject}
                        dtypes={dtypes}
                        isArrayAdd
                    />}
                />
            )}
            <FormUtil.BorderPanel
                title="object matching"
                isVisible={isUseObjType}
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Compare"
                        jsx={<FormUtil.FixedText
                            value={compareMsg}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Status"
                        jsx={<FormUtil.FixedText
                            value={isMatch ? 'OK' : 'NG'}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="extend"
                isVisible={(requestType != null && ModelUtil.isObjectField(requestType))}
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Instance"
                        jsx={<FormUtil.Combobox
                            width={210}
                            checkable={localState.cloneType}
                            setCheckable={(checkable) => {
                                localState.cloneType = checkable;
                                invalidate();
                            }}
                            list={NodeUpdate.CloneTypes.map(item => {
                                return { value: item, labelText: item }
                            })}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'arradd';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeArrayAdd;
