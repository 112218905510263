import { useEffect, useState, useContext, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import ModelEditDialog from "../../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import assert from "assert";
import { TabbedPane } from "../../../../../../../common/component/tab/tabbedPane";
import styled from "styled-components";
import ScopeManager from "../../../option/scopeManager";
import StyleChooser from "../../decrare/styleChooser";
import TagUtil from "./tagUtil";

namespace NodeTagdiv {

    type LocalState = {
        tabIndex: number;
        comment: FormUtil.CheckableValue;
        localId: FormUtil.CheckableValue;
        attrs: FormUtil.CheckableValue;
        /** メモ化の依存変数リスト */
        memoDeps: FormUtil.CheckableValue;
        designRefs: StyleChooser.ReferState[];
        targetPointId: FormUtil.CheckableValue;
        actionOnclick: TagUtil.ListenerForm;
        actionOnContext: TagUtil.ListenerForm;
    }

    export type NodeData = {
        comment: string;
        localId?: string;
        attrs?: Attr[];
        memoDeps?: string[];
        styles: StyleChooser.Refer[];
        listener: TagUtil.Listener;
        elements: ModelUtil.WrapElement[];
    }

    export type Attr = {
        key: string;
        value: string;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;
        const [valueKeys, styleItems, dispatchItems] = useMemo(() => {
            // const funcRootwrap = ModelElementUtil.getAppRootWrap(manageItems.focusNode);

            // const states = ModelElementUtil.getStatesFromApp(funcRootwrap);

            const ownerNode = ModelElementUtil.getOwnerCompFromCurrent(manageItems.focusNode);
            const prpflds = ModelElementUtil.getPrpfldsFromCompdef({ type: 'compdef', data: ownerNode });
            // const actions = ModelElementUtil.getActionsFromCurrent(manageItems.focusNode);
            const caches = ModelElementUtil.getRetentionCachesFromCurrent(manageItems.focusNode);

            const scope = store.system.scope;
            const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];
            const cacheValueKeys = scope.cacheValueKeys as ScopeManager.ValueKeyField[];
            const dispatchItems = scope.dispatchItems as ScopeManager.DispatchItem[];
            const prpfldValueKeys = scope.propFieldValueKeys as ScopeManager.ValueKeyField[];
            const prpclbkItems = scope.prpclbkItems as ScopeManager.DispatchItem[];
            const valueKeys = stateValueKeys
                .concat(cacheValueKeys)
                .concat(prpfldValueKeys);

            const styleItems = scope.styleItems as ScopeManager.StyleItem[];
            return [valueKeys, styleItems, dispatchItems.concat(prpclbkItems)];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            comment: { value: '', errors: [] },
            localId: { value: '', errors: [] },
            attrs: { value: '', errors: [] },
            memoDeps: { value: '', errors: [] },
            tabIndex: 0,
            designRefs: [],
            targetPointId: { value: '', errors: [] },
            actionOnclick: TagUtil.getInitialListenerForm(),
            actionOnContext: TagUtil.getInitialListenerForm(),
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [
            localState.comment,
            localState.localId,
            localState.attrs,
            localState.memoDeps,
            // localState.actionOnclick,
            // localState.actionOnContext
        ];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as NodeData;
                localState.comment.value = data.comment;
                localState.localId.value = data.localId ?? '';
                localState.attrs.value = (() => {
                    if (data.attrs == undefined) return '';
                    return data.attrs.map(attr => `${attr.key}:${attr.value}`).join('\n');
                })();
                localState.memoDeps.value = (() => {
                    if (data.memoDeps == undefined) return '';
                    return data.memoDeps.join('\n');
                })();
                localState.targetPointId.value = data.listener.point ?? '';
                localState.actionOnclick = TagUtil.getMappedListenerStateFromData(data.listener.click, dispatchItems);
                localState.designRefs = StyleChooser.getMappedStyleStates(data.styles, { styleItems, phase: 'tag' });
                invalidate();
            }
        }, []);

        const hasErrorInherits = StyleChooser.hasErrorDesignRefers(localState.designRefs);
        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            if (hasErrorInherits) {
                setInputOK(false);
                return;
            }

            let attrs: undefined | Attr[] = undefined;
            if (localState.attrs.value !== '') {
                let hasError = false;
                attrs = [];
                localState.attrs.value.split('\n').some(attr => {
                    const items = attr.split(':');
                    if (items.length === 2) {
                        attrs?.push({
                            key: items[0], value: items[1]
                        })
                    } else {
                        hasError = true;
                        return 1;
                    }
                });
                if (hasError) {
                    setInputOK(false);
                    return;
                }
            }
            let memoDeps: undefined | string[] = undefined;
            if (localState.memoDeps.value !== '') {
                memoDeps = localState.memoDeps.value.split('\n');
            }
            setInputOK(true);

            let elements: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                elements = (props.temp.data as ModelUtil.NodeDiv).elements;
            }
            const listener: TagUtil.Listener = {
                point: DataUtil.blankToUndefined(localState.targetPointId.value),
                click: TagUtil.getMappedListenerDataFromState(localState.actionOnclick),
            }
            const data: NodeData = {
                comment: localState.comment.value,
                localId: DataUtil.blankToUndefined(localState.localId.value),
                styles: StyleChooser.getMappedReferDatas(localState.designRefs),
                attrs,
                memoDeps,
                listener,
                elements
            }
            setTempData(data);
        }, [localState]);

        const infoTabJsx = (
            <FormUtil.BorderPanel
                title="information"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Comment"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.comment}
                            setCheckable={(checkable) => {
                                localState.comment = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Local Id"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.localId}
                            setCheckable={(checkable) => {
                                localState.localId = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                        />}
                    />
                    {localState.tabIndex !== 0 ? <></> : StyleChooser.getTotalStyleMonitor(localState.designRefs, { styleItems, phase: 'tag' })}
                </>}
            />
        );

        const attrTab = (
            <FormUtil.BorderPanel
                title="attributes"
                height={400}
                innerJsx={<>
                    <FormUtil.TextArea
                        checkable={localState.attrs}
                        setCheckable={(checkable) => {
                            localState.attrs = checkable;
                            invalidate();
                        }}
                    />
                </>}
            />
        );

        const memoTab = (
            <FormUtil.BorderPanel
                title="dependencies"
                height={400}
                innerJsx={<>
                    <FormUtil.TextArea
                        checkable={localState.memoDeps}
                        setCheckable={(checkable) => {
                            localState.memoDeps = checkable;
                            invalidate();
                        }}
                    />
                </>}
            />
        );

        const getListenerTabJsx = () => {
            return (
                <TagUtil.DispatchListeners
                    owner={localState}
                    invalidate={invalidate}
                    listeners={[
                        { method: 'click', propName: 'actionOnclick' }
                    ]}
                    dispatches={dispatchItems}
                />
            );
        }

        const InheritTab = StyleChooser.Component({
            localState: { refers: localState.designRefs },
            invalidate,
            props: { styleItems, phase: 'tag' }
        });

        return (<_TabFrame>
            <TabbedPane
                tabElements={[
                    { name: 'Info', cont: <_TabItemWrap>{infoTabJsx}</_TabItemWrap>, enable: !hasErrorInherits },
                    { name: 'Style', cont: <_TabItemWrap>{InheritTab}</_TabItemWrap>, enable: true },
                    { name: 'Attribute', cont: <_TabItemWrap>{attrTab}</_TabItemWrap>, enable: true },
                    { name: 'Memoize', cont: <_TabItemWrap>{memoTab}</_TabItemWrap>, enable: true },
                    { name: 'Listener', cont: <_TabItemWrap>{getListenerTabJsx()}</_TabItemWrap>, enable: !hasErrorInherits },
                ]}
                activeNo={localState.tabIndex}
                selectTabIndex={(index) => {
                    localState.tabIndex = index;
                    invalidate();
                }}
            />
        </_TabFrame>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'tagdiv';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeTagdiv;

const _TabFrame = styled.div`
    display: inline-block;
    margin: 3px 0 0 3px;
    width: calc(100% - 6px);
    height: calc(100% - 46px);
`;
const _TabItemWrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #c5d3dd;
`;
