import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../../modelEditDialog";
import assert from "assert";
import ModelElementUtil from "../../../util/modelElementUtil";
import StoreSystem from "../../../../../../redux/store/storeSystem";
import ScopeManager from "../../../option/scopeManager";
import VariableChooser from "../variableChooser";
import TreeUtil from "../../../../../../../common/component/tree/treeUtil";
import NodeField from "../../var/nodeField";

namespace NodeFocus {

    export interface Data extends VariableChooser.Chooser, ModelUtil.NodeGenericItems {
    }

    export const AssignTypes = ['pick-out', 'formula', 'initial'] as const;
    export type AssignType = typeof AssignTypes[number];

    type LocalState = {
        rootTarget: FormUtil.CheckableValue;
        rootId: FormUtil.CheckableValue;
        innerFieldForms: VariableChooser.InnerField[];
    }
    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            rootTarget: { value: '', errors: [] },
            rootId: { value: '', errors: [] },
            innerFieldForms: [],
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [dtypes, states, caches] = useMemo(() => {
            const cur = manageItems.focusNode;

            // const scope = store.system.scope;
            // const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];

            const caches = ModelElementUtil.getReferableCaches(cur)
                .concat(ModelElementUtil.getFetchThensFromCurrent(cur));
            const states = ModelElementUtil.getReferableStates(cur);
            const dtypes = ModelElementUtil.getReferableDtypes(cur);
            return [dtypes, states, caches];
        }, []);

        const arg: VariableChooser.Argument = {
            localState, states, caches, models: dtypes
        }

        useEffect(() => {
            const getTargetInitail = (): VariableChooser.RootTargetType | '' => {
                if (states == null) return 'cache'
                else if (caches == null) return 'state';
                return '';
            }

            localState.rootTarget.value = getTargetInitail();
            if (props.temp.data != null) {
                const data = props.temp.data as Data;

                VariableChooser.mappingDataToForm(arg, data);
            }
            invalidate();
        }, []);

        useEffect(() => {
        }, [localState.rootId, localState.innerFieldForms]);

        const targetFroms = [
            localState.rootId
        ];
        const targetFormsModInner = useMemo(() => {
            return localState.innerFieldForms.map(inner => inner.form);
        }, [localState.innerFieldForms]);

        useEffect(() => {
            if (targetFroms.concat(targetFormsModInner).find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const levelProps: string[] = localState.innerFieldForms.map(inner => {
                return inner.form.value;
            });

            let items: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                items = (props.temp.data as Data).items;
            }
            const data: Data = {
                target: localState.rootTarget.value as VariableChooser.RootTargetType,
                rootId: localState.rootId.value,
                levelProps,
                items
            }
            setTempData(data);
        }, [...targetFroms, targetFormsModInner]);

        return (<>
            <VariableChooser.Component
                arg={arg}
                invalidate={invalidate}
            />
        </>);
    }

    export type FocusInfo = {
        target: VariableChooser.RootTargetType;
        address: string;
        lastField: ModelUtil.Field;
    }

    export const getFocusInfo = (node: TreeUtil.ElementNode, vars: VariableChooser.variableProps): FocusInfo => {

        const parentWrap = (node.parent?.data) as ModelUtil.WrapElement;
        const getFocusData = (): VariableChooser.Chooser => {
            if (parentWrap.type === 'focus') {
                return parentWrap.data as NodeFocus.Data;
            } else if (parentWrap.type === 'cache') {
                const data = parentWrap.data as NodeField.CacheData;
                return {
                    target: 'cache',
                    rootId: data.id,
                    levelProps: []
                }
            }
            throw new Error('parentWrap.dataがcache,focusのいずれでもない。');
        }

        const focusData = getFocusData();
        const [lastField, address] = VariableChooser.getChooserStates(focusData, vars);
        return {
            target: focusData.target,
            address,
            lastField
        }
    }

    export const Refer = (props: {
        focusInfo: FocusInfo;
        extendJsx?: JSX.Element;
    }) => {
        const focusInfo = props.focusInfo;
        return (
            <FormUtil.BorderPanel
                title="focus"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Target"
                        jsx={<FormUtil.FixedText
                            value={focusInfo.target}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Address"
                        jsx={<FormUtil.FixedText
                            value={focusInfo.address}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Data Type"
                        jsx={<FormUtil.FixedText
                            value={ModelUtil.getField(focusInfo.lastField)}
                        />}
                    />
                    {props.extendJsx ?? <></>}
                </>}
            />
        );
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'focus';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeFocus;
