import { JsxElement } from "typescript";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelElementUtil from "../../../util/modelElementUtil";
import NodeFocus from "./nodeFocus";
import ModelUtil from "../../../util/modelUtil";

namespace DirectForm {

    export type RecieveState = {
        directVal: FormUtil.CheckableValue;
    }

    export const Component = (props: {
        focusInfo: NodeFocus.FocusInfo;
        localState: RecieveState;
        invalidate: () => void;
        isObject: boolean;
        dtypes: ModelElementUtil.DtypeInfo[];
        isArrayAdd?: boolean;
    }) => {
        const focusInfo = props.focusInfo;
        const localState = props.localState;
        const invalidate = props.invalidate;
        const isObject = props.isObject;
        const dtypes = props.dtypes;

        const isArrayAdd = props.isArrayAdd ?? false;

        const getValueForm = (jsx: JSX.Element) => {
            return <FormUtil.FormRecord
                titleLabel={'Value'}
                // isVisible={!isObject}
                jsx={jsx}
            />
        }

        const getFormsJsx = () => {
            switch (focusInfo.lastField.dataType) {
                case 'string': return (getValueForm(
                    <FormUtil.TextField
                        width={400}
                        checkable={localState.directVal}
                        setCheckable={(checkable) => {
                            localState.directVal = checkable;
                            invalidate();
                        }}
                        validates={isObject ? [] : [
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            }
                        ]}
                    />
                ));
                case 'multiline': return (<FormUtil.BorderPanel
                    title="multiline"
                    height={120}
                    innerJsx={<FormUtil.TextArea
                        checkable={localState.directVal}
                        setCheckable={(checkable) => {
                            localState.directVal = checkable;
                            invalidate();
                        }}
                        validates={isObject ? [] : [
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            }
                        ]}
                    />}
                />);
                case 'number': return getValueForm(<FormUtil.TextField
                    width={284}
                    checkable={localState.directVal}
                    setCheckable={(checkable) => {
                        localState.directVal = checkable;
                        invalidate();
                    }}
                    validates={isObject ? [] : [
                        {
                            checker: (value) => ValidateUtil.checkRequired(value),
                            errorType: "required"
                        }
                    ]}
                />);
                case 'color': return getValueForm(<FormUtil.TextField
                    width={284}
                    checkable={localState.directVal}
                    setCheckable={(checkable) => {
                        localState.directVal = checkable;
                        invalidate();
                    }}
                    validates={isObject ? [] : [
                        {
                            checker: (value) => ValidateUtil.checkRequired(value),
                            errorType: "required"
                        }
                    ]}
                />);
                // case 'boolean': return getValueForm(<FormUtil.SwitchTwoFace
                //     label1="On"
                //     label2="Off"
                //     width={150}
                //     rate1={50}
                //     rate2={50}
                //     isUse={localState.directVal.value === 'true'}
                //     callback={() => {
                //         const flag = localState.directVal.value === 'true';
                //         localState.directVal.value = String(!flag);
                //         invalidate();
                //     }}
                // />);
                case 'boolean': return getValueForm(<FormUtil.TextField
                    width={400}
                    checkable={localState.directVal}
                    setCheckable={(checkable) => {
                        localState.directVal = checkable;
                        invalidate();
                    }}
                    validates={isObject ? [] : [
                        {
                            checker: (value) => ValidateUtil.checkRequired(value),
                            errorType: "required"
                        }
                    ]}
                />);
                case 'name': return getValueForm(<FormUtil.Combobox
                    marginLeft={4}
                    width={200}
                    checkable={localState.directVal}
                    setCheckable={(checkable) => {
                        localState.directVal = checkable;
                        invalidate();
                    }}
                    headBlank
                    list={(() => {
                        const list = dtypes.find(m => m.id === focusInfo.lastField.structId);
                        return list?.fields ?? [];
                    })()
                        .map(c => {
                            return {
                                labelText: c.id, value: c.id
                            };
                        })
                    }
                    validates={[
                        {
                            checker: (value) => ValidateUtil.checkRequired(value),
                            errorType: "required"
                        }
                    ]}
                />);
                default: return <></>;
            }
        }
        return (<>
            <FormUtil.FormRecord
                titleLabel={'Type'}
                isVisible={!isObject}
                jsx={<FormUtil.FixedText
                    value={ModelUtil.getField((() => {
                        const field = JSON.parse(JSON.stringify(focusInfo.lastField)) as ModelUtil.Field;
                        if (isArrayAdd) {
                            field.array--;
                        }
                        return field;
                    })())}
                />}
            />
            {getFormsJsx()}
        </>);
    }
}

export default DirectForm;