import { useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../../system/contents/entry/systemEntry";
import styled from "styled-components";

const GloballDialog = () => {

    const { store, setStore } = useContext(GlobalContext);

    const dialog = store.system.dialog;

    if (dialog == null) return <></>;
    return (<>
        <_Blind onClick={() => {
            if (!store.system.canDialogClose) return;
            store.system.dialog = null;
            store.system.canDialogClose = false;
            setStore({ ...store });
        }} />
        {dialog}
    </>);
}

export default GloballDialog;

const _Blind = styled.div<{
}>`
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000329a2;
    z-index: 10;
    text-align: center;
`;
