import assert from "assert";

namespace StoreDesign {

    export type Props = {
        base1: string;
        base2: string;
        base3: string;
        base4: string;
        sub1: string;
        sub2: string;
        sub3: string;
        accent1: string;
        accent2: string;
        accent3: string;
        text1: string;
        text2: string;
        text3: string;
        text4: string;
    }

    type PresetName = 'light-blue' | 'dark-grey'

    export type Preset = {
        name: PresetName;
        props: Props;
    }


    const presets: Preset[] = [
        {
            name: 'light-blue', props: {
                base1: '#4e5257',
                base2: '#161616',
                base3: '#868686',
                base4: '#cccccc',
                sub1: '#cccccc',
                sub2: '#8b8b8b',
                sub3: '#cccccc',
                accent1: '#dadada',
                accent2: '#420000',
                accent3: '#2c2c2c',
                text1: '#ffffff',
                text2: '#474747',
                text3: '#00683d',
                text4: '#960000',
            }
        }
    ];

    export const getPreset = (name: PresetName) => {
        const preset = presets.find(p => p.name === name);
        assert(preset != undefined, 'preset is undefined.');
        return preset.props;
    }
}

export default StoreDesign;