import { useEffect, useMemo, useState, useContext } from "react";
import ModelUtil from "../develop/function/util/modelUtil";
import ModelElementUtil from "../develop/function/util/modelElementUtil";
import styled, { css } from "styled-components";
import assert, { throws } from "assert";
import DataUtil from "../../../common/dataUtil";
import { GlobalContext } from "../entry/systemEntry";
import ReaderUtil from "./readerUtil";
import NodeCompuse from "../develop/function/editor/ui/nodeCompuse";
import NodeTagdiv from "../develop/function/editor/ui/tag/nodeTagDiv";
import NodeStyle from "../develop/function/editor/decrare/nodeStyle";
import TagUtil from "../develop/function/editor/ui/tag/tagUtil";
import NodeTagSpan from "../develop/function/editor/ui/tag/nodeTagSpan";
import NodeTagInput from "../develop/function/editor/ui/tag/nodeTagInput";
import NodeCompDef from "../develop/function/editor/nodeCompDef";
import PrefixUtil from "../develop/function/util/prefixUtil";
import NodeCase from "../develop/function/editor/condition/nodeCase";
import NodeWhen from "../develop/function/editor/condition/nodeWhen";
import NodeApp from "../develop/function/editor/nodeApp";
import NodeAccept from "../develop/function/editor/condition/nodeAccept";
import NodeDtype from "../develop/function/editor/nodeDtype";
import NodePrpclbk from "../develop/function/editor/var/nodePrpclbkEditor";
import nodeView from "../develop/function/editor/ui/nodeView";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineOpenInFull } from "react-icons/md";
import { BsBoxArrowInDownLeft } from "react-icons/bs";
import NodeField from "../develop/function/editor/var/nodeField";
import NodeIterate from "../develop/function/editor/nodeIterate";
import AppPatialDiv from "./appPatialDiv";
import NodeLauncher from "../develop/function/editor/release/nodeLauncher";

namespace AppReader {

    export const Dialog = (props: {
        projectRootWrap: ModelUtil.WrapElement;
        launchNo: number;
    }) => {
        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const [isWnd, setWnd] = useState<boolean>(true);

        const appendConsole = (text: string, isError: boolean) => {
            dispatcher.system.appendConsole(text);
            // if (isError) setTabIndex(1);
        }

        // const [tabIndex, setTabIndex] = useState<number>(0);

        const margin = isWnd ? 40 : 0;
        return (
            <_AppFrame
                marginLeft={margin}
                marginTop={margin}
            >
                <_AppHeader>
                    {isWnd ?
                        (<_HeaderIcon onClick={() => {
                            setWnd(false);
                        }}><MdOutlineOpenInFull /></_HeaderIcon>) :
                        (<_HeaderIcon onClick={() => {
                            setWnd(true);
                        }}><BsBoxArrowInDownLeft /></_HeaderIcon>)
                    }
                    <_HeaderIcon onClick={() => {
                        store.system.dialog = null;
                        dispatcher.updateStore();
                    }}><AiOutlineClose /></_HeaderIcon>
                </_AppHeader>
                <_AppBody>
                    <Component
                        projectRootWrap={props.projectRootWrap}
                        launchNo={props.launchNo}
                        appendConsole={appendConsole}
                    />
                </_AppBody>
            </_AppFrame>
        );
    }

    export const Component = (props: {
        projectRootWrap: ModelUtil.WrapElement;
        launchNo: number;
        appendConsole?: (text: string, isError: boolean) => void;
    }) => {
        const { store, setStore, dispatcher } = useContext(GlobalContext);
        // assert(store.project != null, 'project is null.');
        const prjRootWrap = props.projectRootWrap;

        const [globalDtypes, globalStyles, globelCompdefs] = useMemo(() => {
            // const project = store.project;
            // assert(project != null, 'project is null.');
            // const prjRootWrap = project.rootData;
            const dtypesData = ModelElementUtil.getInnerWrapFixed(prjRootWrap, 'common', 'dtypes').data as ModelUtil.NodeGenericItems;
            const dtypes: ModelElementUtil.DtypeInfo[] = dtypesData.items
                .map(item => item.data as NodeDtype.Data)
                .map(dtype => ({
                    id: dtype.id, fields: dtype.fields
                        .map(f => f.data as ModelUtil.NodeModelField)
                }));
            const styles = ModelElementUtil.getProjectCommonBelongDatas(prjRootWrap, 'styles') as NodeStyle.Data[];
            const compdefs = ModelElementUtil.getProjectCommonBelongDatas(prjRootWrap, 'comps') as NodeCompDef.Data[];
            return [dtypes, styles, compdefs];
        }, []);

        const appendConsole = props.appendConsole ?? ((text: string, isError: boolean) => { });

        const [appWrap, launchArgKeySets] = useMemo((): [ModelUtil.WrapElement, DataUtil.KeyValue[]] => {
            const launchersData = ModelElementUtil.getInnerWrapFixed(prjRootWrap, 'release', 'launchers').data as ModelUtil.NodeGenericItems;
            const appsData = ModelElementUtil.getInnerWrapFixed(prjRootWrap, 'apps').data as ModelUtil.NodeApps;
            const launcherWrap = launchersData.items
                .find(l => (l.data as NodeLauncher.Data).no === props.launchNo);
            assert(launcherWrap != undefined, 'launchersData.itemsにprops.launchNoと合致する連番が見つからない');
            const launcher = launcherWrap.data as NodeLauncher.Data;
            const appWrap = appsData.apps.find(a => (a.data as NodeApp.Data).id === launcher.appId);
            assert(appWrap != undefined, 'appsData.appsにlauncher.appIdと合致する連番が見つからない');
            return [appWrap, launcher.args];
        }, []);

        /** 機能で参照可能なコンポーネント定義 */
        const compdefs = globelCompdefs
            .concat(ModelElementUtil.getCompsFromApp(appWrap));

        const [readerState, setReaderState] = useState<ReaderUtil.ReaderState>({ globals: null, localUnits: [] });
        /** 再描画 */
        const invalidate = () => {
            // assert(globalStates != null, 'globalStates is null.');
            setReaderState({ ...readerState });
        };
        const globalStates = readerState.globals;

        const globalFixedProps: ReaderUtil.GlobalFixedProps = useMemo(() => {
            const states = ModelElementUtil.getStatesFromApp(appWrap);
            // const events = ModelElementUtil.getEventsFromApp(props.appWrap)
            //     .map(e => ReaderUtil.convertEventNodeToProps(e));
            // const daos = ModelElementUtil.getFetchesFromApp(appWrap);

            // モデル情報を取得
            const dtypes = globalDtypes
                .concat(ModelElementUtil.getAppLocalDtypes(appWrap));
            const buildInitialStructureObject = (field: ModelUtil.NodeModelField) => ReaderUtil.buildInitialStructureObject(field, dtypes);

            const launchArgs: ReaderUtil.FieldObject[] = launchArgKeySets.map(ks => {
                return {
                    field: { id: ks.key, dataType: 'string', array: 0 },
                    value: ks.value
                };
            });
            return { states, launchArgs, dtypes, buildInitialStructureObject };
        }, []);

        useEffect(() => {
            // console.log('init state');
            const stateData = ModelElementUtil.getStatesFromApp(appWrap);
            const globalStates = stateData.map((item) => {
                return globalFixedProps.buildInitialStructureObject(item);
            });
            // console.log(globalStates);
            readerState.globals = globalStates;

            // linkageを処理
            const effects = ModelElementUtil.getLinkageEffectFromApp(appWrap);
            effects.forEach(effect => {
                {
                    if (effect.method === 'init') {
                        const procWrap = ModelElementUtil.getInnerWrapFixed({ type: 'trigger', data: effect }, 'proc');
                        const proc = procWrap.data as ModelUtil.NodeProcedure;
                        const buildProps: ReaderUtil.BuildProps = {
                            globalFixedProps,
                            globalStates,
                            dynamicProps: ReaderUtil.getInitialDynamicProps(),
                            invalidate: () => { },
                            appendConsole
                        };
                        ReaderUtil.applyProcedure(proc.items, buildProps, invalidate);
                    }
                }
            });

            invalidate();
        }, [appWrap]);

        const innerJsxes: JSX.Element[] = useMemo(() => {
            if (globalStates == null) return [];

            const models = globalFixedProps.dtypes;
            const buildInitialStructureObject = globalFixedProps.buildInitialStructureObject;

            const getElementsJsxRec2 = (
                elements: ModelUtil.WrapElement[],
                dynamicProps: ReaderUtil.DynamicProps,
                ownerIndex?: string): [() => void, () => JSX.Element[]] => {

                /**
                 * 内部要素を引数のelementsで初期化するが、
                 * retention要素を含む場合、retention配下を走査した上で、
                 * 同列のelements要素を内部要素として取得する
                 */
                let innerElements = elements;

                const applyProcedure = () => {
                    /** リテンション要素 */
                    const retentionWrap = elements.find(e => e.type === 'retention');
                    if (retentionWrap != undefined) {
                        const retention = retentionWrap.data as ModelUtil.NodeRetention;
                        const buildProps: ReaderUtil.BuildProps = {
                            globalFixedProps,
                            globalStates,
                            dynamicProps,
                            invalidate: () => { },
                            appendConsole
                        };

                        ReaderUtil.applyProcedure(retention.items, buildProps, invalidate);

                        // elementsを上書きする
                        const innerElementsWrap = elements.find(e => e.type === 'elements');
                        assert(innerElementsWrap != undefined, 'ありえないツリー構成');
                        innerElements = (innerElementsWrap.data as ModelUtil.NodeElements).elements;
                    }
                }

                const generateJsxes = () => {
                    const elementToCompJsxList: JSX.Element[] = [];
                    innerElements.forEach((el, i) => {
                        const prefix = ownerIndex == undefined ? '' : `${ownerIndex}-`;
                        const indexKey = `${prefix}${i}`;
                        const compJsxes = convertElementToCompJsxes(el, indexKey, dynamicProps);
                        elementToCompJsxList.push(...compJsxes);
                    });
                    return elementToCompJsxList;
                }
                return [applyProcedure, generateJsxes];
            }

            /**
             * 再帰的に要素のJSXを生成する
             * @param elements 子要素
             * @param dynamicProps 
             * @param ownerIndex 親要素のインデックス
             * @returns JSX
             */
            const getElementsJsxRec = (elements: ModelUtil.WrapElement[], dynamicProps: ReaderUtil.DynamicProps, ownerIndex?: string): JSX.Element[] => {

                // const replaceKeySets = (base: string) => {
                //     return ReaderUtil.replaceKeySets(base, {
                //         globalFixedProps, globalStates, dynamicProps
                //     });
                // }

                /**
                 * 内部要素を引数のelementsで初期化するが、
                 * retention要素を含む場合、retention配下を走査した上で、
                 * 同列のelements要素を内部要素として取得する
                 */
                let innerElements = elements;

                /** リテンション要素 */
                const retentionWrap = elements.find(e => e.type === 'retention');
                if (retentionWrap != undefined) {
                    const retention = retentionWrap.data as ModelUtil.NodeRetention;
                    const buildProps: ReaderUtil.BuildProps = {
                        globalFixedProps,
                        globalStates,
                        dynamicProps,
                        invalidate,
                        appendConsole
                    };

                    ReaderUtil.applyProcedure(retention.items, buildProps, invalidate);

                    // elementsを上書きする
                    const innerElementsWrap = elements.find(e => e.type === 'elements');
                    assert(innerElementsWrap != undefined, 'ありえないツリー構成');
                    innerElements = (innerElementsWrap.data as ModelUtil.NodeElements).elements;
                }
                const elementToCompJsxList: JSX.Element[] = [];
                innerElements.forEach((el, i) => {
                    const prefix = ownerIndex == undefined ? '' : `${ownerIndex}-`;
                    const indexKey = `${prefix}${i}`;
                    const compJsxes = convertElementToCompJsxes(el, indexKey, dynamicProps);
                    elementToCompJsxList.push(...compJsxes);
                });
                // console.log(globalStates);
                return elementToCompJsxList;
            }

            /** グローバルのスタイルを初期化する */
            const setupGlobalStyles = (dynamicProps: ReaderUtil.DynamicProps) => {
                const appStyles = ModelElementUtil.getAppItemsBelongDatas(appWrap, 'styles') as NodeStyle.Data[];

                globalStyles.concat(appStyles).forEach(data => {
                    const obj = ReaderUtil.getStyleObjFromData(data, buildProps);
                    dynamicProps.styles.push(obj);
                });
            }

            /**
             * JSONの要素をコンポーネントのJSXを生成する
             * @param element 要素
             * @param index jsxのkey用の連番
             * @param dynamicProps 
             * @returns コンポーネントのJSX
             */
            const convertElementToCompJsxes = (element: ModelUtil.WrapElement, index: string, dynamicProps: ReaderUtil.DynamicProps): JSX.Element[] => {
                // console.log(`${index}: ${element.type}`);

                const listenerInvalidate = (invalidatePointId?: string) => {
                    const localPoint = dynamicProps.localPoints.find(lp => lp.id === invalidatePointId);
                    // console.log(dynamicProps.localPoints);
                    // console.log(localPoint);
                    // invalidate();
                    if (localPoint != undefined) {
                        localPoint.invalidate();
                    } else {
                        invalidate();
                    }
                }

                const buildProps: ReaderUtil.BuildProps = {
                    globalFixedProps,
                    globalStates,
                    dynamicProps,
                    invalidate: listenerInvalidate,
                    appendConsole
                };
                const replaceKeySets = (base: string) => {
                    return ReaderUtil.replaceKeySets(base, buildProps);
                    // return ReaderUtil.getFomulaAplliedValue(base, buildProps);
                }

                const getFieldObject = (key: string) => {
                    return ReaderUtil.getFieldObject(key, buildProps);
                }

                /** 変数と式を適用した文字列を返す */
                const getAppliedFmlVal = (base: string) => {
                    let retVal = base;
                    retVal = replaceKeySets(retVal);
                    retVal = DataUtil.applyFormula(retVal);
                    return retVal;
                }

                // トレーサの更新
                const tracer = dynamicProps.tracer;
                dynamicProps.tracer = {
                    parent: tracer,
                    cur: element,
                    localId: tracer.localId,
                }

                const getListener = (
                    listeners: TagUtil.Listener,
                    method: TagUtil.ListenerMethod,
                    invalidatePointId?: string
                ) => {
                    const listener = listeners[method];
                    // リスナーが設定されている場合のみ処理する
                    if (listener != undefined) {
                        const callback = ReaderUtil.generateCallbackFromDispatcher({
                            buildProps,
                            dispatch: listener,
                        });
                        return () => {
                            try {
                                if (method === 'change') {
                                    // console.log(listener);
                                    // console.log(callback);
                                }
                                callback();
                                // 再描画
                                listenerInvalidate(invalidatePointId);
                            } catch (e) {
                                // console.log(e);
                                dispatcher.system.appendConsole(e as string);
                            }
                        }
                    }
                }

                switch (element.type) {
                    case 'tagdiv': {
                        const data = element.data as NodeTagdiv.NodeData;

                        // if (data.localId != undefined) {
                        //     if(data.localId === 'listItem') {
                        //         console.log(JSON.stringify(buildProps.dynamicProps.caches, null, 2));
                        //     }
                        // }

                        // const src = ReaderUtil.getAppliedStyleSources(data.styles, buildProps).join('\n');
                        // const nextDynamicProps: ReaderUtil.DynamicProps = { ...buildProps.dynamicProps };
                        // const innerJsx = getElementsJsxRec(data.elements, nextDynamicProps);
                        // return [
                        //     <_TagDiv
                        //         src={src}
                        //         onClick={getListener(data.listener, 'click')}
                        //         onContextMenu={getListener(data.listener, 'context')}
                        //     >{innerJsx}</_TagDiv>
                        // ];
                        // const [applyProcedure, generateJsxes] = getElementsJsxRec2(data.elements, dynamicProps);
                        // applyProcedure();
                        return [
                            <AppPatialDiv.Component
                                key={index}
                                buildProps={buildProps}
                                getListener={getListener}
                                getElementsJsxRec={getElementsJsxRec}
                                // generateJsxes={generateJsxes}
                                // applyProcedure={applyProcedure}
                                // jsx={generateJsxes()}
                                data={data}
                            />
                        ];
                    }
                    case 'tagspan': {
                        const data = element.data as NodeTagSpan.NodeData;
                        // console.log(data.text);
                        let text = replaceKeySets(data.text);
                        // text = DataUtil.applyFormula(`'${text}'`);
                        // console.log(text);
                        const src = ReaderUtil.getAppliedStyleSources(data.desrefs, buildProps).join('\n');
                        return ([
                            <_TagSpan
                                key={index}
                                src={src}
                            // onClick={getListener(data.listener, 'click')}
                            // onContextMenu={getListener(data.listener, 'context')}
                            >{text}</_TagSpan>
                        ]);
                    }
                    case 'taginput': {
                        const data = element.data as NodeTagInput.NodeData;
                        const method = data.method;
                        // console.log(data.desrefs);
                        const src = ReaderUtil.getAppliedStyleSources(data.desrefs, buildProps).join('\n');

                        let inputProps: null | {
                            value: string;
                            obj: any;
                            callback: ((fromVal: string) => void);
                        } = null;

                        if (data.mapper != undefined) {
                            const mapper = data.mapper;
                            const mapperRef = getFieldObject(data.mapper.owner);
                            assert(mapperRef != null, 'mapper is null.');
                            const model = models.find(m => m.id === mapperRef.field.structId);
                            assert(model != null, 'model is null.');
                            let fieldPrpName = mapper.fldnam ?? mapper.fldvar as string;
                            if (mapper.fldvar != undefined) {
                                fieldPrpName = replaceKeySets(fieldPrpName);
                            }
                            const obj = mapperRef.value;
                            const value = obj[fieldPrpName] as string;
                            const callback = (formVal: string) => {
                                // mapperベースの対象フィールドの値を更新
                                const prev = obj[fieldPrpName];
                                obj[fieldPrpName] = formVal;
                                // console.log(`cur:[${obj[fieldPrpName]}], prev:[${prev}], name:[${fieldPrpName}]. mapper.fldnam:[${mapper.fldnam}]`);
                                listenerInvalidate(data.listener.point);
                            };
                            inputProps = { value, obj, callback };
                        }

                        assert(inputProps != null, 'inputProps is null.');
                        const callback = inputProps.callback;

                        const onChangeListener: (fromVal: string) => void = callback;
                        // getListener(data.listener, 'change')
                        // console.log(inputProps?.value);
                        // console.log(src);
                        const getFormJsx = () => {

                            const attrs: any = {};
                            if (data.attrs != undefined) {
                                data.attrs.forEach(attr => {
                                    const value = replaceKeySets(attr.value);
                                    attrs[attr.key] = value;
                                });
                            }
                            if (method !== 'list') {
                                if (method === 'textarea') {
                                    return (
                                        <_TagTextarea
                                            key={index} value={inputProps?.value}
                                            src={src}
                                            {...attrs}
                                            onChange={(prop) => {
                                                onChangeListener(prop.target.value);
                                                const listener = getListener(data.listener, 'change');
                                                if (listener != undefined) listener();
                                            }}
                                            onClick={getListener(data.listener, 'click')}
                                            onContextMenu={getListener(data.listener, 'context')}
                                        />
                                    );
                                } else {
                                    return (
                                        <_TagInput
                                            key={index} type={method} value={inputProps?.value}
                                            src={src}
                                            {...attrs}
                                            onChange={(prop) => {
                                                onChangeListener(prop.target.value);
                                                const listener = getListener(data.listener, 'change');
                                                if (listener != undefined) listener();
                                            }}
                                            onClick={getListener(data.listener, 'click')}
                                            onContextMenu={getListener(data.listener, 'context')}
                                        />
                                    );
                                }
                            } else {
                                const getOptionJsxes = () => {
                                    const listProps = data.listProps;
                                    assert(listProps != undefined, 'listProps is undefined.');
                                    let listItems = ReaderUtil.buildListItems(listProps, buildProps);
                                    if (listProps.headBlank) {
                                        listItems = [['']].concat(listItems);
                                    }
                                    return listItems.map((item, i) => {
                                        const val = item[0];
                                        const disp = item.length === 1 ? item[0] : item[1];
                                        return <option key={i} value={val}>{disp}</option>;
                                    });
                                }
                                return (
                                    <_TagSelect
                                        key={index}
                                        src={src}
                                        {...attrs}
                                        value={inputProps?.value}
                                        onChange={(prop) => {
                                            onChangeListener(prop.target.value);
                                            const listener = getListener(data.listener, 'change');
                                            if (listener != undefined) listener();
                                            // getListener(data.listener, 'change');
                                            // console.log(data.listener);
                                            // console.log(buildProps.globalStates);
                                            // console.log(buildProps.dynamicProps.caches);
                                        }}
                                        onClick={getListener(data.listener, 'click')}
                                        onContextMenu={getListener(data.listener, 'context')}
                                    >{getOptionJsxes()}</_TagSelect>
                                );
                            }
                        }
                        return ([getFormJsx()]);
                    }
                    case 'compuse': {
                        const compuse = element.data as NodeCompuse.Data;
                        // console.log(compuse);
                        /** コンポーネント定義 */
                        const compdef = compdefs.find(c => c.id === compuse.compId);
                        assert(compdef != undefined, 'compdef is undefined.');
                        const conpdefWrap: ModelUtil.WrapElement = { type: 'compdef', data: compdef };
                        const compProps = ModelElementUtil.getInnerWrapFixed(conpdefWrap, 'props').data as ModelUtil.NodeProps;
                        const compElements = ModelElementUtil.getInnerWrapFixed(conpdefWrap, 'elements').data as ModelUtil.NodeElements;
                        // console.log(dynamicProps.caches);
                        const curLocalId = dynamicProps.tracer.localId;
                        const nextDynamicProps = ReaderUtil.getInitialDynamicProps();
                        // props.globalStyles.forEach(data => {
                        //     const obj = ReaderUtil.getStyleObjFromData(data, buildProps);
                        //     buildProps.dynamicProps.styles.push(obj);
                        // });
                        setupGlobalStyles(nextDynamicProps);
                        // ローカルIDリストは引き継ぐ
                        nextDynamicProps.localIds = dynamicProps.localIds;
                        const getNextNo = (): number => {
                            // 現在の階層レベルのID
                            const curLevel = curLocalId.split('.').length;
                            // console.log(`現在の階層：${curLocalId}[${curLevel}]`);
                            // 次の階層レベルのIDリスト
                            // console.log(`リスト：${nextDynamicProps.localIds.map(u => u).join(', ')}`);
                            const nextIds = nextDynamicProps.localIds.filter(id => {
                                return id.split('.').length === curLevel + 1
                                    && id.indexOf(curLocalId) !== -1
                            });
                            if (nextIds.length === 0) return 0;
                            const elements = nextIds[nextIds.length - 1].split('.');
                            const lastNo = elements[elements.length - 1];
                            return Number(lastNo) + 1;
                        }
                        // console.log(`■[${compuse.compId}]start!---`);
                        const nextLocalId = `${curLocalId}.${getNextNo()}`;
                        // console.log(`■[${compuse.compId}: ${nextLocalId}`);
                        // console.log(`■[${compuse.compId}]end!---`);
                        // ローカルIDが存在しない初回のみ、ステートのインスタンスを作成
                        if (nextDynamicProps.localIds.find(id => id === nextLocalId) == undefined) {
                            nextDynamicProps.localIds.push(nextLocalId);

                            if (readerState.localUnits.find(u => u.localId === nextLocalId) == undefined) {
                                const stateData = ModelElementUtil.getStatesFromApp(conpdefWrap);
                                const states = stateData.map((item) => {
                                    return globalFixedProps.buildInitialStructureObject(item);
                                });
                                readerState.localUnits.push({
                                    localId: nextLocalId,
                                    states
                                });
                            }
                        }
                        nextDynamicProps.tracer.localId = nextLocalId;
                        // 引数を動的プロパティに追加
                        compProps.props.forEach((p, i) => {
                            const propValue = compuse.props[i];
                            switch (p.type) {
                                case 'prpfld': {
                                    let objValue: null | string = null;
                                    const prpfld = p.data as ModelUtil.NodeModelField;
                                    // const isObj = prpfld.array >= 1 || prpfld.dataType === 'struct';
                                    // if (isObj) {
                                    if (!NodeField.isPremitive(prpfld)) {
                                        const fieldObject = getFieldObject(propValue);
                                        objValue = fieldObject.value;
                                    } else {
                                        objValue = propValue;
                                        // console.log(objValue);
                                        objValue = replaceKeySets(objValue);
                                        // console.log(objValue);
                                        // 数値の時のみ式を適用する
                                        if ((['number', 'boolean'] as ModelUtil.DataType[]).includes(prpfld.dataType)) {
                                            objValue = DataUtil.applyFormula(objValue);
                                        }
                                        // console.log(objValue);
                                    }
                                    assert(objValue != null, `objValue is null. [${prpfld.id}]`);
                                    const fldObj: ReaderUtil.FieldObject = {
                                        field: prpfld,
                                        value: objValue
                                    };
                                    // console.log(fldObj);
                                    nextDynamicProps.prpflds.push(fldObj);
                                } break;
                                case 'prpclbk': {
                                    const prpclbk = p.data as NodePrpclbk.Data;
                                    // console.log(`propValue: ${propValue}, [${dynamicProps.dispatchers.map(d => d.id).join(', ')}]`);
                                    const prefixKey = propValue.split('.')[0];
                                    const eventKey = propValue.split('.')[1];
                                    const getTargetList = () => {
                                        switch (prefixKey) {
                                            case PrefixUtil.DISPATCH: return dynamicProps.dispatchers;
                                            case PrefixUtil.PRPCLBK: return dynamicProps.prpclbks;
                                        }
                                        throw new Error(`prefixKeyの値が不正。(prefixKey: '${prefixKey}')`);
                                    }
                                    const event = getTargetList().find(d => d.id === eventKey);
                                    assert(event != undefined, 'event is undefined.');
                                    // nextDynamicProps.prpclbks.push({
                                    //     id: prpclbk.id,
                                    //     funcargs: prpclbk.args.map(a => a.data as ModelUtil.NodeModelField),
                                    //     procItems: event.procItems
                                    // });
                                    nextDynamicProps.prpclbks.push({
                                        id: prpclbk.id,
                                        funcargs: prpclbk.args.map(a => a.data as ModelUtil.NodeModelField),
                                        procItems: event.procItems,
                                        callback: (args: []) => {
                                            event.callback(args);
                                        }
                                    });
                                } break;
                            }
                        });
                        return getElementsJsxRec(compElements.elements, nextDynamicProps, index);
                    }
                    case 'iterate': {
                        const iterate = element.data as NodeIterate.Data;
                        let fml = iterate.itrCntFml;

                        // 変数の置換
                        fml = replaceKeySets(fml);

                        // 式の適用
                        fml = DataUtil.applyFormula(fml);
                        const loopCnt = Number(fml);
                        const jsxList: JSX.Element[] = [];
                        // console.log(fml);
                        for (let i = 0; i < loopCnt; i++) {
                            // イテレータのコピーを取得
                            // const cloneIteraters = JSON.parse(JSON.stringify(dynamicProps.iteraters)) as ReaderUtil.IterateProps[];
                            const cloneIteraters = dynamicProps.iteraters.slice();
                            cloneIteraters.push({ id: iterate.id, self: i, max: loopCnt });
                            const actions = dynamicProps.actions.slice();
                            const nextDynamicProps = { ...dynamicProps };
                            nextDynamicProps.caches = nextDynamicProps.caches.slice();
                            nextDynamicProps.iteraters = cloneIteraters;
                            // const jsx = getElementsJsxRec(iterate.elements, { ...dynamicProps, iteraters: cloneIteraters, actions }, `${index}-${i}`);
                            const jsx = getElementsJsxRec(iterate.elements, nextDynamicProps, `${index}-${i}`);
                            // jsxList.push(<div key={`${index}-${i}`}>{jsx}</div>);

                            jsxList.push(...jsx);
                        }
                        return jsxList;
                    }
                    case 'accept': {
                        const accept = element.data as NodeAccept.Data;

                        const isAccept = ReaderUtil.isTrue(accept.condition, buildProps);
                        let jsxList: JSX.Element[] = [];
                        if (isAccept) {
                            jsxList = getElementsJsxRec(accept.elements, dynamicProps, index);
                        }
                        // console.log(jsxList.length);
                        return jsxList;
                    }
                    case 'case': {
                        const data = element.data as NodeCase.Data;
                        let jsxList: JSX.Element[] = [];
                        switch (data.method) {
                            case 'bool': {
                                const condition = data.condition;
                                assert(condition != undefined, 'メソッドがboolの場合、conditionがundefinedであってはならない。');
                                const isAccept = ReaderUtil.isTrue(condition, buildProps);
                                const whenTrue = data.items[0].data as NodeWhen.Data;
                                const whenFalse = data.items[1].data as NodeWhen.Data;
                                assert(whenTrue.bool === true && whenFalse.bool === false, 'case->boolに紐づいている要素が不正。')
                                if (isAccept) {
                                    // console.log('true');
                                    jsxList = getElementsJsxRec(whenTrue.elements, dynamicProps, index);
                                } else {
                                    // console.log('false');
                                    jsxList = getElementsJsxRec(whenFalse.elements, dynamicProps, index);
                                }
                            } break;
                        }
                        return jsxList;
                    }
                }
                return [];
            }

            const getEntryElements = () => {
                const view = ModelElementUtil.getInnerWrapFixed(appWrap, 'view').data as nodeView.Data;
                if (view.entry == undefined) return [];
                const entryComponent = compdefs.find(comp => comp.id === view.entry);
                // return [];
                assert(entryComponent != null, 'entryComponent is null.');
                const wrap: ModelUtil.WrapElement = { type: 'compdef', data: entryComponent };
                // return (wrap.data as NodeCompDef.Data).mngs;
                const elements = ModelElementUtil.getInnerWrapFixed(wrap, 'elements').data as ModelUtil.NodeElements;
                return elements.elements;
            }

            const buildProps: ReaderUtil.BuildProps = {
                dynamicProps: ReaderUtil.getInitialDynamicProps(),
                globalStates: [],
                globalFixedProps: {
                    states: [], launchArgs: [], dtypes: [], buildInitialStructureObject
                },
                invalidate,
                appendConsole
            }
            setupGlobalStyles(buildProps.dynamicProps);

            // console.log('★★★★');
            return getElementsJsxRec(getEntryElements(), buildProps.dynamicProps);
        }, [readerState]);

        return <>{innerJsxes}</>;
        // return (
        //     <_TabWrap>
        //         <TabbedPane
        //             tabElements={[
        //                 { name: 'App', cont: <>{innerJsxes}</>, enable: true },
        //                 { name: 'Console', cont: <AppConsole.Component />, enable: true },
        //             ]}
        //             activeNo={tabIndex}
        //             selectTabIndex={setTabIndex}
        //         />
        //     </_TabWrap>
        // );
    }
}

export default AppReader;

const _AppFrame = styled.div<{
    marginTop: number;
    marginLeft: number;
}>`
    display: inline-block;
    position: absolute;
    z-index: 11;
    top: ${props => props.marginTop}px;
    left: ${props => props.marginLeft}px;
    width: calc(100% - ${props => props.marginLeft * 2}px);
    height: calc(100% - ${props => props.marginTop * 2}px);
    background-color: #000405e6;
    /* border: 1px solid #474747; */
    border-radius: 4px;
    box-shadow: 10px 15px 15px #0000004b;
`;

const _AppHeader = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #ffffffbe;
    text-align: right;
`;
const _HeaderIcon = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 36px;
    height: 26px;
    background-color: #a35656;
    margin: 2px 4px 0 0;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    border-radius: 2px;
    :hover {
        background-color: #dfb5b5
    }
`;
const _AppBody = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(100% - 30px);
    background-color: #0c0077;
    overflow-y: auto;
    overflow-x: auto;
`;

const _TabWrap = styled.div<{
}>`
    display: inline-block;
    position: relative;
    margin: 4px 0 0 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
`;

const _WrapDiv = styled.div<{
    margin: number;
    bgColor?: string;
    textAlign: string;
}>`
    display: inline-block;
    position: relative;
    ${props => css`
        margin: ${props.margin}px 0 0 ${props.margin}px;
    `}
    width: calc(100% - ${props => props.margin * 2}px);
    height: calc(100% - ${props => props.margin * 2}px);
    ${props => !props.bgColor ? '' : css`
        background-color: ${props.bgColor};
    `};
    text-align: ${props => props.textAlign};
`;

const _FreeDiv = styled.div<{
    width: string;
    height: string;
    bgColor?: string;
    textAlign: string;
    left: number;
    top: number;
}>`
    display: inline-block;
    position: relative;
    width: ${props => props.width};
    height: ${props => props.height};
    ${props => !props.bgColor ? '' : css`
        background-color: ${props.bgColor};
    `};
    text-align: ${props => props.textAlign};
    margin-left: ${props => props.left}px;
    margin-top: ${props => props.top}px;
    vertical-align: top;
    box-sizing: border-box;
`;

const _TagDiv = styled.div<{
    src: string;
}>`${props => props.src}`;

const _TagSpan = styled.span<{
    src: string;
}>`${props => props.src}`;

const _TagInput = styled.input<{
    src: string;
}>`${props => props.src}`;
const _TagTextarea = styled.textarea<{
    src: string;
}>`${props => props.src}`;

const _TagSelect = styled.select<{
    src: string;
}>`${props => props.src}`;

const _TagImg = styled.image<{
    src: string;
}>`${props => props.src}`;

const _Div = styled.div<{
    width?: string;
    height?: string;
    bgColor?: string;
    textAlign: string;
    marginTop: string;
    marginRight: string;
    marginBottom: string;
    marginLeft: string;
    paddingTop: string;
    paddingRight: string;
    paddingBottom: string;
    paddingLeft: string;
    scrollX: boolean;
    scrollY: boolean;
}>`
    display: inline-block;
    position: relative;
    ${props => props.width == undefined ? '' : css`
        width: ${props.width};
    `}
    ${props => props.height == undefined ? '' : css`
        height: ${props.height};
    `}
    ${props => !props.bgColor ? '' : css`
        background-color: ${props.bgColor};
    `};
    text-align: ${props => props.textAlign};
    /* margin: calc(${props => props.marginTop}) calc(${props => props.marginRight}) calc(${props => props.marginBottom}) calc(${props => props.marginLeft}); */
    margin: ${props => props.marginTop} ${props => props.marginRight} ${props => props.marginBottom} ${props => props.marginLeft};
    padding: ${props => props.paddingTop} ${props => props.paddingRight} ${props => props.paddingBottom} ${props => props.paddingLeft};
    overflow-x: ${props => !props.scrollX ? 'hidden' : 'auto'};
    overflow-y: ${props => !props.scrollY ? 'hidden' : 'auto'};
    vertical-align: top;
    box-sizing: border-box;
`;

const _Span = styled.span<{
    fontSize: number;
    fontColor: string;
    bgColor?: string;
}>`
    color: ${props => props.fontColor};
    font-size: ${props => props.fontSize}px;
    ${props => !props.bgColor ? '' : css`
        background-color: ${props.bgColor};
    `}
`;

const _TextField = styled.input<{
    width: string;
    height: number;
    fontSize: number;
    bgColor: string;
    color: string;
}>`
    background-color: ${props => props.bgColor};
    width: ${props => props.width};
    height: ${props => props.height}px;
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
`;
