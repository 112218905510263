import styled, { css } from "styled-components";
import ValidateUtil from "./validateUtil";
import { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../../system/contents/entry/systemEntry";
import ColorUtil from "../../colorUtil";
import Styles from "../../../def/design/styles";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataUtil from "../../dataUtil";

namespace FormUtil {

    export type CheckableValue = {
        value: string;
        errors: ValidateUtil.ErrorType[];
    }

    export const isCompleteCheck = (checkables: CheckableValue[]) => {
        let result = true;
        checkables.forEach(checkable => {
            if (checkable.errors.length > 0) {
                result = false;
                return 1;
            }
        });
        return result;
    }

    // export const InputTextFormBak = (props: {
    //     titleLabel?: string;
    //     checkable: CheckableValue;
    //     setCheckable: (value: CheckableValue) => void;
    //     isEnable?: boolean;
    //     placeholder?: string;
    //     isCenter?: boolean;
    //     width?: number;
    //     isPassword?: boolean;
    //     validates?: ValidateUtil.Validate[];
    //     dependency?: CheckableValue[];
    //     marginTop?: number;
    //     isReadOnly?: boolean;
    //     marginBottom?: number;
    // }) => {
    //     const type = props.isPassword ?? false ? 'password' : 'text';

    //     const validaters = props.validates ?? [];
    //     const errors = props.checkable.errors;
    //     const dependency = (props.dependency ?? []).map(dep => dep.value);

    //     const getErrors = (value: string) => {
    //         const errors: ValidateUtil.ErrorType[] = [];
    //         validaters.forEach(validater => {
    //             if (!validater.checker(value)) {
    //                 errors.push(validater.errorType);
    //             }
    //         });
    //         return errors;
    //     }
    //     useEffect(() => {
    //         props.checkable.errors = getErrors(props.checkable.value);
    //         props.setCheckable({ ...props.checkable });
    //     }, dependency);
    //     return (
    //         <_Record
    //             isEnable={props.isEnable ?? true}
    //             isVisible={true}
    //             textAlign={(props.isCenter ?? false) ? 'center' : 'left'}
    //             marginTop={props.marginTop ?? 0}
    //             marginBottom={props.marginBottom ?? 0}
    //         >
    //             {props.titleLabel == undefined ? <></> : <_ItemnLabel
    //                 marginTop={5}
    //             >{props.titleLabel}</_ItemnLabel>}
    //             <_ItemnInputBak
    //                 type={type}
    //                 value={props.checkable.value}
    //                 hasTitle={props.titleLabel != undefined}
    //                 width={props.width}
    //                 isCenter={props.isCenter ?? false}
    //                 placeholder={props.placeholder}
    //                 onChange={(e) => {
    //                     const value = e.target.value;
    //                     props.checkable.value = value;
    //                     props.checkable.errors = getErrors(value);
    //                     props.setCheckable({ ...props.checkable });
    //                 }}
    //                 errorType={errors.length === 0 ? undefined : errors[0]}
    //                 readOnly={props.isReadOnly}
    //             />
    //         </_Record>
    //     );
    // }

    export const Record = (props: {
        isEnable?: boolean;
        textAlign?: 'left' | 'center' | 'right';
        marginTop?: number;
        marginBottom?: number;
        jsx: JSX.Element;
    }) => {
        return (
            <_Record
                isEnable={props.isEnable ?? true}
                isVisible={true}
                textAlign={props.textAlign ?? 'left'}
                marginTop={props.marginTop ?? 0}
                marginBottom={props.marginBottom ?? 0}
            >
                {props.jsx}
            </_Record>
        );
    }

    const DEFAULT_LABEL_WIDTH = 140;
    export const FormRecord = (props: {
        titleLabel?: string;
        labelWidth?: number;
        isEnabled?: boolean;
        isVisible?: boolean;
        textAlign?: 'left' | 'center' | 'right';
        marginTop?: number;
        marginBottom?: number;
        height?: number;
        jsx: JSX.Element;
    }) => {
        const lablelWidth = props.labelWidth ?? DEFAULT_LABEL_WIDTH;
        let marginTop = 5;
        if (props.height != undefined) {
            marginTop = props.height / 2 - 15;
        }
        return (
            <_Record
                isEnable={props.isEnabled ?? true}
                isVisible={props.isVisible ?? true}
                textAlign={props.textAlign ?? 'left'}
                marginTop={props.marginTop ?? 0}
                marginBottom={props.marginBottom ?? 0}
                height={props.height}
            >
                {props.titleLabel == undefined ? <></> : <_ItemnLabel
                    labelWidth={lablelWidth}
                    marginTop={marginTop}
                >{props.titleLabel}</_ItemnLabel>}
                {props.jsx}
            </_Record>
        );
    }

    export const FixedText = (props: {
        value: string;
        isEnable?: boolean;
        width?: number;
        marginLeft?: number;
        isRecordItem?: boolean;
    }) => {
        let width: string | undefined = undefined;
        if (props.isRecordItem) width = `calc(100% - ${DEFAULT_LABEL_WIDTH + 16}px)`;
        if (props.width != undefined) width = props.width + 'px';
        return (
            <_FixedLabel width={width}>{props.value}</_FixedLabel>
        );
    }

    export const TextField = (props: {
        checkable: CheckableValue;
        setCheckable: (value: CheckableValue) => void;
        isEnabled?: boolean;
        placeholder?: string;
        width?: number;
        isPassword?: boolean;
        validates?: ValidateUtil.Validate[];
        dependency?: CheckableValue[];
        isReadOnly?: boolean;
        isNumber?: boolean;
        marginLeft?: number;
        step?: number;
        extend?: (value: string) => void;
    }) => {
        const [isNaN, setNaN] = useState(false);

        // useEffect(() => {
        //     if (props.checkable.value === ' ') {
        //         props.checkable.value = '';
        //         props.setCheckable({ ...props.checkable });
        //     }
        // }, [props.checkable.value]);

        let type = props.isPassword ?? false ? 'password' : 'text';
        if (props.isNumber ?? false) {
            type = 'number';
        }

        const isEnabled = props.isEnabled ?? true;
        const validaters = props.validates ?? [];
        const errors = props.checkable.errors;
        const dependency = (props.dependency ?? []).map(dep => dep.value);

        const getErrors = (value: string) => {
            const errors: ValidateUtil.ErrorType[] = [];
            validaters.forEach(validater => {
                if (!validater.checker(value)) {
                    errors.push(validater.errorType);
                }
            });
            return errors;
        }
        // useEffect(() => {
        //     props.checkable.errors = getErrors(props.checkable.value);
        //     if (!isEnabled) props.checkable.errors.length = 0;
        //     // props.setCheckable({ ...props.checkable });
        // }, [...dependency, props.checkable.value, props.validates?.length]);
        useEffect(() => {
            props.checkable.errors = getErrors(props.checkable.value);
            if (!isEnabled) props.checkable.errors.length = 0;
            props.setCheckable({ ...props.checkable });
        }, [...dependency, props.checkable.value, validaters.length]);

        const value = props.checkable.value;
        const step = props.step ?? 1;

        return (
            <_ItemnInput
                type={type}
                value={value}
                isEnable={isEnabled}
                width={props.width ?? 100}
                placeholder={props.placeholder}
                step={step}
                onChange={(e) => {
                    // console.log(e.target.valueAsNumber);
                    props.checkable.value = e.target.value;
                    if (type === 'number') {
                        setNaN(Number.isNaN(e.target.valueAsNumber));
                    }
                    if(props.extend != undefined) props.extend(e.target.value);
                    props.setCheckable({ ...props.checkable });
                }}
                onBlur={() => {
                    if (type === 'number') {
                        if (isNaN) props.checkable.value = ' ';
                        else props.checkable.value = Number(value).toString();
                        // console.log(`[${props.checkable.value}]`);
                        props.setCheckable({ ...props.checkable });
                    }
                }}
                errorType={!isEnabled || errors.length === 0 ? undefined : errors[0]}
                readOnly={props.isReadOnly}
                marginLeft={props.marginLeft}
            // isEnable={isEnable}
            />
        );
    }
    export const ColorField = (props: {
        checkable: CheckableValue;
        setCheckable: (value: CheckableValue) => void;
        isEnabled?: boolean;
        validates?: ValidateUtil.Validate[];
        dependency?: CheckableValue[];
        isReadOnly?: boolean;
        marginLeft?: number;
    }) => {

        return (<>
            <TextField
                checkable={props.checkable}
                setCheckable={props.setCheckable}
                isEnabled={props.isEnabled}
                width={140}
                validates={props.validates}
                dependency={props.dependency}
                isReadOnly={props.isReadOnly}
                marginLeft={props.marginLeft}
            />
            <_ColorPicker type="color"
                value={props.checkable.value}
                isEnable={props.isEnabled ?? true}
                onChange={(e) => {
                    props.checkable.value = e.target.value;
                    props.setCheckable(props.checkable);
                }}
            />
        </>);
    }

    export type ComboboxItemProps = { value: string, labelText?: string, isDisabled?: boolean };

    export const Combobox = (props: {
        list: ComboboxItemProps[];
        headBlank?: boolean;
        checkable: CheckableValue;
        setCheckable: (value: CheckableValue) => void;
        isEnabled?: boolean;
        placeholder?: string;
        width?: number;
        validates?: ValidateUtil.Validate[];
        dependency?: CheckableValue[];
        isReadOnly?: boolean;
        marginLeft?: number;
        extend?: (value: string) => void;
    }) => {
        const validaters = props.validates ?? [];
        const errors = props.checkable.errors;
        const dependency = (props.dependency ?? []).map(dep => dep.value);
        const headBlank = props.headBlank ?? false;
        const list = props.list.slice();
        if (headBlank) list.unshift({ value: '', labelText: '' });

        const getErrors = (value: string) => {
            const errors: ValidateUtil.ErrorType[] = [];
            validaters.forEach(validater => {
                if (!validater.checker(value)) {
                    errors.push(validater.errorType);
                }
            });
            return errors;
        }

        const isEnabled = props.isEnabled ?? true;
        useEffect(() => {
            // console.log('value: ' + props.checkable.value);
            props.checkable.errors = getErrors(props.checkable.value);
            if (!isEnabled) props.checkable.errors.length = 0;
            props.setCheckable({ ...props.checkable });
        }, [...dependency, props.checkable.value,  validaters.length]);

        return (
            <_ItemCombobox
                value={props.checkable.value}
                width={props.width ?? 100}
                placeholder={props.placeholder}
                onChange={(e) => {
                    props.checkable.value = e.target.value;
                    // props.checkable.errors = getErrors(value);
                    if(props.extend != undefined) props.extend(e.target.value);
                    props.setCheckable({ ...props.checkable });
                }}
                errorType={!isEnabled || errors.length === 0 ? undefined : errors[0]}
                readOnly={props.isReadOnly}
                marginLeft={props.marginLeft}
            >{list.map((item, i) => {
                const labelText = item.labelText ?? item.value;
                return (
                    <_Option key={i}
                        value={item.value}
                        disabled={item.isDisabled ?? false}
                        isDisabled={item.isDisabled ?? false}
                    >{labelText}</_Option>
                );
            })}</_ItemCombobox>
        );
    }

    export const DateTimeField = (props: {
        checkable: CheckableValue;
        setCheckable: (value: CheckableValue) => void;
        isEnabled?: boolean;
        width?: number;
        validates?: ValidateUtil.Validate[];
        dependency?: CheckableValue[];
        isReadOnly?: boolean;
        marginLeft?: number;
    }) => {

        useEffect(() => {
            if (props.checkable.value === ' ') {
                props.checkable.value = '';
                props.setCheckable({ ...props.checkable });
            }
        }, [props.checkable.value]);

        const isEnabled = props.isEnabled ?? true;
        const validaters = props.validates ?? [];
        const errors = props.checkable.errors;
        const dependency = (props.dependency ?? []).map(dep => dep.value);

        const getErrors = (value: string) => {
            const errors: ValidateUtil.ErrorType[] = [];
            validaters.forEach(validater => {
                if (!validater.checker(value)) {
                    errors.push(validater.errorType);
                }
            });
            return errors;
        }
        useEffect(() => {
            props.checkable.errors = getErrors(props.checkable.value);
            if (!isEnabled) props.checkable.errors.length = 0;
            props.setCheckable({ ...props.checkable });
        }, dependency.concat(props.checkable.value));

        const selected = props.checkable.value === '' ? undefined : new Date(props.checkable.value);
        return (
            <ReactDatePicker
                selected={selected}
                dateFormatCalendar="yyyy / MM"
                onChange={(date) => {
                    let value = '';
                    if (date != null) {
                        value = DataUtil.getStringDate(date);
                    }
                    props.setCheckable({ ...props.checkable, value });
                }}
                dateFormat="yyyy/MM/dd"
                customInput={
                    <_ItemDateTime
                        value={props.checkable.value}
                        width={props.width ?? 100}
                        // onChange={(e) => {
                        //     // console.log(e.target.valueAsNumber);
                        //     props.checkable.value = e.target.value;
                        //     props.setCheckable({ ...props.checkable });
                        // }}
                        errorType={!isEnabled || errors.length === 0 ? undefined : errors[0]}
                        readOnly={props.isReadOnly}
                        marginLeft={props.marginLeft}
                    // isEnable={isEnable}
                    />
                }
            />
        );
    }


    export const WrapAdjuster = (props: {
        width?: number;
        surplusWidth?: number;
        height?: number;
        jsx: JSX.Element;
    }) => {
        return <_WrapAdjuster
            width={props.width}
            surplusWidth={props.surplusWidth}
            height={props.height}
        >{props.jsx}</_WrapAdjuster>
    }

    export const TextArea = (props: {
        checkable: CheckableValue;
        setCheckable: (value: CheckableValue) => void;
        isEnabled?: boolean;
        placeholder?: string;
        validates?: ValidateUtil.Validate[];
        dependency?: CheckableValue[];
        isReadOnly?: boolean;
    }) => {

        useEffect(() => {
            if (props.checkable.value === ' ') {
                props.checkable.value = '';
                props.setCheckable({ ...props.checkable });
            }
        }, [props.checkable.value]);

        const validaters = props.validates ?? [];
        const errors = props.checkable.errors;
        const dependency = (props.dependency ?? []).map(dep => dep.value);

        const getErrors = (value: string) => {
            const errors: ValidateUtil.ErrorType[] = [];
            validaters.forEach(validater => {
                if (!validater.checker(value)) {
                    errors.push(validater.errorType);
                }
            });
            return errors;
        }
        useEffect(() => {
            props.checkable.errors = getErrors(props.checkable.value);
            props.setCheckable({ ...props.checkable });
        }, dependency.concat(props.checkable.value));

        const value = props.checkable.value;

        const isEnable = props.isEnabled ?? true;
        return (
            <_ItemnTextArea
                spellCheck="false"
                value={value}
                placeholder={props.placeholder}
                onChange={(e) => {
                    // console.log(e.target.valueAsNumber);
                    props.checkable.value = e.target.value;
                    props.setCheckable({ ...props.checkable });
                }}
                // errorType={!isEnable || errors.length === 0 ? undefined : errors[0]}
                readOnly={props.isReadOnly}
            />
        );
    }

    export type ButtonProps = {
        label: string;
        isEnable?: boolean;
        width?: number;
        icon?: JSX.Element;
        interval?: number;
        callback: () => void;
    }

    export const ButtonRecord = (props: {
        buttons: ButtonProps[],
        align?: 'left' | 'center' | 'right';
        marginTop?: number;
        marginBottom?: number;
        isIconButton?: boolean;
        interval?: number;
    }) => {
        const { store } = useContext(GlobalContext);
        const design = store.design;
        const align = props.align ?? 'right';
        const interval = props.interval ?? 4;
        return (
            <_Record
                isEnable={true}
                isVisible={true}
                textAlign={align}
                marginTop={props.marginTop ?? 0}
                marginBottom={props.marginBottom ?? 0}
                height={!props.isIconButton ? 40 : 68}
            >
                {props.buttons.map((button, i) => {
                    if (button.icon == undefined) {
                        return (
                            <_Button
                                key={i}
                                grad1={design.accent1}
                                grad2={ColorUtil.toWhite(design.accent1, 30)}
                                textColor={design.text1}
                                borderColor={design.text1}
                                isCenter={align === 'center'}
                                isEnable={button.isEnable ?? true}
                                width={button.width}
                                interval={interval}
                                onClick={button.callback}
                            >{button.label}</_Button>
                        );
                    } else {
                        return (
                            <_IconButton
                                key={i}
                                grad1={design.accent1}
                                grad2={ColorUtil.toWhite(design.accent1, 30)}
                                textColor={design.text1}
                                borderColor={design.text1}
                                isCenter={align === 'center'}
                                isEnable={button.isEnable ?? true}
                                width={button.width}
                                interval={interval}
                                onClick={button.callback}
                            >{button.icon}<br />{button.label}</_IconButton>
                        );
                    }
                })}
            </_Record>
        );
    }

    export const ButtonItem = (props: {
        button: ButtonProps
    }) => {
        const { store } = useContext(GlobalContext);
        const design = store.design;
        const button = props.button;
        const interval = button.interval ?? 4;
        if (button.icon == undefined) {
            return (
                <_Button
                    grad1={design.accent1}
                    grad2={ColorUtil.toWhite(design.accent1, 30)}
                    textColor={design.text1}
                    borderColor={design.text1}
                    isCenter={false}
                    isEnable={button.isEnable ?? true}
                    width={button.width}
                    interval={interval}
                    onClick={button.callback}
                >{button.label}</_Button>
            );
        } else {
            return (
                <_IconButton
                    isCenter={false}
                    grad1={design.accent1}
                    grad2={ColorUtil.toWhite(design.accent1, 30)}
                    textColor={design.text1}
                    borderColor={design.text1}
                    isEnable={button.isEnable ?? true}
                    width={button.width}
                    interval={interval}
                    onClick={button.callback}
                >{button.icon}<br />{button.label}</_IconButton>
            );
        }
        // return (
        //     <_Button
        //         isCenter={false}
        //         isEnable={props.button.isEnable ?? true}
        //         width={props.button.width}
        //         onClick={props.button.callback}
        //     >{props.button.label}</_Button>
        // );
    }

    export const Message = (props: {
        message: string,
        isCenter?: boolean;
        marginTop?: number;
        marginBottom?: number;
    }) => {
        return (
            <_Record
                isEnable={true}
                isVisible={true}
                textAlign={(props.isCenter ?? false) ? 'center' : 'left'}
                marginTop={props.marginTop ?? 0}
                marginBottom={props.marginBottom ?? 0}
            >
                <_Message
                    isCenter={props.isCenter ?? false}
                >{props.message}</_Message>
            </_Record>
        );
    }

    export const MessageLink = (props: {
        message: string,
        isCenter?: boolean;
        clickAction: () => void;
        marginTop?: number;
        marginBottom?: number;
    }) => {
        return (
            <_Record
                isEnable={true}
                isVisible={true}
                textAlign={(props.isCenter ?? false) ? 'center' : 'left'}
                marginTop={props.marginTop ?? 0}
                marginBottom={props.marginBottom ?? 0}
            >
                <_Message
                    isLink={true}
                    onClick={props.clickAction}
                    isCenter={props.isCenter ?? false}
                >{props.message}</_Message>
            </_Record>
        );
    }

    export const BorderPanel = (props: {
        title: string;
        isEnable?: boolean;
        isVisible?: boolean;
        height?: number;
        backgroundColor?: string;
        innerJsx: JSX.Element;
    }) => {
        const { store } = useContext(GlobalContext);
        // const title = props.title ?? 'title pl';
        const isVisible = props.isVisible ?? true;
        const isEnable = props.isEnable ?? true;
        const backgroundColor = props.backgroundColor ?? '#cedadd';
        return (<>
            {!isVisible ? <></> : <_BorderText
                textColor={store.design.text4}
            >{props.title}</_BorderText>}
            <_BorderPanel
                isEnable={isEnable}
                isVisible={isVisible}
                height={props.height}
                backgroundColor={backgroundColor}
            >{props.innerJsx}</_BorderPanel>
        </>);
    }

    export const InfoFrame = (props: {
        height?: number;
        textRecords: string[];
    }) => {
        const height = props.height ?? 70;
        const color = '#1a9900';
        return (<>
            <_InfoDiv height={height} color={color}>
                {props.textRecords.map((text, i) => {
                    return <span key={i}>{text}<br /></span>;
                })}
            </_InfoDiv>
        </>);
    };

    export const Switch = (props: {
        label: string;
        isUse: boolean;
        isEnable?: boolean;
        width?: number;
        isRelation?: boolean;
        callback: () => void;
    }) => {
        const isRelation = props.isRelation ?? false;
        const callback = isRelation && props.isUse ? () => { } : props.callback;
        return (
            <_Switch
                isRelation={isRelation}
                isUse={props.isUse}
                isEnable={props.isEnable ?? true}
                width={props.width}
                onClick={callback}
            >{props.label}</_Switch>
        );
    }

    export const SwitchTwoFace = (props: {
        label1: string;
        label2: string;
        width: number;
        rate1: number;
        rate2: number;
        isUse: boolean;
        isEditable?: boolean;
        isEnable?: boolean;
        isLarge?: boolean;
        callback: () => void;
    }) => {
        const { store } = useContext(GlobalContext);
        const design = store.design;
        const isEnable = props.isEnable ?? true;
        const isEditable = props.isEditable ?? true;
        return (<_SwitchTwoFace
            isEnable={isEnable}
            isEditable={isEditable}
            width={props.width}
            isLarge={props.isLarge ?? false}
            onClick={props.callback}
        >
            <_SwitchItem
                isUse={isEnable && !props.isUse}
                rate={props.rate1}
                isLeft={true}
                isEditable={isEditable}
                isLarge={props.isLarge ?? false}
                bgColor={ColorUtil.toWhite(design.accent2, -60)}
                activeColor={design.accent2}
            >{props.label1}</_SwitchItem>
            <_SwitchItem
                isUse={isEnable && props.isUse}
                rate={props.rate2}
                isLeft={false}
                isEditable={isEditable}
                isLarge={props.isLarge ?? false}
                bgColor={ColorUtil.toWhite(design.accent2, -60)}
                activeColor={design.accent2}
            >{props.label2}</_SwitchItem>
        </_SwitchTwoFace>);
    }

    export const checkItems = (list: CheckableValue[]) => {
        return list.find(item => item.errors.length > 0) == undefined;
    }

    export const InputTextFormBak = (props: {
        titleLabel?: string;
        checkable: CheckableValue;
        setCheckable: (value: CheckableValue) => void;
        isEnable?: boolean;
        placeholder?: string;
        isCenter?: boolean;
        width?: number;
        isPassword?: boolean;
        validates?: ValidateUtil.Validate[];
        dependency?: CheckableValue[];
        marginTop?: number;
        isReadOnly?: boolean;
        marginBottom?: number;
    }) => {
        const type = props.isPassword ?? false ? 'password' : 'text';

        const validaters = props.validates ?? [];
        const errors = props.checkable.errors;
        const dependency = (props.dependency ?? []).map(dep => dep.value);

        const getErrors = (value: string) => {
            const errors: ValidateUtil.ErrorType[] = [];
            validaters.forEach(validater => {
                if (!validater.checker(value)) {
                    errors.push(validater.errorType);
                }
            });
            return errors;
        }
        useEffect(() => {
            props.checkable.errors = getErrors(props.checkable.value);
            props.setCheckable({ ...props.checkable });
        }, dependency);
        return (
            <_Record
                isEnable={props.isEnable ?? true}
                isVisible={true}
                textAlign={(props.isCenter ?? false) ? 'center' : 'left'}
                marginTop={props.marginTop ?? 0}
                marginBottom={props.marginBottom ?? 0}
            >
                {props.titleLabel == undefined ? <></> : <_ItemnLabel marginTop={5}>{props.titleLabel}</_ItemnLabel>}
                <_ItemnInputBak
                    type={type}
                    value={props.checkable.value}
                    hasTitle={props.titleLabel != undefined}
                    width={props.width}
                    isCenter={props.isCenter ?? false}
                    placeholder={props.placeholder}
                    onChange={(e) => {
                        const value = e.target.value;
                        props.checkable.value = value;
                        props.checkable.errors = getErrors(value);
                        props.setCheckable({ ...props.checkable });
                    }}
                    errorType={errors.length === 0 ? undefined : errors[0]}
                    readOnly={props.isReadOnly}
                />
            </_Record>
        );
    }
}

export default FormUtil;

const _Record = styled.div<{
    isEnable: boolean;
    isVisible: boolean;
    textAlign: 'left' | 'center' | 'right';
    marginTop: number;
    marginBottom: number;
    height?: number;
}>`
    display: inline-block;
    ${props => props.isVisible ? '' : css`
        display: none;
    `}
    position: relative;
    width: 100%;
    height: ${props => props.height ?? '40'}px;
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom}px;
    /* background-color: #b3b4c4; */
    /* border: 1px solid #000; */
    ${props => props.isEnable ? '' : Styles.CSS_DISABLE}
    text-align: ${props => props.textAlign};
    white-space: nowrap;
    overflow: hidden;
`;

const _ItemnLabel = styled.div<{
    labelWidth?: number;
    marginTop: number;
}>`
    display: inline-block;
    width: ${props => props.labelWidth}px;
    ${Styles.CSS_LABEL_MIDIUM}
    color: #585858;
    /* border: solid 1px #7a7a7abf;
    box-sizing: border-box; */
    background-color: #dbe3e9;
    /* background: linear-gradient(to bottom,
        #9b00000f,
        #9b00000f,
        #9b00000f,
        #9b00000f,
        #9b00000f, #9b0000c5
    ); */
    border-top: solid 1px #8cb3d3;
    border-bottom: solid 4px #75abd6;
    box-sizing: border-box;
    /* border-radius: 6px 6px 0 0; */
    text-align: center;
    vertical-align: top;
    margin: ${props => props.marginTop}px 4px 0 4px;
`;

const _FixedLabel = styled.div<{
    width?: string;
}>`
    display: inline-block;
    height: calc(100% - 4px);
    ${Styles.CSS_LABEL_MIDIUM}
    color: #a32323;
    box-sizing: border-box;
    background-color: #85a2c51a;
    text-align: left;
    vertical-align: top;
    user-select: text;
    margin: 5px 4px 0 4px;
    overflow-x: hidden;
    overflow-y: hidden;
    ${props => props.width == undefined ? '' : css`
        width: ${props.width};
    `}
`;

const _ItemnInputBak = styled.input<{
    readOnly?: boolean;
    isCenter: boolean;
    hasTitle: boolean;
    width?: number;
    errorType?: ValidateUtil.ErrorType;
}>`
    display: inline-block;
    height: calc(100% - 4px);
    ${props => !props.hasTitle ? css`
    ` : css`
        width: calc(100% - 152px);
    `}
    ${props => props.width == undefined ? '' : css`
        width: ${props.width}px;
    `}
    margin-top: 2px;
    font-size: 24px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #3d3d3d;
    border: solid 1px #959595;
    border-radius: 4px;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: ${props => {
        if (props.errorType == undefined) return '#ececec';
        else {
            switch (props.errorType) {
                case 'required': return '#dbd7b3';
                case 'length': return '#dbb3b3';
                case 'value': return '#b3b3db';
                case 'relation': return '#b3dbb3';
            }
        }
    }};
    text-align: left;
    vertical-align: top;
    user-select: text;
    /* ${props => !props.isCenter ? css`
        margin-left: 4px;
    ` : css``
    } */
    ${props => !props.readOnly ? '' : css`
        color: #020075;
        /* border: none; */
        outline: none;
        background-color: transparent;
    `}
    ::placeholder {
        color: #00000050;
        font-style: italic;
    }
`;

const _ItemnInput = styled.input<{
    readOnly?: boolean;
    width?: number;
    errorType?: ValidateUtil.ErrorType;
    marginLeft?: number;
    isEnable: boolean;
}>`
    display: inline-block;
    height: calc(100% - 4px);
    width: ${props => props.width}px;
    margin-top: 2px;
    margin-left: ${props => props.marginLeft ?? 0}px;
    font-size: 24px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #3d3d3d;
    border: solid 1px #959595;
    border-radius: 4px;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: ${props => {
        if (props.errorType == undefined) return '#ececec';
        else {
            switch (props.errorType) {
                case 'required': return '#dbd7b3';
                case 'length': return '#dbb3b3';
                case 'value': return '#b3b3db';
                case 'relation': return '#b3dbb3';
            }
        }
    }};
    text-align: left;
    vertical-align: top;
    user-select: text;
    ${props => !props.readOnly ? '' : css`
        color: #020075;
        /* border: none; */
        outline: none;
        background-color: transparent;
    `}
    ${props => props.isEnable ? '' : Styles.CSS_DISABLE}
    ::placeholder {
        color: #00000050;
        font-style: italic;
    }
`;

const _ItemCombobox = styled.select<{
    readOnly?: boolean;
    width?: number;
    errorType?: ValidateUtil.ErrorType;
    marginLeft?: number;
}>`
    display: inline-block;
    height: calc(100% - 4px);
    width: ${props => props.width}px;
    margin-top: 2px;
    margin-left: ${props => props.marginLeft ?? 0}px;
    font-size: 24px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #3d3d3d;
    border: solid 1px #959595;
    border-radius: 4px;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: ${props => {
        if (props.errorType == undefined) return '#ececec';
        else {
            switch (props.errorType) {
                case 'required': return '#dbd7b3';
                case 'length': return '#dbb3b3';
                case 'value': return '#b3b3db';
                case 'relation': return '#b3dbb3';
            }
        }
    }};
    text-align: left;
    vertical-align: top;
    user-select: text;
    ${props => !props.readOnly ? '' : css`
        color: #020075;
        /* border: none; */
        outline: none;
        background-color: transparent;
        pointer-events: none;
    `}
    ::placeholder {
        color: #00000050;
        font-style: italic;
    }
`;

const _ItemDateTime = styled.input<{
    readOnly?: boolean;
    width?: number;
    errorType?: ValidateUtil.ErrorType;
    marginLeft?: number;
    // isEnable: boolean;
}>`
    display: inline-block;
    /* position: absolute; */
    /* height: calc(100% - 4px); */
    height: 36px;
    width: ${props => props.width}px;
    margin-top: 2px;
    margin-left: ${props => props.marginLeft ?? 0}px;
    z-index: 20;
    font-size: 24px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #000000;
    border: solid 1px #959595;
    border-radius: 8px;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: ${props => {
        if (props.errorType == undefined) return '#ececec';
        else {
            switch (props.errorType) {
                case 'required': return '#dbd7b3';
                case 'length': return '#dbb3b3';
                case 'value': return '#b3b3db';
                case 'relation': return '#b3dbb3';
            }
        }
    }};
    text-align: left;
    vertical-align: top;
    user-select: text;
    ${props => !props.readOnly ? '' : css`
        color: #020075;
        /* border: none; */
        outline: none;
        background-color: transparent;
    `}
`;

const _Option = styled.option<{
    isDisabled: boolean;
}>`
    ${props => !props.isDisabled ? '' : css`
        color: #0000004b;
    `}
`;

const _Message = styled.div<{
    isLink?: boolean;
    isCenter: boolean;
}>`
    display: inline-block;
    height: calc(100% - 4px);
    margin-top: 2px;
    font-size: ${Styles.FONT_MIDIUM}px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #002c52;
    border-radius: 4px;
    padding: 0 5px;
    box-sizing: border-box;
    /* background-color: #d37878; */
    text-align: left;
    vertical-align: top;
    user-select: text;
    ${props => !props.isCenter ? css`
        margin-left: 4px;
    ` : css``
    }
    ${props => !props.isLink ? '' : css`
        color: #0459a3;
        user-select: none;
        text-decoration: underline;
        &:hover {
            color: #68a4d8;
        }
    `}
`;

const _WrapAdjuster = styled.div<{
    width?: number;
    surplusWidth?: number;
    height?: number;
}>`
    display: inline-block;
    ${props => props.width == undefined ? '' : css`
        width: ${props.width}px;
    `}
    ${props => props.surplusWidth == undefined ? '' : css`
        width: calc(100% - ${props.surplusWidth}px);
    `}
    ${props => props.height == undefined ? '' : css`
        height: ${props.height}px;
    `}
    /* background-color: #78d37d; */
    border: 1px solid #696969;
    margin: 3px;
    box-sizing: border-box;
`;

const _Button = styled.div<{
    grad1: string;
    grad2: string;
    textColor: string;
    borderColor: string;
    isEnable: boolean;
    isCenter: boolean;
    width?: number;
    interval: number;
}>`
    ${Styles.CSS_LABEL_MIDIUM}
    ${props => props.width == undefined ? css`
        padding: 0 40px;
    ` : css`
        width: ${props.width}px;
    `}
    /* line-height: 25px; */
    box-sizing: border-box;
    /* background-color: #a8a8a8; */
    ${props => css`
        background: linear-gradient(to bottom, ${props.grad1}, ${props.grad2}, ${props.grad1});
        border: 1px solid ${props.borderColor};
    `}
    color: #515151;
    border-radius: 4px;
    vertical-align: top;
    margin: 5px 0 0 ${props => props.interval}px;
    ${props => !props.isCenter ? css<{ interval: number }>`
        margin-left: ${props => props.interval}px;
    ` : css``
    }
    ${props => props.isEnable ? '' : Styles.CSS_DISABLE}
    &:hover {
        /* background-color: #98bfc2; */
        /* background: linear-gradient(to bottom, #989898, #b4b4b4, #989898); */
        opacity: 0.7;
    }
`;

const _IconButton = styled.div<{
    grad1: string;
    grad2: string;
    textColor: string;
    borderColor: string;
    isEnable: boolean;
    isCenter: boolean;
    width?: number;
    interval: number;
}>`
    ${Styles.CSS_LABEL_MIDIUM}
    ${props => props.width == undefined ? css`
        padding: 0 40px;
    ` : css`
        width: ${props.width}px;
    `}
    height: 58px;
    padding: 4px 0 0 0;
    /* line-height: 25px; */
    box-sizing: border-box;
    /* background-color: #a8a8a8; */
    ${props => css`
        background: linear-gradient(to bottom, ${props.grad1}, ${props.grad2}, ${props.grad1});
        border: 1px solid ${props.borderColor};
    `}
    color: #515151;
    border-radius: 4px;
    margin: 5px 0 0 ${props => props.interval}px;
    ${props => !props.isCenter ? css<{ interval: number }>`
        margin-left: ${props => props.interval}px;
    ` : css``
    }
    ${props => props.isEnable ? '' : Styles.CSS_DISABLE}
    &:hover {
        /* background-color: #98bfc2; */
        /* background: linear-gradient(to bottom, #989898, #b4b4b4, #989898); */
        opacity: 0.7;
    }
`;

const _Switch = styled.div<{
    isUse: boolean;
    isEnable: boolean;
    width?: number;
    isRelation: boolean;
}>`
    ${Styles.CSS_LABEL_MIDIUM}
    ${props => props.width == undefined ? css`
        padding: 0 40px;
    ` : css`
        width: ${props.width}px;
    `}
    /* line-height: 25px; */
    box-sizing: border-box;
    background-color: #a8a8a8;
    color: #515151;
    border: 1px solid #747474;
    ${props => !props.isRelation ? css<{ isUse: boolean }>`
        background-color: ${props => !props.isUse ? '#e7e7e779' : '#e4f67e'};
    ` : css<{ isUse: boolean }>`
        background-color: ${props => !props.isUse ? '#ddc1d979' : '#d898b3'};
        color: ${props => !props.isUse ? '#515151' : '#9c2626'};
    `}
    border-radius: 4px;
    margin: 5px 0 0 4px;
    ${props => props.isEnable ? '' : Styles.CSS_DISABLE}
    &:hover {
        ${props => !props.isRelation ? css<{ isUse: boolean }>`
            background-color: ${props => !props.isUse ? '#edefb286' : '#b5be7f'};
        ` : css<{ isUse: boolean }>`
            background-color: ${props => !props.isUse ? '#d3a1cb79' : '#d898b3'};
            color: ${props => !props.isUse ? '#515151' : '#9c2626'};
        `}
    }
`;

const _SwitchTwoFace = styled.div<{
    isEnable: boolean;
    isEditable: boolean;
    width: number;
    isLarge: boolean;
}>`
    width: ${props => props.width}px;
    ${props => !props.isLarge ? css`
        ${Styles.CSS_LABEL_MIDIUM}
    ` : css`
        ${Styles.CSS_LABEL_LARGE}
    `}
    margin: 5px 0 0 4px;
    ${props => props.isEditable ? '' : css`    
        pointer-events: none;
    `}
    ${props => props.isEnable ? '' : Styles.CSS_DISABLE}
    &:hover {
        /* opacity: 0.7; */
    }
`;

const _SwitchItem = styled.div<{
    isUse: boolean;
    rate: number;
    isLeft: boolean;
    isEditable: boolean;
    isLarge: boolean;
    bgColor: string;
    activeColor: string;
}>`
    ${Styles.CSS_LABEL_MIDIUM}
    width: ${props => props.rate}%;
    height: 100%;
    /* line-height: 25px; */
    border-top: 1px solid #747474;
    border-bottom: 1px solid #747474;
    text-align: ${props => props.isLeft ? css`
        border-left: 1px solid #747474;
    ` : css`
        border-right: 1px solid #747474;
    `};
    text-align: ${props => props.isLeft ? 'right' : 'left'};
    box-sizing: border-box;
    background-color: ${props => props.bgColor};
    color: #515151;
    ${props => !props.isLarge ? css<{ isLeft: boolean }>`
        padding: 0 10px;
        font-size: 16px;
        border-radius: ${props => props.isLeft ? '8px 0 0 8px' : '0 8px 8px 0'};
    ` : css<{ isLeft: boolean }>`
        font-size: 22px;
        padding: 10px 10px;
        border-radius: ${props => props.isLeft ? '22px 0 0 22px' : '0 22px 22px 0'};
    `}
    ${props => props.isEditable ? css`
        ${!props.isUse ? '' : css<{ isLarge: boolean; activeColor: string; }>`
            color: #b30000;
            ${props => !props.isLarge ? css`
                font-size: 22px;
            ` : css`
                font-size: 28px;
            `}
            background-color: ${props => props.activeColor};
            ${props => !props.isLarge ? css`
                font-size: 22px;
            ` : css`
                font-size: 28px;
            `}
        `}
    ` : css`
        ${!props.isUse ? '' : css<{ isLarge: boolean; activeColor: string; }>`
            color: #663e3e;
            ${props => !props.isLarge ? css`
                font-size: 22px;
            ` : css`
                font-size: 28px;
            `}
            background-color: ${props => props.activeColor};
        `};
    `}
    vertical-align: top;
    transition: background-color 0.3s;
`;

const _BorderText = styled.div<{
    textColor: string;
}>`
    ${Styles.CSS_LABEL_SMALL}
    color: ${props => props.textColor};
    /* background-color: #0a0a0a40; */
    font-style: italic;
    margin: 4px 0 0 8px;
`;
const _BorderPanel = styled.div<{
    isEnable: boolean;
    isVisible: boolean;
    height?: number;
    backgroundColor: string;
}>`
    display: inline-block;
    ${props => props.isVisible ? '' : css`
        display: none;
    `}
    margin: 0 0 0 6px;
    padding: 4px;
    width: calc(100% - 12px);
    ${props => props.height == undefined ? '' : css`
        height: ${props.height}px;
    `}
    background-color: ${props => props.backgroundColor};
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: left;
    /* overflow-y: auto; */
    ${props => props.isEnable ? '' : Styles.CSS_DISABLE}
`;

const _InfoDiv = styled.div<{
    height: number;
    color: string;
}>`
    margin: 4px 0 0 0;
    display: inline-block;
    background-color: #e7e5e561;
    border: solid 1px #949494;
    width: 100%;
    height: ${props => props.height}px;
    padding: 2px;
    box-sizing: border-box;
    text-align: left;
    font-size: ${Styles.FONT_SMALL}px;
    color: ${props => props.color};
    overflow-y: auto;
`;


const _ItemnTextArea = styled.textarea<{

}>`
   display: inline-block;
   width: 100%;
   height: 100%;
   background-color: #ebf5f5;
   font-size: 18px;
   color: black;
   border: none;
   resize: none;
   box-sizing: border-box;
`;

const _FromWrap = styled.div<{
}>`
   display: inline-block;
   position: relative;
`;

const _ColorPicker = styled.input<{
    isEnable: boolean;
}>`
   display: inline-block;
   margin: 7px 0 0 4px;
   width: 50px;
    ${props => props.isEnable ? '' : Styles.CSS_DISABLE}
`;