import styled from "styled-components";
import NodeTagdiv from "../develop/function/editor/ui/tag/nodeTagDiv";
import ModelUtil from "../develop/function/util/modelUtil";
import ReaderUtil from "./readerUtil";
import TagUtil from "../develop/function/editor/ui/tag/tagUtil";
import { useMemo, useState } from "react";
import assert from "assert";

namespace AppPatialDiv {

    export const Component = (props: {
        buildProps: ReaderUtil.BuildProps;
        getListener: (listeners: TagUtil.Listener, method: TagUtil.ListenerMethod, invalidatePointId?: string) => (() => void) | undefined;
        getElementsJsxRec: (elements: ModelUtil.WrapElement[], dynamicProps: ReaderUtil.DynamicProps, ownerIndex?: string) => JSX.Element[];
        // generateJsxes: () => JSX.Element[];
        // applyProcedure: () => void;
        // jsx: JSX.Element[];
        data: NodeTagdiv.NodeData;
    }) => {

        const [dummy, setDummy] = useState<any>({});

        // const jsx = useMemo(() => {
        //     console.log('update!');

        const getFomulaAplliedValue = (base: string) => ReaderUtil.getFomulaAplliedValue(base, props.buildProps);

        // const invalidate = props.buildProps.invalidate;

        // assert(invalidate != undefined, 'AppPatialDivでinvalidateがundefinedであってはならない。');

        const buildJsx = () => {
            const data = props.data;
            const getListener = props.getListener;

            const nextDynamicProps: ReaderUtil.DynamicProps = { ...props.buildProps.dynamicProps };
            nextDynamicProps.caches = nextDynamicProps.caches.slice();
            // const nextDynamicProps: ReaderUtil.DynamicProps = JSON.parse(JSON.stringify(props.buildProps.dynamicProps));
            const localId = data.localId;
            if (localId != undefined) {
                const localPoints: ReaderUtil.LocalPoint[] = nextDynamicProps.localPoints.slice();
                nextDynamicProps.localPoints = localPoints;
                const invalidate = () => setDummy({});

                localPoints.push({
                    id: localId,
                    invalidate
                });
                // if(localId === 'listItem') {
                //     console.log(props.buildProps.dynamicProps.caches);
                // }
                // console.log(localPoints);
            }
            // console.log(buildProps.dynamicProps.styles);
            const src = ReaderUtil.getAppliedStyleSources(data.styles, props.buildProps).join('\n');

            const innerJsx = props.getElementsJsxRec(data.elements, nextDynamicProps);
            // const innerJsx = props.generateJsxes();
            // const innerJsx = <>{props.jsx}</>;

            const attrs: any = {};
            if (data.attrs != undefined) {
                data.attrs.forEach(attr => {
                    const value = getFomulaAplliedValue(attr.value);

                    // console.log(`${attr.key} = ${value}`);
                    attrs[attr.key] = value;
                });
            }
            return (
                <_TagDiv
                    src={src}
                    onClick={getListener(data.listener, 'click')}
                    onContextMenu={getListener(data.listener, 'context')}
                    {...attrs}
                >{innerJsx}</_TagDiv>
            );
        }
        const memoDeps = props.data.memoDeps;
        let deps: any[] = [{}];
        if (memoDeps != undefined) {
            deps = memoDeps.map(dep => getFomulaAplliedValue(dep.toString()));
        }
        const jsx = useMemo(buildJsx, deps);

        return jsx;
        // }, [props.buildProps.globalStates, dummy]);

        // return jsx;
    }
}

export default AppPatialDiv;


const _TagDiv = styled.div<{
    src: string;
}>`${props => props.src}`;

