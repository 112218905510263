import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import assert from "assert";import AbstractModelEditor from "./editor/abstractModelEditor";
import { GlobalContext } from "../../entry/systemEntry";
import FormUtil from "../../../../common/component/form/formUtiil";
import ModelElementUtil from "./util/modelElementUtil";
import ModelUtil from "./util/modelUtil";
import TreeUtil from "../../../../common/component/tree/treeUtil";

namespace ModelEditDialog {

    export type TempPorps = {
        data: object | null;
        inputOK: boolean;
    }

    export type ExtendModProps = {
        focusNode: TreeUtil.ElementNode;
        prevData: any;
        newData: any;
    }

    export const Conponent = (props: {
        editor: AbstractModelEditor;
        isCreate: boolean;
    }) => {

        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;
        const focusNode = manageItems.focusNode;
        const wrap = manageItems.focusNode.data as ModelUtil.WrapElement;

        const [temp, setTemp] = useState<TempPorps>({
            data: wrap.data,
            inputOK: false
        });

        const close = () => {
            store.system.dialog = null;
            dispatcher.updateStore();
        }

        // useEffect(() => {
        //     console.log(element.data);
        //     setTempData(element.data);
        // }, []);

        return (
            <_Frame
                width={600}
                height={800}
            >
                <FormUtil.ButtonRecord
                    align="center"
                    buttons={[
                        {
                            label: 'Cancel',
                            callback: () => {
                                // 初期生成時にダイアログを閉じる場合、自身（仮想ノード）を削除する
                                if (props.isCreate) {
                                    ModelElementUtil.removeSelf(manageItems);
                                }
                                close();
                            }
                        },
                        {
                            label: props.isCreate ? 'Create' : 'Regist',
                            isEnable: temp.inputOK,
                            callback: () => {
                                assert(temp.data != null, 'registされた時点でtempDataはnullでない想定。');
                                if (props.isCreate) {
                                    props.editor.createAction(manageItems, temp.data);
                                    props.editor.createExtendAction(focusNode);
                                } else {
                                    const prevData = JSON.parse(JSON.stringify(wrap.data));
                                    props.editor.modifyAction(manageItems, temp.data);
                                    props.editor.modifyExtendAction({
                                        focusNode,
                                        prevData,
                                        newData: temp.data,
                                    });
                                }
                                close();
                            }
                        },
                    ]}
                />
                {props.editor.getForm(temp, setTemp)}
            </_Frame>
        );
    }
}

export default ModelEditDialog;

const _Frame = styled.div<{
    width: number;
    height: number;
}>`
    display: inline-block;
    position: absolute;
    z-index: 11;
    top: calc(50% - ${props => props.height / 2}px);
    left: calc(50% - ${props => props.width / 2}px);
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-color: #cfd7dae6;
    border: 1px solid #474747;
    border-radius: 4px;
    box-shadow: 10px 15px 15px #0000004b;
    overflow-y: auto;
`;