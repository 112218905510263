import { useContext, useMemo } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../../entry/systemEntry";
import assert from "assert";
import StoreProject from "../../../../redux/store/storeProject";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import ModelUtil from "../util/modelUtil";
import ModelElementUtil from "../util/modelElementUtil";
import StoreSystem from "../../../../redux/store/storeSystem";
import NodeStlarg from "../editor/decrare/nodeStlarg";
import NodeStyle from "../editor/decrare/nodeStyle";
import PrefixUtil from "../util/prefixUtil";
import AssertUtil from "../../../../../common/assertUtil";
import StyleChooser from "../editor/decrare/styleChooser";
import StylePropDefiner from "../editor/decrare/style/stylePropDefiner";
import NodePrpclbk from "../editor/var/nodePrpclbkEditor";
import NodeFunction from "../editor/var/func/nodeFunction";
import NodeField from "../editor/var/nodeField";
import NodeIterate from "../editor/nodeIterate";

namespace ScopeManager {

    export type KeyOwner = 'state' | 'cache' | 'iterator' | 'propField' | 'arg';
    export type ValueKeyField = {
        prefix: string;
        key: string;
        dataType: ModelUtil.DataType;
        array: number;
        structId?: string;
    }

    export type DispatchItem = {
        id: string;
        ret?: NodeFunction.ReturnProps;
        args: ModelUtil.NodeModelField[];
    }
    export type ActionItem = {
        id: string;
        target: string;
        args: string[];
    }
    export type StyleItem = {
        id: string;
        // src: string;
        defs: StylePropDefiner.Record[];
        args: NodeStlarg.Data[];
    }
    export type StlargItem = {
        id: string;
    }

    export const isObject = (field: {
        dataType: ModelUtil.DataType;
        array: number;
    }) => field.array >= 1 || field.dataType === 'struct';

    // const getKeyPrefix = (owner: KeyOwner) => {
    //     switch (owner) {
    //         case 'state': return PrefixUtil.STATE;
    //         case 'cache': return 'c';
    //         case 'arg': return 'a';
    //         case 'propField': return 'pf';
    //         case 'iterator': return 'i';
    //     }
    // };

    export const Monitor = () => {

        const { store, setStore, dispatcher } = useContext(GlobalContext);

        assert(store.project != undefined, 'store.project is null.');
        const scope = store.system.scope;

        const jsxes = useMemo(() => {
            const jsxList: JSX.Element[] = [];

            const addRecordJsx = (
                targetName: string,
                items: undefined | any[],
                callback: (item: any) => JSX.Element
            ) => {
                if (items == undefined) return;
                jsxList.push(
                    <_Label key={jsxList.length}>{targetName}</_Label>
                );
                if (items.length > 0) {
                    items.forEach(item => {
                        jsxList.push(
                            <_Record key={jsxList.length}>{callback(item)}</_Record>
                        );
                    });
                } else {
                    jsxList.push(
                        <_Record key={jsxList.length}>-</_Record>
                    );
                }
            }

            const getValueKeyJsx = (valueKey: ValueKeyField) => {
                const array = '[]'.repeat(valueKey.array);
                const [type, color] = valueKey.dataType !== 'struct' ? [valueKey.dataType, "#fddf35"] : [valueKey.structId, "#ff5e5e"];
                const typeJsx = <_Span color={color}>@{type}{array}</_Span>;
                // const prefixJsx = <_Span color="#ecffb6">{valueKey.prefix}</_Span>;
                return <>{`\${`}<_Span color="#6ffc38">{valueKey.key}</_Span>{`}`}: {typeJsx}</>;
            }
            const addValueKeyJsx = (targetName: string, valueKeys: undefined | ValueKeyField[]) => {
                addRecordJsx(targetName, valueKeys, getValueKeyJsx);
            }

            /** データ型のJSXを生成 */
            const getDtypeJsx = (item: ModelElementUtil.DtypeInfo) => {
                const idJsx = <_Span color="#fa7edf">{item.id}</_Span>;
                const listStr = item.fields.map(f => f.id).join(', ');
                const argsJsx = <_Span color="#bcf0b794">{`{${listStr}}`}</_Span>;
                return <>{idJsx} {argsJsx}</>;
            }
            addRecordJsx('Types', scope.dtypeItems, getDtypeJsx);

            addValueKeyJsx('Arguments', scope.argValueKeys);
            addValueKeyJsx('States', scope.stateValueKeys);
            addValueKeyJsx('Caches', scope.cacheValueKeys);
            addValueKeyJsx('Property Fields', scope.propFieldValueKeys);
            addValueKeyJsx('Iterates', scope.iterateValueKeys);

            /** アクションのJSXを生成 */
            const getActionJsx = (item: ActionItem) => {
                const idJsx = <_Span color="#6ffc38">{item.id}</_Span>;
                const eventId = <_Span color="#e2e189">{item.target}</_Span>;
                const argsJsx = <_Span color="#90e4f396">{item.args.join(', ')}</_Span>;
                return <>*{idJsx} [{eventId}] ({argsJsx})</>;
            }
            /** ディスパッチのJSXを生成 */
            const getDispatchJsx = (item: DispatchItem) => {
                const idJsx = <_Span color="#38fce2">{item.id}</_Span>;
                const argNames = item.args.map(a => ModelUtil.getFieldType(a));
                const argsJsx = <_Span color="#90e4f396">{argNames.join(', ')}</_Span>;
                const getRetJsx = () => {
                    const ret = item.ret;
                    if (ret == undefined) return <_Span color='#cccccc'>void</_Span>;
                    return <><_Span color='#e60000'>{ModelUtil.getField(ret)}-[<_Span color='#e09f9f'>{ret.fml}</_Span>]</_Span></>;
                }
                return <>*{idJsx}({argsJsx}){' => '}{getRetJsx()}</>;
            }
            /** スタイルのJSXを生成 */
            const getDesignUnitJsx = (item: StyleItem) => {
                const idJsx = <_Span color="#fc8638">{item.id}</_Span>;
                const argNames = item.args.map(a => a.id);
                const argsJsx = <_Span color="#ece79595">{argNames.join(', ')}</_Span>;
                return <>*{idJsx}{': '}({argsJsx})</>;
            }

            addRecordJsx('Actions', scope.actionItems, getActionJsx);
            addRecordJsx('Functions', scope.dispatchItems, getDispatchJsx);
            addRecordJsx('Property Callbacks', scope.prpclbkItems, getDispatchJsx);
            addRecordJsx('Closures', scope.closureItems, getDispatchJsx);
            addRecordJsx('Styles', scope.styleItems, getDesignUnitJsx);

            return jsxList;
        }, [scope]);

        return (<_Wrap>
            {jsxes}
        </_Wrap>);
    }

    /**
     * ノードに対応したスコーププロパティを取得する
     * @param node ノード
     * @returns スコーププロパティ
     */
    export const getScopeProps = (node: TreeUtil.ElementNode): StoreSystem.Scope => {
        const wrap = node.data as ModelUtil.WrapElement;
        const props = StoreSystem.getBlankScope();

        let dtypes: null | ModelElementUtil.DtypeInfo[] = null;
        let states: null | ModelUtil.NodeModelField[] = null;
        let caches: null | ModelUtil.NodeModelField[] = null;
        let funcargs: null | ModelUtil.NodeModelField[] = null;
        let prpflds: null | ModelUtil.NodeModelField[] = null;
        let prpclbks: null | NodePrpclbk.Data[] = null;
        let actions: null | ModelUtil.NodeAction[] = null;
        let dispatchers: null | NodeFunction.Data[] = null;
        let closures: null | NodeFunction.Data[] = null;
        let iterators: null | NodeIterate.Data[] = null;
        let styles: null | NodeStyle.Data[] = null;

        // /** 除外リスト */
        // const ignoreTypes: ModelUtil.NodeType[] = ['dtype'];

        const loadStyles = () => ModelElementUtil.getReferableStyles(node);
        const loadDtypes = () => ModelElementUtil.getReferableDtypes(node);

        // if (ModelElementUtil.isAppSubordinate(node) && !ignoreTypes.includes(wrap.type)) {

        // /** アプリのルートノード */
        // const appRootWrap = ModelElementUtil.getAppRootWrap(node);

        // const loadStates = () => ModelElementUtil.getStatesFromApp(appRootWrap);
        const loadStates = () => ModelElementUtil.getReferableStates(node);
        const loadCatches = () => ModelElementUtil.getReferableCaches(node);
        const loadPrpflds = () => ModelElementUtil.getPropFieldsFromCurrent(node);
        const loadPrpclbks = () => ModelElementUtil.getPrpclbksFromCurrent(node);
        const loadActions = () => ModelElementUtil.getActionsFromCurrent(node);
        const loadIterators = () => ModelElementUtil.getIteratorsFromCurrent(node);
        const loadDispatchers = () => ModelElementUtil.getRetentionDispatchersFromCurrent(node);
        const loadClosures = () => ModelElementUtil.getClosuresFromCurrent(node);
        const loadFuncargs = () => ModelElementUtil.getArgumentFromCurrent(node);

        const isDispatchSub = () => ModelElementUtil.isDispatchSub(node);

        let valueKeyAccepter = (field: ValueKeyField): boolean => true;

        switch (wrap.type) {
            case 'focus':
            case 'assign':
            case 'arradd':
            case 'arrdel':
            // case 'update': {
            //     dtypes = loadDtypes();
            //     states = loadStates();
            //     prpflds = loadPrpflds();
            //     if (isDispatchSub()) funcargs = loadFuncargs();
            //     caches = loadCatches();
            //     iterators = loadIterators();
            // } break;
            case 'execute': {
                dtypes = loadDtypes();
                states = loadStates();
                if (ModelElementUtil.isComponentSubordinate(node)) {
                    prpflds = loadPrpflds();
                }
                if (isDispatchSub()) funcargs = loadFuncargs();
                caches = loadCatches();
                iterators = loadIterators();
                dispatchers = loadDispatchers();
            } break;
            case 'log':
            case 'tagdiv':
            case 'tagspan': {
                dtypes = loadDtypes();
                states = loadStates();
                caches = loadCatches();
                prpflds = loadPrpflds();
                prpclbks = loadPrpclbks();
                actions = loadActions();
                iterators = loadIterators();
                styles = loadStyles();
                dispatchers = loadDispatchers();
            } break;
            case 'tagimg': {
                valueKeyAccepter = (field: ValueKeyField): boolean => {
                    return field.array === 0 && ['color', 'number'].includes(field.dataType);
                }
                dtypes = loadDtypes();
                states = loadStates();
                caches = loadCatches();
                prpflds = loadPrpflds();
                actions = loadActions();
                iterators = loadIterators();
                styles = loadStyles();
                dispatchers = loadDispatchers();
            } break;
            case 'taginput': {
                dtypes = loadDtypes();
                states = loadStates();
                caches = loadCatches();
                prpflds = loadPrpflds();
                actions = loadActions();
                iterators = loadIterators();
                styles = loadStyles();
                dispatchers = loadDispatchers();
            } break;
            // case 'span': {
            //     valueKeyAccepter = (field: ValueKeyField): boolean => {
            //         return field.array === 0 && ['color', 'number', 'string'].includes(field.dataType);
            //     }
            //     dtypes = loadDtypes();
            //     states = loadStates()
            //     caches = loadCatches()
            //     prpflds = loadPrpflds()
            //     iterators = loadIterators();
            //     styles = loadStyles();
            // } break;
            // case 'input': {
            //     // valueKeyAccepter = (field: ValueKeyField): boolean => {
            //     //     return field.array === 0 && ['color', 'number'].includes(field.dataType);
            //     // }
            //     dtypes = loadDtypes();
            //     states = loadStates();
            //     caches = loadCatches()
            //     prpflds = loadPrpflds()
            // } break;
            // case 'action': {
            //     states = loadStates();
            //     caches = loadCatches();
            //     propFields = loadPrpflds();
            //     dispatchers = loadDispatchers();
            //     iterators = loadIterators();
            // } break;
            // case 'execute': {
            //     states = loadStates();
            //     caches = loadCatches();
            //     prpflds = loadPrpflds();
            //     closures = loadClosures();
            //     iterators = loadIterators();
            // } break;
            case 'fetch': {
                dtypes = loadDtypes();
                states = loadStates();
                caches = loadCatches();
                prpflds = loadPrpflds();
                iterators = loadIterators();
            } break;
            // case 'button': {
            //     actions = loadActions();
            // } break;
            case 'compuse': {
                dtypes = loadDtypes();
                states = loadStates();
                caches = loadCatches();
                prpflds = loadPrpflds();
                prpclbks = loadPrpclbks();
                dispatchers = loadDispatchers();
                iterators = loadIterators();
            } break;
            case 'input': {
                dtypes = loadDtypes();
                caches = loadCatches();
                prpflds = loadPrpflds();
                dispatchers = loadDispatchers();
            } break;
            case 'iterate': {
                valueKeyAccepter = (field: ValueKeyField): boolean => {
                    return field.array === 0 && field.dataType === 'number';
                }
                dtypes = loadDtypes();
                states = loadStates()
                caches = loadCatches()
                prpflds = loadPrpflds()
            } break;
            case 'style': {
                dtypes = loadDtypes();
                valueKeyAccepter = (field: ValueKeyField): boolean => {
                    return field.array === 0 && ['color', 'number'].includes(field.dataType);
                }
                if (ModelElementUtil.isComponentSubordinate(node)) {
                    states = loadStates();
                    caches = loadCatches();
                    prpflds = loadPrpflds();
                    iterators = loadIterators();
                }
                styles = loadStyles();
            } break;
            case 'states':
            case 'retention': {
                dtypes = loadDtypes();
                states = loadStates();
                caches = loadCatches();
            } break;
            case 'cache':
            case 'member':
            case 'state': {
                dtypes = loadDtypes();
            } break;
        }

        const build = (
            valueKeys: ValueKeyField[],
            prefix: string,
            field: ModelUtil.NodeModelField,
            address: string[]
        ) => {
            assert(dtypes != null, 'dtypes is null.');
            let fieldId = field.id;
            // モデルフィールドの場合、親情報を付与する
            if (address.length > 0) fieldId = `${address.join('.')}.${fieldId}`;
            valueKeys.push({
                ...field,
                prefix,
                key: `${prefix}.${fieldId}`,
            });
            // 文字列の場合、長さも取得できるようにする
            if (NodeField.isPremitive(field) && field.dataType === 'string') {
                valueKeys.push({
                    ...field,
                    dataType: 'number',
                    prefix,
                    key: `${prefix}.${fieldId}.${PrefixUtil.STR_LEN}`,
                });
            }
            // 配列の場合、要素数を取得できるようにする
            if (field.array >= 1) {
                valueKeys.push({
                    prefix,
                    key: `${prefix}.${fieldId}.${PrefixUtil.LIST_LEN}`,
                    dataType: 'number',
                    array: 0
                });
            } else if (field.dataType === 'struct') {
                const model = dtypes.find(m => m.id === field.structId);
                assert(model != undefined, `dtypeリストに存在しない。search:[${field.structId}], id:${field.id}, list:[${dtypes.map(t => t.id).join(',')}]`);
                model.fields.forEach(f => {
                    build(valueKeys, prefix, f, address.concat(field.id));
                });
            }
        }

        const getValueKeys = (prefix: string, entities: ModelUtil.NodeModelField[] | null) => {
            if (entities != null) {
                const valueKeys: ValueKeyField[] = [];
                entities.forEach(entity => build(valueKeys, prefix, entity, []));
                return valueKeys;
            }
        }
        props.stateValueKeys = getValueKeys(PrefixUtil.STATE, states);
        props.cacheValueKeys = getValueKeys(PrefixUtil.CACHE, caches);
        props.argValueKeys = getValueKeys(PrefixUtil.ARGUMENT, funcargs);
        props.propFieldValueKeys = getValueKeys(PrefixUtil.PRPFLD, prpflds);

        if (iterators != null) {
            const valueKeys: ValueKeyField[] = [];
            iterators.forEach(it => {
                const addItProps = (suffix: string) => {
                    const owner: KeyOwner = 'iterator';
                    // const prefix = getKeyPrefix(owner);
                    valueKeys.push({
                        prefix: PrefixUtil.ITERATE,
                        key: `${PrefixUtil.ITERATE}.${it.id}.${suffix}`,
                        dataType: 'number',
                        array: 0
                    });
                }
                addItProps(PrefixUtil.LIST_CUR);
                addItProps(PrefixUtil.LIST_LEN);
                props.iterateValueKeys = valueKeys;
            });
        }

        const buildDispatchItems = (mastarItems: NodeFunction.Data[] | null) => {
            if (mastarItems != null) {
                const destItems: DispatchItem[] = [];
                mastarItems.forEach(d => {
                    const argsData = ModelElementUtil.getInnerWrapFixed({ type: 'func', data: d }, 'args').data as ModelUtil.ArgsData;
                    destItems.push({
                        id: `${PrefixUtil.DISPATCH}.${d.id}`,
                        ret: d.ret,
                        args: argsData.args.map(a => {
                            return a.data as ModelUtil.NodeModelField;
                        })
                    });
                });
                return destItems;
            }
        }

        // フィルターを適用
        if (props.stateValueKeys != undefined) props.stateValueKeys = props.stateValueKeys.filter(valueKeyAccepter);
        if (props.cacheValueKeys != undefined) props.cacheValueKeys = props.cacheValueKeys.filter(valueKeyAccepter);
        if (props.propFieldValueKeys != undefined) props.propFieldValueKeys = props.propFieldValueKeys.filter(valueKeyAccepter);
        if (props.argValueKeys != undefined) props.argValueKeys = props.argValueKeys.filter(valueKeyAccepter);

        props.dispatchItems = buildDispatchItems(dispatchers);
        props.closureItems = buildDispatchItems(closures);

        if (prpclbks != null) {
            props.prpclbkItems = prpclbks.map(c => ({
                id: `${PrefixUtil.PRPCLBK}.${c.id}`,
                args: c.args.map(a => a.data as ModelUtil.NodeModelField)
            }));
        }

        if (actions != null) {
            const items: ActionItem[] = [];
            actions.forEach(a => {
                items.push({
                    id: a.id,
                    target: a.eventId,
                    args: a.args
                });
            });
            props.actionItems = items;
        }

        // }

        if (dtypes != null) {
            props.dtypeItems = dtypes;
        }
        if (styles != null) {
            const items: StyleItem[] = [];
            // console.log(styles);
            styles.forEach(s => {
                const inhDefs = s.inherits.map(inh => {
                    const inhSt = items.find(it => it.id === inh.refId);
                    assert(inhSt != undefined, AssertUtil.errorFindFromArray('inhSt', inh.refId, items.map(i => i.id)));
                    return inhSt.defs;
                });
                // let src = '';
                // if (inhDefs.length > 0) {
                //     src += inhDefs.join() + '\n'
                // }
                // src += s.src;
                const defs = inhDefs.reduce((prev, cur) => prev.concat(cur), []);
                defs.push(...s.defs);

                const getArgDatas = (refer: StyleChooser.Refer) => {
                    const list: NodeStlarg.Data[] = [];
                    const inhSt = items.find(it => it.id === refer.refId);
                    assert(inhSt != undefined, AssertUtil.errorFindFromArray('inhSt', refer.refId, items.map(i => i.id)));

                    inhSt.args.forEach((arg) => {
                        // キーが存在しなければ未定義（abstract）なので追加
                        if (refer.args.find(a => a.key === arg.id) == undefined) {
                            list.push(arg);
                        }
                    });
                    return list;
                }
                items.push({
                    id: s.id,
                    defs,
                    args: s.inherits.reduce((prev, inh) => {
                        return prev.concat(getArgDatas(inh));
                    }, [] as NodeStlarg.Data[]).concat(
                        s.args.map(wrap => wrap.data as NodeStlarg.Data)
                    )
                });
            });
            props.styleItems = items;
        }
        return props;
    }
}

export default ScopeManager;

const _Wrap = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #57686b;
    overflow-y: auto;
`;

const _Label = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: calc(100% - 4px);
    height: 28px;
    /* background-color: #f800003d; */
    margin: 1px 0 0 2px;
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    padding: 0 0 0 2px;
    box-sizing: border-box;
    line-height: 32px;
`;

const _Record = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: calc(100% - 4px);
    height: 28px;
    background-color: #2936388b;
    margin: 1px 0 0 2px;
    font-size: 18px;
    color: #ffffff88;
    font-weight: 600;
    padding: 0 0 0 2px;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
`;

const _Span = styled.span<{
    color: string;
}>`
    color: ${props => props.color};
`;