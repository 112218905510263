import { useContext, useRef, useState, useMemo, useEffect } from "react";
import { GlobalContext } from "../../entry/systemEntry";
import Styles from "../../../../def/design/styles";
import assert from "assert";
import TreeUtil from "../../../../common/component/tree/treeUtil";
import StructTreeContext from "./structTreeContext";
import styled, { css } from "styled-components";
import ModelUtil from "./util/modelUtil";
import FormUtil from "../../../../common/component/form/formUtiil";
import StoreProject from "../../../redux/store/storeProject";
import { TabbedPane } from "../../../../common/component/tab/tabbedPane";
import ScopeManager from "./option/scopeManager";
import ClipboardMonitor from "./option/clipboardMonitor";
import KeyInputManager from "./option/keyInputManager";
import StructHideMenu from "./structHideMenu";
import { workerData } from "worker_threads";

namespace StructManageTab {

    /** 隠しメニューの状態 */
    export type HideMenu = 'off' | 'mark-node' | 'aaa';

    export type BookmarkAccess = {
        id: number;
        node: TreeUtil.ElementNode;
        name: string;
    }
    export type LocalState = {
        focusNode: TreeUtil.ElementNode | null;
        contextParam: StructTreeContext.Param | null;
        keyHolds: KeyInputManager.KeyHold[];
        // activeBookmark: number;
        bookmarks: BookmarkAccess[];
        referNode: null | TreeUtil.ElementNode;

        hideMenu: HideMenu;

        scrollY: number;
    }

    const getInitialKeySwitches = (): KeyInputManager.KeyHold[] => {
        return [
            { name: 'control', key: 'Control', on: false },
            { name: 'shift', key: 'Shift', on: false },
        ];
    }

    export const Component = (props: {
    }) => {

        const { store, setStore, dispatcher } = useContext(GlobalContext);

        assert(store.project != null, 'project is null.');

        const [ls] = useState<LocalState>({
            focusNode: null,
            contextParam: null,
            keyHolds: getInitialKeySwitches(),
            bookmarks: [],
            referNode: null,
            hideMenu: 'off',
            scrollY: 0,
        });
        const focusNode = ls.focusNode;

        const [dummy, setDummy] = useState<any>(null);
        const invalidate = () => setDummy({});

        const treeRef = useRef<HTMLDivElement>(null);

        const project = store.project;
        const rootData = project.rootData;

        // プロジェクトタブを選択した際に、ワークデータを生成する
        useEffect(() => {
            if (store.system.devTabIndex === 0) {// && focusNode != null) {
                assert(focusNode != null, 'focusNodeがnullであるケースは想定していない。');
                const openNodeList: string[] = [];
                let selectNode = '';
                const bookmarkList: StoreProject.BookmarkData[] = [];

                // ツリー要素より、作業のリカバリ情報を生成する
                TreeUtil.deligateFullRun(rootElementNode, (curNode: TreeUtil.ElementNode, curKey: string) => {
                    // 開いているノードを追加
                    if (curNode.isOpen) openNodeList.push(curKey);

                    if (ls.focusNode == curNode) selectNode = curKey;

                    // ブックマークのインデックスを作成
                    const bm = ls.bookmarks.find(b => b.id === curNode.id);
                    if (bm != undefined) {
                        bookmarkList.push({
                            key: curKey,
                            name: bm.name
                        });
                    }

                    if (ls.focusNode == curNode) selectNode = curKey;
                });
                // ブックマークを更新
                project.workData.bookmarks = bookmarkList;
                project.workData = {
                    ...project.workData,
                    treeMemo: {
                        openNodes: openNodeList,
                        selectNode,
                        scrollX: 0, scrollY: ls.scrollY
                    }
                };
                dispatcher.updateStore();
                // console.log(project.workData);
            }
        }, [store.system.devTabIndex]);

        const rootElementNode: TreeUtil.ElementNode = useMemo(() => {
            // console.log(JSON.stringify(rootData));
            const dataNode = {
                data: rootData,
                children: ModelUtil.getChildrenDataNode(rootData)
            }
            const rootElementNode = TreeUtil.buildElementNodeFromData(dataNode);

            // ワークデータからブックマークをロード
            const workData = project.workData;
            const memo = workData.treeMemo;
            TreeUtil.deligateFullRun(rootElementNode, (curNode: TreeUtil.ElementNode, curKey: string) => {

                // assert(memo != undefined, 'memoがundefinedであることはない。');
                if (memo.openNodes.includes(curKey)) curNode.isOpen = true;

                // console.log(`${memo.selectNode} === ${curKey} ${memo.selectNode === curKey}`);
                if (memo.selectNode === curKey) ls.focusNode = curNode;

                const bm = workData.bookmarks.find(b => {
                    // console.log(`${b.key} === ${curKey}`);
                    return b.key === curKey;
                });
                if (bm != undefined) {
                    // const id = curNode.id;
                    ls.bookmarks.push({
                        id: curNode.id,
                        node: curNode,
                        name: bm.name
                    });
                }
            });

            // // // スクロール状態を復元
            // treeRef.current?.scrollBy({ left: 0, top: workData.treeMemo.scrollY });

            // 再描画する
            invalidate();
            return rootElementNode;
        }, [rootData]);

        const getElementJson = () => {
            if (focusNode == null) return '';
            const el = focusNode.data as ModelUtil.WrapElement;
            return JSON.stringify(el, null, 2);
        }

        const selectNode = (node: TreeUtil.ElementNode) => {
            // setFocusNode(node);
            ls.focusNode = node;
            store.system.scope = ScopeManager.getScopeProps(node);
            dispatcher.updateStore();
            invalidate();
        }

        const buildListener = (e: React.KeyboardEvent<HTMLDivElement>, listener: (props: KeyInputManager.Props) => void) => {
            listener({
                e, store, dispatcher, localState: ls, invalidate, treeRef
            });
        }

        const contextCallback = (node: TreeUtil.ElementNode, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            selectNode(node);
            // ローカル変数で参照するための設定
            const focusNode = node;
            const rect = treeRef.current?.getBoundingClientRect();
            assert(rect != undefined, 'rect is undefined.');
            assert(focusNode != null, 'focusNode is null.');
            const [x, y] = [e.clientX - rect.left, e.clientY - rect.top];
            ls.contextParam = {
                rect,
                pos: {
                    x: x + Styles.DivMarginType.WIDE,
                    y: y + Styles.DivMarginType.WIDE
                }
            };

            const freeCache: ModelUtil.ManageItems = {
                focusNode,
                setFocusNode: (focusNode: TreeUtil.ElementNode | null) => {
                    ls.focusNode = focusNode;
                    invalidate();
                },
                // model: store.project as StoreProject.Props,
                invalidate
            };
            store.system.freeCache = freeCache;
            dispatcher.updateStore();
            invalidate();
        };

        const getLabelJsx = (node: TreeUtil.ElementNode) => {
            return ModelUtil.getNodeDisplayJsx(node, focusNode?.data == node.data);
        };

        return (
            <Styles._Div margin={Styles.DivMarginType.NARROW}
                color="#444444"
            >
                <_Blind isOpen={ls.hideMenu !== 'off'} onClick={() => {
                    ls.hideMenu = 'off';
                    invalidate();
                }} />
                <_OptionDiv>
                    <StructHideMenu.Component
                        rootNode={rootElementNode}
                        manageState={ls}
                        menu={ls.hideMenu}
                        set={(menu: HideMenu) => {
                            ls.hideMenu = menu;
                            invalidate();
                        }}
                    />
                </_OptionDiv>
                <_LeftDiv>
                    <_LeftHeader>
                        <FormUtil.ButtonItem button={{
                            label: 'Root',
                            width: 90,
                            isEnable: ls.referNode != undefined,
                            callback: () => {
                                ls.referNode = null;
                                invalidate();
                            }
                        }} />
                        <FormUtil.ButtonItem button={{
                            label: 'Upper',
                            width: 100,
                            isEnable: ls.referNode != undefined,
                            callback: () => {
                                if (ls.referNode != undefined) {
                                    ls.referNode = ls.referNode.parent;
                                    invalidate();
                                }
                            }
                        }} />
                    </_LeftHeader>
                    <_LeftBody>
                        <Styles._Div
                            tabIndex={99}
                            ref={treeRef}
                            onClick={() => {
                                ls.contextParam = null;
                                invalidate();
                                // setContextParam(null);
                            }}
                            onKeyDown={(e) => buildListener(e, KeyInputManager.keyDown)}
                            onKeyUp={(e) => buildListener(e, KeyInputManager.keyUp)}
                            margin={Styles.DivMarginType.NARROW} borderColor="#000000"
                        >
                            <TreeUtil.Frame
                                clickEvent={selectNode}
                                contextEvent={contextCallback}
                                getLabelJsx={getLabelJsx}
                                invalidate={dummy}
                                focusNode={focusNode}
                                isDisable={false}
                                initialize={(rootElement) => {
                                    if (ls.focusNode == null) {
                                        ls.focusNode = rootElement;
                                    }
                                    invalidate();
                                    // setFocusNode(rootElement);
                                }}
                                referElementNode={(() => {
                                    return ls.referNode ?? rootElementNode;
                                })()}
                            onScroll={(e) => {
                                ls.scrollY = e.currentTarget.scrollTop
                            }}
                            />
                        </Styles._Div>
                    </_LeftBody>
                    {/* コンテキストメニューの表示 */}
                    {ls.contextParam == null ? <></> : <StructTreeContext.Component
                        param={ls.contextParam}
                        close={() => {
                            ls.contextParam = null;
                            invalidate();
                            // setContextParam(null);
                        }}
                        ls={ls}
                    />}
                </_LeftDiv>
                <_RightDiv>
                    <_InfoTabDiv>
                        <TabbedPane
                            margin={4}
                            tabElements={[
                                { name: 'Scope', cont: <ScopeManager.Monitor />, enable: true },
                                { name: 'Json', cont: <_JsonViewer readOnly value={getElementJson()} />, enable: true },
                            ]}
                            activeNo={0}
                        />
                    </_InfoTabDiv>
                    <_CacheTabDiv>
                        <TabbedPane
                            margin={4}
                            tabElements={[
                                { name: 'Clipboard', cont: <ClipboardMonitor.Component />, enable: true },
                            ]}
                            activeNo={0}
                        />
                    </_CacheTabDiv>
                </_RightDiv>
            </Styles._Div>
        );
    }
}

export default StructManageTab;

const RIGHT_DIV_WIDTH = 430;
const OPTION_COL_WIDTH = 30;

const _Blind = styled.div<{
    isOpen: boolean;
}>`
    display: ${props => props.isOpen ? 'inline-block' : 'none'};
    position: absolute;
    z-index: 4;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #01004216;
    backdrop-filter: blur(6px);
`;

const _OptionDiv = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: ${OPTION_COL_WIDTH}px;
    height: 100%;
    /* background-color: #0c0c0c; */
    vertical-align: top;
`;
const _LeftDiv = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: calc(100% - ${RIGHT_DIV_WIDTH + OPTION_COL_WIDTH}px);
    height: 100%;
    /* background-color: #d8d8d8; */
`;

const LEFT_HEADER_HEIGHT = 40;
const _LeftHeader = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: ${LEFT_HEADER_HEIGHT}px;
    background-color: #1f1f1f;
`;
const _BMCombobox = styled.select<{
}>`
    display: inline-block;
    position: relative;
    margin: 5px 0 0 5px;
    width: 300px;
    height: 30px;
    font-size: 22px;
    color: #00005a;
    background-color: #8ea8a5;
`;

const _LeftBody = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(100% - ${LEFT_HEADER_HEIGHT}px);
    /* background-color: #4edd66; */
`;

const CLIPBOARD_WIDTH = 200;
const _RightDiv = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: ${RIGHT_DIV_WIDTH}px;
    height: 100%;
    /* background-color: #bccdce; */
    vertical-align: top;
`;
const _InfoTabDiv = styled.div<{
}>`
    height: calc(100% - ${CLIPBOARD_WIDTH}px);
    /* background-color: #78ce6d; */
`;
const _CacheTabDiv = styled.div<{
}>`
    height: ${CLIPBOARD_WIDTH}px;
    /* background-color: #1cdfd5; */
`;

const _TreeBody = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    background-color: #02ffd979;
    height: calc(100% - 40px);
    vertical-align: top;
`;

const _JsonViewer = styled.textarea<{
    // isEdit: boolean;
}>`
    display: inline-block;
    position: relative;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    margin: 4px 0 0 4px;
    /* width: 100%;
    height: 100%; */
    resize: none;
    font-size: 18px;
    /* font-weight: 600; */
    ${props => props.readOnly ? css`
        color: #0043a7;
        background-color: #f0eeee;
    ` : css`
        color: #000d20;
        background-color: #facb66;  
    `}
    box-sizing: border-box;
    /* border: solid 2px #865959; */
`;

