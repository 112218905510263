import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import assert from "assert";
import VariableChooser from "../variableChooser";
import NodeUpdate from "../../var/nodeUpdate";
import NodeFocus from "./nodeFocus";
import PrefixUtil from "../../../util/prefixUtil";
import NodeAssign from "./nodeAssign";

namespace NodeAsgnNam {

    export type Data = {
        target: VariableChooser.RootTargetType;
        rootId: string;
        prpName: string;
        instance: NodeAssign.CloneType;
    }

    type LocalState = {
        rootTarget: FormUtil.CheckableValue;
        rootId: FormUtil.CheckableValue;
        prpName: FormUtil.CheckableValue;
        instance: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            rootTarget: { value: '', errors: [] },
            rootId: { value: '', errors: [] },
            prpName: { value: '', errors: [] },
            instance: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [focusInfo, dtypes, states, funcargs, caches, propFields, nameVarList] = useMemo(() => {

            const cur = manageItems.focusNode;

            // const scope = store.system.scope;
            // const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];

            const caches = ModelElementUtil.getRetentionCachesFromCurrent(cur)
                .concat(ModelElementUtil.getFetchThensFromCurrent(cur));
            const states = ModelElementUtil.getReferableStates(cur);
            const dtypes = ModelElementUtil.getReferableDtypes(cur);

            const isDispatchSub = () => ModelElementUtil.isDispatchSub(cur);
            const funcargs = !isDispatchSub() ? undefined : ModelElementUtil.getArgumentFromCurrent(cur);
            const propFields = ModelElementUtil.getPropFieldsFromCurrent(cur);

            const focusInfo = NodeFocus.getFocusInfo(cur, {
                states, caches, prpflds: propFields, funcargs, dtypes
            });

            const nameVarList = states
                .filter(s => s.dataType === 'name').map(s => `${PrefixUtil.STATE}.${s.id}`).concat(
                    caches.filter(c => c.dataType === 'name').map(c => `${PrefixUtil.CACHE}.${c.id}`)
                ).concat(
                    propFields.filter(p => p.dataType === 'name').map(p => `${PrefixUtil.PRPFLD}.${p.id}`)
                )

            return [focusInfo, dtypes, states, funcargs, caches, propFields, nameVarList];
        }, []);

        const isObject = ModelUtil.isObjectField(focusInfo.lastField);


        const getRootTarget = () => {
            let target = localState.rootTarget.value;
            if (target === '') return null;
            return target as VariableChooser.RootTargetType;
        }

        const getRootModels = () => {
            const rootTarget = getRootTarget();
            if (rootTarget == null) return [];
            switch (rootTarget) {
                case 'state': return states ?? [];
                case 'cache': return caches ?? [];
                case 'comparg': return propFields ?? [];
                case 'funcarg': return funcargs ?? [];
            }
        }

        const rootModels = useMemo(() => {
            return getRootModels();
        }, [localState.rootTarget]);

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.rootTarget.value = data.target;
                localState.rootId.value = data.rootId;
                localState.prpName.value = data.prpName;
                localState.instance.value = data.instance;
            }
            invalidate();
        }, []);

        const targetFroms = [localState.rootTarget, localState.rootId, localState.prpName, localState.instance];

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined
            ) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const data: Data = {
                target: localState.rootTarget.value as VariableChooser.RootTargetType,
                rootId: localState.rootId.value,
                prpName: localState.prpName.value,
                instance: localState.instance.value as NodeAssign.CloneType
            }
            setTempData(data);
        }, [...targetFroms]);

        const isRootEnable = localState.rootTarget.errors.length === 0;

        return (<>
            <NodeFocus.Refer
                focusInfo={focusInfo}
            />
            <FormUtil.BorderPanel
                title="Assign"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Target"
                        jsx={<FormUtil.Combobox
                            width={300}
                            isReadOnly={states == undefined || caches == undefined}
                            checkable={localState.rootTarget}
                            setCheckable={(checkable) => {
                                localState.rootTarget = checkable;
                                invalidate();
                            }}
                            extend={() => {
                                localState.rootId.value = '';
                            }}
                            headBlank
                            list={VariableChooser.RootTargetTypes
                                .filter(t => (
                                    t === 'state' && states != undefined ||
                                    t === 'cache' && caches != undefined ||
                                    t === 'comparg' && propFields != undefined ||
                                    t === 'funcarg' && funcargs != undefined
                                ))
                                .map(t => {
                                    return { value: t, labelText: t };
                                })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Root"
                        isEnabled={isRootEnable}
                        jsx={<FormUtil.Combobox
                            width={300}
                            checkable={localState.rootId}
                            setCheckable={(checkable) => {
                                localState.rootId = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={rootModels.map(model => ModelUtil.getFieldListItem(model))}
                            validates={!isRootEnable ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Property"
                        isEnabled={isRootEnable}
                        jsx={<FormUtil.Combobox
                            width={300}
                            checkable={localState.prpName}
                            setCheckable={(checkable) => {
                                localState.prpName = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={nameVarList.map(v => ({ labelText: v, value: v }))}
                            validates={!isRootEnable ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Instance"
                        jsx={<FormUtil.Combobox
                            width={210}
                            checkable={localState.instance}
                            setCheckable={(checkable) => {
                                localState.instance = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={NodeAssign.CloneTypes.map(item => {
                                return { value: item, labelText: item }
                            })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'asgnnam';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeAsgnNam;
