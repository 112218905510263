import assert from "assert";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";

namespace TreeUtil {

    export type ElementNode = {
        // jsx: JSX.Element;
        id: number;
        isOpen: boolean;
        // index: number;
        depth: number;
        data: any;
        children: ElementNode[];
        parent: null | ElementNode;
    }

    export type Design = {
        backgroundColor: string;
        // relationColor: string;
        trajectColor: string;
        brotherColor: string;
        focusColor: string;
        disableColor: string;
    }

    export type DataNode = {
        data: any;
        children: DataNode[];
    }

    /** ツリーの開閉や選択状態を保持するプロパティ群 */
    export type Memo = {
        openNodes: string[];
        selectNode: string;
        scrollX: number;
        scrollY: number;
    }

    // /** リカバリーするためのメモデータを生成する */
    // export const getRecoveryMemo = (props: {
    //     rootElementNode: ElementNode;
    //     focusNode: ElementNode;
    //     scrollX: number;
    //     scrollY: number;
    // }): Memo => {
    //     const openNodes: string[] = [];
    //     let selectNode = '';
    //     const bookmarkList: BookmarkData[] = [];

    //     const runRec = (curNode: ElementNode, curKey: string) => {

    //         if (curNode.isOpen) openNodes.push(curKey);

    //         // ブックマーク登録されている場合Keyデータを追加する
    //         const bc = props.bookmarkCaches.find(b => b.id === curNode.id);
    //         if (bc != undefined) {
    //             bookmarkList.push({
    //                 key: curKey,
    //                 name: bc.name
    //             });
    //         }

    //         if (props.focusNode == curNode) selectNode = curKey;
    //         curNode.children.forEach((c, i) => runRec(c, `${curKey}.${i}`));
    //     }
    //     runRec(props.rootElementNode, 'r');

    //     return {
    //         openNodes,
    //         selectNode,
    //         scrollX: props.scrollX,
    //         scrollY: props.scrollY,
    //         bookmarks: bookmarkList
    //     };
    // }

    /** ツリーの全階層を走査して、階層ごとに任意の処理を委譲する */
    export const deligateFullRun = (
        rootElementNode: ElementNode,
        callback: (node: ElementNode, curKey: string) => void
    ) => {
        const runRec = (curNode: ElementNode, address: number[]) => {

            callback(curNode, address.join('.'));
            curNode.children.forEach((c, i) => runRec(c, address.concat([i])));
        }
        runRec(rootElementNode, []);
    }

    export const createNode = (data: any, parent: ElementNode): ElementNode => {
        return {
            id: getNextId(parent),
            data, parent, depth: parent.depth + 1,
            isOpen: false,
            children: [],
        };
    }

    export const getCurKey = (start: ElementNode): string => {
        let cur: ElementNode | null = start;
        const indexList: number[] = [];
        while (cur.parent != null) {

            let index = 0;
            if (cur.parent != null) {
                index = cur.parent.children.findIndex(c => c == cur);
            }
            indexList.push(index);

            cur = cur.parent;
        }
        return indexList.reverse().join('.');
    }

    export const getNextId = (cur: ElementNode): number => {
        let root = cur;
        while (root.parent != null) {
            root = root.parent;
        }
        let max = -1;
        const runRec = (it: ElementNode) => {
            if (it.id > max) max = it.id;
            it.children.forEach(c => runRec(c));
        }
        runRec(root);
        return max + 1;
    }

    export const getChildIndex = (node: TreeUtil.ElementNode) => {
        const parentNode = node.parent;
        if (parentNode != null) {
            for (let i = 0; i < parentNode.children.length; i++) {
                if (node == parentNode.children[i]) return i;
            }
        }
        return -1;
    }

    /**
     * データを元に再帰的に以下のツリーを構成する。
     * @returns ツリー
     */
    export const buildElementNodeFromData = (rootDataNode: DataNode) => {
        let seqId = 0;
        const build = (depth: number, dataNode: DataNode, parent: null | ElementNode): ElementNode => {
            const node: ElementNode = {
                id: seqId,
                data: dataNode.data,
                depth: depth,
                // index: count,
                isOpen: false,
                children: [],
                parent
            };
            // シーケンスをインクリメント
            seqId++;

            node.children = (() => {
                return dataNode.children.map(child => {
                    return build(depth + 1, child, node);
                });
            })();
            return node;
        }
        return build(0, rootDataNode, null);
    }

    export const Frame = (props: {
        rootDataNode?: DataNode;
        clickEvent: (node: ElementNode) => void;
        contextEvent?: (node: ElementNode, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
        getLabelJsx: (node: ElementNode) => JSX.Element;
        invalidate: any;
        isDisable?: boolean;
        focusNode?: ElementNode | null;
        initialize?: (rootElementNode: ElementNode) => void;
        design?: Design;
        setElementNode?: (rootElementNode: ElementNode) => void;
        referElementNode: ElementNode;
        onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
    }) => {
        const isDisable = props.isDisable ?? false;
        const focusNode = props.focusNode ?? null;

        const design: Design = props.design ?? {
            backgroundColor: '#56645e',
            // relationColor: '#ada797',
            trajectColor: '#ada79783',
            brotherColor: '#d4b67492',
            focusColor: '#df9e53',
            disableColor: '#6d3939'
        }

        const ref = useRef({} as HTMLDivElement);

        const [rootElementNode, setRootElementNode] = useState<ElementNode>();
        // const [dummy, setDummy] = useState<any>(null);
        // const invalidate = () => setDummy({});

        useEffect(() => {
            let rootElementNode: ElementNode | null = null;
            if (props.rootDataNode != undefined) {
                rootElementNode = buildElementNodeFromData(props.rootDataNode);
            } else if (props.referElementNode != undefined) {
                rootElementNode = props.referElementNode;
            }
            assert(rootElementNode != null, 'rootElementNodeがnullであってはいけない。');
            setRootElementNode(rootElementNode);
            if (props.initialize) {
                props.initialize(rootElementNode);
            }
            if (props.setElementNode != undefined) props.setElementNode(rootElementNode);

            // ref.current.scrollTop = 1111;
        }, [props.rootDataNode, props.referElementNode]);

        useEffect(() => {
            if (focusNode != null && focusNode.parent != null) {
                focusNode.parent.isOpen = true;
            }
        }, [focusNode]);

        const jsxList = useMemo(() => {
            if (rootElementNode == undefined) return <></>;
            const list: JSX.Element[] = [];
            const buildJsxRecords = (element: ElementNode, spaceList: boolean[]) => {

                const getSpaces = () => {
                    return spaceList.map((space, i) => {
                        const lines: JSX.Element[] = [];
                        if (spaceList.length - 1 === i || space) lines.push(space ? <_LineFull key={lines.length} /> : <_LineTop key={lines.length} />);
                        if (spaceList.length - 1 === i) lines.push(<_LineRight key={lines.length} />);
                        return <_Space key={i}>{lines}</_Space>;
                    });
                }

                // const isRelationNode = () => {
                //     let result = false;
                //     let tempNode = focusNode;
                //     // 親判定
                //     while (tempNode != null) {
                //         if (element.parent == null || element == tempNode) {
                //             result = true;
                //             break;
                //         }
                //         tempNode = tempNode.parent;
                //     }
                //     // 兄弟判定
                //     if (result === false && focusNode != null && focusNode.parent != null) {
                //         result = focusNode.parent.children.find(node => node == element) != undefined;
                //     }
                //     return result;
                // }
                const isTrajectNode = () => {
                    let result = false;
                    let tempNode = focusNode;
                    // 親判定
                    while (tempNode != null) {
                        if (element.parent == null || element == tempNode) {
                            result = true;
                            break;
                        }
                        tempNode = tempNode.parent;
                    }
                    return result;
                }
                const isBrotherNode = () => {
                    let result = false;
                    // 兄弟判定
                    if (result === false && focusNode != null && focusNode.parent != null) {
                        result = focusNode.parent.children.find(node => node == element) != undefined;
                    }
                    return result;
                }
                const isCurrent = element.data == focusNode?.data;
                list.push(
                    <_Record
                        key={list.length}
                        width={ref.current.scrollWidth}
                        isActive={isCurrent}
                        // isRelation={!isDisable && isRelationNode()
                        isTraject={!isDisable && isTrajectNode()}
                        isBrother={!isDisable && isBrotherNode()}
                        isDisable={isCurrent && isDisable}
                        focusColor={design.focusColor}
                        // relationColor={design.relationColor}
                        trajectColor={design.trajectColor}
                        brotherColor={design.brotherColor}
                        disableColor={design.disableColor}
                    >
                        {getSpaces()}
                        {element.children.length === 0 ? <></> : <_Branch
                            onClick={() => {
                                element.isOpen = !element.isOpen;
                                setRootElementNode({ ...rootElementNode });
                                // alert(element.isOpen);
                            }}
                        >{element.isOpen ? '-' : '+'}</_Branch>}
                        <_Jsx
                            onClick={() => {
                                props.clickEvent(element);
                                setRootElementNode({ ...rootElementNode });
                            }}
                            onContextMenu={(e) => {
                                if (!props.contextEvent) return;
                                props.contextEvent(element, e);
                                setRootElementNode({ ...rootElementNode });
                            }}
                            onDoubleClick={() => {
                                element.isOpen = !element.isOpen;
                                setRootElementNode({ ...rootElementNode });
                            }}
                        >
                            {props.getLabelJsx(element)}
                        </_Jsx>
                    </_Record>
                );
                if (element.isOpen) {
                    element.children.forEach((child, i) => {
                        buildJsxRecords(child, spaceList.slice().concat(element.children.length - 1 !== i));
                    });
                }
            }
            buildJsxRecords(rootElementNode, []);
            // rootJsxNode.forEach((el) => {
            //     addElement(el, []);
            // });
            return list;
        }, [rootElementNode, props.invalidate, isDisable]);

        return (
            <_Wrap
                ref={ref}
                isDisable={isDisable}
                backgroundColor={design.backgroundColor}
                onScroll={(e)=> {
                    // console.log(ref.current.scrollTop);
                    if(props.onScroll != undefined) {
                        props.onScroll(e);
                    }
                }}
            >
                {jsxList}
            </_Wrap>
        );
    }
}

export default TreeUtil;

const _Wrap = styled.div<{
    isDisable: boolean;
    backgroundColor: string;
}>`
    display: inline-block;
    position: relative;
    background-color: ${props => props.backgroundColor};
    width: 100%;
    height: 100%;
    /* box-sizing: border-box;
    border: solid 1px #000; */
    overflow: auto;
    ${props => !props.isDisable ? '' : css`
        pointer-events: none;
        background-color: #1f3642;
    `}
`;

const _Record = styled.div<{
    width: number;
    isActive: boolean;
    // isRelation: boolean;
    isTraject: boolean;
    isBrother: boolean;
    isDisable: boolean;
    trajectColor: string;
    brotherColor: string;
    focusColor: string;
    disableColor: string;
}>`
    display: inline-block;
    & *{
        vertical-align: top;
        opacity: 0.7;
    }
    /* position: relative; */
    ${props => !props.isDisable ? '' : css<{ disableColor: string }>`
        background-color: ${props => props.disableColor};
    `}
    ${props => !props.isTraject ? '' : css<{ trajectColor: string }>`
        background-color: ${props => props.trajectColor};
        /* opacity: 1; */
    `}
    ${props => !props.isBrother ? '' : css<{ brotherColor: string }>`
        background-color: ${props => props.brotherColor};
        /* opacity: 1; */
    `}
    ${props => !props.isActive ? '' : css<{ focusColor: string }>`
        background-color: ${props => props.focusColor};
        & *{
            opacity: 1;
        }
    `}
    width: ${props => props.width}px;
    height: 40px;
    box-sizing: border-box;
    white-space: nowrap;
    /* border: solid 1px #000;
    margin: 5px; */
    &:hover {
        & *{
            opacity: 1;
        }
    }
`;

const _Space = styled.div`
    display: inline-block;
    position: relative;
    /* background-color: #b6d5be; */
    width: 40px;
    height: 100%;
    box-sizing: border-box;
    /* border: solid 1px #000; */
`;
const _LineTop = styled.div`
    display: inline-block;
    position: relative;
    background-color: #b60000;
    width: 4px;
    height: 22px;
    box-sizing: border-box;
    margin: 0 0 0 20px;
`;
const _LineFull = styled.div`
    display: inline-block;
    position: relative;
    background-color: #b60000;
    width: 4px;
    height: 100%;
    box-sizing: border-box;
    margin: 0 0 0 20px;
`;
const _LineRight = styled.div`
    display: inline-block;
    position: relative;
    background-color: #b60000;
    width: 16px;
    height: 4px;
    box-sizing: border-box;
    margin: 18px 0 0 0;
`;

const _Branch = styled.div`
    display: inline-block;
    /* position: relative; */
    background-color: #d1d1d1;
    width: 30px;
    height: calc(100% - 10px);
    border: solid 1px #000;
    margin: 5px 0 0 3px;
    box-sizing: border-box;
    font-size: 30px;
    /* font-weight: 600; */
    color: #6d1717;
    line-height: 20px;
    padding-left: 3px;
`;
const _Jsx = styled.div`
    display: inline-block;
    position: relative;
    /* background-color: #ffffff3c; */
    min-width: 100px;
    height: 100%;
    margin: 0 0  0 3px;
    box-sizing: border-box;
`;
