import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import assert from "assert";
import VariableChooser from "../variableChooser";
import NodeUpdate from "../../var/nodeUpdate";
import NodeFocus from "./nodeFocus";
import DirectForm from "./directForm";

namespace NodeAssign {

    export const CloneTypes = ['shallow', 'deep'] as const;
    // export type CloneTypes = 'cache' | 'direct';
    export type CloneType = typeof CloneTypes[number];

    export interface ObjectValue extends VariableChooser.Chooser {
        cloneType?: CloneType;
    }

    export interface Data {
        assignType: NodeFocus.AssignType;
        objVal?: ObjectValue;
        fmlVal?: string;
    }

    type LocalState = {
        assignType: FormUtil.CheckableValue;
        directVal: FormUtil.CheckableValue;
        rootTarget: FormUtil.CheckableValue;
        rootId: FormUtil.CheckableValue;
        innerFieldForms: VariableChooser.InnerField[];
        cloneType: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            assignType: { value: 'pick-out', errors: [] },
            directVal: { value: '', errors: [] },
            rootTarget: { value: '', errors: [] },
            rootId: { value: '', errors: [] },
            innerFieldForms: [],
            cloneType: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [focusInfo, dtypes, states, funcargs, caches, propFields] = useMemo(() => {

            const cur = manageItems.focusNode;

            // const scope = store.system.scope;
            // const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];

            const caches = ModelElementUtil.getRetentionCachesFromCurrent(cur)
                .concat(ModelElementUtil.getFetchThensFromCurrent(cur));
                // console.log(caches);
            const states = ModelElementUtil.getReferableStates(cur);
            const dtypes = ModelElementUtil.getReferableDtypes(cur);

            const isDispatchSub = () => ModelElementUtil.isDispatchSub(cur);
            const funcargs = !isDispatchSub() ? undefined : ModelElementUtil.getArgumentFromCurrent(cur);
            const propFields = ModelElementUtil.getPropFieldsFromCurrent(cur);

            const focusInfo = NodeFocus.getFocusInfo(cur, {
                states, caches, prpflds: propFields, funcargs, dtypes
            });

            return [focusInfo, dtypes, states, funcargs, caches, propFields];
        }, []);

        const isObject = ModelUtil.isObjectField(focusInfo.lastField);
        const nowAssignType = localState.assignType.value as NodeFocus.AssignType;
        const isUseObjType = nowAssignType === 'pick-out';

        const arg: VariableChooser.Argument = {
            localState, states, caches, models: dtypes, propFields, funcargs
        }

        useEffect(() => {

            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.assignType.value = data.assignType;
                // if (data.objVal != undefined) {
                //     VariableChooser.mappingDataToForm(arg, data.objVal);
                //     localState.cloneType.value = data.objVal.cloneType ?? '';
                //     // console.log(localState.cloneType.value);
                // } else if (data.fmlVal != undefined) {
                //     localState.directVal.value = data.fmlVal;
                // }
                switch (data.assignType) {
                    case 'pick-out': {
                        if (data.objVal != undefined) {
                            VariableChooser.mappingDataToForm(arg, data.objVal);
                            localState.cloneType.value = data.objVal.cloneType ?? '';
                            // console.log(localState.cloneType.value);
                        }
                    } break;
                    case 'formula': {
                        if (data.fmlVal != undefined) {
                            localState.directVal.value = data.fmlVal;
                        }
                    } break;
                    case 'initial': {
                    } break;
                }
            }
            invalidate();
        }, []);

        const [last, requestType, directName] = useMemo(() => {
            const last = VariableChooser.getLastFieldInfo(arg);

            const getRequestType = (): null | ModelUtil.Field => {
                if (last == null) return null;
                let array = last.array;
                if (last.isIndex) array--;
                return {
                    dataType: last.dataType,
                    array,
                    structId: last.structId
                };
            }

            const requestType = getRequestType();

            let directName = '-';
            if (requestType != null) {
                switch (requestType.dataType) {
                    case 'string': directName = 'Text'; break;
                    case 'number': directName = 'Number'; break;
                    case 'boolean': directName = 'Boolean'; break;
                    case 'multiline': directName = 'Multiline'; break;
                }
            }
            return [last, requestType, directName];
        }, [localState.rootId, localState.innerFieldForms, localState.directVal]);
        const isObjectAssign = requestType != null && ModelUtil.isObjectField(requestType);

        const [compareMsg, isMatch] = useMemo(() => {
            const focusDataType = ModelUtil.getField(focusInfo.lastField);
            const assignDataType = requestType == null ? '...?' : ModelUtil.getField(requestType);
            const isMatch = focusDataType === assignDataType;
            return [`${focusDataType} ← ${assignDataType}`, isMatch];
        }, [requestType]);

        // useEffect(() => {
        //     (localState.cloneType.value as NodeUpdate.CloneType) = 'shallow';
        // }, [isObjectAssign]);


        const directForms = [localState.directVal];
        const pickoutForms = useMemo(() => {
            return [localState.rootId, localState.cloneType].concat(localState.innerFieldForms.map(inner => inner.form));
        }, [localState.innerFieldForms]);
        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            // if (targetFroms.find(form => form.errors.length > 0) != undefined
            // ) {
            //     setInputOK(false);
            //     return;
            // }
            // 1つでも入力エラーがあると処理しない
            switch (nowAssignType) {
                case 'pick-out': {
                    // console.log('pickout');
                    if (pickoutForms.find(form => form.errors.length > 0) != undefined
                        || !isMatch
                    ) {
                        setInputOK(false);
                        return;
                    }
                } break;
                case 'formula': {
                    if (directForms.find(form => form.errors.length > 0) != undefined) {
                        setInputOK(false);
                        return;
                    }
                } break;
                case 'initial': {
                } break;
            }
            setInputOK(true);

            const levelProps: string[] = localState.innerFieldForms.map(inner => {
                return inner.form.value;
            });

            let objVal: undefined | ObjectValue = undefined;
            let directVal: undefined | string = undefined;
            // if (isObject) {
            //     objVal = {
            //         target: localState.rootTarget.value as VariableChooser.RootTargetType,
            //         rootId: localState.rootId.value,
            //         levelProps,
            //         cloneType: DataUtil.blankToUndefined(localState.cloneType.value) as NodeUpdate.CloneType | undefined
            //     }
            // } else {
            //     // console.log('set: ' + localState.directVal.value);
            //     directVal = localState.directVal.value;
            // }

            switch (nowAssignType) {
                case 'pick-out': {
                    objVal = {
                        target: localState.rootTarget.value as VariableChooser.RootTargetType,
                        rootId: localState.rootId.value,
                        levelProps,
                        cloneType: DataUtil.blankToUndefined(localState.cloneType.value) as NodeUpdate.CloneType | undefined
                    }
                } break;
                case 'formula': {
                    // console.log('set: ' + localState.directVal.value);
                    directVal = localState.directVal.value;
                } break;
                case 'initial': {
                } break;
            }
            const data: Data = {
                assignType: localState.assignType.value as NodeFocus.AssignType,
                objVal,
                fmlVal: directVal
            }
            setTempData(data);
        }, [...directForms, localState.directVal, pickoutForms, isMatch]);

        return (<>
            <NodeFocus.Refer
                focusInfo={focusInfo}
            />
            <FormUtil.BorderPanel
                title="method"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Assign Type"
                        jsx={<FormUtil.Combobox
                            width={210}
                            checkable={localState.assignType}
                            setCheckable={(checkable) => {
                                localState.assignType = checkable;
                                invalidate();
                            }}
                            extend={() => {
                                // console.log('change');
                                const type = focusInfo.lastField.dataType;
                                if (localState.assignType.value as NodeFocus.AssignType === 'pick-out') {
                                    localState.directVal.value = '';
                                    (localState.cloneType.value as NodeUpdate.CloneType) = 'shallow';
                                } else {
                                    localState.rootTarget.value = '';
                                    localState.rootId.value = '';
                                    localState.innerFieldForms = [];
                                    if (type === 'boolean') localState.directVal.value = String(false);
                                }
                            }}
                            list={NodeFocus.AssignTypes
                                .filter(t => !(isObject && t === 'formula'))
                                .map(item => {
                                    return { value: item, labelText: item }
                                })}
                        />}
                    />
                </>}
            />
            {(() => {
                switch (nowAssignType) {
                    case 'formula': return (
                        <FormUtil.BorderPanel
                            title="formula"
                            innerJsx={<DirectForm.Component
                                focusInfo={focusInfo}
                                localState={localState}
                                invalidate={invalidate}
                                isObject={isObject}
                                dtypes={dtypes}
                            />}
                        />
                    );
                    case 'pick-out': return (
                        <VariableChooser.Component
                            arg={arg}
                            invalidate={invalidate}
                        />
                    );
                    case 'initial': return (
                        <></>
                    );
                }
            })()}
            {/* {isUseObjType ? (
                <VariableChooser.Component
                    arg={arg}
                    invalidate={invalidate}
                />
            ) : (
                <FormUtil.BorderPanel
                    title="formula"
                    innerJsx={<DirectForm.Component
                        focusInfo={focusInfo}
                        localState={localState}
                        invalidate={invalidate}
                        isObject={isObject}
                        dtypes={dtypes}
                    />}
                />
            )} */}
            <FormUtil.BorderPanel
                title="object matching"
                isVisible={isUseObjType}
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Compare"
                        jsx={<FormUtil.FixedText
                            value={compareMsg}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Status"
                        jsx={<FormUtil.FixedText
                            value={isMatch ? 'OK' : 'NG'}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="extend"
                isVisible={isUseObjType}
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Instance"
                        jsx={<FormUtil.Combobox
                            width={210}
                            checkable={localState.cloneType}
                            setCheckable={(checkable) => {
                                localState.cloneType = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={NodeUpdate.CloneTypes.map(item => {
                                return { value: item, labelText: item }
                            })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'assign';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeAssign;
