import assert from "assert";

namespace DrawUtil {

    export type Rgba = {
        r: number;
        g: number;
        b: number;
        a: number;
    }

    export type ColorStop = {
        offset: number;
        color: string;
    }

    export const convertRgbaFromColorCode = (code: string): Rgba | null => {
        const div = code.split('#');
        if (div.length !== 2 || div[1].length < 6) return null;

        const value = div[1];
        const r = Number.parseInt(value.substring(0, 2), 16);
        const g = Number.parseInt(value.substring(2, 4), 16);
        const b = Number.parseInt(value.substring(4, 6), 16);
        let a = 255;
        if (value.length === 8) {
            a = Number.parseInt(value.substring(6, 8), 16);
        }
        return { r, g, b, a };
    }

    export const convertColorCodeFromRgba = (rgba: Rgba): string => {
        const r1 = (Math.floor(rgba.r));
        const g1 = (Math.floor(rgba.g));
        const b1 = (Math.floor(rgba.b));
        const a1 = (Math.floor(rgba.a));
        const r = ('00' + r1.toString(16)).slice(-2);
        const g = ('00' + g1.toString(16)).slice(-2);
        const b = ('00' + b1.toString(16)).slice(-2);
        const a = ('00' + a1.toString(16)).slice(-2);
        return `#${r}${g}${b}${a}`;
    }

    export const toWhite = (color: string, subset: number): string => {
        const rgba = convertRgbaFromColorCode(color);
        // if(rgba == null) return color;
        assert(rgba != null, 'rgba is null.');

        rgba.r += subset;
        rgba.g += subset;
        rgba.b += subset;
        if(rgba.r > 255) rgba.r = 255;
        if(rgba.g > 255) rgba.g = 255;
        if(rgba.b > 255) rgba.b = 255;
        if(rgba.r < 0) rgba.r = 0;
        if(rgba.g < 0) rgba.g = 0;
        if(rgba.b < 0) rgba.b = 0;
        // console.log(convertColorCodeFromRgba(rgba));
        return convertColorCodeFromRgba(rgba);
    }
}

export default DrawUtil;