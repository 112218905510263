import { useEffect, useState, useMemo, useContext } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../util/modelUtil";
import ModelEditDialog from "../../modelEditDialog";
import { GlobalContext } from "../../../../entry/systemEntry";
import assert from "assert";
import ModelElementUtil from "../../util/modelElementUtil";
import styled from "styled-components";
import { TabbedPane } from "../../../../../../common/component/tab/tabbedPane";
import DataUtil from "../../../../../../common/dataUtil";
import ScopeManager from "../../option/scopeManager";

namespace nodeInput {

    type LocalState = {
        isMapper: boolean;
        setterId: FormUtil.CheckableValue;
        mapperId: FormUtil.CheckableValue;
        isVar: boolean;
        field: FormUtil.CheckableValue;

        bgColor: FormUtil.CheckableValue;
        widthRate: FormUtil.CheckableValue;
        widthPixel: FormUtil.CheckableValue;
        heightPixel: FormUtil.CheckableValue;
        marginTop: FormUtil.CheckableValue;
        marginRight: FormUtil.CheckableValue;
        marginBottom: FormUtil.CheckableValue;
        marginLeft: FormUtil.CheckableValue;

        borderSize: FormUtil.CheckableValue;
        borderColor: FormUtil.CheckableValue;
        roundSize: FormUtil.CheckableValue;

        paddingTop: FormUtil.CheckableValue;
        paddingRight: FormUtil.CheckableValue;
        paddingBottom: FormUtil.CheckableValue;
        paddingLeft: FormUtil.CheckableValue;
        lineHeight: FormUtil.CheckableValue;
        fontColor: FormUtil.CheckableValue;
        fontSize: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [setters, models, valueKeys] = useMemo(() => {
            const funcRootwrap = ModelElementUtil.getAppRootWrap(manageItems.focusNode);

            // const mappers = ModelElementUtil.getMappersFromApp(funcRootwrap);
            // const states = ModelElementUtil.getStatesFromApp(funcRootwrap);
            // const mappers = ModelElementUtil.getCachesFromCurrent(manageItems.focusNode);
            const setters = ModelElementUtil.getClosuresFromCurrent(manageItems.focusNode);
            const models = ModelElementUtil.getReferableDtypes(manageItems.focusNode);

            // const ownerNode = ModelElementUtil.getOwnerComponentFromCurrent(manageItems.focusNode);
            // const caches = ModelElementUtil.getCachesFromCurrent(manageItems.focusNode);
            // const prpflds = ModelElementUtil.getPrpfldsFromCompdef({ type: 'compdef', data: ownerNode });

            const scope = store.system.scope;
            const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];
            const cacheValueKeys = scope.cacheValueKeys as ScopeManager.ValueKeyField[];
            const propFieldValueKeys = scope.propFieldValueKeys as ScopeManager.ValueKeyField[];
            const valueKeys = stateValueKeys
                .concat(cacheValueKeys)
                .concat(propFieldValueKeys);
            return [setters, models, valueKeys];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            isMapper: false,
            setterId: { value: '', errors: [] },
            mapperId: { value: '', errors: [] },
            isVar: false,
            field: { value: '', errors: [] },

            bgColor: { value: '#ffffff', errors: [] },
            widthRate: { value: '', errors: [] },
            widthPixel: { value: '200', errors: [] },
            heightPixel: { value: '30', errors: [] },
            marginTop: { value: '0', errors: [] },
            marginRight: { value: '0', errors: [] },
            marginBottom: { value: '0', errors: [] },
            marginLeft: { value: '0', errors: [] },

            borderSize: { value: '1', errors: [] },
            borderColor: { value: '#000000', errors: [] },
            roundSize: { value: '0', errors: [] },

            paddingTop: { value: '0', errors: [] },
            paddingRight: { value: '0', errors: [] },
            paddingBottom: { value: '0', errors: [] },
            paddingLeft: { value: '4', errors: [] },
            lineHeight: { value: '22', errors: [] },
            fontColor: { value: '#000000', errors: [] },
            fontSize: { value: '22', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.setterId, localState.mapperId, localState.field,
        localState.bgColor, localState.widthRate, localState.widthPixel,
        localState.marginTop, localState.marginRight, localState.marginBottom, localState.marginLeft,
        localState.borderSize, localState.borderColor, localState.roundSize,
        localState.paddingTop, localState.paddingRight, localState.paddingBottom, localState.paddingLeft,
        localState.lineHeight, localState.fontColor, localState.fontSize
        ];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as ModelUtil.NodeInput;
                localState.isMapper = data.setter == undefined;
                localState.setterId.value = data.setter ?? '';
                localState.mapperId.value = data.mapper ?? '';
                localState.field.value = data.field ?? '';
                invalidate();
            }
        }, []);

        const fields = useMemo(() => {
            const mapper = valueKeys.find(m => m.key === localState.mapperId.value);
            if (mapper == undefined) return [];
            // const field = ModelElementUtil.getChoosedField(mapper, { states, caches: [], models })
            const model = models.find(m => m.id === mapper.structId);
            assert(model != null, 'model is null.');
            return model.fields;
        }, [localState.mapperId]);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const data: ModelUtil.NodeInput = {
                setter: DataUtil.blankToUndefined(localState.setterId.value),
                mapper: DataUtil.blankToUndefined(localState.mapperId.value),
                field: localState.field.value,

                bgColor: localState.bgColor.value,
                widthRate: localState.widthRate.value,
                widthPixel: localState.widthPixel.value,
                heightPixel: localState.heightPixel.value,
                marginTop: localState.marginTop.value,
                marginRight: localState.marginRight.value,
                marginBottom: localState.marginBottom.value,
                marginLeft: localState.marginLeft.value,

                borderSize: Number(localState.borderSize.value),
                borderColor: localState.borderColor.value,
                roundSize: Number(localState.field.value),

                paddingTop: localState.paddingTop.value,
                paddingRight: localState.paddingRight.value,
                paddingBottom: localState.paddingBottom.value,
                paddingLeft: localState.paddingLeft.value,
                lineHeight: Number(localState.lineHeight.value),
                fontColor: localState.fontColor.value,
                fontSize: Number(localState.fontSize.value),
            }
            setTempData(data);
        }, targetFroms);

        const mapperSelected = localState.mapperId.value != '';

        const mappingJsx = (<FormUtil.BorderPanel
            title="info"
            innerJsx={<>
                <FormUtil.FormRecord
                    titleLabel="Method"
                    jsx={<FormUtil.SwitchTwoFace
                        label1="Setter"
                        label2="Mapper"
                        width={240}
                        rate1={50}
                        rate2={50}
                        isUse={localState.isMapper}
                        callback={() => {
                            localState.isMapper = !localState.isMapper;
                            localState.setterId.value = '';
                            localState.mapperId.value = '';
                            localState.field.value = '';
                            invalidate();
                        }}
                    />}
                />
                <FormUtil.FormRecord
                    titleLabel="Setter Id"
                    isVisible={!localState.isMapper}
                    jsx={<FormUtil.Combobox
                        width={160}
                        checkable={localState.setterId}
                        setCheckable={(checkable) => {
                            localState.setterId = checkable;
                            invalidate();
                        }}
                        headBlank
                        list={setters.map(s => {
                            return { value: s.id, labelText: s.id }
                        })}
                        validates={localState.isMapper ? [] : [
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            }
                        ]}
                    />}
                />
                <FormUtil.FormRecord
                    titleLabel="Mapper Id"
                    isVisible={localState.isMapper}
                    jsx={<FormUtil.Combobox
                        width={260}
                        checkable={localState.mapperId}
                        setCheckable={(checkable) => {
                            localState.mapperId = checkable;
                            localState.isVar = false;
                            invalidate();
                        }}
                        headBlank
                        list={valueKeys
                            .filter(m => m.array === 0 && m.dataType === 'struct')
                            .map(m => {
                                return { value: m.key, labelText: m.key }
                            })}
                        validates={!localState.isMapper ? [] : [
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            }
                        ]}
                    />}
                />
                <FormUtil.FormRecord
                    titleLabel="Field Id"
                    isVisible={localState.isMapper}
                    isEnabled={mapperSelected}
                    jsx={<>
                        <FormUtil.SwitchTwoFace
                            label1="Direct"
                            label2="Var"
                            width={180}
                            rate1={60}
                            rate2={40}
                            isUse={localState.isVar}
                            callback={() => {
                                localState.isVar = !localState.isVar;
                                localState.field.value = '';
                                invalidate();
                            }}
                        />
                        {!localState.isVar ? (
                            <FormUtil.Combobox
                                marginLeft={4}
                                width={220}
                                checkable={localState.field}
                                setCheckable={(checkable) => {
                                    localState.field = checkable;
                                    invalidate();
                                }}
                                headBlank
                                list={fields.map(e => {
                                    return { value: e.id, labelText: e.id }
                                })}
                                validates={!mapperSelected ? [] : [
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />
                        ) : (
                            <FormUtil.Combobox
                                marginLeft={4}
                                width={220}
                                checkable={localState.field}
                                setCheckable={(checkable) => {
                                    localState.field = checkable;
                                    invalidate();
                                }}
                                headBlank
                                list={valueKeys
                                    .filter(v => v.array === 0 && v.dataType === 'name')
                                    .map(v => {
                                    return { value: `\${${v.key}}`, labelText: v.key }
                                })}
                                validates={!mapperSelected ? [] : [
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />
                        )}
                    </>}
                />
            </>}
        />);

        const widthValidate: ValidateUtil.Validate[] = [
            {
                checker: (value) => ValidateUtil.checkStringLength(value, 0, 64),
                errorType: "length"
            },
            {
                checker: (value) => ModelElementUtil.checkAppliedFmlNum(value, valueKeys),
                errorType: "value"
            }
        ];
        const marginValidate: ValidateUtil.Validate[] = [
            {
                checker: (value) => ValidateUtil.checkStringLength(value, 1, 32),
                errorType: "length"
            },
            {
                checker: (value) => ModelElementUtil.checkAppliedFmlNum(value, valueKeys),
                errorType: "value"
            }
        ];

        const marginWidth = 103;

        const visualTabJsx = (<>
            <FormUtil.BorderPanel
                title="Visual"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Bg Color"
                        jsx={<FormUtil.ColorField
                            checkable={localState.bgColor}
                            setCheckable={(checkable) => {
                                localState.bgColor = checkable;
                                invalidate();
                            }}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Round size"
                        jsx={<FormUtil.TextField
                            width={80}
                            isNumber
                            checkable={localState.roundSize}
                            setCheckable={(checkable) => {
                                localState.roundSize = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, 0, 24),
                                    errorType: "length"
                                },
                            ]}
                        />}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="Border"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Border size"
                        jsx={<FormUtil.TextField
                            width={80}
                            isNumber
                            checkable={localState.borderSize}
                            setCheckable={(checkable) => {
                                localState.borderSize = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, 1, 10),
                                    errorType: "length"
                                },
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Border Color"
                        jsx={<>
                            <FormUtil.ColorField
                                checkable={localState.borderColor}
                                setCheckable={(checkable) => {
                                    localState.borderColor = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />
                        </>}
                    />
                </>}
            />
        </>);

        const layoutTabJsx = (
            <FormUtil.BorderPanel
                title="Layout"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Width Rate"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.widthRate}
                            setCheckable={(checkable) => {
                                localState.widthRate = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={widthValidate}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Width Pixel"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.widthPixel}
                            setCheckable={(checkable) => {
                                localState.widthPixel = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={widthValidate}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Height Pixel"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.heightPixel}
                            setCheckable={(checkable) => {
                                localState.heightPixel = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={widthValidate}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Margin"
                        jsx={<>
                            <FormUtil.TextField
                                width={marginWidth}
                                checkable={localState.marginTop}
                                setCheckable={(checkable) => {
                                    localState.marginTop = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={marginValidate}
                            />
                            <FormUtil.TextField
                                width={marginWidth}
                                marginLeft={2}
                                checkable={localState.marginRight}
                                setCheckable={(checkable) => {
                                    localState.marginRight = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={marginValidate}
                            />
                            <FormUtil.TextField
                                width={marginWidth}
                                marginLeft={2}
                                checkable={localState.marginBottom}
                                setCheckable={(checkable) => {
                                    localState.marginBottom = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={marginValidate}
                            />
                            <FormUtil.TextField
                                width={marginWidth}
                                marginLeft={2}
                                checkable={localState.marginLeft}
                                setCheckable={(checkable) => {
                                    localState.marginLeft = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={marginValidate}
                            />
                        </>}
                    />
                </>}
            />
        );

        const innerTabJsx = (<>
            <FormUtil.BorderPanel
                title="Inner"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Padding"
                        jsx={<>

                            <FormUtil.TextField
                                width={marginWidth}
                                checkable={localState.paddingTop}
                                setCheckable={(checkable) => {
                                    localState.paddingTop = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={marginValidate}
                            />
                            <FormUtil.TextField
                                width={marginWidth}
                                marginLeft={2}
                                checkable={localState.paddingRight}
                                setCheckable={(checkable) => {
                                    localState.paddingRight = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={marginValidate}
                            />
                            <FormUtil.TextField
                                width={marginWidth}
                                marginLeft={2}
                                checkable={localState.paddingBottom}
                                setCheckable={(checkable) => {
                                    localState.paddingBottom = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={marginValidate}
                            />
                            <FormUtil.TextField
                                width={marginWidth}
                                marginLeft={2}
                                checkable={localState.paddingLeft}
                                setCheckable={(checkable) => {
                                    localState.paddingLeft = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={marginValidate}
                            />z
                        </>}
                    />
                </>}
            />
            <FormUtil.BorderPanel
                title="Text"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Font size"
                        jsx={<FormUtil.TextField
                            width={80}
                            isNumber
                            checkable={localState.fontSize}
                            setCheckable={(checkable) => {
                                localState.fontSize = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, 8, 128),
                                    errorType: "length"
                                },
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Line height"
                        jsx={<FormUtil.TextField
                            width={80}
                            isNumber
                            checkable={localState.lineHeight}
                            setCheckable={(checkable) => {
                                localState.lineHeight = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, -64, 64),
                                    errorType: "length"
                                },
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Font Color"
                        jsx={<>
                            <FormUtil.ColorField
                                checkable={localState.fontColor}
                                setCheckable={(checkable) => {
                                    localState.fontColor = checkable;
                                    invalidate();
                                }}
                                isEnabled={true}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />
                        </>}
                    />
                </>}
            />
        </>);

        return (<_TabFrame>
            <TabbedPane
                tabElements={[
                    { name: 'Mapping', cont: <_TabItemWrap>{mappingJsx}</_TabItemWrap>, enable: true },
                    { name: 'Visual', cont: <_TabItemWrap>{visualTabJsx}</_TabItemWrap>, enable: true },
                    { name: 'Layout', cont: <_TabItemWrap>{layoutTabJsx}</_TabItemWrap>, enable: true },
                    { name: 'Inner', cont: <_TabItemWrap>{innerTabJsx}</_TabItemWrap>, enable: true },
                ]}
                activeNo={0}
            />
        </_TabFrame>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'input';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default nodeInput;

const _TabFrame = styled.div`
    display: inline-block;
    margin: 3px 0 0 3px;
    width: calc(100% - 6px);
    height: calc(100% - 46px);
`;
const _TabItemWrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #c5d3dd;
`;
