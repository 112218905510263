import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../../util/modelUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import NodeFocus from "./nodeFocus";
import DataUtil from "../../../../../../../common/dataUtil";
import assert from "assert";

namespace NodeArrayEff {

    export interface Data {
        method: ArrayMethod;
        condition?: string;
        sort?: SortProps;
        // clbkId: string;
    }

    export type SortProps = {
        items: SortItem[];
    }
    export type SortItem = {
        prpName: string;
        asc: number;
    }

    export const ArrayMethods = ['sort', 'filter'] as const;
    // export type DataType = 'string' | 'number'| 'boolean'| 'color'| 'struct'| 'name';
    export type ArrayMethod = typeof ArrayMethods[number];

    type LocalState = {
        method: FormUtil.CheckableValue;
        condition: FormUtil.CheckableValue;
        sort: FormUtil.CheckableValue;
        // clbkId: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            method: { value: '', errors: [] },
            condition: { value: '', errors: [] },
            sort: { value: '', errors: [] },
            // clbkId: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [focusInfo, dtypes, states, funcargs, caches, propFields, funcs] = useMemo(() => {

            const cur = manageItems.focusNode;

            // const scope = store.system.scope;
            // const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];

            const caches = ModelElementUtil.getRetentionCachesFromCurrent(cur)
                .concat(ModelElementUtil.getFetchThensFromCurrent(cur));
            const states = ModelElementUtil.getReferableStates(cur);
            const dtypes = ModelElementUtil.getReferableDtypes(cur);
            const funcs = ModelElementUtil.getRetentionDispatchersFromCurrent(cur);

            const isDispatchSub = () => ModelElementUtil.isDispatchSub(cur);
            const funcargs = !isDispatchSub() ? undefined : ModelElementUtil.getArgumentFromCurrent(cur);
            const propFields = ModelElementUtil.getPropFieldsFromCurrent(cur);

            const focusInfo = NodeFocus.getFocusInfo(cur, {
                states, caches, prpflds: propFields, funcargs, dtypes
            });

            return [focusInfo, dtypes, states, funcargs, caches, propFields, funcs];
        }, []);

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;

                localState.method.value = data.method;
                // localState.clbkId.value = data.clbkId;
                if (localState.method.value !== '') {
                    const method = localState.method.value as ArrayMethod;
                    switch (method) {
                        case 'filter': {
                            assert(data.condition != undefined, 'data.conditionがundefinedであってはならない。');
                            localState.condition.value = data.condition;
                        } break;
                        case 'sort': {
                            assert(data.sort != undefined, 'data.sortがundefinedであってはならない。');
                            localState.sort.value = data.sort.items.map(item => `${item.prpName}:${item.asc}`).join('\n');
                        } break;
                    }
                }
            }
            invalidate();
        }, []);

        const targetFroms = [localState.method, localState.condition, , localState.sort];
        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (localState.method.errors.length > 0) {
                setInputOK(false);
                return;
            }

            let condition: undefined | string = undefined;
            let sort: undefined | SortProps = undefined;

            const method = localState.method.value as ArrayMethod;
            switch (method) {
                case 'filter': {
                    condition = localState.condition.value;
                    if (localState.condition.errors.length > 0) {
                        setInputOK(false);
                        return;
                    }
                } break;
                case 'sort': {
                    sort = { items: [] };
                    let hasError = false;
                    localState.sort.value.split('\n').some(record => {
                        const items = record.split(':');
                        const prpName = items[0];
                        const asc = Number(items[1]);
                        // console.log(asc);
                        if (isNaN(asc)) {
                            hasError = true;
                            return 1;
                        }
                        sort?.items.push({ prpName, asc });
                    });
                    if (hasError) {
                        setInputOK(false);
                        return;
                    }
                } break;
            }
            setInputOK(true);

            const data: Data = {
                method: localState.method.value as ArrayMethod,
                condition,
                sort
                // clbkId: localState.clbkId.value
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <NodeFocus.Refer
                focusInfo={focusInfo}
            />
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Method"
                        jsx={
                            <FormUtil.Combobox
                                width={220}
                                checkable={localState.method}
                                setCheckable={(checkable) => {
                                    localState.method = checkable;
                                    invalidate();
                                }}
                                list={ArrayMethods.map(item => ({
                                    value: item, labelText: item
                                }))}
                                headBlank
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />}
                    />
                    {(() => {
                        if (localState.method.value === '') return <></>;
                        const method = localState.method.value as ArrayMethod;
                        switch (method) {
                            case 'sort': return (
                                <FormUtil.BorderPanel
                                    title="items"
                                    height={200}
                                    innerJsx={<>
                                        <FormUtil.TextArea
                                            checkable={localState.sort}
                                            setCheckable={(checkable) => {
                                                localState.sort = checkable;
                                                invalidate();
                                            }}
                                        />
                                    </>}
                                />
                            );
                            case 'filter': return <>
                                <FormUtil.FormRecord
                                    titleLabel="Condition"
                                    jsx={<FormUtil.TextField
                                        width={400}
                                        checkable={localState.condition}
                                        setCheckable={(checkable) => {
                                            localState.condition = checkable;
                                            invalidate();
                                        }}
                                        isEnabled={true}
                                        validates={method !== 'filter' ? [] : [
                                            {
                                                checker: (value) => ValidateUtil.checkRequired(value),
                                                errorType: "required"
                                            },
                                            {
                                                checker: (value) => ValidateUtil.checkStringLength(value, 1, 124),
                                                errorType: "length"
                                            },
                                            // {
                                            //     checker: (value) => !existNameList.includes(value),
                                            //     errorType: "relation"
                                            // }
                                        ]}
                                    />}
                                />
                            </>;
                        }
                    })()}
                    {/* <FormUtil.FormRecord
                        titleLabel="Callback"
                        jsx={
                            <FormUtil.Combobox
                                width={220}
                                checkable={localState.clbkId}
                                setCheckable={(checkable) => {
                                    localState.clbkId = checkable;
                                    invalidate();
                                }}
                                headBlank
                                list={funcs.map(f => ({ value: f.id, labelText: f.id }))}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />
                        }
                    /> */}
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'arreff';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeArrayEff;
