import styled, { css } from "styled-components";
import Styles from "../../../../def/design/styles";
import FormUtil from "../../../../common/component/form/formUtiil";
import ModelElementUtil from "../function/util/modelElementUtil";
import ModelUtil from "../function/util/modelUtil";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../entry/systemEntry";
import StoreProject from "../../../redux/store/storeProject";
import DataUtil from "../../../../common/dataUtil";
import ServerUtil from "../../../../common/serverUtil";

namespace JsonTab {

    export const Component = () => {

        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const devStore = store.develop;
        const isManage = devStore.manageData != null;

        type SourceCache = {
            total: string;
            common: string;
        }
        const [sourceCache, setSourceCatche] = useState<SourceCache>({
            total: '',
            common: ''
        });
        const invalidate = () => { setSourceCatche({ ...sourceCache }) };
    
        useEffect(() => {
            if (store.project != null) {
                sourceCache.total = JSON.stringify(store.project, null, 2);
    
                const rootWrap = store.project.rootData;
                const commonWrap = ModelElementUtil.getInnerWrapFixed(rootWrap, 'common');
    
                const commonItems = (commonWrap.data as ModelUtil.NodeGenericMngs).mngs;
                sourceCache.common = JSON.stringify(commonItems, null, 2);
                invalidate();
            }
        }, [JSON.stringify(store.project)]);
    
    
        return (
            <Styles._Div margin={Styles.DivMarginType.NARROW} color="#b7ceba">
                <_LeftDiv>
                    <FormUtil.ButtonRecord
                        align="left"
                        buttons={[
                            {
                                label: 'New project',
                                width: 200,
                                isEnable: store.project == null && sourceCache.total === '',
                                callback: () => {
                                    let commonItems: ModelUtil.WrapElement[] | undefined = undefined;
                                    if (sourceCache.common !== '') {
                                        commonItems = JSON.parse(sourceCache.common) as ModelUtil.WrapElement[];
                                    }
                                    store.project = StoreProject.getInitialData({
                                        commonItems
                                    });
                                    dispatcher.updateStore();
                                }
                            },
                            {
                                label: 'Load',
                                width: 140,
                                isEnable: store.project == null && sourceCache.total !== '',
                                callback: () => {
                                    store.project = JSON.parse(sourceCache.total);
                                    dispatcher.updateStore();
                                }
                            },
                            {
                                label: 'Clear',
                                width: 140,
                                // 管理されている場合削除できない
                                isEnable: store.project != null,
                                callback: () => {
                                    store.project = null;
                                    sourceCache.total = '';
                                    sourceCache.common = '';
                                    dispatcher.updateStore();
                                }
                            },
                            {
                                label: 'Test',
                                width: 140,
                                isEnable: store.project != null,
                                callback: () => {
                                    const base = JSON.stringify(store.project);
                                    // console.log(`base: ${base.length}, zip: ${ServerUtil.utf8_to_b64(base).length}`);
                                    // console.log(base);
                                    console.log(ServerUtil.gZip(base));
                                }
                            },
    
                        ]}
                    />
                    <_JsonViewer
                        value={sourceCache.total}
                        onChange={(e) => {
                            sourceCache.total = e.target.value;
                            invalidate();
                        }}
                        readOnly={store.project != null}
                        isEnable={!(store.project == null && sourceCache.common !== '')}
                    />
                </_LeftDiv>
                <_RightDiv>
                    <FormUtil.ButtonRecord
                        align="left"
                        buttons={[
                        ]}
                    />
                    <_JsonViewer
                        value={sourceCache.common}
                        onChange={(e) => {
                            sourceCache.common = e.target.value;
                            invalidate();
                        }}
                        readOnly={store.project != null}
                        isEnable={!(store.project == null && sourceCache.total !== '')}
                    />
                </_RightDiv>
            </Styles._Div>
        );
    }
}

export default JsonTab;


export const _LeftDiv = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    background-color: #757575
`;

export const _RightDiv = styled.div<{
}>`
    display: inline-block;
    width: 50%;
    height: 100%;
    background-color: #b4b4b4;
    vertical-align: top;
`;

const _JsonViewer = styled.textarea<{
    isEnable: boolean;
}>`
    display: inline-block;
    position: relative;
    width: calc(100% - 8px);
    height: calc(100% - 44px - 8px);
    margin: 4px 0 0 4px;
    /* width: 100%;
    height: 100%; */
    resize: none;
    font-size: 18px;
    /* font-weight: 600; */
    ${props => props.readOnly ? css`
        color: #0043a7;
        background-color: #f0eeee;
    ` : css`
        color: #000d20;
        background-color: #afbac2;  
    `}
    ${props => props.isEnable ? '' : css`
        opacity: 0.4;
        pointer-events: none;
    `}
    box-sizing: border-box;
    border: solid 2px #865959;
`;