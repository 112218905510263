import { useEffect, useState, useContext, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../../util/modelUtil";
import DataUtil from "../../../../../../../common/dataUtil";
import ModelEditDialog from "../../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import assert from "assert";
import { TabbedPane } from "../../../../../../../common/component/tab/tabbedPane";
import styled from "styled-components";
import ScopeManager from "../../../option/scopeManager";
import StyleChooser from "../../decrare/styleChooser";
import TagUtil from "./tagUtil";

namespace NodeConsoleLog {

    type LocalState = {
        text: FormUtil.CheckableValue;
    }

    export type Data = {
        text: string;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;
        const [actions, valueKeys, styleItems, dispatchItems] = useMemo(() => {

            const actions = ModelElementUtil.getActionsFromCurrent(manageItems.focusNode);

            const scope = store.system.scope;
            const dispatchItems = scope.dispatchItems as ScopeManager.DispatchItem[];
            const stateValueKeys = scope.stateValueKeys as ScopeManager.ValueKeyField[];
            const cacheValueKeys = scope.cacheValueKeys as ScopeManager.ValueKeyField[];
            const propFieldValueKeys = scope.propFieldValueKeys as ScopeManager.ValueKeyField[];
            const valueKeys = stateValueKeys
                .concat(cacheValueKeys)
                .concat(propFieldValueKeys);

            const styleItems = scope.styleItems as ScopeManager.StyleItem[];
            return [actions, valueKeys, styleItems, dispatchItems];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            text: { value: '', errors: [] },
            // actionOnclick: TagUtil.getInitialListenerForm(),
            // actionOnContext: TagUtil.getInitialListenerForm(),
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [
            localState.text,
        ];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.text.value = data.text;
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const data: Data = {
                text: localState.text.value,
            }
            setTempData(data);
        }, [localState]);

        return (<>
            <FormUtil.BorderPanel
                title="Info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Text"
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.text}
                            setCheckable={(checkable) => {
                                localState.text = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'log';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeConsoleLog;

