import assert, { throws } from "assert";
import ModelElementUtil from "../develop/function/util/modelElementUtil";
import ModelUtil from "../develop/function/util/modelUtil";
import DataUtil from "../../../common/dataUtil";
import VariableChooser from "../develop/function/editor/proc/variableChooser";
import NodeFocus from "../develop/function/editor/proc/focus/nodeFocus";
import NodeStlarg from "../develop/function/editor/decrare/nodeStlarg";
import StyleChooser from "../develop/function/editor/decrare/styleChooser";
import TagUtil from "../develop/function/editor/ui/tag/tagUtil";
import NodeAssign from "../develop/function/editor/proc/focus/nodeAssign";
import NodeArrayAdd from "../develop/function/editor/proc/focus/nodeArrayAdd";
import NodeArrayDel from "../develop/function/editor/proc/focus/nodeArrayDel";
import NodeStyle from "../develop/function/editor/decrare/nodeStyle";
import PrefixUtil from "../develop/function/util/prefixUtil";
import AssertUtil from "../../../common/assertUtil";
import StyleFormulaDefiner from "../develop/function/editor/decrare/style/styleFormulaDefiner";
import NodeCase from "../develop/function/editor/condition/nodeCase";
import NodeWhen from "../develop/function/editor/condition/nodeWhen";
import NodeAccept from "../develop/function/editor/condition/nodeAccept";
import NodeAsgnNam from "../develop/function/editor/proc/focus/nodeAsgnNam";
import NodeField from "../develop/function/editor/var/nodeField";
import NodeFunction from "../develop/function/editor/var/func/nodeFunction";
import NodeTagInput from "../develop/function/editor/ui/tag/nodeTagInput";
import NodeExecute from "../develop/function/editor/proc/nodeExecute";
import NodeFetch from "../develop/function/editor/proc/nodeFetch";
import NodeIterate from "../develop/function/editor/nodeIterate";
import NodeAssignMatch from "../develop/function/editor/proc/focus/nodeAssignMatch";
import NodeArrayCat from "../develop/function/editor/proc/focus/nodeArrayCat";
import NodeArrayEff from "../develop/function/editor/proc/focus/nodeArrayEff";
import NodeConsoleLog from "../develop/function/editor/ui/tag/nodeConsoleLog";
import NodeCatch from "../develop/function/editor/proc/nodeCatch";
import SystemFunctionUtil from "./systemFunctionUtil";
import NodeAssignFormula from "../develop/function/editor/proc/focus/nodeAssignFormula";
import NodeReturn from "../develop/function/editor/var/func/nodeReturn";

namespace ReaderUtil {

    export type ReaderState = {
        globals: FieldObject[] | null;
        localUnits: LocalStateUnit[];
    }
    export type LocalStateUnit = {
        localId: string;
        states: FieldObject[];
    }
    export type FieldObject = {
        field: ModelUtil.NodeModelField;
        value: any;
    }

    export type IterateProps = {
        id: string;
        self: number;
        max: number;
    }

    export type Tracer = {
        parent: Tracer | null;
        cur: ModelUtil.WrapElement | null;
        localId: string;
    }

    export type ActionObject = {
        key: string;
        callback: () => void;
    }

    export type EventObject = {
        id: string;
        funcargs: ModelUtil.NodeModelField[];
        procItems: ModelUtil.WrapElement[];
        callback: Function;
    }

    export type StyleObject = {
        id: string;
        src: string;
        fmls: StyleFormulaDefiner.Record[];
        args: NodeStlarg.Data[];
    }

    export type LocalPoint = {
        id: string;
        invalidate: () => void;
    }

    export const getDefaultValue = (dType: ModelUtil.DataType) => {
        switch (dType) {
            case 'multiline':
            case 'string': return '';
            case 'number': return 0;
            case 'boolean': return false;
            case 'color': return '#000';
        }
        throw new Error(`dTypeがプリミティブ型でない。dType:[${dType}]`);
    }
    /**
      * モデル型の構成に従って初期オブジェクト（JSON）を生成する。
      * @param field モデル型
      * @param dtypes データタイプリスト
      * @returns モデル・初期オブジェクト
      */
    export const buildInitialStructureObject = (
        field: ModelUtil.NodeModelField,
        dtypes: ModelElementUtil.DtypeInfo[]
    ): ReaderUtil.FieldObject => {
        const baseField = field;
        // console.log(field.id);
        const getValueRec = (field: ModelUtil.NodeModelField, useDefval: boolean): any => {
            if (field.array > 0) return '[]';
            if (field.dataType === 'struct') {
                const model = dtypes.find(m => m.id === field.structId);
                assert(model != undefined, 'model is null.');
                const list = model.fields.map(f => {
                    if (!(['number', 'boolean', 'struct'] as ModelUtil.DataType[]).includes(f.dataType)) {
                        return `"${f.id}": "${getValueRec(f, true)}"`;
                    } else {
                        return `"${f.id}": ${getValueRec(f, true)}`;
                    }
                });
                return `{${list.join(',')}}`;
            }
            else {
                // デフォルト値が設定されている場合のみ利用する
                const memberData = field as NodeField.MemberData;
                if (useDefval && memberData.defval != undefined) {
                    let defval: any = memberData.defval;
                    if ((['number', 'boolean'] as ModelUtil.DataType[]).includes(field.dataType)) {
                        defval = DataUtil.applyFormula(defval);
                    }
                    return defval;
                }
                return getDefaultValue(field.dataType);
            }
        }

        let value = getValueRec(field, false);
        if (field.array >= 1 || field.dataType === 'struct') {
            value = JSON.parse(value);
        }
        return { field: field, value };
    }

    /** プロシージャを適用する */
    export const applyProcedure = (items: ModelUtil.WrapElement[], buildProps: BuildProps, invalidate: () => void) => {
        const dynamicProps = buildProps.dynamicProps;
        const caches = dynamicProps.caches;
        const buildInitialStructureObject = buildProps.globalFixedProps.buildInitialStructureObject;

        let ret = undefined;

        /** 自身の処理を再帰的に行う */
        const rec = (items: ModelUtil.WrapElement[]) => applyProcedure(items, buildProps, invalidate);

        items.some(item => {
            // console.log(item);
            // トレーサの更新
            const tracer = dynamicProps.tracer;
            dynamicProps.tracer = {
                parent: tracer,
                cur: item,
                localId: tracer.localId,
            }

            switch (item.type) {
                case 'cache': {
                    const cache = item.data as NodeField.CacheData;
                    caches.push(buildInitialStructureObject(cache));
                    ReaderUtil.affectOperation({
                        data: {
                            target: 'cache',
                            rootId: cache.id,
                            levelProps: [],
                            items: cache.items,
                        }, buildProps
                    });
                } break;
                case 'style': {
                    const obj = ReaderUtil.getStyleObjFromData(item.data, buildProps);
                    dynamicProps.styles.push(obj);
                } break;
                case 'accept': {
                    const accept = item.data as NodeAccept.Data;
                    // console.log(dynamicProps.iteraters);
                    // console.log(accept.condition);
                    const isAccept = ReaderUtil.isTrue(accept.condition, buildProps);
                    if (isAccept) {
                        // console.log('pass');
                        rec(accept.elements);
                    }
                } break;
                case 'case': {
                    const data = item.data as NodeCase.Data;
                    switch (data.method) {
                        case 'bool': {
                            const condition = data.condition;
                            // console.log(buildProps.dynamicProps.caches);
                            assert(condition != undefined, 'メソッドがboolの場合、conditionがundefinedであってはならない。');
                            const isAccept = ReaderUtil.isTrue(condition, buildProps);
                            // console.log(isAccept);
                            const whenTrue = data.items[0].data as NodeWhen.Data;
                            const whenFalse = data.items[1].data as NodeWhen.Data;
                            assert(whenTrue.bool === true && whenFalse.bool === false, 'case->boolに紐づいている要素が不正。')
                            if (isAccept) {
                                // console.log('true');
                                rec(whenTrue.elements);
                            } else {
                                // console.log('false');
                                rec(whenFalse.elements);
                            }
                        } break;
                    }
                } break;
                case 'iterate': {
                    const data = item.data as NodeIterate.Data;
                    // console.log(data);
                    let fml = data.itrCntFml;

                    // 変数の置換
                    fml = replaceKeySets(fml, buildProps);

                    // console.log(fml);
                    // 式の適用
                    fml = DataUtil.applyFormula(fml);
                    const loopCnt = Number(fml);
                    // console.log(fml);
                    // console.log(loopCnt);
                    for (let i = 0; i < loopCnt; i++) {
                        // イテレータのコピーを取得
                        // const cacheIteraters = JSON.parse(JSON.stringify(dynamicProps.iteraters)) as ReaderUtil.IterateProps[];
                        const cacheIteraters = buildProps.dynamicProps.iteraters.slice();
                        cacheIteraters.push({ id: data.id, self: i, max: loopCnt });
                        const cacheBuildProps: BuildProps = { ...buildProps, dynamicProps: { ...buildProps.dynamicProps, iteraters: cacheIteraters } };
                        // console.log(data);
                        applyProcedure(data.elements, cacheBuildProps, invalidate);
                    }
                } break;
                case 'focus': ReaderUtil.affectOperation({ data: item.data, buildProps }); break;
                case 'func': {
                    const dispatcher = item.data as NodeFunction.Data;
                    dynamicProps.dispatchers = dynamicProps.dispatchers
                        .concat(ReaderUtil.buildEventObjectFromData(dispatcher, buildProps, invalidate));
                } break;
                // case 'closure': {
                //     const closure = item.data as ModelUtil.NodeDispatcher;
                //     dynamicProps.closures = dynamicProps.closures
                //         .concat(ReaderUtil.convertEventNodeToProps(closure));
                // } break;
                // ディスパッチャを実行する
                case 'execute': {
                    const data = item.data as NodeExecute.Data;
                    // console.log(data);
                    const callback = ReaderUtil.generateCallbackFromDispatcher({
                        buildProps, dispatch: {
                            dsptId: data.eventId, args: data.args
                        }
                    });
                    callback();
                } break;
                case 'log': {
                    const data = item.data as NodeConsoleLog.Data;
                    // let fml = data.text;
                    // // 変数の置換
                    // fml = replaceKeySets(fml, buildProps);

                    // // 式の適用
                    // fml = DataUtil.applyFormula(fml);
                    // console.log(fml);
                    console.log(getFormulaObject(data.text, buildProps));
                } break;
                case 'return': {
                    const data = item.data as NodeReturn.Data;

                    const [formula, args] = getArgsFormatFromFormula(data.fml, buildProps);
                    ret = DataUtil.applyFormulaArgs(args, formula);
                } break;
                // 非同期処理を実行する
                case 'fetch': {
                    const data = item.data as NodeFetch.Data;

                    /** データよりリクエストオブジェクトを生成 */
                    const getRequestInit = () => {
                        const req = data.reqOpt;
                        let body = req.body != undefined ? replaceKeySets(req.body, buildProps) : undefined;

                        // console.log(body);
                        return {
                            mode: req.mode,
                            method: req.method,
                            headers: {
                                Accept: req.accept,
                                'Content-Type': req.contentType,
                            },
                            body: body
                        } as RequestInit;
                    }
                    const thenProc = (res: any) => {
                        // console.log(res);

                        const thenWrap = data.mngs.find(m => m.type === 'then');
                        if (thenWrap != undefined) {
                            const then = thenWrap.data as NodeField.ThenData;

                            // レスポンスのキャッシュの追加する
                            const resCacheData: NodeField.CacheData = {
                                id: then.id,
                                dataType: then.dataType,
                                array: then.array,
                                structId: then.structId,
                                isRefer: true,
                                items: []
                            }
                            const cacheObject = buildInitialStructureObject(resCacheData);
                            cacheObject.value = res;
                            caches.push(cacheObject);

                            // console.log(caches);
                            // thenの内部処理を実行
                            applyProcedure(then.items, buildProps, invalidate);
                            // console.log(then.items);
                            // ステートを更新するため再描画
                            // assert(invalidate != undefined, 'invalidateはundefinedであってはならない。');
                            invalidate();
                        }
                    }
                    const catchProc = (err: string) => {
                        // console.log(res);

                        const catchWrap = data.mngs.find(m => m.type === 'catch');
                        if (catchWrap != undefined) {
                            const data = catchWrap.data as NodeCatch.Data;

                            // レスポンスのキャッシュの追加する
                            const errCacheData: NodeField.CacheData = {
                                id: data.errVar,
                                dataType: 'string',
                                array: 0,
                                isRefer: true,
                                items: []
                            }
                            const cacheObject = buildInitialStructureObject(errCacheData);
                            cacheObject.value = err;
                            caches.push(cacheObject);

                            // console.log(caches);
                            // thenの内部処理を実行
                            applyProcedure(data.items, buildProps, invalidate);
                            // console.log(then.items);
                            // ステートを更新するため再描画
                            // assert(invalidate != undefined, 'invalidateはundefinedであってはならない。');
                            invalidate();
                        }
                    }
                    const req = getRequestInit();
                    // console.log(req);
                    fetch(data.url, req)
                        .then(res => {
                            res.json()
                                .then(value => thenProc(value))
                                .catch(err => {
                                    alert('JSONパースに失敗（開発中）');
                                    console.log(req.body);
                                    console.log(err);
                                });
                        })
                        .catch(err => {
                            // buildProps.appendConsole(err, true);
                            catchProc(err);
                        });
                } break;
                default: throw new Error('想定していないtypeです。');
            }
        });

        return ret;
    }

    /**
     * 定義を元に、イベントオブジェクトを生成する
     * @param eventData 
     * @param buildProps 
     * @returns イベントオブジェクト
     */
    export const buildEventObjectFromData = (eventData: NodeFunction.Data, buildProps: BuildProps, invalidate: () => void): EventObject => {
        const argsData = ModelElementUtil.getInnerWrapFixed({ type: 'func', data: eventData }, 'args').data as ModelUtil.ArgsData;
        const procData = ModelElementUtil.getInnerWrapFixed({ type: 'func', data: eventData }, 'proc').data as ModelUtil.NodeProcedure;

        // const dispatch = getDispatch(eventData.id, buildProps);
        const funcargs = argsData.args.map(arg => arg.data as ModelUtil.NodeModelField);
        return {
            id: eventData.id,
            funcargs,
            procItems: procData.items,
            callback: () => {
                return applyProcedure(procData.items, buildProps, invalidate);
            }
        }
    }

    const getDispatch = (dispatchKey: string, buildProps: BuildProps) => {
        const prefix = dispatchKey.split('.')[0];
        const eventKey = dispatchKey.split('.')[1];
        console.log(buildProps.dynamicProps);
        const getTargetList = () => {
            switch (prefix) {
                case PrefixUtil.DISPATCH: return buildProps.dynamicProps.dispatchers;
                case PrefixUtil.PRPCLBK: return buildProps.dynamicProps.prpclbks;
            }
            throw new Error(`prefixの値が不正。(prefix: '${prefix}', dispatchKey: '${dispatchKey}')`);
        }
        const targetList = getTargetList();
        const dispatch = targetList.find(d => d.id === eventKey);
        assert(dispatch != undefined, `dispatchが見つからない。${eventKey}: [${targetList.map(d => d.id).join(', ')}]`);
        return dispatch;
    }

    export type GlobalFixedProps = {
        states: ModelUtil.NodeModelField[];
        launchArgs: FieldObject[];
        dtypes: ModelElementUtil.DtypeInfo[];
        buildInitialStructureObject: (field: ModelUtil.NodeModelField) => ReaderUtil.FieldObject;
    };

    /** 動的に生成するキャッシュデータ群 */
    export type DynamicProps = {
        iteraters: IterateProps[];
        caches: FieldObject[];
        temps: FieldObject[];
        funcargs: FieldObject[];
        dispatchers: EventObject[];
        closures: EventObject[];
        prpflds: FieldObject[];
        prpclbks: EventObject[];
        actions: ActionObject[];
        styles: StyleObject[];
        tracer: Tracer;
        localIds: string[];
        localPoints: LocalPoint[];
    }

    export const getInitialDynamicProps = (): ReaderUtil.DynamicProps => ({
        iteraters: [], caches: [], temps: [], funcargs: [], actions: [], dispatchers: [], closures: [],
        prpflds: [], prpclbks: [], styles: [],
        tracer: { parent: null, cur: null, localId: '0' },
        localIds: [],
        localPoints: []
    });

    export type BuildProps = {
        dynamicProps: DynamicProps;
        globalStates: FieldObject[];
        globalFixedProps: GlobalFixedProps;
        invalidate: (localId?: string) => void;
        appendConsole: (text: string, isError: boolean) => void;
    }

    export type ReplaceProps = {
        strKeySets: DataUtil.KeyValue[];
        numKeySets: DataUtil.KeyValue[];
    }

    // /**
    //  * 置換用のプロパティを構成する
    //  * @param buildProps 
    //  * @returns 
    //  */
    // export const buildReplaceProps = (buildProps: BuildProps): ReplaceProps => {
    //     const globalStates = buildProps.globalStates;
    //     const dynamicProps = buildProps.dynamicProps;
    //     const models = buildProps.globalFixedProps.dtypes;

    //     const strKeySets: KeySet[] = [];
    //     const numKeySets: KeySet[] = [];

    //     const getTargetSet = (dataType: ModelUtil.DataType) => {
    //         switch (dataType) {
    //             case 'string': return strKeySets;
    //             case 'number': return numKeySets;
    //         }
    //         return numKeySets;
    //     }

    //     const buildRec = (prefix: string, keys: string[], field: ModelUtil.NodeModelField, obj: any) => {
    //         // if (field.array === 0 && field.dataType !== 'struct') {
    //         if (NodeField.isPremitive(field)) {
    //             const key = `\${${prefix}.${keys.join('.')}}`;
    //             const keySet = getTargetSet(field.dataType);
    //             let value = obj;
    //             // 論理型の場合は括弧を付与する
    //             if (field.dataType === 'boolean') value = `(${obj})`;
    //             keySet.push({ key, value });

    //             // 文字列は長さも取得できるようにする
    //             if (NodeField.isPremitive(field) && field.dataType === 'string') {
    //                 const key = `\${${prefix}.${keys.join('.')}.${PrefixUtil.STR_LEN}}`;
    //                 numKeySets.push({ key, value: obj.length.toString() });
    //             }
    //         } else {
    //             if (field.array >= 1) {
    //                 const key = `\${${prefix}.${keys.concat(PrefixUtil.LIST_LEN).join('.')}}`;
    //                 // const list = JSON.parse(obj) as any[];
    //                 // numKeySets.push({ key, value: list.length.toString() });
    //                 numKeySets.push({ key, value: obj.length.toString() });
    //             } else if (field.dataType === 'struct') {
    //                 const model = models.find(m => m.id === field.structId);
    //                 assert(model != undefined, 'model is undefined.');
    //                 model.fields.forEach(f => {
    //                     // const jsonObj = JSON.parse(obj);
    //                     const jsonObj = obj;
    //                     // console.log(f);
    //                     try {

    //                         let nextObj = jsonObj[`${f.id}`];
    //                         // if (f.dataType === 'model') {
    //                         //     nextObj = JSON.stringify(nextObj);
    //                         // }
    //                         buildRec(prefix, keys.concat(f.id), f, nextObj);
    //                     } catch (e) {
    //                         // console.log(e);
    //                         // console.log(f);
    //                         buildProps.appendConsole(e as string, true);
    //                     }
    //                 });
    //             }
    //         }
    //     }
    //     // ステート
    //     globalStates.forEach(state => {
    //         buildRec(PrefixUtil.STATE, [state.field.id], state.field, state.value);
    //     });
    //     // キャッシュ
    //     dynamicProps.caches.forEach(cache => {
    //         buildRec(PrefixUtil.CACHE, [cache.field.id], cache.field, cache.value);
    //     });
    //     // 引数
    //     dynamicProps.funcargs.forEach(arg => {
    //         buildRec(PrefixUtil.ARGUMENT, [arg.field.id], arg.field, arg.value);
    //     });
    //     // コンポーネント引数のフィールド
    //     dynamicProps.prpflds.forEach(prparg => {
    //         buildRec(PrefixUtil.PRPFLD, [prparg.field.id], prparg.field, prparg.value);
    //     });
    //     // console.log(strKeySets);
    //     // dynamicProps.iteraters.forEach(itr => {
    //     // });

    //     return {
    //         strKeySets, numKeySets
    //     };
    // }

    export const getFomulaAplliedValue = (base: string, buildProps: BuildProps) => {
        const [formula, args] = getArgsFormatFromFormula(base, buildProps);
        return DataUtil.applyFormulaArgs(args, formula);
    }
    export const getArgsFormatFromFormula = (base: string, buildProps: BuildProps): [string, any[]] => {
        // const regEx = /\$\{.*?\}/g;
        const varKeys = base.match(DataUtil.VALUE_REG_EX);

        const args: any[] = [];
        let formula = base;
        if (varKeys != null) {
            // 重複削除
            const varKeySet = Array.from(new Set(varKeys));
            varKeySet.some((str, i) => {
                const apply = (value: any) => {
                    args.push(value);
                    formula = formula.replaceAll(str, `__arg_${i}`);
                };
                // const extract = /\$\{(.*?)\}/;
                const key = str.match(DataUtil.VALUE_REG_EX_INNER);
                assert(key != null, 'keyはnullであってはならない。');
                const props = key[1].split('.');
                const prefix = props[0];
                const addr = props.slice(1, props.length);
                const varId = addr[0];

                const getTargetObjects = () => {
                    switch (prefix) {
                        case PrefixUtil.STATE: return buildProps.globalStates;
                        case PrefixUtil.CACHE: return buildProps.dynamicProps.caches;
                        case PrefixUtil.LAUNCH_ARGUMENT: return buildProps.globalFixedProps.launchArgs;
                        case PrefixUtil.TEMP: return buildProps.dynamicProps.temps;
                        case PrefixUtil.ARGUMENT: return buildProps.dynamicProps.funcargs;
                        case PrefixUtil.PRPFLD: return buildProps.dynamicProps.prpflds;
                        case PrefixUtil.ITERATE: return buildProps.dynamicProps.iteraters;
                        case PrefixUtil.SYSTEM: return (name: SystemFunctionUtil.DefName) => SystemFunctionUtil.getMappedFuncValue(name, buildProps);
                        case PrefixUtil.DISPATCH: return buildProps.dynamicProps.dispatchers;
                    }
                    throw new Error(`prefixが何にも該当しない[${prefix}]`);
                }
                const targetObjects = getTargetObjects();

                switch (prefix) {
                    case PrefixUtil.STATE:
                    case PrefixUtil.CACHE:
                    case PrefixUtil.LAUNCH_ARGUMENT:
                    case PrefixUtil.TEMP:
                    case PrefixUtil.ARGUMENT:
                    case PrefixUtil.PRPFLD: {
                        const obj = (targetObjects as FieldObject[]).find(o => o.field.id === varId);
                        if (obj == undefined) return str;
                        let isError = false;
                        let value = (obj as FieldObject).value;
                        if (addr.length >= 2) {
                            addr.slice(1, addr.length).some(name => {
                                value = value[name];
                                if (value == undefined) {
                                    isError = true;
                                    return 1;
                                }
                            });
                        }
                        if (!isError) {
                            apply(value);
                        }
                    } break;
                    case PrefixUtil.ITERATE: {
                        const obj = (targetObjects as IterateProps[]).find(o => o.id === varId);
                        if (obj == undefined) return str;
                        if (addr.length === 2) {
                            const listPropName = addr[1];
                            switch (listPropName) {
                                case PrefixUtil.LIST_LEN: {
                                    apply(obj.max);
                                } break;
                                case PrefixUtil.LIST_CUR: {
                                    apply(obj.self);
                                } break;
                            }
                        }
                    } break;
                    case PrefixUtil.DISPATCH: {
                        const event = (targetObjects as EventObject[]).find(o => o.id === varId);
                        if (event == undefined) throw new Error(`${key}が見つからない。`);
                        const callback = getCallbackObjectArgs({ buildProps, dispatch: event });
                        apply(callback);
                    } break;
                    case PrefixUtil.SYSTEM: {
                        const obj = targetObjects as ((name: SystemFunctionUtil.DefName) => string);
                        apply(obj(varId as SystemFunctionUtil.DefName));
                    } break;
                }
            });
        }
        return [formula, args];
    }

    export const getFormulaObject = (base: string, buildProps: BuildProps): any => {
        // const regEx = /\$\{.*?\}/g;
        const varKeys = base.match(DataUtil.VALUE_REG_EX);

        let formula = base;
        // console.log(formula);
        if (varKeys != null) {

            // console.log(varKeys);

            varKeys.some(str => {
                // console.log(str);
                // const extract = /\$\{(.*?)\}/;
                const key = str.match(DataUtil.VALUE_REG_EX_INNER);
                // console.log(key);
                assert(key != null, 'keyはnullであってはならない。');
                const props = key[1].split('.');
                const prefix = props[0];
                const addr = props.slice(1, props.length);
                const varId = addr[0];

                const getTargetObjects = () => {
                    switch (prefix) {
                        case PrefixUtil.STATE: return buildProps.globalStates;
                        case PrefixUtil.CACHE: return buildProps.dynamicProps.caches;
                        case PrefixUtil.LAUNCH_ARGUMENT: return buildProps.globalFixedProps.launchArgs;
                        case PrefixUtil.TEMP: return buildProps.dynamicProps.temps;
                        case PrefixUtil.ARGUMENT: return buildProps.dynamicProps.funcargs;
                        case PrefixUtil.PRPFLD: return buildProps.dynamicProps.prpflds;
                        case PrefixUtil.ITERATE: return buildProps.dynamicProps.iteraters;
                        case PrefixUtil.SYSTEM: return (name: SystemFunctionUtil.DefName) => SystemFunctionUtil.getMappedFuncValue(name, buildProps);
                    }
                    throw new Error(`prefixが何にも該当しない[${prefix}]`);
                }
                const targetObjects = getTargetObjects();

                switch (prefix) {
                    case PrefixUtil.STATE:
                    case PrefixUtil.CACHE:
                    case PrefixUtil.LAUNCH_ARGUMENT:
                    case PrefixUtil.TEMP:
                    case PrefixUtil.ARGUMENT:
                    case PrefixUtil.PRPFLD: {
                        const obj = (targetObjects as FieldObject[]).find(o => o.field.id === varId);
                        if (obj == undefined) return str;
                        let isError = false;
                        let value = (obj as FieldObject).value;
                        if (addr.length >= 2) {
                            addr.slice(1, addr.length).some(name => {
                                value = value[name];
                                if (value == undefined) {
                                    isError = true;
                                    return 1;
                                }
                            });
                        }
                        if (!isError) {
                            if (typeof value === 'object') {
                                let jsonStr = JSON.stringify(value);
                                jsonStr = jsonStr.replaceAll('\\t', ' ');
                                // const a = JSON.parse(`[{"a": "\taadfas"}]`);
                                // console.log(a);
                                // console.log(jsonStr);
                                formula = formula.replace(str, `JSON.parse(\`${jsonStr}\`)`);
                            } else {
                                // formula = formula.replace(str, (value as string).replace('\n', ','));
                                // formula = value;
                                formula = formula.replace(str, `${value}`);
                            }
                            // try {
                            //     const jsonStr = JSON.stringify(value);
                            //     JSON.parse(jsonStr);
                            //     // console.log(jsonStr);
                            //     formula = formula.replace(str, `(JSON.parse('${jsonStr}'))`);
                            // } catch(e) {
                            //     // console.log(e);
                            //     formula = formula.replace(str, `'${value}'`);
                            // }
                        }
                    } break;
                    case PrefixUtil.ITERATE: {
                        const obj = (targetObjects as IterateProps[]).find(o => o.id === varId);
                        if (obj == undefined) return str;
                        if (addr.length === 2) {
                            const listPropName = addr[1];
                            switch (listPropName) {
                                case PrefixUtil.LIST_LEN: {
                                    formula = formula.replace(str, obj.max.toString());
                                    // console.log(`[${str}]を[${obj.max.toString()}]に置換しました。`);
                                } break;
                                case PrefixUtil.LIST_CUR: {
                                    formula = formula.replace(str, obj.self.toString());
                                    // console.log(`[${str}]を[${obj.self.toString()}]に置換しました。`);
                                } break;
                            }
                        }
                    } break;
                    case PrefixUtil.SYSTEM: {
                        const obj = targetObjects as ((name: SystemFunctionUtil.DefName) => string);
                        formula = obj(varId as SystemFunctionUtil.DefName);
                    } break;
                }
            });
        }
        // console.log(formula);
        return DataUtil.applyFormula(formula);
    }

    export const replaceKeySets = (base: string, buildProps: BuildProps): string => {
        return replaceVariableKeys(base, buildProps);

        // const dynamicProps = buildProps.dynamicProps;
        // let str = base;
        // const sets = buildReplaceProps(buildProps);
        // // console.log(sets);
        // sets.strKeySets.forEach(ks => {
        //     // str = str.replaceAll(ks.key, `'${ks.value}'`);
        //     str = str.replaceAll(ks.key, ks.value);
        // });
        // sets.numKeySets.forEach(ks => {
        //     str = str.replaceAll(ks.key, ks.value);
        // });
        // // console.log(`itr: ${dynamicProps.iteraters.length}`);
        // dynamicProps.iteraters.forEach(itr => {
        //     {
        //         const key = `\${${PrefixUtil.ITERATE}.${itr.id}.${PrefixUtil.LIST_LEN}}`;
        //         if (str.indexOf(key) !== -1) {
        //             str = str.replaceAll(key, itr.max.toString());
        //         }
        //     }
        //     {
        //         const key = `\${${PrefixUtil.ITERATE}.${itr.id}.${PrefixUtil.LIST_CUR}}`;
        //         // console.log(`key: ${key}, str: ${str}`);
        //         if (str.indexOf(key) !== -1) {
        //             str = str.replaceAll(key, itr.self.toString());
        //         }
        //     }
        // });
        // return str;
    }

    export const replaceVariableKeys = (base: string, buildProps: BuildProps): string => {
        // const regEx = /\$\{.*?\}/g;
        const varKeys = base.match(DataUtil.VALUE_REG_EX);

        if (varKeys == null) return base;
        // console.log(varKeys);
        let ret = base;
        varKeys.some(str => {
            // console.log(str);
            // const extract = /\$\{(.*?)\}/;
            const key = str.match(DataUtil.VALUE_REG_EX_INNER);
            // console.log(key);
            assert(key != null, 'keyはnullであってはならない。');
            const props = key[1].split('.');
            const prefix = props[0];
            const addr = props.slice(1, props.length);
            const varId = addr[0];

            const getTargetObjects = () => {
                switch (prefix) {
                    case PrefixUtil.STATE: return buildProps.globalStates;
                    case PrefixUtil.CACHE: return buildProps.dynamicProps.caches;
                    case PrefixUtil.LAUNCH_ARGUMENT: return buildProps.globalFixedProps.launchArgs;
                    case PrefixUtil.TEMP: return buildProps.dynamicProps.temps;
                    case PrefixUtil.ARGUMENT: return buildProps.dynamicProps.funcargs;
                    case PrefixUtil.PRPFLD: return buildProps.dynamicProps.prpflds;
                    case PrefixUtil.ITERATE: return buildProps.dynamicProps.iteraters;
                    case PrefixUtil.SYSTEM: return SystemFunctionUtil.getMappedFuncValue;
                }
                throw new Error(`prefixが何にも該当しない[${prefix}]`);
            }
            const targetObjects = getTargetObjects();

            switch (prefix) {
                case PrefixUtil.STATE:
                case PrefixUtil.CACHE:
                case PrefixUtil.LAUNCH_ARGUMENT:
                case PrefixUtil.TEMP:
                case PrefixUtil.ARGUMENT:
                case PrefixUtil.PRPFLD: {
                    const obj = (targetObjects as FieldObject[]).find(o => o.field.id === varId);
                    if (obj == undefined) return str;
                    let isError = false;
                    let value = (obj as FieldObject).value;
                    if (addr.length >= 2) {
                        addr.slice(1, addr.length).some(name => {
                            value = value[name];
                            if (value == undefined) {
                                isError = true;
                                return 1;
                            }
                        });
                    }
                    if (!isError) {
                        ret = ret.replace(str, value);
                        // if (str.indexOf('t.v') !== -1) {
                        //     console.log(`[${str}]を[${value}]に置換しました。ret: [${ret}]`);
                        // }
                    }
                    // if (str === '\${s.focus}') {
                    //     console.log(`[${str}] error: ${isError}, ret: [${ret}]`);
                    // }
                } break;
                case PrefixUtil.ITERATE: {
                    const obj = (targetObjects as IterateProps[]).find(o => o.id === varId);
                    if (obj == undefined) return str;
                    if (addr.length === 2) {
                        const listPropName = addr[1];
                        switch (listPropName) {
                            case PrefixUtil.LIST_LEN: {
                                ret = ret.replace(str, obj.max.toString());
                                // console.log(`[${str}]を[${obj.max.toString()}]に置換しました。`);
                            } break;
                            case PrefixUtil.LIST_CUR: {
                                ret = ret.replace(str, obj.self.toString());
                                // console.log(`[${str}]を[${obj.self.toString()}]に置換しました。`);
                            } break;
                        }
                    }
                } break;
                case PrefixUtil.SYSTEM: {
                    const obj = targetObjects as ((name: SystemFunctionUtil.DefName) => string);
                    ret = obj(varId as SystemFunctionUtil.DefName);
                } break;
            }
        });
        // if (varKeys.length > 0 && varKeys[0].indexOf('c.insValuesStr') !== -1) {
        //     console.log(ret);
        //     console.log(varKeys);
        // }
        return ret;
    }

    export type KeyFieldObject = {
        key: string;
        fieldObject: FieldObject;
    }
    export const buildKeyFieldObject = (buildProps: BuildProps): KeyFieldObject[] => {
        const globalStates = buildProps.globalStates;
        const dynamicProps = buildProps.dynamicProps;
        const models = buildProps.globalFixedProps.dtypes;

        const keyFieldObject: KeyFieldObject[] = [];

        const buildRec = (prefix: string, keys: string[], field: ModelUtil.NodeModelField, obj: any) => {

            const addFieldObject = () => {
                const key = `${prefix}.${keys.join('.')}`;
                const fieldObject: FieldObject = { field, value: obj };
                keyFieldObject.push({ key, fieldObject: fieldObject });
            }
            // console.log(obj);
            // if (field.array === 0 && field.dataType !== 'struct') {
            if (NodeField.isPremitive(field)) {
                addFieldObject();
            } else {
                if (field.array >= 1) {
                    // 配列のオブジェクトを追加
                    addFieldObject();

                    // 配列の長さを追加
                    const lenKey = `\${${prefix}.${keys.concat(PrefixUtil.LIST_LEN).join('.')}}`;
                    const lenFieldObject: FieldObject = {
                        field: {
                            id: '', dataType: 'number', array: 0
                        }, value: obj.length
                    };
                    keyFieldObject.push({ key: lenKey, fieldObject: lenFieldObject });
                } else if (field.dataType === 'struct') {
                    // モデルのオブジェクトを追加
                    addFieldObject();

                    const model = models.find(m => m.id === field.structId);
                    assert(model != undefined, 'model is undefined.');
                    model.fields.forEach(f => {
                        // const jsonObj = JSON.parse(obj);
                        const jsonObj = obj;
                        let nextObj = jsonObj[`${f.id}`];
                        // if (f.dataType === 'model') {
                        //     nextObj = JSON.stringify(nextObj);
                        // }
                        buildRec(prefix, keys.concat(f.id), f, nextObj);
                    });
                }
            }
        }
        globalStates.forEach(state => {
            buildRec(PrefixUtil.STATE, [state.field.id], state.field, state.value);
        });
        dynamicProps.caches.forEach(cache => {
            buildRec(PrefixUtil.CACHE, [cache.field.id], cache.field, cache.value);
        });
        dynamicProps.funcargs.forEach(arg => {
            buildRec(PrefixUtil.ARGUMENT, [arg.field.id], arg.field, arg.value);
        });
        dynamicProps.prpflds.forEach(prparg => {
            buildRec(PrefixUtil.PRPFLD, [prparg.field.id], prparg.field, prparg.value);
        });
        return keyFieldObject;
    }

    export const getFieldObject = (key: string, buildProps: BuildProps): FieldObject => {
        const keyFieldObject = buildKeyFieldObject(buildProps);
        const fieldObject = keyFieldObject.find(k => k.key === key);
        assert(fieldObject != undefined,
            AssertUtil.errorFindFromArray('fieldObject', key,
                keyFieldObject.map(k => k.key)
            )
        );
        return fieldObject.fieldObject;
    }

    export type EventArgument = {
        field: ModelUtil.NodeModelField;
        value: string;
    }

    export const getChoosedValue = (chooser: VariableChooser.Chooser, buildProps: BuildProps): any => {
        const globalStates = buildProps.globalStates;
        const caches = buildProps.dynamicProps.caches;
        const prpflds = buildProps.dynamicProps.prpflds;
        const funcargs = buildProps.dynamicProps.funcargs;
        const models = buildProps.globalFixedProps.dtypes;
        const dynamicProps = buildProps.dynamicProps;

        const getTargets = () => {
            switch (chooser.target) {
                case 'state': return globalStates;
                case 'cache': return caches;
                case 'comparg': return prpflds;
                case 'funcarg': return funcargs;
            }
        }
        const replaceKeySets = (base: string) => {
            return ReaderUtil.replaceKeySets(base, buildProps);
        }
        const root = getTargets().find(t => t.field.id === chooser.rootId);
        assert(root != null, 'root is null.');

        /** 書き換えするためディープコピー */
        let curField = JSON.parse(JSON.stringify(root.field)) as ModelUtil.NodeModelField;
        let curValue = root.value;

        chooser.levelProps.forEach((l, i) => {
            if (curField.array >= 1) {
                let index = l;
                index = replaceKeySets(index);
                // 式の適用
                index = DataUtil.applyFormula(index);
                assert(!isNaN(Number(index)), `${l}を数値に変換できない。[${index}], buildProps: ${buildProps.dynamicProps.funcargs.map(a => a.field.id)}`);
                curValue = curValue[index];
                curField.array--;
            } else if (curField.dataType === 'struct') {
                const model = models.find(m => m.id === curField.structId);
                assert(model != undefined, 'model is undefined.');
                const nextField = model.fields.find(f => f.id === l);
                assert(nextField != undefined, 'nextField is undefined.');
                curValue = curValue[l];
                curField = JSON.parse(JSON.stringify(nextField));
            }
        });
        return curValue;
    }

    export const getChoosedFocusUtil = (
        focusData: NodeFocus.Data,
        buildProps: BuildProps
    ): {
        executeAssign: (assignVal: any) => void,
        getFocusObject: () => any,
        getFocusFieldType: () => ModelUtil.NodeModelField
    } => {
        const globalStates = buildProps.globalStates;
        const caches = buildProps.dynamicProps.caches;
        const prpflds = buildProps.dynamicProps.prpflds;
        const funcargs = buildProps.dynamicProps.funcargs;
        const models = buildProps.globalFixedProps.dtypes;
        const dynamicProps = buildProps.dynamicProps;

        const replaceKeySets = (base: string) => {
            return ReaderUtil.replaceKeySets(base, buildProps);
        }

        const getTargets = () => {
            switch (focusData.target) {
                case 'state': return globalStates;
                case 'cache': return caches;
                case 'comparg': return prpflds;
                case 'funcarg': return funcargs;
            }
        }
        const root = getTargets().find(t => t.field.id === focusData.rootId);
        assert(root != null, 'root is null.');

        /** 書き換えするためディープコピー */
        let curField = JSON.parse(JSON.stringify(root.field)) as ModelUtil.NodeModelField;
        // const isRootObject = ModelUtil.isObjectField(curField);
        // const valueObject = root.value;
        let curValue = root.value;

        let executeAssign: null | ((assignVal: any) => void) = null;
        let getFocusObject: null | (() => any) = null;
        let getFocusFieldType: null | (() => ModelUtil.NodeModelField) = null;
        // console.log(focusData.levelProps.length);
        focusData.levelProps.some((l, i) => {
            const isTail = i === focusData.levelProps.length - 1;
            if (curField.array >= 1) {
                let index = l;
                index = replaceKeySets(index);
                // 式の適用
                index = DataUtil.applyFormula(index);
                if (isTail) {
                    const assignRef = curValue;
                    executeAssign = (assignVal: any) => {
                        // console.log(assignRef);
                        assignRef[Number(index)] = assignVal;
                        // (curValue as any[]).splice(Number(index), 1, assignVal);
                        // console.log(curValue);
                        // console.log(assignVal);
                    };
                }
                curValue = curValue[Number(index)];
                curField.array--;
            } else if (curField.dataType === 'struct') {
                const model = models.find(m => m.id === curField.structId);
                assert(model != undefined, 'model is undefined.');
                const nextField = model.fields.find(f => f.id === l);
                assert(nextField != undefined, 'nextField is undefined.');

                if (isTail) {
                    const assignRef = curValue;
                    executeAssign = (assignVal: any) => {
                        // console.log(assignRef);
                        assignRef[l] = assignVal;
                    };
                }
                curValue = curValue[l];
                curField = JSON.parse(JSON.stringify(nextField));
            }
            if (executeAssign != null) return true;
        });
        // ルート要素がターゲットの場合
        if (executeAssign == null) {
            executeAssign = (assignVal: any) => {
                root.value = assignVal;
            }
        }
        getFocusObject = () => {
            return curValue;
        }
        getFocusFieldType = () => {
            return curField;
        }
        return { executeAssign, getFocusObject, getFocusFieldType }
    }

    /**
     * assign要素の更新処理を実行する
     * @param assign 要素プロパティ
     * @param buildProps ビルド用のキーセット群
     * @param eventArguments イベントの引数（イベントのプロシージャでのみ利用）
     */
    export const executeAssign = (assign: ModelUtil.NodeAssignBak, buildProps: BuildProps, eventArguments: EventArgument[]) => {
        const globalStates = buildProps.globalStates;
        const caches = buildProps.dynamicProps.caches;
        const prpflds = buildProps.dynamicProps.prpflds;
        const funcargs = buildProps.dynamicProps.funcargs;
        const models = buildProps.globalFixedProps.dtypes;
        const dynamicProps = buildProps.dynamicProps;

        dynamicProps.funcargs = eventArguments;
        const replaceKeySets = (base: string) => {
            return ReaderUtil.replaceKeySets(base, buildProps);
        }

        const dest = caches.find(c => c.field.id === assign.dest);
        assert(dest != undefined, 'accepter is undefined.');

        const getTargets = () => {
            switch (assign.target) {
                case 'state': return globalStates;
                case 'cache': return caches;
                case 'comparg': return prpflds;
                case 'funcarg': return funcargs;
            }
        }
        const root = getTargets().find(t => t.field.id === assign.rootId);
        assert(root != null, 'root is null.');

        const variables: ModelUtil.Variables = {
            models,
            caches: dynamicProps.caches.map(c => c.field),
            propFields: dynamicProps.prpflds.map(pf => pf.field),
            states: buildProps.globalFixedProps.states
        };

        const chooser: VariableChooser.Chooser = {
            ...assign,
            levelProps: assign.levelProps.map(l => {
                let index = l;
                index = replaceKeySets(index);
                // 式の適用
                index = DataUtil.applyFormula(index);
                return index;
            })
        }
        let assignValue = ModelElementUtil.getChoosedValue(root.value, chooser, variables);
        if (assign.cloneType != undefined && assign.cloneType === 'deep') {
            assignValue = JSON.parse(JSON.stringify(assignValue));
        }
        dest.value = assignValue;
    }


    export const isTrue = (base: string, buildProps: ReaderUtil.BuildProps) => {

        // const replaceKeySets = (base: string) => {
        //     return ReaderUtil.replaceKeySets(base, buildProps);
        // }
        // // 変数の置換
        let condition = base;
        // condition = replaceKeySets(condition);
        const [formula, args] = getArgsFormatFromFormula(base, buildProps);

        // 式の適用
        // condition = DataUtil.applyFormula(condition);
        condition = DataUtil.applyFormulaArgs(args, formula);
        // console.log(`base: [${base}], condition: [${condition}], formula: [${formula}], args: [${args.join(',')}]`);

        // assert(['true', 'false'].includes(condition), `condition is invalid.[${condition}]`);
        return Boolean(condition);
    }

    export const isComponent = (tracer: Tracer, upperNum: number) => {
        let cur = tracer.cur;
        for (let i = 0; i < upperNum; i++) {
            assert(tracer.parent != null, 'tracer.parent is null.');
            cur = tracer.parent.cur;
        }
        assert(cur != null, 'cur is null.');
        const type = cur.type;
        const compTypes: ModelUtil.NodeType[] = ['elements', 'div'];
        const retentionTypes: ModelUtil.NodeType[] = ['retention', 'proc'];
        if (compTypes.includes(type)) return true;
        else if (retentionTypes.includes(type)) return false;
        else throw new Error('想定していないtypeです。');
    }

    export const getAppliedStyleSources = (refers: StyleChooser.Refer[], buildProps: ReaderUtil.BuildProps) => {
        return refers.map(refer => {
            // console.log(`style: ${refer.refId}, accept: ${refer.accept}`);
            // 適用条件が設定されている場合
            if (refer.accept != undefined) {
                let fml = refer.accept;
                // fml = replaceKeySets(fml, buildProps);
                // 条件を満たしていない場合、空文字を返す。
                if (!isTrue(fml, buildProps)) return '';
                // const accept = DataUtil.applyFormula(fml);
                // console.log(`refer: ${refer.refId}, accept:[${fml}](${accept})`);
                // try {
                //     // 条件を満たしていない場合、空文字を返す。
                //     if (!Boolean(accept)) return '';
                // } catch {
                //     throw new Error(`accept:[${accept}]は論理式ではない。`)
                // }
            }
            // スタイルの取得
            const style = buildProps.dynamicProps.styles.find(d => d.id === refer.refId);
            assert(
                style != undefined,
                AssertUtil.errorFindFromArray('style', refer.refId, buildProps.dynamicProps.styles.map(s => s.id))
            );
            let src = style.src;
            // ベースのソースにスタイル引数を適用
            // console.log(`id: ${style.id} [${style.args.map(a => a.id)}]`);
            const fmls = JSON.parse(JSON.stringify(style.fmls)) as StyleFormulaDefiner.Record[];
            style.args.forEach(arg => {
                const argValue = refer.args.find(a => a.key === arg.id);
                if (argValue == undefined) return 1;
                // assert(argValue != undefined, `argValue is undefined. find:${arg.id} from list:[${refer.args.map(a => a.key)}]`);
                let val = argValue.val ?? arg.defVal;
                // 初期値が設定されていない場合、引数が必須入力となるため、undefinedにはなりえない
                assert(val != undefined, '初期値が設定されていない場合、引数が必須入力となるため、valがundefinedにはなりえない。');
                val = replaceKeySets(val, buildProps);
                // // 式の適用
                // val = DataUtil.applyFormula(val);
                // スタイル引数を個別に適用
                src = src.replaceAll(`__${PrefixUtil.STYLE_ARGUMENT}.${arg.id}__`, val);
                fmls.forEach(fml => {
                    // fml.fml = fml.fml.replaceAll(`\${${PrefixUtil.STYLE_ARGUMENT}.${arg.id}}`, val as string);
                    fml.fml = fml.fml.replaceAll(`__${PrefixUtil.STYLE_ARGUMENT}.${arg.id}__`, val as string);
                });
            });
            fmls.forEach(fml => {
                let val = fml.fml;
                // 式の適用
                val = DataUtil.applyFormula(val);
                // src = src.replaceAll(`\${${PrefixUtil.STYLE_FOUMULA}.${fml.id}}`, val);
                // console.log(src);
                // console.log(`__${PrefixUtil.STYLE_FOUMULA}.${fml.id}__`);
                src = src.replaceAll(`__${PrefixUtil.STYLE_FOUMULA}.${fml.id}__`, val);
                // console.log(src);
            });
            // src = replaceKeySets(src, buildProps);
            return src;
        });
    }

    /**
     * 関数の定義から、実行可能な関数を生成して返す。
     * @param props 
     * @returns 
     */
    export const generateCallbackFromDispatcher = (props: {
        buildProps: ReaderUtil.BuildProps,
        dispatch: TagUtil.Dispatch
    }) => {
        return () => {
            // console.log(props.listener.dsptId);
            const dispatchKey = props.dispatch.dsptId;
            const prefix = dispatchKey.split('.')[0];
            const eventKey = dispatchKey.split('.')[1];
            // console.log(props.buildProps.dynamicProps);
            const getTargetList = () => {
                switch (prefix) {
                    case PrefixUtil.DISPATCH: return props.buildProps.dynamicProps.dispatchers;
                    case PrefixUtil.PRPCLBK: return props.buildProps.dynamicProps.prpclbks;
                }
                throw new Error(`prefixの値が不正。(prefix: '${prefix}')`);
            }
            const targetList = getTargetList();
            const dispatch = targetList.find(d => d.id === eventKey);
            assert(dispatch != undefined, `dispatchが見つからない。${eventKey}: [${targetList.map(d => d.id).join(', ')}]`);

            // const caches = props.buildProps.dynamicProps.caches;
            // const buildInitialStructureObject = props.buildProps.globalFixedProps.buildInitialStructureObject;

            // 引数の登録
            const funcargs = props.buildProps.dynamicProps.funcargs;
            funcargs.length = 0;
            dispatch.funcargs.forEach((arg, i) => {
                const argVal = props.dispatch.args[i];
                funcargs.push({
                    field: arg,
                    value: argVal
                });
            });
            // console.log(`${dispatch.id}: args[${funcargs.length}]`);
            return dispatch.callback();
        }
    }

    export const getCallbackObjectArgs = (props: {
        buildProps: ReaderUtil.BuildProps,
        dispatch: EventObject,
    }) => {
        return (...args: any[]) => {
            const dispatch = props.dispatch;
            // 引数の登録
            const funcargs = props.buildProps.dynamicProps.funcargs;
            funcargs.length = 0;
            dispatch.funcargs.forEach((arg, i) => {
                const argVal = args[i];
                funcargs.push({
                    field: arg,
                    value: argVal
                });
            });
            return dispatch.callback();
        }
    }

    export const affectOperation = (props: {
        data: NodeFocus.Data,
        buildProps: BuildProps
    }) => {
        const focusData = props.data;
        const buildProps = props.buildProps;
        const replaceKeySets = (base: string) => {
            return ReaderUtil.replaceKeySets(base, buildProps);
        }

        const affectWrap = focusData.items.length === 0 ? null : focusData.items[0] as ModelUtil.WrapElement;
        if (affectWrap != null) {
            const focusUtil = getChoosedFocusUtil(focusData, buildProps);
            const fieldType = focusUtil.getFocusFieldType();
            switch (affectWrap.type) {
                case 'assign': {
                    const affectData = affectWrap.data as NodeAssign.Data;
                    let assignVal = null;
                    switch (affectData.assignType) {
                        case 'pick-out': {
                            const objVal = affectData.objVal;
                            assert(objVal != undefined, 'objVal is undefined.');
                            assignVal = ReaderUtil.getChoosedValue(objVal, buildProps);
                            if (objVal.cloneType === 'deep') {
                                assignVal = JSON.parse(JSON.stringify(assignVal));
                            }
                            // console.log(assignVal);
                        } break;
                        case 'formula': {
                            const fmlVal = affectData.fmlVal;
                            assert(fmlVal != undefined, 'fmlVal is undefined.');
                            assignVal = replaceKeySets(fmlVal);
                            // // console.log(assignVal);
                            if (['number', 'boolean'].includes(fieldType.dataType)) {
                                assignVal = DataUtil.applyFormula(assignVal);
                                // console.log(assignVal);
                            }
                            // assignVal = getFomulaAplliedValue(fmlVal, buildProps);
                            // const [formula, args] = getArgsFormatFromFormula(fmlVal, buildProps);
                            // assignVal = DataUtil.applyFormulaArgs(args, formula);
                            // console.log(`base: [${fmlVal}], after: [${assignVal}]], formula: [${formula}], args: [${args.join(',')}]`);
                        } break;
                        case 'initial': {
                            if (ModelUtil.isObjectField(fieldType)) {
                                const fieldObject = buildInitialStructureObject(fieldType, buildProps.globalFixedProps.dtypes);
                                assignVal = fieldObject.value;
                            } else {
                                assignVal = getDefaultValue(fieldType.dataType);
                            }
                            // console.log(assignVal);
                        }
                    }
                    // if (affectData.directVal == undefined && affectData.objVal != undefined) {
                    //     const objVal = affectData.objVal;
                    //     assert(objVal != undefined, 'objVal is undefined.');
                    //     assignVal = ReaderUtil.getChoosedValue(objVal, buildProps);
                    //     if (objVal.cloneType === 'deep') {
                    //         assignVal = JSON.parse(JSON.stringify(assignVal));
                    //     }
                    //     // console.log(assignVal);
                    // } else if(affectData.directVal != undefined && affectData.objVal == undefined) {
                    //     const directVal = affectData.directVal;
                    //     assert(directVal != undefined, 'directVal is undefined.');
                    //     assignVal = replaceKeySets(directVal);
                    //     // console.log(assignVal);
                    //     assignVal = DataUtil.applyFormula(assignVal);
                    //     // console.log(assignVal);
                    // } else throw new Error('affectDataが不整合。');
                    focusUtil.executeAssign(assignVal);
                } break;
                case 'asgnfml': {
                    const data = affectWrap.data as NodeAssignFormula.Data;
                    // const obj = getFormulaObject(data.fml, buildProps);
                    const [formula, args] = getArgsFormatFromFormula(data.fml, buildProps);
                    const obj = DataUtil.applyFormulaArgs(args, formula);
                    // console.log(obj);
                    focusUtil.executeAssign(obj);
                } break;
                case 'asnmtch': {
                    const data = affectWrap.data as NodeAssignMatch.Data;
                    let assignVal = null;
                    const targetList = ReaderUtil.getChoosedValue(data, buildProps) as any[];

                    let right = replaceKeySets(`\${${data.right}}`);
                    right = DataUtil.applyFormula(right);
                    const findVal = targetList.find(t => {
                        let left = t;
                        data.left.split('.').forEach(prp => {
                            left = t[prp];
                        });
                        return left === right;
                    });
                    if (findVal != undefined) {
                        assignVal = JSON.parse(JSON.stringify(findVal));
                        focusUtil.executeAssign(assignVal);
                    }
                } break;
                case 'mtchidx': {
                    const data = affectWrap.data as NodeAssignMatch.Data;
                    const targetList = ReaderUtil.getChoosedValue(data, buildProps) as any[];

                    let right = replaceKeySets(data.right);
                    right = DataUtil.applyFormula(right);
                    const index = targetList.findIndex(t => {
                        let left = t;
                        data.left.split('.').forEach(prp => {
                            left = t[prp];
                        });
                        // console.log(`${left} === ${right}`);
                        return left === right;
                    });
                    focusUtil.executeAssign(index);
                } break;
                case 'asgnnam': {
                    const globalStates = buildProps.globalStates;
                    const caches = buildProps.dynamicProps.caches;
                    const prpflds = buildProps.dynamicProps.prpflds;
                    const funcargs = buildProps.dynamicProps.funcargs;

                    const data = affectWrap.data as NodeAsgnNam.Data;
                    let assignVal = null;

                    const getTargets = () => {
                        switch (data.target) {
                            case 'state': return globalStates;
                            case 'cache': return caches;
                            case 'comparg': return prpflds;
                            case 'funcarg': return funcargs;
                        }
                    }
                    const replaceKeySets = (base: string) => {
                        return ReaderUtil.replaceKeySets(base, buildProps);
                    }
                    const root = getTargets().find(t => t.field.id === data.rootId);
                    assert(root != null, 'root is null.');

                    const prpName = replaceKeySets(`\${${data.prpName}}`);
                    assignVal = root.value[prpName];
                    // console.log(assignVal);
                    focusUtil.executeAssign(assignVal);
                } break;
                case 'arradd': {
                    const affectData = affectWrap.data as NodeArrayAdd.Data;
                    let assignVal = null;
                    switch (affectData.assignType) {
                        case 'pick-out': {
                            const objVal = affectData.objVal;
                            assert(objVal != undefined, 'objVal is undefined.');
                            assignVal = ReaderUtil.getChoosedValue(objVal, buildProps);
                            if (objVal.cloneType === 'deep') {
                                assignVal = JSON.parse(JSON.stringify(assignVal));
                            }
                        } break;
                        case 'formula': {
                            const fmlVal = affectData.fmlVal;
                            assert(fmlVal != undefined, 'fmlVal is undefined.');
                            assignVal = replaceKeySets(fmlVal);
                        }
                    }
                    // if(affectData.directVal == undefined && affectData.objVal != undefined) {
                    //     const objVal = affectData.objVal;
                    //     assert(objVal != undefined, 'objVal is undefined.');
                    //     assignVal = ReaderUtil.getChoosedValue(objVal, buildProps);
                    //     if (objVal.cloneType === 'deep') {
                    //         assignVal = JSON.parse(JSON.stringify(assignVal));
                    //     }
                    // } else if(affectData.directVal != undefined && affectData.objVal == undefined) {
                    //     const directVal = affectData.directVal;
                    //     assert(directVal != undefined, 'directVal is undefined.');
                    //     assignVal = replaceKeySets(directVal);
                    // } else throw new Error('affectDataが不整合。');
                    const targetArray = focusUtil.getFocusObject() as any[];
                    if (affectData.index != undefined) {
                        const index = Number(replaceKeySets(affectData.index));
                        const insertedArray = targetArray.splice(index, 0, assignVal);
                        focusUtil.executeAssign(insertedArray);
                    } else {
                        targetArray.push(assignVal);
                    }
                } break;
                case 'arrcat': {
                    const affectData = affectWrap.data as NodeArrayCat.Data;
                    let assignVal = null;
                    const objVal = affectData.objVal;
                    assignVal = ReaderUtil.getChoosedValue(objVal, buildProps);
                    if (objVal.cloneType === 'deep') {
                        assignVal = JSON.parse(JSON.stringify(assignVal));
                    }
                    const targetArray = focusUtil.getFocusObject() as any[];
                    if (affectData.index != undefined) {
                        const index = Number(replaceKeySets(affectData.index));
                        const insertedArray = targetArray.splice(index, 0, assignVal);
                        focusUtil.executeAssign(insertedArray);
                    } else {
                        const cacheArray = JSON.parse(JSON.stringify(targetArray));
                        cacheArray.splice(cacheArray.length - 1, 0, ...assignVal);
                        // console.log(targetArray);
                        // console.log(assignVal);
                        // console.log(cacheArray);
                        focusUtil.executeAssign(cacheArray);
                    }
                } break;
                case 'arrdel': {
                    const affectData = affectWrap.data as NodeArrayDel.Data;
                    const targetArray = focusUtil.getFocusObject() as any[];

                    const index = Number(replaceKeySets(affectData.index));
                    const delCnt = Number(replaceKeySets(affectData.delCnt));
                    // console.log(`index: ${replaceKeySets(affectData.index)}, delCnt:${delCnt}`);
                    // const removedArray = targetArray.splice(index, delCnt);
                    targetArray.splice(index, delCnt);
                    focusUtil.executeAssign(targetArray);
                } break;
                case 'arreff': {
                    const data = affectWrap.data as NodeArrayEff.Data;
                    const targetArray = focusUtil.getFocusObject() as any[];

                    switch (data.method) {
                        case 'filter': {
                            const condition = data.condition;
                            assert(condition != undefined, 'conditionがundefinedであってはならない。');

                            // console.log(targetArray);
                            const filteredList = targetArray.filter(v => {
                                const tempBuildProps = { ...buildProps };
                                const tempDynamicProps = { ...tempBuildProps.dynamicProps };
                                const temps: FieldObject[] = [];
                                temps.push({
                                    field: { id: 'v', array: 0, dataType: fieldType.dataType, structId: fieldType.structId },
                                    value: v
                                });
                                // console.log(temps);
                                tempDynamicProps.temps = temps;
                                tempBuildProps.dynamicProps = tempDynamicProps;
                                const isAccept = ReaderUtil.isTrue(condition, tempBuildProps);
                                // console.log(isAccept);
                                return isAccept;
                            });
                            // console.log(filteredValue);
                            focusUtil.executeAssign(filteredList);
                        } break;
                        case 'sort': {
                            const sortData = data.sort;
                            assert(sortData != undefined, 'sortDataがundefinedであってはならない。');

                            // console.log(targetArray);
                            const sortedArr = targetArray.sort((a, b) => {
                                let ret = 0;
                                sortData.items.some(item => {
                                    if (item.asc === 0 && a[item.prpName] < b[item.prpName] ||
                                        item.asc === 1 && b[item.prpName] < a[item.prpName]
                                    ) {
                                        ret = -1;
                                        return 1;
                                    }
                                });
                                return ret;
                            });
                            console.log(sortedArr);
                            focusUtil.executeAssign(sortedArr);
                        } break;
                    }
                } break;
                default: throw new Error(`ありえない分岐。[${affectWrap.type}]`);
            }
        }
    }

    export const getStyleObjFromData = (data: NodeStyle.Data, buildProps: BuildProps): ReaderUtil.StyleObject => {

        const getArgDatas = (refer: StyleChooser.Refer) => {
            const items = buildProps.dynamicProps.styles;
            const list: NodeStlarg.Data[] = [];
            const style = items.find(it => it.id === refer.refId);
            assert(style != undefined, AssertUtil.errorFindFromArray('style', refer.refId, items.map(i => i.id)));

            style.args.forEach((arg) => {
                // キーが存在しなければ未定義（abstract）なので引数を追加
                if (refer.args.find(a => a.key === arg.id) == undefined) {
                    list.push(arg);
                }
            });
            return list;
        }
        const args = data.inherits.reduce((prev, inh) => {
            return prev.concat(getArgDatas(inh));
        }, [] as NodeStlarg.Data[])
            .concat(
                data.args.map(a => a.data as NodeStlarg.Data)
            );
        const src = ReaderUtil.getAppliedStyleSources(data.inherits, buildProps).join('\n')
            + data.defs.map(def => {
                // console.log(def);
                if (def.val != undefined) {
                    return `${def.prop}: ${def.val};`;
                } else if (def.defs != undefined) {
                    const defs = def.defs.map(d => {
                        return `${d.prop}: ${d.val};`;
                    });
                    return `${def.prop} {\n${defs}\n}`;
                } else throw new Error('valかdefsのどちらかが設定されている必要がある。');
            }).join('\n');
        return {
            id: data.id,
            src,
            fmls: data.fmls,
            args
        };
    }

    export const buildListItems = (listProps: NodeTagInput.ListProps, buildProps: BuildProps): string[][] => {
        const varAddrArr = listProps.listVar.split('.');
        const prefix = varAddrArr[0];
        const rootId = varAddrArr[1];
        let levelProps: string[] = [];
        if (levelProps.length > 3) {
            levelProps = varAddrArr.slice(2, varAddrArr.length - 1);
        }
        const chooser: VariableChooser.Chooser = {
            target: getVariableTargetFromPrefix(prefix),
            rootId,
            levelProps
        };
        const chooseValue = getChoosedValue(chooser, buildProps);
        // console.log(chooseValue);
        if (listProps.converter == undefined) {
            return (chooseValue as string[]).map(v => [v]);
        }
        return [];
    }

    const getVariableTargetFromPrefix = (prefix: string): VariableChooser.RootTargetType => {
        switch (prefix) {
            case PrefixUtil.STATE: return 'state';
            case PrefixUtil.CACHE: return 'cache';
            case PrefixUtil.PRPFLD: return 'comparg';
        }
        throw new Error(`prefix:[${prefix}]が不正。`);
    }
}

export default ReaderUtil;