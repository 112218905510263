import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelEditDialog from "../../modelEditDialog";
import ModelElementUtil from "../../util/modelElementUtil";
import assert from "assert";
import RefactoringUtil from "../../util/refactoringUtil";
import NodeDtype from "../nodeDtype";
import NodeCompDef from "../nodeCompDef";

namespace NodePrpclbk {

    type LocalState = {
        id: FormUtil.CheckableValue;
    }
    export interface Data extends ModelUtil.ArgsData {
        id: string;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [localState, setLocalState] = useState<LocalState>({
            id: { value: '', errors: [] }
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.id];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.id.value = data.id;
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            let args: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                args = (props.temp.data as Data).args;
            }
            const data: Data = {
                id: localState.id.value,
                args
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Id"
                        jsx={<FormUtil.TextField
                            width={200}
                            checkable={localState.id}
                            setCheckable={(checkable) => {
                                localState.id = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 16),
                                    errorType: "length"
                                },
                                {
                                    checker: (value) => ModelUtil.checkCamelCase(value),
                                    errorType: "value"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'prpclbk';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }


        override createExtendAction(node: TreeUtil.ElementNode): void {
            const ownerWrap = node.parent?.parent?.data as ModelUtil.WrapElement;
            const compdefData = ownerWrap.data as NodeCompDef.Data;
            const projectRoot = ModelElementUtil.getProjectRootFromCurrent(node);
            // console.log(dtypeData);
            RefactoringUtil.syncCompdefAddProps({
                compdefId: compdefData.id,
                insertIndex: (node.parent?.children.length as number) - 1,
                projectRootNode: projectRoot,
                defaultValue: ''
            });
        }

        override deleteExtendAction(node: TreeUtil.ElementNode): void {
            const ownerWrap = node.parent?.parent?.data as ModelUtil.WrapElement;
            const compdefData = ownerWrap.data as NodeCompDef.Data;
            const projectRoot = ModelElementUtil.getProjectRootFromCurrent(node);
            // console.log(dtypeData);
            RefactoringUtil.syncCompdefRemoveProps({
                compdefId: compdefData.id,
                removeIndex: this.getCurIndex(node),
                projectRootNode: projectRoot,
            });
        }

        override orderExtendAction(node: TreeUtil.ElementNode, offset: number): void {
            const ownerWrap = node.parent?.parent?.data as ModelUtil.WrapElement;
            const compdefData = ownerWrap.data as NodeCompDef.Data;
            const projectRoot = ModelElementUtil.getProjectRootFromCurrent(node);
            // console.log(compdefData);
            RefactoringUtil.syncCompdefOrderProps({
                compdefId: compdefData.id,
                targetIndex: this.getCurIndex(node),
                projectRootNode: projectRoot,
                offset
            });
        }
    }
}

export default NodePrpclbk;
