import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../../abstractModelEditor";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ModelUtil from "../../../util/modelUtil";
import { GlobalContext } from "../../../../../entry/systemEntry";
import ModelEditDialog from "../../../modelEditDialog";
import ModelElementUtil from "../../../util/modelElementUtil";

namespace NodeReturn {

    type LocalState = {
        fml: FormUtil.CheckableValue;
    }

    export type Data = {
        fml: string;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            fml: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [dtypes, states, funcargs, caches, propFields] = useMemo(() => {

            const cur = manageItems.focusNode;

            const caches = ModelElementUtil.getRetentionCachesFromCurrent(cur)
                .concat(ModelElementUtil.getFetchThensFromCurrent(cur));
            // console.log(caches);
            const states = ModelElementUtil.getReferableStates(cur);
            const dtypes = ModelElementUtil.getReferableDtypes(cur);

            const isDispatchSub = () => ModelElementUtil.isDispatchSub(cur);
            const funcargs = !isDispatchSub() ? undefined : ModelElementUtil.getArgumentFromCurrent(cur);
            const propFields = ModelElementUtil.getPropFieldsFromCurrent(cur);


            return [dtypes, states, funcargs, caches, propFields];
        }, []);


        useEffect(() => {

            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.fml.value = data.fml;
            }
            invalidate();
        }, []);

        const targetFroms = [
            localState.fml,
        ];

        useEffect(() => {
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const data: Data = {
                fml: localState.fml.value,
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="formula"
                height={300}
                innerJsx={<>
                    <FormUtil.TextArea
                        checkable={localState.fml}
                        setCheckable={(checkable) => {
                            localState.fml = checkable;
                            invalidate();
                        }}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'return';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeReturn;
