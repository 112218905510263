namespace PrefixUtil {

    /** ステート*/
    export const STATE = 's';
    /** テンポラリ */
    export const TEMP = 't';
    /** キャッシュ */
    export const CACHE = 'c';
    /** ディスパッチ引数 */
    export const DISPATCH = 'd';
    /** ディスパッチ引数 */
    export const ARGUMENT = 'da';
    /** ランチャー引数 */
    export const LAUNCH_ARGUMENT = 'la';
    /** システム */
    export const SYSTEM = 'sy';
    /** スタイル引数 */
    export const STYLE_ARGUMENT = 'sa';
    export const STYLE_FOUMULA = 'sf';
    export const PRPFLD = 'pf';
    export const PRPCLBK = 'pc';
    export const ITERATE = 'it';
    export const LIST_LEN = 'len';
    export const LIST_CUR = 'cur';
    export const STR_LEN = 'len';
}

export default PrefixUtil;