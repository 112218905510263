import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelEditDialog from "../../modelEditDialog";
import ModelElementUtil from "../../util/modelElementUtil";
import assert from "assert";
import NodeWhen from "./nodeWhen";

namespace NodeCase {

    type LocalState = {
        method: FormUtil.CheckableValue;
        condition: FormUtil.CheckableValue;
        targetId: FormUtil.CheckableValue;
    }
    
    /** 条件タイプ */
    export const CaseTypes = ['bool', 'match', 'break', 'detail'] as const;
    export type CaseType = typeof CaseTypes[number];
    export type Data = {
        method: CaseType;
        targetId?: string;
        condition?: string;
        items: ModelUtil.WrapElement[];
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            method: { value: '', errors: [] },
            condition: { value: '', errors: [] },
            targetId: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.method, localState.condition, localState.targetId];

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        // const [daos, vars] = useMemo(() => {
        //     let cur = manageItems.focusNode;
        //     while (true) {
        //         const wrap = cur.data as ModelUtil.WrapElement;
        //         if (wrap.type === 'func') break;
        //         assert(cur.parent != null, 'cur.parent is null.');
        //         cur = cur.parent;
        //     }
        //     const wrap = cur.data as ModelUtil.WrapElement;
        //     const daosWrap = ModelElementUtil.getInnerWrapFixed(wrap, 'global', 'daos');
        //     const daos = (daosWrap.data as ModelUtil.NodeDAOs).daos.map(daoWrap => daoWrap.data as ModelUtil.NodeDAO);
        //     const varsWrap = ModelElementUtil.getInnerWrapFixed(wrap, 'global', 'variables');
        //     const vars = (varsWrap.data as ModelUtil.NodeVariables).items.map(varWrap => varWrap.data as ModelUtil.NodeVariable);
        //     return [daos, vars];
        // }, []);

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.method.value = data.method;
                if (data.method === 'bool') localState.condition.value = data.condition as string;
                else if (data.method === 'match' || caseType === 'break') localState.targetId.value as string;
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const method = localState.method.value as CaseType;

            let items: ModelUtil.WrapElement[] = [];
            if (props.temp.data != null) {
                items = (props.temp.data as Data).items;
            }
            let condition: string | undefined = undefined;
            if (method === 'bool') condition = localState.condition.value;
            if (method === 'match' || method === 'break') condition = localState.targetId.value;
            let targetId: string | undefined = undefined;
            const data: Data = {
                method,
                condition: condition,
                targetId,
                items
            }
            setTempData(data);
        }, targetFroms);

        const caseType = localState.method.value as CaseType;
        const useCondition = caseType === 'bool';
        const useTarget = caseType === 'match' || caseType === 'break';
        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Method"
                        jsx={<FormUtil.Combobox
                            width={200}
                            checkable={localState.method}
                            setCheckable={(checkable) => {
                                localState.method = checkable;
                                invalidate();
                            }}
                            extend={() => {
                                if (caseType !== 'detail') localState.condition.value = '';
                                if (caseType !== 'match' && caseType !== 'break') localState.targetId.value = '';
                            }}
                            headBlank
                            list={CaseTypes.map(type => {
                                return { value: type, labelText: type }
                            })}
                            validates={[
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Target Id"
                        isVisible={useTarget}
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.targetId}
                            setCheckable={(checkable) => {
                                localState.targetId = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={!useTarget ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 24),
                                    errorType: "length"
                                },
                                // {
                                //     checker: (value) => !existNameList.includes(value),
                                //     errorType: "relation"
                                // }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Condition"
                        isVisible={useCondition}
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.condition}
                            setCheckable={(checkable) => {
                                localState.condition = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={!useCondition ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                // {
                                //     checker: (value) => ValidateUtil.checkStringLength(value, 1, 64),
                                //     errorType: "length"
                                // },
                                // {
                                //     checker: (value) => !existNameList.includes(value),
                                //     errorType: "relation"
                                // }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'case';
        }

        override createAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            const element = node.data as ModelUtil.WrapElement;
            element.data = tempData;
            // const modelData = element.data as ModelUtil.NodeFunc;

            const caseData = element.data as Data;
            if (caseData.method === 'bool') {
                for (let i = 0; i < 2; i++) {
                    ModelElementUtil.addElementNodeDeep(node, {
                        type: 'when',
                        data: {
                            bool: i === 0,
                            elements: []
                        } as NodeWhen.Data
                    });
                }
            }
            manageItems.invalidate();
        }


        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeCase;
