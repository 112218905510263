import { useMemo, useState } from "react";
import FormUtil from "../../../../../../../common/component/form/formUtiil";
import ScopeManager from "../../../option/scopeManager";
import assert from "assert";

namespace TagUtil {

    export type ListenerMethod = 'click' | 'focus' | 'context' | 'change';
    export type Listener = {
        point?: string;
        click?: Dispatch;
        focus?: Dispatch;
        context?: Dispatch;
        change?: Dispatch;
    }
    export type Dispatch = {
        dsptId: string;
        args: string[];
    }
    export type ListenerForm = {
        dispatchId: FormUtil.CheckableValue;
        args: ListenerArg[];
    }
    export type ListenerArg = {
        label: string;
        form: FormUtil.CheckableValue;
    }

    export const getInitialListenerForm = (): ListenerForm => {
        return {
            dispatchId: { value: '', errors: [] },
            args: []
        }
    }

    export type ListenerTarget = {
        method: ListenerMethod;
        propName: string;
    }
    export type ListenerValue = {
        dispatchId: string;
        args: string[];
    }

    /**
     * リスナーデータをフォームのステートに変換して返す。
     * @param data ノードのデータ
     * @param dispatches 利用可能なディスパッチのリスト
     */
    export const getMappedListenerStateFromData = (
        data: Dispatch | undefined,
        dispatches: ScopeManager.DispatchItem[]
    ): ListenerForm => {
        if (data == undefined) return getInitialListenerForm();
        const dispatch = dispatches.find(d => d.id === data.dsptId);
        assert(dispatch != undefined, 'dispatch is undefined.');
        return {
            dispatchId: { value: data.dsptId, errors: [] },
            args: data.args.map(arg => {
                return {
                    label: dispatch.id,
                    form: { value: arg, errors: [] }
                }
            })
        };
    }

    /**
     * フォームのステートをリスナーのデータに変換して返す。
     * @param form 格納する対象のステート
     */
    export const getMappedListenerDataFromState = (
        form: ListenerForm
    ): Dispatch | undefined => {
        if (form.dispatchId.value === '') return undefined;
        return {
            dsptId: form.dispatchId.value,
            args: form.args.map(arg => arg.form.value)
        }
    }

    export const DispatchListeners = (props: {
        listeners: ListenerTarget[];
        owner: any;
        invalidate: () => void;
        dispatches: ScopeManager.DispatchItem[];
    }) => {
        // const [state, setState] = useState<ListenerValue[]>(new Array(props.listeners.length));
        const targetPointId: FormUtil.CheckableValue = props.owner['targetPointId'];
        assert(targetPointId != undefined, '呼び出し元のローカルステート[owner]にtargetPointIdが存在しない。');

        const jsxes = props.listeners.map((l, i) => {

            const form = props.owner[l.propName] as ListenerForm;

            const getArgForms = () => {
                return form.args.map((arg, j) => {
                    return (
                        <FormUtil.FormRecord key={j}
                            titleLabel={arg.label}
                            jsx={<FormUtil.TextField
                                width={400}
                                checkable={arg.form}
                                setCheckable={(checkable) => {
                                    arg.form = checkable;
                                    props.invalidate();
                                }}
                            />}
                        />
                    );
                });
            };
            return (
                <FormUtil.BorderPanel
                    key={i}
                    title={l.method}
                    innerJsx={<>
                        <FormUtil.FormRecord
                            titleLabel="Dispatch Id"
                            jsx={<FormUtil.Combobox
                                width={200}
                                checkable={form.dispatchId}
                                setCheckable={(checkable) => {
                                    form.dispatchId = checkable;
                                    const getInitialArgForm = (): ListenerArg[] => {
                                        if (form.dispatchId.value === '') return [];
                                        const dispatch = props.dispatches.find(d => d.id === form.dispatchId.value);
                                        assert(dispatch != undefined, 'dispatch is undefined.');
                                        return dispatch.args.map(arg => {
                                            return {
                                                label: arg.id,
                                                form: { value: '', errors: [] }
                                            };
                                        });
                                    }
                                    form.args = getInitialArgForm();
                                    props.invalidate();
                                }}
                                headBlank
                                list={props.dispatches.map(d => {
                                    return { value: d.id, labelText: d.id }
                                })}
                            />}
                        />
                        {getArgForms()}
                    </>}
                />
            );
        });
        return <>
            <FormUtil.FormRecord
                titleLabel="Local Id"
                jsx={<FormUtil.TextField
                    width={400}
                    checkable={targetPointId}
                    setCheckable={(checkable) => {
                        props.owner.targetPointId = checkable;
                        props.invalidate();
                    }}
                    isEnabled={true}
                />}
            />
            {jsxes}
        </>;
    }
}

export default TagUtil;