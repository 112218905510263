import assert from "assert";

namespace LocalStrageUtil {

    export type RegionDef = {
        defName: string;
        domain: string;
        salt: string;
    };
    
    export const DB_SERVER_DOMAIN = 'https://hkdev-db.glitch.me';
    
    export const DEFAULT_DEF: RegionDef = {
        defName: 'default',
        domain: DB_SERVER_DOMAIN,
        salt: ''
    };

    export const KEY_ACTIVE_REGION = 'active_region';
    export const KEY_REGION_DEFS = 'region_defs';

    export const getActiveRegionDef = () => {
        const active = localStorage.getItem(KEY_ACTIVE_REGION);
        const regions = localStorage.getItem(KEY_REGION_DEFS);
        assert(active != null && regions != null, 'ローカルストレージから値の取得に失敗。');
        const regionDefs = JSON.parse(regions) as RegionDef[];
        const list = [DEFAULT_DEF].concat(regionDefs);
        const activeDef = list.find(d => d.defName === active);
        assert(activeDef != null, 'activeDefがundefinedであってはならない。');
        return activeDef;
    }
}

export default LocalStrageUtil;