import { useContext, useEffect, useState, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import TreeUtil from "../../../../../../common/component/tree/treeUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import ModelEditDialog from "../../modelEditDialog";
import ModelElementUtil from "../../util/modelElementUtil";
import assert from "assert";
import VariableChooser from "../proc/variableChooser";

namespace NodeUpdate {

    export const UpdateDoMethods = ['assign', 'push', 'remove'] as const;
    // export type UpdateDoMethod = 'cache' | 'direct';
    export type UpdateDoMethod = typeof UpdateDoMethods[number];

    export const AssignTypes = ['object', 'direct'] as const;
    export type AssignType = typeof AssignTypes[number];

    export const CloneTypes = ['shallow', 'deep'] as const;
    // export type CloneTypes = 'cache' | 'direct';
    export type CloneType = typeof CloneTypes[number];

    export const ObjectTargets = ['state', 'cache', 'argument'] as const;
    // export type ObjectType = 'cache' | 'direct';
    export type ObjectTarget = typeof ObjectTargets[number];

    export type ObjectProps = {
        cloneType: CloneType;
        referType: ObjectTarget;
    }

    export interface Data extends VariableChooser.Chooser {
        method: UpdateDoMethod;
        referProps?: ObjectProps;
        assign: string;
        index?: string;
    }

    type LocalState = {
        rootTarget: FormUtil.CheckableValue;
        rootId: FormUtil.CheckableValue;
        innerFieldForms: VariableChooser.InnerField[];
        initUse: boolean;
        obj: FormUtil.CheckableValue;
        method: FormUtil.CheckableValue;
        valType: FormUtil.CheckableValue;
        cloneType: FormUtil.CheckableValue;
        direct: FormUtil.CheckableValue;
        referType: FormUtil.CheckableValue;
        index: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            rootTarget: { value: '', errors: [] },
            rootId: { value: '', errors: [] },
            innerFieldForms: [],
            initUse: false,
            obj: { value: '', errors: [] },
            method: { value: '', errors: [] },
            valType: { value: '', errors: [] },
            cloneType: { value: '', errors: [] },
            direct: { value: '', errors: [] },
            referType: { value: CloneTypes[0], errors: [] },
            index: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [models, states, args, caches] = useMemo(() => {
            const funcRootwrap = ModelElementUtil.getAppRootWrap(manageItems.focusNode);

            const parentWrap = (manageItems.focusNode.parent?.parent?.data) as ModelUtil.WrapElement;
            const isDispatch = parentWrap.type === 'func'

            // 自身がDispach配下のキャッシュである場合のみ取得する
            // const states = !isDispatch ? undefined : ModelElementUtil.getStatesFromApp(funcRootwrap);
            const states = ModelElementUtil.getStatesFromApp(funcRootwrap);
            const args = !isDispatch ? undefined : ModelElementUtil.getArgumentFromCurrent(manageItems.focusNode);

            const caches = ModelElementUtil.getRetentionCachesFromCurrent(manageItems.focusNode);
            const models = ModelElementUtil.getAppLocalDtypes(funcRootwrap);

            return [models, states, args, caches];
        }, []);

        const arg: VariableChooser.Argument = {
            localState, states, caches, models, extendAction: () => {
                localState.initUse = true;
            }
        }

        const last = useMemo(() => {
            return VariableChooser.getLastFieldInfo(arg);
        }, [localState.rootId, localState.innerFieldForms]);

        const getRequestType = (): null | ModelUtil.Field => {
            const last = VariableChooser.getLastFieldInfo(arg);
            // console.log(last);
            if (last == null) return null;
            let array = last.array;
            if (last.isIndex) array--;
            return {
                dataType: last.dataType,
                array,
                structId: last.structId
            };
        }
        const [requestType, directName] = useMemo(() => {
            const requestType = getRequestType();

            let directName = '-';
            if (requestType != null) {
                switch (requestType.dataType) {
                    case 'string': directName = 'Text'; break;
                    case 'number': directName = 'Number'; break;
                    case 'boolean': directName = 'Boolean'; break;
                    case 'name': directName = 'Name'; break;
                }
            }
            return [requestType, directName];
        }, [localState.rootId, localState.innerFieldForms]);

        useEffect(() => {
            const getTargetInitail = (): VariableChooser.RootTargetType | '' => {
                if (states == null) return 'cache'
                else if (caches == null) return 'state';
                return '';
            }

            localState.rootTarget.value = getTargetInitail();
            if (props.temp.data != null) {
                const data = props.temp.data as Data;

                VariableChooser.mappingDataToForm(arg, data);

                localState.method.value = data.method;

                const isRefer = data.referProps != undefined;
                (localState.valType.value as AssignType) = !isRefer ? 'direct' : 'object';
                if (isRefer) {
                    const objProps = data.referProps as ObjectProps;
                    localState.referType.value = objProps.referType;
                    localState.cloneType.value = objProps.cloneType;
                    localState.obj.value = data.assign;
                } else {
                    localState.direct.value = data.assign;
                }
                localState.index.value = data.index ?? '';
            }
            invalidate();
        }, []);

        const initMethod = () => {
            if (!localState.initUse) return;
            const requestType = getRequestType();
            if (requestType == null) {
                localState.method.value = '';
                localState.valType.value = '';
            } else if (requestType.array === 0) {
                (localState.method.value as UpdateDoMethod) = 'assign';
                (localState.valType.value as AssignType) = requestType.dataType !== 'struct' ? 'direct' : 'object';
            } else {
                (localState.method.value as UpdateDoMethod) = 'push';
                (localState.valType.value as AssignType) = requestType.dataType !== 'struct' ? 'direct' : 'object';
            }
            // console.log(localState.valType.value);
            localState.initUse = false;
            invalidate();
        }

        useEffect(() => {
            initMethod();
        }, [localState.rootId, localState.innerFieldForms]);

        const targetFroms = [
            localState.rootId, localState.direct, localState.obj, localState.index,
            localState.method, localState.referType, localState.cloneType
        ];
        const targetFormsModInner = useMemo(() => {
            return localState.innerFieldForms.map(inner => inner.form);
        }, [localState.innerFieldForms]);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            // if (targetFroms.find(form => form.errors.length > 0) != undefined) {
            //     setInputOK(false);
            //     return;
            // }
            if (targetFroms.concat(targetFormsModInner).find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);

            const levelProps: string[] = localState.innerFieldForms.map(inner => {
                return inner.form.value;
            });

            let objProps: ObjectProps | undefined = undefined;
            const isObject = localState.valType.value as AssignType === 'object';
            if (isObject) {
                objProps = {
                    referType: localState.referType.value as ObjectTarget,
                    cloneType: localState.cloneType.value as CloneType,
                }
            }
            const data: Data = {
                target: localState.rootTarget.value as VariableChooser.RootTargetType,
                rootId: localState.rootId.value,
                levelProps,
                method: localState.method.value as UpdateDoMethod,
                referProps: objProps,
                assign: !isObject ? localState.direct.value : localState.obj.value,
                index: DataUtil.blankToUndefined(localState.index.value)
            }
            setTempData(data);
        }, [...targetFroms, targetFormsModInner]);

        const isSetValueEnable = () => {
            return (localState.method.value as UpdateDoMethod) !== 'remove';
        }

        const isUseIndex = localState.method.value as UpdateDoMethod === 'remove';
        const isObject = localState.valType.value as AssignType === 'object';

        const getObjects = () => {
            switch (localState.referType.value as ObjectTarget) {
                case 'cache': return caches;
                case 'state': return states ?? [];
                case 'argument': return args ?? [];
            }
            return [];
        }

        /** 参照フォームの表示 */
        const referEnable = !isUseIndex && isObject;

        return (<>
            <VariableChooser.Component
                arg={arg}
                invalidate={invalidate}
            />
            <FormUtil.BorderPanel
                title="update"
                isVisible={requestType != null}
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Do Method"
                        jsx={<FormUtil.Combobox
                            width={210}
                            checkable={localState.method}
                            setCheckable={(checkable) => {
                                localState.method = checkable;
                                invalidate();
                            }}
                            list={UpdateDoMethods.
                                filter(item => {
                                    if (requestType == null) return false;
                                    else if (requestType.array === 0) return item === 'assign';
                                    return true;
                                }).map(item => {
                                    return { value: item, labelText: item }
                                })}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Value Type"
                        isVisible={!isUseIndex}
                        jsx={<FormUtil.Combobox
                            width={210}
                            checkable={localState.valType}
                            setCheckable={(checkable) => {
                                localState.valType = checkable;
                                invalidate();
                            }}
                            extend={() => {
                                if (localState.valType.value as AssignType === 'object') {
                                    (localState.referType.value as ObjectTarget) = 'cache';
                                }
                            }}
                            list={AssignTypes.
                                filter(item => {
                                    // if (localState.method.value === '' || requestType == null) return false;
                                    if (requestType == null) return false;
                                    const method = localState.method.value as UpdateDoMethod;
                                    const isModel = requestType.dataType === 'struct';
                                    // 指定対象がプリミティブの場合、ダイレクトを有効とする
                                    if (method === 'push' && !isModel || requestType.array === 0 && !isModel) return true;
                                    // それ以外はキャッシュ指定のみ許可
                                    return item === 'object';
                                }).map(item => {
                                    return { value: item, labelText: item }
                                })}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Index"
                        isVisible={isUseIndex}
                        jsx={<FormUtil.TextField
                            width={400}
                            checkable={localState.index}
                            setCheckable={(checkable) => {
                                localState.index = checkable;
                                invalidate();
                            }}
                            isEnabled={true}
                            validates={!isUseIndex ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkStringLength(value, 1, 64),
                                    errorType: "length"
                                },
                                // {
                                //     checker: (value) => ModelElementUtil.checkAppliedFmlNum(value, { states }),
                                //     errorType: "value"
                                // }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Refer"
                        isVisible={referEnable}
                        jsx={<>
                            <FormUtil.Combobox
                                width={140}
                                checkable={localState.referType}
                                setCheckable={(checkable) => {
                                    localState.referType = checkable;
                                    invalidate();
                                }}
                                list={ObjectTargets.map(item => {
                                    return { value: item, labelText: item }
                                })}
                                validates={[]}
                            />
                            <FormUtil.Combobox
                                marginLeft={4}
                                width={284}
                                checkable={localState.obj}
                                setCheckable={(checkable) => {
                                    localState.obj = checkable;
                                    invalidate();
                                }}
                                headBlank
                                list={getObjects()
                                    .filter(c => {
                                        if (requestType == null) return false;
                                        let array = requestType.array;
                                        if (localState.method.value as UpdateDoMethod === 'push') {
                                            array--;
                                        }
                                        if (c.array !== array || c.dataType !== requestType.dataType) return false;
                                        if (requestType.dataType === 'struct') {
                                            if (requestType.structId !== c.structId) return false;
                                        }
                                        return true;
                                    })
                                    .map(c => ModelUtil.getFieldListItem(c))}
                                validates={!referEnable ? [] : [
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    }
                                ]}
                            />
                        </>}
                    />
                    <FormUtil.FormRecord
                        titleLabel={directName}
                        isVisible={isSetValueEnable() && !isObject}
                        jsx={(() => {
                            if (requestType == null || requestType.dataType === 'struct') return <></>;
                            switch (requestType.dataType) {
                                case 'string': return (<FormUtil.TextField
                                    width={284}
                                    checkable={localState.direct}
                                    setCheckable={(checkable) => {
                                        localState.direct = checkable;
                                        invalidate();
                                    }}
                                    validates={isObject ? [] : [
                                        {
                                            checker: (value) => ValidateUtil.checkRequired(value),
                                            errorType: "required"
                                        }
                                    ]}
                                />);
                                case 'number': return (<FormUtil.TextField
                                    width={284}
                                    checkable={localState.direct}
                                    setCheckable={(checkable) => {
                                        localState.direct = checkable;
                                        invalidate();
                                    }}
                                    validates={isObject ? [] : [
                                        {
                                            checker: (value) => ValidateUtil.checkRequired(value),
                                            errorType: "required"
                                        }
                                    ]}
                                />);
                                case 'color': return (<FormUtil.TextField
                                    width={284}
                                    checkable={localState.direct}
                                    setCheckable={(checkable) => {
                                        localState.direct = checkable;
                                        invalidate();
                                    }}
                                    validates={isObject ? [] : [
                                        {
                                            checker: (value) => ValidateUtil.checkRequired(value),
                                            errorType: "required"
                                        }
                                    ]}
                                />);
                                case 'boolean': return (<FormUtil.SwitchTwoFace
                                    label1="On"
                                    label2="Off"
                                    width={150}
                                    rate1={50}
                                    rate2={50}
                                    isUse={localState.direct.value === 'true'}
                                    callback={() => {
                                        const flag = localState.direct.value === 'true';
                                        localState.direct.value = String(!flag);
                                        invalidate();
                                    }}
                                />);
                                case 'name': return (<FormUtil.Combobox
                                    marginLeft={4}
                                    width={200}
                                    checkable={localState.direct}
                                    setCheckable={(checkable) => {
                                        localState.direct = checkable;
                                        invalidate();
                                    }}
                                    headBlank
                                    list={(() => {
                                        console.log(requestType.structId);
                                        const list = models.find(m => m.id === requestType.structId);
                                        return list?.fields ?? [];
                                    })()
                                        .map(c => {
                                            return {
                                                labelText: c.id, value: c.id
                                            };
                                        })
                                    }
                                    validates={[
                                        {
                                            checker: (value) => ValidateUtil.checkRequired(value),
                                            errorType: "required"
                                        }
                                    ]}
                                />);
                                default: return <></>;
                            }
                        })()}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Copy"
                        isVisible={!isUseIndex
                            && (requestType != null && (requestType.array > 0 || requestType.dataType === 'struct'))}
                        jsx={<FormUtil.Combobox
                            width={210}
                            checkable={localState.cloneType}
                            setCheckable={(checkable) => {
                                localState.cloneType = checkable;
                                invalidate();
                            }}
                            list={CloneTypes.map(item => {
                                return { value: item, labelText: item }
                            })}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'update';
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default NodeUpdate;
