import assert from "assert";

namespace DataUtil {

    export type KeyValue = {
        key: string;
        value: string;
    }

    // const VALUE_REG_EX = /\$\{.*?\}/g;
    export const VALUE_REG_EX = /__.*?__/g;
    export const VALUE_REG_EX_INNER = /__(.*?)__/;

    export type Flag = '1';

    export const blankToUndefined = (value: string) => {
        return value === '' ? undefined : value;
    }

    export const flag = (flag: '1' | undefined) => {
        return flag === '1';
    }

    export const convertKeyValuesToLineText = (keyValues: KeyValue[]) => {
        if (keyValues == undefined) return '';
        return keyValues.map(kv => `${kv.key}:${kv.value}`).join('\n');
    };

    export const convertLineTextToKeyValues = (lineText: string) => {
        let hasError = false;
        const keyValues: KeyValue[] = [];
        if (lineText !== '') {
            lineText.split('\n').some(attr => {
                const items = attr.split(':');
                if (items.length === 2) {
                    keyValues.push({
                        key: items[0], value: items[1]
                    })
                } else {
                    hasError = true;
                    return 1;
                }
            });
        }
        if (!hasError) {
            return keyValues;
        }
    }

    export const getJsonClone = (json: object) => {
        return JSON.parse(JSON.stringify(json));
    }

    export const applyFormula = (value: string) => {
        let ret: any = value;
        try {
            ret = Function(`return (${value})`)();
        } catch (error) {
            // console.log(value);
        }
        return ret;
    }

    export const applyFormulaArgs = (args: any[], formula: string) => {
        let ret: any = formula;
        try {
            const func = new Function(...(args.map((a, i) => `__arg_${i}`)), `return (${formula})`);
            ret = func(...args);
        } catch (error) {
            // console.log(value);
        }
        return ret;
    }

    export const swapOrder = (list: any[], targetIndex: number, offset: number) => {
        const swapNode = list[targetIndex];
        list[targetIndex] = list[targetIndex + offset];
        list[targetIndex + offset] = swapNode;
    }

    /**
    * システム日付の取得
    * @returns システム日付(XXXX/XX/XX)
    */
    export const getSystemDate = () => {
        let today = new Date();
        const year = ('0000' + today.getFullYear()).slice(-4);
        const month = ('00' + (today.getMonth() + 1)).slice(-2);
        const day = ('00' + today.getDate()).slice(-2);
        return year + '-' + month + '-' + day;
    };

    /**
    * システム日付・時間の取得
    * @returns システム日付(XXXX-XX-XX XX:XX:XX)
    */
    export const getSystemTime = () => {
        let today = new Date();
        const year = ('0000' + today.getFullYear()).slice(-4);
        const month = ('00' + (today.getMonth() + 1)).slice(-2);
        const day = ('00' + today.getDate()).slice(-2);
        const hour = ('00' + today.getHours()).slice(-2);
        const minute = ('00' + today.getMinutes()).slice(-2);
        const seconds = ('00' + today.getSeconds()).slice(-2);
        return `${year}-${month}-${day} ${hour}:${minute}:${seconds}`;
    };

    /**
    * 日付をXXXX/XX/XXの形に変更
    * @returns 日付(XXXX/XX/XX)
    */
    export const getStringDate = (date: Date) => {
        const year = ('0000' + date.getFullYear()).slice(-4);
        const month = ('00' + (date.getMonth() + 1)).slice(-2);
        const day = ('00' + date.getDate()).slice(-2);
        return year + '/' + month + '/' + day;
    }
}

export default DataUtil;