import { useState } from "react";
import ModelUtil from "../util/modelUtil";
import ModelEditDialog from "../modelEditDialog";
import TreeUtil from "../../../../../common/component/tree/treeUtil";
import assert from "assert";

abstract class AbstractModelEditor {

    constructor() {
    }

    public abstract getNodeType(): ModelUtil.NodeType;

    public createAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
        const node = manageItems.focusNode;
        (node.data as ModelUtil.WrapElement).data = tempData;
        manageItems.invalidate();
    }

    public createExtendAction(node: TreeUtil.ElementNode): void {
    }

    public modifyAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
        const node = manageItems.focusNode;
        (node.data as ModelUtil.WrapElement).data = tempData;
        manageItems.invalidate();
    }

    public modifyExtendAction(props: ModelEditDialog.ExtendModProps): void {
    }

    public deleteExtendAction(node: TreeUtil.ElementNode): void {
    }

    public orderExtendAction(node: TreeUtil.ElementNode, offset: number): void {
    }

    public abstract getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element;


    protected getCurIndex(node: TreeUtil.ElementNode): number {
        assert(node.parent != undefined, 'node.parentが存在しない。');
        const index = node.parent.children.findIndex(child => child == node);
        assert(index !== -1, '自身のノードが親の配下に存在しない。');
        return index;
    }
}

export default AbstractModelEditor;