import DataUtil from "../../../common/dataUtil";
import ReaderUtil from "./readerUtil";

namespace SystemFunctionUtil {

    export type DefName =
        'sys_date_time' |
        'sys_date' |
        'sys_time' |
        'invalidate'
        ;
    export const getMappedFuncValue = (name: DefName, buildProps: ReaderUtil.BuildProps) => {
        switch (name) {
            case 'sys_date_time': return DataUtil.getSystemTime();
            case 'sys_date': return DataUtil.getSystemDate();
            case 'sys_time': return '';
            case 'invalidate': return buildProps.invalidate;
        }
    }
}

export default SystemFunctionUtil;