import { useContext } from "react";
import styled from "styled-components";
import { GlobalContext } from "./systemEntry";
import { MdOutlineDeveloperBoard } from 'react-icons/md';
import { AiOutlineProject, AiOutlineHome } from 'react-icons/ai';
import { VscLibrary } from "react-icons/vsc";
import { MAIN_FRAME_MARGIN } from "../../../def/systemConst";
import DevelopChannel from "../develop/developChannel";
import LibraryChannel from "../project/libraryChannel";
import ChannelPane from "../../../common/component/tab/channelPane";
import CacheChannel from "../cache/cacheChannel";

const MainFrame = () => {
    const { store, dispatcher } = useContext(GlobalContext);

    const isEditDevManage = store.develop.isEditManage;
    const isLogin = store.user != null;
    return (
        <_Wrap>
            <ChannelPane.Component
                tabElements={[
                    { name: 'Library', icon: <VscLibrary />, isEnable: isLogin && !isEditDevManage, keepInstance: false, cont: <LibraryChannel /> },
                    { name: 'Develop', icon: <MdOutlineDeveloperBoard />, isEnable: true, cont: <DevelopChannel /> },
                    // { name: 'Apps', icon: <FaSitemap />, isEnable: hasProject, cont: <AppChannel.Component />, keepInstance: false },
                    // { name: 'Region', icon: <FaServer />, isEnable: !isEditDevManage, cont: <RegionChannel.Component/>, keepInstance: false },
                    { name: 'Cache', icon: <AiOutlineHome />, isEnable: !isEditDevManage, cont: <CacheChannel.Component />, keepInstance: false },
                ]}
                activeNo={store.system.channelIndex}
                selectTabIndex={(index: number) => {
                    store.system.channelIndex = index;
                    dispatcher.updateStore();
                }}
            />
        </_Wrap>
    );
}

export default MainFrame;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    margin: ${MAIN_FRAME_MARGIN}px 0 0 ${MAIN_FRAME_MARGIN}px;
    width: calc(100% - ${MAIN_FRAME_MARGIN * 2}px);
    height: calc(100% - ${MAIN_FRAME_MARGIN * 2}px);
    background-color: transparent;
    /* background-color: #c3d8dd6f; */
    /* border: solid 1px black;
    border-radius: 5px; */
    /* box-shadow: 0 2px 14px 4px #000b3a48; */
`;