import styled from "styled-components";
import Styles from "../../../def/design/styles";
import TreeUtil from "../../../common/component/tree/treeUtil";
import { useContext, useEffect, useMemo, useState } from "react";
import FormUtil from "../../../common/component/form/formUtiil";
import { GlobalContext } from "../entry/systemEntry";
import ModelElementUtil from "../develop/function/util/modelElementUtil";
import ChannelPane from "../../../common/component/tab/channelPane";
import { TabbedPane } from "../../../common/component/tab/tabbedPane";
import assert from "assert";
import ServerUtil from "../../../common/serverUtil";
import ValidateUtil from "../../../common/component/form/validateUtil";
import ModelUtil from "../develop/function/util/modelUtil";
import LocalStrageUtil from "../../util/localStrageUtil";

namespace CacheChannel {

    export const Component = () => {
        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const [ls, setLs] = useState<{
            active: FormUtil.CheckableValue;
            focus: FormUtil.CheckableValue;
            defName: FormUtil.CheckableValue;
            domain: FormUtil.CheckableValue;
            salt: FormUtil.CheckableValue;

            defList: LocalStrageUtil.RegionDef[];
            isEdit: boolean;
        }>({
            active: { value: '', errors: [] },
            focus: { value: '', errors: [] },
            defName: { value: '', errors: [] },
            domain: { value: '', errors: [] },
            salt: { value: '', errors: [] },

            defList: [],
            isEdit: false
        });

        useEffect(() => {
            let activeRegion = localStorage.getItem('active_region');
            if (activeRegion == null) {
                localStorage.setItem('active_region', 'default');
                activeRegion = 'default';
            }
            ls.active.value = activeRegion;
            ls.focus.value = activeRegion;

            let list: LocalStrageUtil.RegionDef[] = [];
            const regionDefs = localStorage.getItem('region_defs');
            if (regionDefs == null) {
                localStorage.setItem('region_defs', JSON.stringify(list));
            } else {
                list = JSON.parse(regionDefs) as LocalStrageUtil.RegionDef[];
            }
            ls.defList = list;
            invalidate();
        }, []);

        const invalidate = () => { setLs({ ...ls }) }

        const acceptActive = !ls.isEdit && ls.focus.value !== '' && ls.focus.value !== ls.active.value;
        const acceptEdit = acceptActive && ls.focus.value !== 'default';
        const regionJsx = <>
            <>
                <FormUtil.FormRecord
                    labelWidth={180}
                    titleLabel="Active Region"
                    jsx={
                        <FormUtil.FixedText
                            width={400}
                            value={ls.active.value}
                        />
                    }
                />
                <FormUtil.FormRecord
                    labelWidth={180}
                    titleLabel="Target Define"
                    jsx={
                        <FormUtil.Combobox
                            width={400}
                            isReadOnly={ls.isEdit}
                            checkable={ls.focus}
                            setCheckable={(checkable) => {
                                ls.focus = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={[LocalStrageUtil.DEFAULT_DEF].concat(ls.defList).map(item => {
                                return { value: item.defName, labelText: (ls.active.value === item.defName ? '*' : '') + item.defName }
                            })}
                        />
                    }
                />

                <FormUtil.ButtonRecord
                    align="left"
                    buttons={[
                        {
                            label: 'Create', width: 100, isEnable: !ls.isEdit && ls.focus.value === '', callback: () => {
                                ls.isEdit = true;
                                ls.defName.value = '';
                                ls.domain.value = '';
                                ls.salt.value = '';
                                invalidate();
                            }
                        },
                        {
                            label: 'Edit', width: 80, isEnable: acceptEdit, callback: () => {
                                ls.isEdit = true;
                                const def = ls.defList.find(d => d.defName === ls.focus.value);
                                assert(def != undefined, 'defがundefinedであってはらならない。');
                                ls.defName.value = def.defName;
                                ls.domain.value = def.domain;
                                ls.salt.value = def.salt;
                                invalidate();
                            }
                        },
                        {
                            label: 'Delete', width: 100, isEnable: acceptEdit, callback: () => {
                                const removeIndex = ls.defList.findIndex(d => d.defName === ls.focus.value);
                                assert(removeIndex !== -1, 'removeIndexが-1であってはならない。');
                                ls.defList.splice(removeIndex, 1);
                                invalidate();
                                ls.focus.value = '';
                                localStorage.setItem('region_defs', JSON.stringify(ls.defList));
                            }
                        },
                        {
                            label: 'Active', width: 100, isEnable: acceptActive, callback: () => {
                                ls.active.value = ls.focus.value;
                                invalidate();
                                localStorage.setItem('active_region', ls.active.value);
                            }
                        },
                    ]}
                />
                <FormUtil.FormRecord
                    labelWidth={180}
                    titleLabel="Define Name"
                    jsx={
                        !ls.isEdit ?
                            <FormUtil.FixedText
                                width={400}
                                value={(() => {
                                    if (ls.focus.value === '') return '-';
                                    if (ls.focus.value === 'default') return 'default'
                                    const def = ls.defList.find(d => d.defName === ls.focus.value);
                                    assert(def != undefined, 'defがundefinedであってはらならない。');
                                    return def.defName;
                                })()}
                            /> :
                            <FormUtil.TextField
                                width={400}
                                checkable={ls.defName}
                                setCheckable={(checkable) => {
                                    ls.defName = checkable;
                                    invalidate();
                                }}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    },
                                    {
                                        checker: (value) => ValidateUtil.checkStringLength(value, 1, 24),
                                        errorType: "length"
                                    },
                                    {
                                        checker: (value) => ModelUtil.checkIdChars(value),
                                        errorType: "value"
                                    },
                                    {
                                        checker: (value) => {
                                            const list = [LocalStrageUtil.DEFAULT_DEF].concat(ls.defList);
                                            if (ls.focus.value !== '') {
                                                const removeIndex = list.findIndex(d => d.defName === ls.focus.value);
                                                assert(removeIndex !== -1, 'removeIndexが-1であってはならない。');
                                                list.splice(removeIndex, 1);
                                            }
                                            return list.find(d => d.defName === value) == undefined;
                                        },
                                        errorType: "relation"
                                    }
                                ]}
                            />
                    }
                />
                <FormUtil.FormRecord
                    labelWidth={180}
                    titleLabel="Domain"
                    jsx={
                        !ls.isEdit ?
                            <FormUtil.FixedText
                                width={400}
                                value={(() => {
                                    if (ls.focus.value === '') return '-';
                                    if (ls.focus.value === 'default') return '********';
                                    const def = ls.defList.find(d => d.defName === ls.focus.value);
                                    assert(def != undefined, 'defがundefinedであってはらならない。');
                                    return def.domain;
                                })()}
                            /> :
                            <FormUtil.TextField
                                width={400}
                                checkable={ls.domain}
                                setCheckable={(checkable) => {
                                    ls.domain = checkable;
                                    invalidate();
                                }}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkRequired(value),
                                        errorType: "required"
                                    },
                                    {
                                        checker: (value) => ValidateUtil.checkStringLength(value, 1, 64),
                                        errorType: "length"
                                    }
                                ]}
                            />
                    }
                />
                <FormUtil.FormRecord
                    labelWidth={180}
                    titleLabel="Crypt Salt"
                    jsx={
                        !ls.isEdit ?
                            <FormUtil.FixedText
                                width={400}
                                value={(() => {
                                    if (ls.focus.value === '') return '-';
                                    if (ls.focus.value === 'default') return '********';
                                    const def = ls.defList.find(d => d.defName === ls.focus.value);
                                    assert(def != undefined, 'defがundefinedであってはらならない。');
                                    return def.salt;
                                })()}
                            /> :
                            <FormUtil.TextField
                                width={400}
                                checkable={ls.salt}
                                setCheckable={(checkable) => {
                                    ls.salt = checkable;
                                    invalidate();
                                }}
                                validates={[
                                    {
                                        checker: (value) => ValidateUtil.checkStringLength(value, 0, 8),
                                        errorType: "length"
                                    },
                                    {
                                        checker: (value) => ModelUtil.checkIdChars(value),
                                        errorType: "value"
                                    }
                                ]}
                            />
                    }
                />
                <FormUtil.ButtonRecord
                    align="left"
                    buttons={[
                        {
                            label: 'Cancel', width: 110, isEnable: ls.isEdit, callback: () => {
                                ls.isEdit = false;
                                invalidate();
                            }
                        },
                        {
                            label: 'Regist', width: 110, isEnable: ls.isEdit, callback: () => {
                                const isCreate = ls.focus.value === '';
                                ls.isEdit = false;
                                const newDef: LocalStrageUtil.RegionDef = {
                                    defName: ls.defName.value,
                                    domain: ls.domain.value,
                                    salt: ls.salt.value
                                };
                                if (isCreate) {
                                    ls.defList.push(newDef);
                                } else {
                                    const modIndex = ls.defList.findIndex(d => d.defName === ls.focus.value);
                                    assert(modIndex !== -1, 'modIndexが-1であってはらならない。');
                                    ls.defList[modIndex] = newDef;
                                }
                                localStorage.setItem('region_defs', JSON.stringify(ls.defList));
                                ls.focus.value = '';
                                invalidate();
                            }
                        },
                    ]}
                />
            </>
        </>;

        return (
            <Styles._Div margin={Styles.DivMarginType.NARROW} color="#858585">
                <TabbedPane
                    tabElements={[
                        { name: 'Region', cont: regionJsx, enable: true },
                        { name: 'Contents', cont: <></>, enable: false },
                        { name: 'Projects', cont: <></>, enable: false },
                    ]}
                    activeNo={0}
                // activeNo={store.system.devTabIndex}
                // selectTabIndex={(index: number) => {
                //     store.system.devTabIndex = index;
                //     dispatcher.updateStore();
                // }}
                />
                <_LeftDiv>
                </_LeftDiv>
            </Styles._Div>
        );
    };

};

export default CacheChannel;

const LEFT_DIV_WIDTH = 730;
export const _Header = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 40px;
    /* background-color: #b67777; */
`;
export const _LeftDiv = styled.div<{
}>`
    display: inline-block;
    width: ${LEFT_DIV_WIDTH}px;
    height: calc(100% - 40px);
    /* background-color: #b67777; */
`;
