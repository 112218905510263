import { useContext, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "../entry/systemEntry";
import assert from "assert";
import FormUtil from "../../../common/component/form/formUtiil";
import ServerUtil from "../../../common/serverUtil";

namespace LibDevTab {

    type LocalState = {
        focusIndex: number;
    }

    export const Componnet = () => {

        const { store, setStore, dispatcher } = useContext(GlobalContext);

        const [localState, setLocalState] = useState<LocalState>({
            focusIndex: -1
        });
        const invalidate = () => setLocalState({ ...localState });

        assert(store.user != null, 'store.userはnullであってはならない');
        const devmdList = store.user.devmdList;

        const devmdJsxList = useMemo(() => {
            return devmdList.map((d, i) => {
                return (
                    <_ItemFrame key={i}
                        isFocus={localState.focusIndex === i}
                        onClick={() => {
                            localState.focusIndex = i;
                            // console.log(localState.focusIndex);
                            invalidate();
                        }}
                    >
                        <_Col width={50}>{d.seq}</_Col>
                        <_Col width={300}>{d.name}</_Col>
                    </_ItemFrame>
                );
            })
        }, [localState.focusIndex]);

        return (
            <_Wrap>
                <_Left>
                    <_ListFrame>
                        {devmdJsxList}
                    </_ListFrame>
                    <FormUtil.ButtonRecord buttons={[
                        {
                            label: 'Open',
                            isEnable: localState.focusIndex != -1 && store.develop.manageData == null,
                            callback: () => {
                                const devmd = devmdList[localState.focusIndex];
                                store.develop.manageData = devmd;
                                // const json = ServerUtil.b64_to_utf8(devmd.source);
                                const json = ServerUtil.unZip(devmd.source);
                                store.project = JSON.parse(json);
                                dispatcher.updateStore();
                            }
                        },
                        {
                            label: 'Delete',
                            isEnable: localState.focusIndex != -1,
                            callback: () => {

                            }
                        },
                    ]} />
                </_Left>
                <_Right></_Right>
            </_Wrap>
        );
    }
}
export default LibDevTab;


const _Wrap = styled.div<{
}>`
    display: inline-block;
    position: relative;
    margin: 4px 0 0 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background-color: #cab7b7;
`;
const _Left = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    background-color: #b7cac0;
    vertical-align: top;
`;
const _ListFrame = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: calc(100% - 40px);
    background-color: #d2e8e9;
    vertical-align: top;
`;
const _ItemFrame = styled.div<{
    isFocus: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: 40px;
    background-color: #55d65c;
    ${props => !props.isFocus ? '' : css`
        background-color: #dae480;
    `}
    margin-top: 1px;
    vertical-align: top;
    &:hover {
        background-color: #70e676;
        ${props => !props.isFocus ? '' : css`
            background-color: #ced879e3;
        `}
    }
`;
const _Col = styled.div<{
    width: number;
}>`
    display: inline-block;
    width: ${props => props.width}px;
    height: 34px;
    background-color: #b9f3bc7d;
    margin-top: 3px;
    margin-left: 3px;
    vertical-align: top;
    border-radius: 2px;
    font-size: 24px;
    font-weight: 600;
    color: #214892;
`;
const _Right = styled.div<{
}>`
    display: inline-block;
    width: 50%;
    height: 100%;
    background-color: #b7b7ca;
    vertical-align: top;
`;
