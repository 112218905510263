import { useEffect, useState, useContext, useMemo } from "react";
import AbstractModelEditor from "../abstractModelEditor";
import FormUtil from "../../../../../../common/component/form/formUtiil";
import ValidateUtil from "../../../../../../common/component/form/validateUtil";
import ModelUtil from "../../util/modelUtil";
import DataUtil from "../../../../../../common/dataUtil";
import ModelEditDialog from "../../modelEditDialog";
import { GlobalContext } from "../../../../entry/systemEntry";
import assert from "assert";
import ModelElementUtil from "../../util/modelElementUtil";

namespace nodeView {

    export type Data = {
        entry?: string;
        isFixed?: DataUtil.Flag;
        fixedProps?: FixedProps;
    }
    export type FixedProps = {
        width: number;
        height: number;
    }
    type LocalState = {
        entryId: FormUtil.CheckableValue;
        isFixed: boolean;
        width: FormUtil.CheckableValue;
        height: FormUtil.CheckableValue;
    }

    const Component = (props: {
        temp: ModelEditDialog.TempPorps;
        setTemp: (temp: ModelEditDialog.TempPorps) => void;
    }): JSX.Element => {
        const { store, dispatcher } = useContext(GlobalContext);
        const manageItems = store.system.freeCache as ModelUtil.ManageItems;

        const [comps] = useMemo(() => {
            const appRootwrap = ModelElementUtil.getAppRootWrap(manageItems.focusNode);

            const comps = ModelElementUtil.getCompsFromApp(appRootwrap);
            /** 引数が存在しないコンポーネントのみ表示する */
            const filteredComps = comps.filter(c => {
                const propsWrap = ModelElementUtil.getInnerWrapFixed({ type: 'compdef', data: c }, 'props');
                return (propsWrap.data as ModelUtil.NodeProps).props.length === 0;
            });
            return [filteredComps];
        }, []);

        const [localState, setLocalState] = useState<LocalState>({
            entryId: { value: '', errors: [] },
            isFixed: true,
            width: { value: '', errors: [] },
            height: { value: '', errors: [] },
        });
        const invalidate = () => setLocalState({ ...localState });
        const setInputOK = (inputOK: boolean) => props.setTemp({ ...props.temp, inputOK });
        const setTempData = (data: object) => props.setTemp({ data, inputOK: true });

        const targetFroms = [localState.entryId, localState.width, localState.height];

        useEffect(() => {
            if (props.temp.data != null) {
                const data = props.temp.data as Data;
                localState.entryId.value = data.entry ?? '';
                localState.isFixed = DataUtil.flag(data.isFixed);
                if (data.fixedProps != undefined) {
                    localState.width.value = data.fixedProps.width.toString();
                    localState.height.value = data.fixedProps.height.toString();
                }
                invalidate();
            }
        }, []);

        useEffect(() => {
            // 1つでも入力エラーがあると処理しない
            if (targetFroms.find(form => form.errors.length > 0) != undefined) {
                setInputOK(false);
                return;
            }
            setInputOK(true);
            let fixedProps: FixedProps | undefined = undefined;
            if (localState.isFixed) {
                fixedProps = {
                    width: Number(localState.width.value),
                    height: Number(localState.height.value),
                };
            }
            const entryId = localState.entryId.value;
            const data: Data = {
                entry: entryId === '' ? undefined : entryId,
                isFixed: localState.isFixed ? '1' : undefined,
                fixedProps
            }
            setTempData(data);
        }, targetFroms);

        return (<>
            <FormUtil.BorderPanel
                title="info"
                innerJsx={<>
                    <FormUtil.FormRecord
                        titleLabel="Entry"
                        jsx={<FormUtil.Combobox
                            width={300}
                            checkable={localState.entryId}
                            setCheckable={(checkable) => {
                                localState.entryId = checkable;
                                invalidate();
                            }}
                            headBlank
                            list={comps.map(comp => {
                                return { value: comp.id, labelText: comp.id };
                            })}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Fixed"
                        jsx={<FormUtil.SwitchTwoFace
                            label1="OFF"
                            label2="ON"
                            width={160}
                            rate1={50}
                            rate2={50}
                            isUse={localState.isFixed}
                            callback={() => {
                                localState.isFixed = !localState.isFixed;
                                if (!localState.isFixed) {
                                    localState.width.value = '';
                                    localState.height.value = '';
                                }
                                invalidate();
                            }}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Fixed Width"
                        isEnabled={localState.isFixed}
                        jsx={<FormUtil.TextField
                            width={100}
                            checkable={localState.width}
                            isNumber
                            setCheckable={(checkable) => {
                                localState.width = checkable;
                                invalidate();
                            }}
                            validates={!localState.isFixed ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, 300, 1500),
                                    errorType: "length"
                                }
                            ]}
                        />}
                    />
                    <FormUtil.FormRecord
                        titleLabel="Fixed Height"
                        isEnabled={localState.isFixed}
                        jsx={<FormUtil.TextField
                            width={100}
                            checkable={localState.height}
                            isNumber
                            setCheckable={(checkable) => {
                                localState.height = checkable;
                                invalidate();
                            }}
                            validates={!localState.isFixed ? [] : [
                                {
                                    checker: (value) => ValidateUtil.checkRequired(value),
                                    errorType: "required"
                                },
                                {
                                    checker: (value) => ValidateUtil.checkNumberRange(value, 300, 1500),
                                    errorType: "length"
                                }
                            ]}
                        />}
                    />
                </>}
            />
        </>);
    }

    export class Editor extends AbstractModelEditor {

        getNodeType(): ModelUtil.NodeType {
            return 'view';
        }

        override modifyAction(manageItems: ModelUtil.ManageItems, tempData: object): void {
            const node = manageItems.focusNode;
            (node.data as ModelUtil.WrapElement).data = tempData;
            manageItems.invalidate();
        }

        override getForm(temp: ModelEditDialog.TempPorps, setTemp: (tempData: ModelEditDialog.TempPorps) => void): JSX.Element {
            return (<Component temp={temp} setTemp={setTemp} />);
        }
    }
}

export default nodeView;
