import ScopeManager from "../../contents/develop/function/option/scopeManager";
import ModelElementUtil from "../../contents/develop/function/util/modelElementUtil";
import ModelUtil from "../../contents/develop/function/util/modelUtil";

namespace StoreSystem {

    export type Param = {
        channelIndex: number;
        devTabIndex: number;
        isSplash: boolean;
        dialog: null | JSX.Element;
        canDialogClose: boolean;
        /** スマートホンモード */
        isSPMode: boolean;
        logoutActions: (() => void)[];

        freeCache: object | null;
        scope: Scope;
        clipboad: null | Clipboard;

        reader: Reader;
    }

    export type Clipboard = {
        source: ModelUtil.WrapElement;
        cutAction: () => void;
    }

    export type Scope = {
        dtypeItems?: ModelElementUtil.DtypeInfo[];
        stateValueKeys?: ScopeManager.ValueKeyField[];
        cacheValueKeys?: ScopeManager.ValueKeyField[];
        argValueKeys?: ScopeManager.ValueKeyField[];
        propFieldValueKeys?: ScopeManager.ValueKeyField[];
        prpclbkItems?: ScopeManager.DispatchItem[];
        iterateValueKeys?: ScopeManager.ValueKeyField[];
        dispatchItems?: ScopeManager.DispatchItem[];
        closureItems?: ScopeManager.DispatchItem[];
        actionItems?: ScopeManager.ActionItem[];
        styleItems?: ScopeManager.StyleItem[];
        stlargItems?: ScopeManager.StlargItem[];
    }

    export const getBlankScope = (): Scope => {
        return {};
    }

    export type Env = {
        serverUrl: string;
        screenRate: number;
    }

    export type Reader = {
        console: string;
    } 
}

export default StoreSystem;